<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
  

          <h3 class="bs-title"  *ngIf="addflag == 0">  Wellness<span class="spanbs-title"> Benefit Client</span></h3>
          <h3 class="bs-title"  *ngIf="addflag == 1">  Wellness Benefit Client Edit
            <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
          </h3>
          
          <hr class="mb-2 mt-4" style="height: 0px">
  
          <!-- Add -->
          <div class="card" *ngIf="addflag == 0">
            <div class="card-body">
                <form [formGroup]="basicInfo">
                    <div class="row">
          
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="client_id">Client<span class="redstar">*</span></label>
                                <ng-select formControlName="client_id" id="client_id" (change)="getInfo()" 
                                    [items]="InfoArrayClient"
                                    bindLabel="name"
                                    bindValue="id"
                                    [placeholder]="'Select Client'"
                                    [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.client_id.errors}">
                                </ng-select>
                                <ng-container
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors">
                            <p class="error"
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.client_id.errors.required">
                            Client is required
                            </p>
                          </ng-container>
                            </div>
                        </div>
        
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="wellness_benefit">Wellness Benefit<span class="redstar">*</span></label>
                                <select class="form-select" formControlName="wellness_benefit" id="wellness_benefit" [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.wellness_benefit.errors}"
                                required>
                                    <option value="">Select Benefit Wellness</option>
                                    <option *ngFor="let wellness of InfoArrayWellness; let i = index" value="{{wellness.id}}">
                                        {{wellness.partner_name}} - {{wellness.benefit_name}}</option>
                                </select>
                                <ng-container
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.wellness_benefit.errors">
                            <p class="error"
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.wellness_benefit.errors.required">
                            Wellness Benefit is required
                            </p>
                          </ng-container>
                            </div>
                        </div>
                    
                        <div class="col-sm-4">
                            <button id="submitbutton" type="button" class="btn btn-primary mt-4" (click)="onSubmitAdd()" role="button">Save</button>
                        </div>
        
                    </div>
                  </form>
            </div>
          </div>
          
  
          <hr  *ngIf="addflag == 0">
  
          <!-- List -->
          <div *ngIf="addflag == 0">
            <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th style="text-align: center;">Client</th>
                        <th style="text-align: center;"> Wellness Partner</th>
                        <th style="text-align: center;">Benefit Name</th>
                        <!-- <th style="text-align: center;">URL</th> -->
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['employer_name']}}</td>
                        <td>{{group['partner_name']}}</td>
                        <td>{{group['benefit_name']}}</td>
                        <!-- <td>{{group['benefit_url']}}</td>                                             -->
                        <td>
                          <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                          <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>
                        <td style="width: 15%" align="center">
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img src="../../../assets/img/selectproduct/delete.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" />
                            <img src="../../../assets/img/selectproduct/deactive.png" *ngIf="group['is_active']==1" (click)="openPopupDeactivate(group)" id="deactive"  class="ml-4" role="button" title="Deactive" />
                            <img src="../../../assets/img/selectproduct/active.png" *ngIf="group['is_active']==0" (click)="openPopupActivate(group)" id="active"  class="ml-4" role="button" title="Active" />
                        </td>        
                    </tr>
                </tbody>
            </table>
          </div>

          <!-- Edit -->
            <div class="card" *ngIf="addflag == 1">
                <div class="card-body">
                    <form [formGroup]="EditInfo" >
                        <div class="row">
                          <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
            
                          <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="client_id_edit">Client<span class="redstar">*</span></label>
                                <ng-select formControlName="client_id_edit" id="client_id_edit"
                                    [items]="InfoArrayClient"
                                    bindLabel="name"
                                    bindValue="id"
                                    [placeholder]="'Select Client'"
                                    [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors}">
                                </ng-select>              
                                <ng-container
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors">
                                    <p class="error"
                                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.client_id_edit.errors.required">
                                    Client is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
            
                          <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="wellness_benefit_edit">Wellness Benefit <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="wellness_benefit_edit" id="wellness_benefit_edit" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.wellness_benefit_edit.errors}"
                                    required>
                                        <option value="">Select Wellness Benefit</option>
                                        <option *ngFor="let wellness of InfoArrayWellness; let i = index" value="{{wellness.id}}">
                                          {{wellness.partner_name}} - {{wellness.benefit_name}}</option>
                                </select>
                                <ng-container
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_benefit_edit.errors">
                                <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_benefit_edit.errors.required">
                                Wellness Benefit is required
                                </p>
                              </ng-container>
                            </div>
                          </div>
              
                          <div class="col-sm-4">
                              <button type="button" class="btn btn-primary mt-4" (click)="onSubmitEdit()" role="button">Update</button>
                          </div>
                        </div>
                      </form>
                </div>
            </div>
         
  
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Deactivate Employer Wellness</h4>
                      </div>
                      <form [formGroup]="DeactivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Deactivate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                  role="button">Deactivate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          <!-- Activate Form -->
          <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Activate Employer Wellness</h4>
                      </div>
                      <form [formGroup]="ActivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Activate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                  role="button">Activate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Wellness Partner</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
  