import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

export function noLeadingWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasLeadingWhitespace = (control.value || '').startsWith(' ');
    return hasLeadingWhitespace ? { 'leadingWhitespace': true } : null;
  };
}

@Component({
  selector: 'app-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.css']
})
export class WellnessComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  
  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];   

  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Wellness';

  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.show = true;

    $(window).scrollTop(0);

    // setTimeout(()=>{
    //   this.show = false;
    // },3000);

    this.getInfo();
    this.assignrights();
    
    $('#validmsg').css('display', 'none');
    this.basicInfo = this.fb.group({
      wellness_partner: ['', [Validators.required, noLeadingWhitespaceValidator()]],
      // url: new FormControl('', Validators.required),
      document_file: new FormControl(''),
    });

    this.EditInfo = this.fb.group({      
      wellness_partner_edit: ['', [Validators.required, noLeadingWhitespaceValidator()]],
      // url: new FormControl('', Validators.required),
      document_file: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getsamplefile(){


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_number'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.samplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }


  ondownload(val: any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', 'Sample_Policy_Member_Data.xls');
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // uploadfile(event: any){
    
  //   if(event.target.files.length > 0){
  //     this.document_file = event.target.files[0];
  //     $("#features-file-upload_document_file").empty();
  //     $('#features-file-upload_document_file').html(event.target.files[0]['name']);
  //     $('#features-file-upload_document_file').css('text-align', 'left');

  //     var iSize = Math.round(event.target.files[0]['size'] / 1024);

  //     iSize = (Math.round(iSize * 100) / 100);

  //     $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


  //   }
  // }

  uploadfile(event: any) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    const errorElement = document.getElementById('document_file_error') as HTMLElement;
    const uploadLabel = document.getElementById('features-file-upload_document_file') as HTMLElement;
    const imagePreview = document.getElementById('imagePreview') as HTMLImageElement;
  
    // Define allowed file types and max file size (in KB)
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const maxSizeKB = 2048;  // 2 MB
  
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        errorElement.textContent = 'Invalid file type. Please upload a .jpeg, .jpg, or .png file.';
        errorElement.style.display = 'block';
        fileInput.value = '';  // Clear the file input
        imagePreview.style.display = 'none'; // Hide the image preview
      } else if (Math.round(file.size / 1024) > maxSizeKB) {
        errorElement.textContent = `File size exceeds the 2MB limit. Please upload a smaller file.`;
        errorElement.style.display = 'block';
        fileInput.value = '';  // Clear the file input
        imagePreview.style.display = 'none'; // Hide the image preview
      } else {
        errorElement.style.display = 'none';
        $('#features-file-upload_document_file').css('border', '1px solid #dee2e6')
        this.document_file = file;
        uploadLabel.innerHTML = '';
        uploadLabel.style.textAlign = 'left';
  
        const fileName = file.name;
        const iSize = Math.round(file.size / 1024);
        const displaySize = (Math.round(iSize * 100) / 100);
  
        uploadLabel.setAttribute('title', fileName);
        // uploadLabel.innerHTML = fileName + '<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">' + displaySize + 'kb</span>';
        uploadLabel.innerHTML = fileName
  
        // Preview the image
        const reader = new FileReader();
        reader.onload = function(e: any) {
          imagePreview.src = e.target.result;
          imagePreview.style.display = 'block';
        };
        reader.readAsDataURL(file);
      }
    } else {
      errorElement.textContent = 'Logo Required';
      errorElement.style.display = 'block';
      imagePreview.style.display = 'none'; // Hide the image preview
    }
  }
  
  
  

  onSubmitAdd(){

    if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
      $('#document_file_error').css('display', 'block')
      $('#features-file-upload_document_file').css('border', '1px solid red')

    }else{


      $('#document_file_error').css('display', 'none')
      $('#features-file-upload_document_file').css('border', '1px solid #dee2e6')
     }

     
    $('#submitbutton').prop('disabled', true);

    setTimeout(() => {
      $('#submitbutton').prop('disabled', false);
    }, 4000);
    
    this.basicInfoSubmitted = true;
    if (this.basicInfoSubmitted && this.basicInfo.valid) {

      // this.show = true;

      console.log(this.document_file);

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
        $('#document_file_error').css('display', 'block')
        $('#features-file-upload_document_file').css('border', '1px solid red')

        return;
      }else{

        $('#document_file_error').css('display', 'none')
        $('#features-file-upload_document_file').css('border', '1px solid #dee2e6')
       }
  
     
       var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "wellness_name":this.basicInfo.controls['wellness_partner'].value,
        // "wellness_url":this.basicInfo.controls['url'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "image_data" : this.document_file
      };
      console.log(postdata);

      this.service.uploadsamplefile(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = false;            

            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);
            
          }
        }, (error) => {
          this.validmsg = error.error.message;
          this.toastService.showFailed('Failed', this.validmsg);
            // console.log(error);
            // this.toastService.showFailed('Failed', 'There was some error');

      });
      
    }    

  }

  closePopup() {    
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
    this.displayStyleDelete = "none";  
  }

  getInfo(){

    // this.show = true;
    // this.fullScreen = true;
    // this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  // getPolicyuploaddoclist(){    
  //   var postdata = {
  //     "tokenable_id":localStorage.getItem('tokenable_id'),
  //     "broker_id":localStorage.getItem('broker_id'),
  //     "company_id":this.basicInfo.controls['company_id'].value,
  //   }
  //   this.service.getPolicyuploaddoclist(postdata)
  //     .subscribe((result) => {

  //       this.Policyuploaddoclist = result.data;

  //       this.dtTrigger.next();
  //     }, (error) => {
  //         // console.log(result);
  //         this.Policyuploaddoclist = [];
  //   });
  // }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });    

  }

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  // ngAfterViewInit(): void {
  //   this.dtTrigger.next();
  // } 

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    $(window).scrollTop(0);

    this.addflag = 1;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['wellness_partner_edit'].setValue(result.data[0]['wellness_name']);
        // this.EditInfo.controls['url'].setValue(result.data[0]['wellness_url']);
        this.imgpath = result.data[0]['wellness_logo'];
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    // console.log(id);
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "broker_id":localStorage.getItem('broker_id'),
        
        "wellness_name":this.EditInfo.controls['wellness_partner_edit'].value,
        // "wellness_url":this.EditInfo.controls['url'].value,
        "image_data" : this.document_file
      };

      console.log(postdata);

      this.show = false;

      this.service.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

          
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          this.toastService.showFailed('Failed', this.validmsg);
       
      });

    }

  }


  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDelete(res: any) {

    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.DeleteInfo.controls['delete_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
    };

    this.service.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deleted Successfully!');

          
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
      
    });

  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

}
