import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }

  Step1_store(data: any, token: any): Observable<any> {

    // var URL;
    // if(data.master_policies_id == 0){
    //   URL = 'masterpolicy/step1-store';
    // }else{
    //   URL = 'masterpolicy/step1-update/' + data.master_policies_id;
    // }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/enrolementupdatestep1',
      data
    , httpOptions);
  }

  Step2_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step2-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step3_update(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step3-update/' + data.master_policies_id,
      data
    , httpOptions);
  }

  Step4_update(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'masterpolicy/step4-update/' + data.master_policies_id,
      data
    , httpOptions);
  }    

  /************* ALL GET *************/

  getRelation(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterrelations-list',
    httpOptions);
  }  

  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'tpa-details/show/' + data.id,
      data
    , httpOptions);
  }

  getRelations(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'masterrelations-list', httpOptions);
  }

  getdetails(data: any, token: any): Observable<any> {   

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getemployeeenrollement',
      data
    , httpOptions);
  }

  /********** Step 3**********/
  getemployeepoliciesbenefits(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getemployeepoliciesbenefits',
    data,
    httpOptions);
  }

  getsteptwodata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getsteptwodata',
    data,
    httpOptions);
  }

  steptwosaveinsuredmemberdata(data: any, token: any): Observable<any> {   

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policy_id",data.policy_id);
    filedata.append("relation_id",data.relation_id);
    filedata.append("relation",data.relation);
    filedata.append("gender",data.gender);
    filedata.append("first_name",data.first_name);
    filedata.append("last_name",data.last_name);
    filedata.append("contact",data.contact);
    filedata.append("contact_email",data.contact_email);
    filedata.append("dob",data.dob);
    filedata.append("si_array",data.si_array);
    filedata.append("rater_id",data.rater_id);
    filedata.append("age_diff_flag",data.age_diff_flag);

    if(data.relation_id == '2'){
      filedata.append("marriage_date",data.marriage_date);
      filedata.append("marriage_certificate_document",data.marriage_certificate_document);
    }

    if(data.relation_id == '3' || data.relation_id == '4'){
      filedata.append("is_adopted_child",data.is_adopted_child);
      filedata.append("document",data.document);
      filedata.append("birth_certificate_document",data.birth_certificate_document);
      filedata.append("has_special_child",data.has_special_child);
      filedata.append("has_unmarried_child",data.has_unmarried_child);
    }        
        
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/steptwosaveinsuredmemberdata',
    filedata,
    httpOptions);
  }

  steptwoupdateinsuredmemberdata(data: any, token: any): Observable<any> {   

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policy_id",data.policy_id);
    filedata.append("relation_id",data.relation_id);
    filedata.append("epm_id",data.emp_id_edit);    
    filedata.append("gender",data.gender);
    filedata.append("first_name",data.first_name);
    filedata.append("last_name",data.last_name);
    filedata.append("contact",data.contact);
    filedata.append("contact_email",data.contact_email);
    filedata.append("dob",data.dob);    
    // filedata.append("si_array",data.si_array);
    // filedata.append("rater_id",data.rater_id);
    
    filedata.append("upload_lockin_death_cert",data.upload_lockin_death_cert);

    if(data.relation_id == '2'){
      filedata.append("marriage_date",data.marriage_date);
      filedata.append("marriage_certificate_document",data.marriage_certificate_document);
    }

    if(data.relation_id == '3' || data.relation_id == '4'){
      filedata.append("is_adopted_child",data.is_adopted_child);
      filedata.append("document",data.document);
      filedata.append("birth_certificate_document",data.birth_certificate_document);
      filedata.append("has_special_child",data.has_special_child);
      filedata.append("has_unmarried_child",data.has_unmarried_child);
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/steptwoupdateinsuredmemberdata',
    filedata,
    httpOptions);
  }
    
  steptwodeleteinsuredmemberdata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/steptwodeleteinsuredmemberdata',
    data,
    httpOptions);
  }

  /************ Step 4 **********/
  getstepthreenomineedata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getstepthreenomineedata',
    data,
    httpOptions);
  }

  savestepthreenomineedata(data: any, token: any): Observable<any> {   

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policy_id",data.policy_id);
    filedata.append("relation_id",data.relation_id);
    filedata.append("relation",data.relation);
    filedata.append("first_name",data.first_name);
    filedata.append("last_name",data.last_name);
    filedata.append("share_percentage",data.share_percentage);
    filedata.append("guardian_relation",data.guardian_relation);
    filedata.append("guardian_first_name",data.guardian_first_name);
    filedata.append("guardian_last_name",data.guardian_last_name);
    filedata.append("guardian_dob",data.guardian_dob);
    filedata.append("dob",data.dob);
    filedata.append("nominee_same_for_other_polocies",data.nominee_same_for_other_polocies);

    filedata.append("step3_document",data.step3_document);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/savestepthreenomineedata',
    filedata,
    httpOptions);
  }

  updatestepthreenomineedata(data: any, token: any): Observable<any> {   

    let filedata = new FormData();

    filedata.append("tokenable_id",data.tokenable_id);
    filedata.append("policy_id",data.policy_id);
    filedata.append("relation_id",data.relation_id);
    filedata.append("relation",data.relation);
    filedata.append("first_name",data.first_name);
    filedata.append("last_name",data.last_name);
    filedata.append("share_percentage",data.share_percentage);
    filedata.append("guardian_relation",data.guardian_relation);
    filedata.append("guardian_first_name",data.guardian_first_name);
    filedata.append("guardian_last_name",data.guardian_last_name);
    filedata.append("guardian_dob",data.guardian_dob);
    filedata.append("dob",data.dob);
    filedata.append("is_guardian",data.is_guardian);    
    filedata.append("step3_document",data.step3_document);

    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/updatestepthreenomineedata',
    filedata,
    httpOptions);
  }

  deletestepthreenomineedata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/deletestepthreenomineedata',
    data,
    httpOptions);
  }

  submitstep4(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/submitemployeeenrollement',
    data,
    httpOptions);
  }

  getSIArray(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getsteptwosibyrelationwise',
    data,
    httpOptions);
  }

  gettopupdisplaydatabyID(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/gettopupdisplaydatabyID',
    data,
    httpOptions);
  }
  
  submittopup_Popup(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/savetopuppolicydetails',
    data,
    httpOptions);
  }
  
  getstepfourdata(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getstepfourdata',
    data,
    httpOptions);
  }

  savestepfour(data: any, token: any): Observable<any> {   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/stepfoursaveinstallmentdata',
    data,
    httpOptions);
  }

  getrelinfo(data: any, token: any): Observable<any> {    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'employee/getinsurednomineedata',
      data
    , httpOptions);
  }

  downloadDcoument(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'employee/nomineedatawordfile',
      data
    , httpOptions);
  }

}
