import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface DropdownItem {
  id: string;
  name: string; // Assuming the dropdown items have 'id' and 'name' properties
}

@Component({
  selector: 'app-flex-configurator',
  templateUrl: './flex-configurator.component.html',
  styleUrls: ['./flex-configurator.component.css']
})
export class FlexConfiguratorComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  addflag: any = 0;

	selectedItems:any = [];  
  selectedItemsRelation:any = [];
  selectedItemsSI:any = [];
	dropdownSettings : IDropdownSettings={};
  dropdownSettings_relations : IDropdownSettings={};
  dropdownSettings_SI : IDropdownSettings={};

	familyArrData: any = [];
	familyArr: any = {};

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  cnt_BenefitData = 1;
  cnt_allowed = 1;

  cnt_BenefitData_edit = 1;
  cnt_allowed_edit = 1;

  addonbasecount = 1;

  public benefit_info:FormGroup;
  public features_info:FormGroup;

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  
  InfoArray: any = [];  
  InfoArrayBenefit: any = [];  
  InfoArrayBroker: any = []; 
  InfoArrayCorporate: any = [];
  InfoArrayCorporate_edit: any = [];
  InfoArrayPolicyType: any = [];
  InfoArrayPolicyTypeEdit: any = [];
  InfoArrayPolicyName: any = [];
  InfoArrayPolicyNameEdit: any = [];
  InfoArrayMultiple: any = [];
  InfoArrayMultipleSI: any = [];
  InfoArrayMultipleEdit: any = [];
  InfoArrayPlanType: any = [];
  InfoArrayMultipleRelations: any = [];
  public InfoArraydropdown: DropdownItem[] = [];
  public selectedClientName: string | null = null;
  
  addonbaseArray: any = [];
  addonmultipleArray: any = [];
  FeatureTableArray: any = [];
  NotCoverTableArray: any = [];

  addonbaseArray_edit: any = [];
  addonmultipleArray_edit: any = [];
  FeatureTableArray_edit: any = [];
  NotCoverTableArray_edit: any = [];
  
  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  PopupBenefits = "none";
  PopupFeatures = "none";
  PopupBenefits_edit = "none";
  PopupFeatures_edit = "none";
  PopupAuditDetails = "none";

  add_base_flag:any;
  edit_base_flag:any;

  Covertype:any;
  Premiumtype:any;

  add_base_fieldname:any;
  edit_base_fieldname:any;

  feature_flag:any = 0;
  feature_update_id:any;


  add_edit_flag:any = 0;
  update_id:any;
  
  arrayAddOn:any = [];

  PlanName:any = [];
  checkedValues: any[] = [];

  form: FormGroup;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [ {
      targets: ['_all'], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  clientNameControl!: FormControl;


  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder, private dataservice: Service, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
      this.form = this.fb.group({
        client_name: [null]
      });
    });
  }

  ngOnInit(): void {

    this.initGroup();

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);

    this.clientNameControl = this.form.get('client_name') as FormControl;

  }

  initGroup(){

    $(window).scrollTop(0);
    this.InfoArray = [];
    const savedClientId = localStorage.getItem('listpolicies_company_id_listing');
    this.getInfodropdown(savedClientId);

    //Add
    // Form element defined below
    this.AddInfo = this.fb.group({
      corporate:  new FormControl(null, Validators.required),
      policy_type:  new FormControl('0'),  
      policy_name: new FormControl('0'),
      plan_type: new FormControl('', Validators.required),
      sum_insured: new FormControl(''),    
      plan_name:  new FormControl('', Validators.required),   
      roll_back_allowed: new FormControl('Yes'),
      show_diff_premium: new FormControl('Yes'),
      plan_description: new FormControl(''),
      disclaimer: new FormControl(''),
      benefit_name: new FormControl(''),
      benefit_description: new FormControl(''),

      set_allowed_increase: new FormControl(''),
      increase_cover_level: new FormControl(''),
      set_allowed_decrease: new FormControl(''),      
      decrease_cover_level: new FormControl(''),
      si_grade_dropdown: [this.selectedItems]
     
    });        

    this.benefit_info = this.fb.group({        
      benefit_name_val:new FormControl('0'),
      benefit_description_val:new FormControl(''),
      benefit_visible_from_SI:new FormControl(''),
      benefit_mandatory:new FormControl('Mandatory'),
      prorata_calculation_applicable:new FormControl('Yes'),
      mandatory_not_selected:new FormControl(''),
      addon_si_grade_dropdown: [this.selectedItemsSI]

    });

    this.features_info = this.fb.group({        
      feature_desc:new FormControl(''),
      to_hide_detail:new FormControl('Yes'),
      relation_inclusive:new FormControl('Yes'),
      sum_insured:new FormControl(''),
      sum_insured_type:new FormControl('0'),
      sum_insured_by:new FormControl('by_value'),
      premium:new FormControl(''),
      premium_by:new FormControl('by_value'),
      premium_type:new FormControl('0'),
      contributions:new FormControl('0'),
      is_optional:new FormControl('Yes'),
      relations:[this.selectedItemsRelation],
    });

    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Add Sum insured 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    // Relations 
    this.dropdownSettings_relations = {
      singleSelection: false,
      idField: 'relation_id',
      textField: 'relation',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: true,      
    };

    // Addon SI Benefit
    this.dropdownSettings_SI = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      // showSelectedItemsAtTop: true,      
    };

  }  
  
  /******** Add Sum Insured - grade Dropdown  ********/

  onItemSelect(item: any) {    

    var abcflag = false; 
    for(var i=0;i<this.selectedItems.length;i++){
      if(this.selectedItems[i]['id']==item['id']){
        abcflag = true;
        break;
      }else{
        abcflag = false;
      }
    }

    if(!abcflag){
      this.selectedItems.push(item);
    }
  }

  OnItemDeSelect(item: any) {    
    this.selectedItems = this.selectedItems.filter((el:any) => el.id !== item.id);
  }

  onSelectAll(items: any) {
    this.selectedItems = [];
    this.selectedItems = items;
  }

  onDeSelectAll(items: any) {
    this.selectedItems = [];
  }

  /**************** Relations Dropdown **************/

  onItemSelectRelation(item: any) {   
    var abcflag = false; 
    for(var i=0;i<this.selectedItemsRelation.length;i++){
      if(this.selectedItemsRelation[i]['relation_id']==item['relation_id']){
        abcflag = true;
        break;
      }else{
        abcflag = false;
      }
    }

    if(!abcflag){
      this.selectedItemsRelation.push(item);
    }
    
  }

  OnItemDeSelectRelation(item: any) {    
    this.selectedItemsRelation = this.selectedItemsRelation.filter((el:any) => el.id !== item.relation_id);
    console.log(this.selectedItemsRelation);
  }

  onSelectAllRelation(items: any) {
    this.selectedItemsRelation = [];
    this.selectedItemsRelation = items;
  }

  onDeSelectAllRelation(items: any) {
    this.selectedItemsRelation = [];
  }

  /******************* Addon SI Benefits Dropdown *************************/

  onItemSelect_SI(item: any) {    

    var abcflag = false; 
    for(var i=0;i<this.selectedItemsSI.length;i++){
      if(this.selectedItemsSI[i]['id']==item['id']){
        abcflag = true;
        break;
      }else{
        abcflag = false;
      }
    }

    if(!abcflag){
      this.selectedItemsSI.push(item);
    }
  }

  OnItemDeSelect_SI(item: any) {    
    this.selectedItemsSI = this.selectedItemsSI.filter((el:any) => el.id !== item.id);
  }

  onSelectAll_SI(items: any) {
    this.selectedItemsSI = [];
    this.selectedItemsSI = items;
  }

  onDeSelectAll_SI(items: any) {
    this.selectedItemsSI = [];
  }

  //Add Flex 

  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }

  getInfodropdown(savedClientId: string | null): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    const postdata = {
      tokenable_id: localStorage.getItem('tokenable_id'),
      broker_id: localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata).subscribe((result: any) => {
      this.show = false;
      this.InfoArraydropdown = result.data;
      if (this.InfoArraydropdown.length > 0 && savedClientId) {
        const savedClient = this.InfoArraydropdown.find(client => client.id.toString() === savedClientId);
        if (this.InfoArraydropdown.length > 0 && savedClientId) {
          const savedClient = this.InfoArraydropdown.find(client => client.id.toString() === savedClientId);
          if (savedClient) {
            this.form.get('client_name')?.setValue(savedClient);
            this.selectedClientName = savedClient.id; // Assign the ID of the saved client
            this.getInfo(savedClient);
          }
        }
        
      }
    }, (error) => {
      this.show = false;
      this.InfoArraydropdown = [];
      if (error.error.message === 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/login']);
      }
    });
  }

  onClientChange(event: any): void {
    this.selectedClientName = event;
    this.getInfo(event);
    console.log('Selected client:', this.selectedClientName);
    window.location.reload();
  }

  openPopupAdd(){
    this.addflag = 1;
    this.getCorporate();    
    this.getBenefit();    
    this.getPlanType();  
    this.getCoverType();
    this.getPlanName();
    this.getPremiumType();  
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      // this.show = true;
      // this.fullScreen = true;
      // this.template = ``; 

      var roll_back_allowed:any;

      if(this.AddInfo.controls['roll_back_allowed'].value == 'Yes'){
        roll_back_allowed = 1;
      }else{
        roll_back_allowed = 0;
      }
      
      var show_diff_premium:any;

      if(this.AddInfo.controls['show_diff_premium'].value == 'Yes'){
        show_diff_premium = 1;
      }else{
        show_diff_premium = 0;
      }    
      
      // return false;
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "roll_back_allowed": roll_back_allowed,
        "show_diff_premium": show_diff_premium,
        "corporate":this.AddInfo.controls['corporate'].value,        
        "policy_type":this.AddInfo.controls['policy_type'].value,        
        "policy_name":this.AddInfo.controls['policy_name'].value,      
        "type":this.AddInfo.controls['plan_type'].value,        
        "sum_insured":JSON.stringify(this.selectedItems),        
        "plan_name":this.AddInfo.controls['plan_name'].value,        
        "plan_description":this.AddInfo.controls['plan_description'].value,        
        "disclaimer":this.AddInfo.controls['disclaimer'].value,                

        "will_there_be_any_premium":$('#will_there_be_any_premium').is(':checked'),        
        "initial_Premium_will_be_paid":$('#initial_Premium_will_be_paid').is(':checked'),
                
        "set_allowed_increase":$('#set_allowed_increase').is(':checked'),
        "increase_cover_level":this.AddInfo.controls['increase_cover_level'].value,
        "set_allowed_decrease":$('#set_allowed_decrease').is(':checked'),
        "decrease_cover_level":this.AddInfo.controls['decrease_cover_level'].value,                      

        "addOnArray": JSON.stringify(this.arrayAddOn),
        // "addonmultipleArray": JSON.stringify(this.addonmultipleArray),
        "notCoverTableArray": JSON.stringify(this.NotCoverTableArray),
        "featureTableArray": JSON.stringify(this.FeatureTableArray),

        "flag":this.add_base_flag

      };

      console.log(postdata);
      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {  
            
            this.show = false;

            this.toastService.showSuccess('Success', 'Details Saved Successfully!');
            setTimeout(() => {
              window.location.reload();
            }, 3000);


            this.getInfo('');
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;            

          }else {            
            this.show = false;         
          }
        }, (error) => {
          this.show = false;          
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block');       
          
          this.toastService.showFailed('Failed', 'There was some error');

      });
      
    } else {
      // validate all form fields
    }
  }

  openPopupEdit(group: any){              

    localStorage.setItem('flex_edit_id', group.id);
    this.route.navigate(['flex-configurator-edit']);

  }

  //Delete
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data.flexplan_info[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          this.toastService.showSuccess('Success', 'Flex Deactivated Successfully!');

          window.location.reload();
      
          this.dtTrigger.next();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();        
    });

  }

  //Delete
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data.flexplan_info[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Flex Activated Successfully!');

          this.closePopup();   
          window.location.reload();
    
          this.dtTrigger.next();

        } 
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error !');
     
    });

  }

  /************************** Common functions **************************/

  getInfo(val: any): void {
    this.InfoArray = [];
    let postdata: any;

    if (val === '') {
      postdata = {
        tokenable_id: localStorage.getItem('tokenable_id'),
        employer_id: '0',
        broker_id: localStorage.getItem('broker_id')
      };
      localStorage.setItem('listpolicies_company_id_listing', '0');
    } else {
      postdata = {
        tokenable_id: localStorage.getItem('tokenable_id'),
        employer_id: val.id,
        broker_id: localStorage.getItem('broker_id')
      };
      localStorage.setItem('listpolicies_company_id_listing', val.id);
    }

    this.dataservice.getInfo(postdata).subscribe((result: any) => {
      this.show = false;
      this.InfoArray = result.data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
        this.dtTrigger.next();
        setTimeout(() => {
          $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
        }, 1000);
      });
    }, (error) => {
      this.show = false;
      this.InfoArray = [];
      if (error.error.message === 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/login']);
      }
    });
  }
  
  getPolicy(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    this.dataservice.getPolicy()
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBroker = result.data; 

        }, (error) => {          
          
          this.InfoArrayBroker = [];
          
      });
  }

  getCorporate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    // $('#corporate').val('0');


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    };
    
    this.dataservice.getCorporate(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayCorporate = result.data;

          console.log(this.InfoArrayCorporate_edit);

        }, (error) => {          
          
          this.InfoArrayCorporate = [];
          
      });
  }

  getPolicyType(){
  
    setTimeout(() => {
      $('#policy_type').val('0');
      $('#policy_name').val('0');
    }, 1000);    

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id": this.AddInfo.controls['corporate'].value,
    };
    
    this.dataservice.getPolicyType(postdata)
        .subscribe((result: any) => {          
          
          // this.show = false;
          this.InfoArrayPolicyType = result.data; 

        }, (error) => {          
          
          this.InfoArrayPolicyType = [];
          
      });
  }

  getPolicyName(){
  
    setTimeout(() => {      
      $('#policy_name').val('0');
    }, 1000);     

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "client_id":  this.AddInfo.controls['corporate'].value,
      "policytype_id": $('#policy_type').val(),
    };
    
    this.dataservice.getPolicyName(postdata)
        .subscribe((result: any) => {          
          
          this.InfoArrayPolicyName = result.data; 

        }, (error) => {          
          
          this.InfoArrayPolicyName = [];
          
      });
  }

  getMultiSelect(){

    $('#sum_insuredDiv').css('display', 'block');

    if($('#policy_name').val()=='0' || $('#policy_name').val()==0){
        return;
    }
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": $('#policy_name').val(),
    };
    
    this.dataservice.getMultiSelect(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayMultiple = result.data.dropdowninfo;
          this.InfoArrayMultipleSI = result.data.dropdowninfo;
          if( this.InfoArrayMultiple.length > 0){
            $('#sum_insuredDiv').css('display', 'block');
          }else{
            $('#sum_insuredDiv').css('display', 'none');
          }
          this.add_base_fieldname = result.data.field_name;
          this.add_base_flag =  result.data.flag;

        }, (error) => {          
          
          this.InfoArrayMultiple = [];
          
      });
  }

  getMultiSelectRelation(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "policy_id": $('#policy_name').val(),
    };
    
    this.dataservice.getMultiSelectRelation(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayMultipleRelations = result.data;

          // console.log(this.InfoArrayMultiple);

        }, (error) => {          
          this.show = false;
          this.InfoArrayMultipleRelations = [];
          
      });
  }

  getBenefit(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    };
    
    this.dataservice.getBenefit(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayBenefit = result.data;

          // console.log(this.InfoArrayBenefit);

        }, (error) => {          
          
          this.InfoArrayBenefit = [];
          
      });
  }

  getPlanType(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    };
    
    this.dataservice.getPlanType(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          this.InfoArrayPlanType = result.data;

        }, (error) => {          
          
          this.InfoArrayPlanType = [];
          
      });
    
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    
    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none"; 
    this.PopupBenefits="none";
    this.PopupFeatures="none";
    this.PopupBenefits_edit="none";
    this.PopupFeatures_edit="none";
    this.PopupAuditDetails="none";

    $('.newbenefitData').empty();
    this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  
  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  /******************************************************************************/

  /***** Add Field *****/
  addBenefitData(){
    let html = `
    <div id="this_Data_benefit_row_`+this.cnt_BenefitData+`" class="row mt-4" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

      <div class="col-sm-3 form-group mt-3">
        <label class="bs-form-label" for="plan_name_`+this.cnt_BenefitData+`">Sub Plan Name</label>
        <select class="form-select" formControlName="plan_name_`+this.cnt_BenefitData+`" id="plan_name_`+this.cnt_BenefitData+`">
              <option value="0" selected>Select Sub Plan Name</option>              
        </select>
      </div>

      <div class="col-sm-3 form-group mt-3 other_plan_name_div_`+this.cnt_BenefitData+`" style="display:none">
          <label class="bs-form-label" for="other_plan_name_`+this.cnt_BenefitData+`">Sub Plan Name (if Other option selected)</label>
          <input type="text" class="form-control" formControlName="other_plan_name_`+this.cnt_BenefitData+`" id="other_plan_name_`+this.cnt_BenefitData+`" placeholder="Sub Plan Name"/>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="feature_name_`+this.cnt_BenefitData+`">Feature Name </label>
          <input type="text" class="form-control" formControlName="feature_name_`+this.cnt_BenefitData+`" id="feature_name_`+this.cnt_BenefitData+`" placeholder="Feature Name" />
      </div>

      <div class="col-sm-6 form-group mt-3">
          <label class="bs-form-label" for="feature_description_`+this.cnt_BenefitData+`">Feature Description </label>
          <input type="text" class="form-control" formControlName="feature_description_`+this.cnt_BenefitData+`" id="feature_description_`+this.cnt_BenefitData+`" placeholder="Enter Content Here..." />
      </div>        

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="add_on_SI_`+this.cnt_BenefitData+`">Add On SI</label>
          <input type="text" class="form-control" formControlName="add_on_SI_`+this.cnt_BenefitData+`" id="add_on_SI_`+this.cnt_BenefitData+`" placeholder="Add On SI" value="0" />
      </div>     

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="cover_by_`+this.cnt_BenefitData+`">Cover By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_value" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+this.cnt_BenefitData+`" value="by_SI" name="cover_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                  </div>
              </div>
          </div>
      </div>
        
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="cover_type_`+this.cnt_BenefitData+`">Cover Type</label>
          <select class="form-select" formControlName="cover_type_`+this.cnt_BenefitData+`" id="cover_type_`+this.cnt_BenefitData+`">
                <option value="0" selected>Select Cover Type</option>
          </select>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="sum_insured_cap_`+this.cnt_BenefitData+`">Sum Insured Cap</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="Yes" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+this.cnt_BenefitData+`" value="No" name="sum_insured_cap_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="premium_by_`+this.cnt_BenefitData+`">Premium By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_value" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+this.cnt_BenefitData+`" value="by_premium" name="premium_by_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_value_`+this.cnt_BenefitData+`">Premium Value</label>
          <input type="text" class="form-control" formControlName="premium_value_`+this.cnt_BenefitData+`" id="premium_value_`+this.cnt_BenefitData+`" placeholder="Premium Value" value="0" />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_comparison_`+this.cnt_BenefitData+`">Premium Comparision</label>
          <select class="form-select" formControlName="premium_comparison_`+this.cnt_BenefitData+`" id="premium_comparison_`+this.cnt_BenefitData+`">
                <option value="" selected>Select Premium Comparision</option>
                <option value="Low">Low</option>
                <option value="Lower">Lower</option>
                <option value="Lowest">Lowest</option>
                <option value="High">High</option>
                <option value="Higher">Higher</option>
                <option value="Highest">Highest</option>
          </select>
      </div>      

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_type_`+this.cnt_BenefitData+`">Premium Type</label>
          <select class="form-select" formControlName="premium_type_`+this.cnt_BenefitData+`" id="premium_type_`+this.cnt_BenefitData+`">
                <option value="0" selected>Select Premium Type</option>
          </select>
      </div>      

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="tag_name_`+this.cnt_BenefitData+`">Tag Name</label>
          <input type="text" class="form-control" formControlName="tag_name_`+this.cnt_BenefitData+`" id="tag_name_`+this.cnt_BenefitData+`" placeholder="Tag Name"/>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="is_differential_`+this.cnt_BenefitData+`">Is Differential</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="is_differential_`+this.cnt_BenefitData+`" value="Yes" name="is_differential_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="is_differential_`+this.cnt_BenefitData+`" value="No" name="is_differential_`+this.cnt_BenefitData+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-12 mt-3">
        <div class="form-group" name="visible_from_SI_value[]" id="visible_from_SI_value_${this.cnt_BenefitData}">
          <label class="bs-form-label">Visible From SI Value</label><br>
        </div>
      </div>
      
      <div class="col-sm-2 form-group">
          <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+this.cnt_BenefitData+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
    </div>    


    `;

    $('.newbenefitData').append(html);

    // $('.other_plan_name_div_' + this.cnt_BenefitData).css('display','none');

    for(var i=0; i<this.PlanName.length; i++){
      var val = '#' + 'plan_name_'+ this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.PlanName[i]['id'])
          .text(this.PlanName[i]['title']));
    }

    for(var i=0; i<this.Covertype.length; i++){
      var val = '#' + 'cover_type_'+ this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Covertype[i]['value_id'])
          .text(this.Covertype[i]['name']));
    }

    for(var i=0; i<this.Premiumtype.length; i++){
      var val = '#' + 'premium_type_'+ this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Premiumtype[i]['value_id'])
          .text(this.Premiumtype[i]['name']));
    } 

    for (var i = 0; i < this.InfoArrayMultiple.length; i++) {
      var checkboxId = 'visible_from_SI_value_' + this.cnt_BenefitData + '_' + i;
      var checkboxLabel = this.InfoArrayMultiple[i]['name'];
      
      // Create checkbox element
      var checkbox = $(document.createElement('input')).attr({
          type: 'checkbox',
          id: checkboxId,
          name: 'visible_from_SI_value_' + this.cnt_BenefitData,
          value: this.InfoArrayMultiple[i]['id']
      });
  
      // Create label for checkbox
      var label = $('<label>').attr('for', checkboxId).text(checkboxLabel);
  
      // Create div to contain checkbox and label
      var containerDiv = $('<div>').addClass('form-check');
  
      // Append checkbox and label to container div
      containerDiv.append(checkbox).append(label);
  
      // Append container div to the target element
      $('#visible_from_SI_value_' + this.cnt_BenefitData).append(containerDiv);
  }

    $('head').append('<style>.form-check input[type="checkbox"] { display: inline-block; width: auto; margin-right: 5px; position: relative; padding: 10px;} </style><style>.form-check label { display: inline-block; vertical-align: middle; }</style><style>.form-check{position: relative; display: inline-block; margin-top: 0px; margin-bottom: 5px; padding-left: 5px; }</style>');

    
    let flag = this;
    $("#Data_benefit_row_"+this.cnt_BenefitData).on('click',function(e) {
      flag.removeRow_Benefit(e);
    });

    $("#plan_name_"+this.cnt_BenefitData).on('change',function(e) {
      flag.plan_name(e);
    });

    $("#add_on_SI_"+this.cnt_BenefitData).on('keypress',function(e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#premium_value_"+this.cnt_BenefitData).on('keypress',function(e) {
      flag.isnumberBenefit_benefit(e);
    });
    
    $("#visible_from_SI_value_"+this.cnt_BenefitData + " input[type='checkbox']").on('click', function(e) {
      var value = $(this).val(); 
      flag.onCheckboxChange(e, value, flag.cnt_BenefitData);
  });

    this.cnt_BenefitData++;    
    // console.log("----this.cnt_BenefitData-----",this.cnt_BenefitData);

  }

  plan_name(e:any){   
    // console.log(e.target.id)
    var inputString:any = e.target.id;
    const keyword:any = "plan_name_";
    const cnt:any = inputString.split(keyword)[1];
    var val = '.other_plan_name_div_' + cnt;      
    var value:any = $("select[id=plan_name_"+cnt+"] option:selected").text();    
    // console.log(value);
    if(value == "other"){
      $(val).css('display','block');
    }else{
      $(val).css('display','none');
    }
  }

  getCoverType(){
    this.dataservice.getCoverType()
      .subscribe((result) => {

        this.Covertype = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getPlanName(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getPlanName(postdata)
      .subscribe((result) => {

        this.PlanName = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  getPremiumType(){
    this.dataservice.getPremiumType()
      .subscribe((result) => {

        this.Premiumtype = result.data;

      }, (error) => {
          // console.log(result);
    });
  }

  removeRow_Benefit(e: any){
    var str = e.target.id;
    var id = str.charAt(str.length -1 );
    var idstr = '#this_Data_benefit_row_' + id;
    $(idstr).remove();

  }

  onCheckboxChange(event: any, value: any, cnt:any) {
    console.log('--------',cnt,value);
    if (event.target.checked) {
        this.checkedValues.push({cnt, value});
    } else {
        const index = this.checkedValues.indexOf(value);
        if (index !== -1) {
            this.checkedValues.splice(index, 1);
        }
    }
  }

  isnumberBenefit_benefit(evt: any) {
    // Get the input value
    const inputValue: string = evt.target.value;

    // Check if the entered character is a number or a decimal point
    const charCode: number = (evt.which) ? evt.which : evt.keyCode;

    // Allow only numbers, decimal point, backspace, and delete keys
    if (
        (charCode >= 48 && charCode <= 57) || // Numbers 0-9
        charCode === 46 || // Decimal point
        charCode === 8 || // Backspace
        charCode === 46 || // Delete
        charCode === 37 || // Left arrow
        charCode === 39 || // Right arrow
        charCode === 9 // Tab
    ) {
        // Ensure only one decimal point is entered
        if (charCode === 46 && inputValue.indexOf('.') !== -1) {
            evt.preventDefault();
        }

        if(evt.target.value == "00"){
              var value = '#' + evt.target.id;
              $(value).val('');
            }
        return true;
    } else {
        evt.preventDefault();
        return false;
    }
}

  /**************** Add Benefits *****************/

  showPopup(){

    this.getMultiSelectRelation();

    this.add_edit_flag = 0;

    this.PopupBenefits = "block";

    let val1:any = $('#benefit_name').val();
    $('#benefit_name_val').val(val1);
    let val2:any = $('#benefit_description').val();
    $('#benefit_description_val').val(val2);   
    this.benefit_info.controls['benefit_mandatory'].setValue('Mandatory');              
    this.benefit_info.controls['benefit_visible_from_SI'].setValue('0');              
    this.benefit_info.controls['mandatory_not_selected'].setValue('');          
    $('.benefit_mandatory_row').css('display', 'none');
    this.benefit_info.controls['prorata_calculation_applicable'].setValue('Yes');  
    this.selectedItemsSI = [];    
    this.checkedValues = [];    

  }

  savebenefit(){

    this.PopupBenefits="none";

    let val1:any = $("select[id=benefit_name_val] option:selected").text();
    let val2:any = $('#benefit_description_val').val();
    let val3 = this.selectedItemsSI;
    let val4 = $('input[name="benefit_mandatory"]:checked').val();
    let val5 = $('input[name="prorata_calculation_applicable"]:checked').val();
    let val6 = $('#mandatory_not_selected').val();
    let val7 = $('#benefit_name_val').val();

    // console.log(this.checkedValues);

    const dynamicArrays: any = {};

    this.checkedValues.forEach(item => {
        if (dynamicArrays[item.cnt]) {
            dynamicArrays[item.cnt].push(item);
        } else {
            dynamicArrays[item.cnt] = [item];
        }
    });

    console.log(dynamicArrays)

    let result: string[][] = [];

    // Get the maximum count value
    const maxCount = Math.max(...Object.keys(dynamicArrays).map(Number));

    // Initialize the result array with empty arrays up to the maximum count value
    for (let i = 0; i <= maxCount; i++) {
        result.push([]);
    }

    // Populate the result array with values from the data object
    Object.entries(dynamicArrays).forEach(([countStr, values]) => {
        const count = parseInt(countStr);
        (values as { cnt: number; value: string; }[]).forEach(({ value }) => {
            result[count].push(value);
        });
    });
    
    // Remove empty arrays
    result = result.filter(arr => arr.length > 0);

    console.log(result);
    
    let arr:any = [];
    for(var i = 1; i < this.cnt_BenefitData; i++){  
      // console.log(i); 
      if($('#feature_name_'+i).val() != undefined){
        var index = i - 1;

        arr[index] = {
          id:i,
          plan_name: $('#plan_name_'+i).val(),
          other_plan_name: $('#other_plan_name_'+i).val(),
          premium_comparison: $('#premium_comparison_'+i).val(),
          tag_name: $('#tag_name_'+i).val(),
          is_differential: $('input[name="is_differential_'+i+'"]:checked').val(),          
          feature_name: $('#feature_name_'+i).val(), 
          feature_description:$('#feature_description_'+i).val(),
          add_on_SI: $('#add_on_SI_'+i).val(), 
          cover_by: $('input[name="cover_by_'+i+'"]:checked').val(), 
          cover_type: $('#cover_type_'+i).val(), 
          cover_type_text: $("select[id=cover_type_"+i+"] option:selected").text(),
          sum_insured_cap: $('input[name="sum_insured_cap_'+i+'"]:checked').val(),       
          premium_by: $('input[name="premium_by_'+i+'"]:checked').val(),
          premium_value: $('#premium_value_'+i).val(), 
          premium_type: $('#premium_type_'+i).val(),  
          premium_type_text: $("select[id=premium_type_"+i+"] option:selected").text(),         
          // visible_from_SI_value: $('input[name="visible_from_SI_value_'+i+'"]:checked').val(),
          visible_from_SI_value: result[i - 1] || [],
          // visible_from_SI_value: checkedValuesForId,
        };
      }   
      
    }

    // let cnt_BenefitDataval;
    // if(this.cnt_BenefitData == 1){
    //   cnt_BenefitDataval = 1;
    // }else{
    //   cnt_BenefitDataval = this.cnt_BenefitData - 1;
    // }

    this.addonbaseArray.push(
      {
        "id":this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,      
        "mandatory_not_selected": val6,      
        "benefit_id": val7,      
        "relations": this.selectedItemsRelation,      
      }
    );

    let arrbase = [];

    arrbase.push(
      {
        "id":this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,      
        "mandatory_not_selected": val6,      
        "benefit_id": val7,  
        "relations": this.selectedItemsRelation,          
      }
    );

    // this.addonmultipleArray.push(arr);

    this.arrayAddOn.push({
      "arrbase": arrbase, "arrmultiple":arr
    });
    
    console.log(this.arrayAddOn);

    $('.newbenefitData').empty();
    // this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');
    // this.cnt_BenefitData = 1;
    this.addonbasecount = this.addonbasecount + 1;
    
  }   

  /***************************************************************************/
  saveWhatsNotCover(){
    let val1:any = $("select[id=benefit_name_not_cover] option:selected").text();
    let val2:any = $('#benefit_description_not_cover').val();
    let val3:any = $('#benefit_name_not_cover').val();

    if(val2 != 0 && val2 != '' && val2 != null && val3 != 0 && val3 != '' && val3 != null){
      this.NotCoverTableArray.push(
        {
          
          "benefit_name": val1,
          "benefit_description": val2,
          "benefit_not_cover_id": val3,
          
        }
      )
    }else{
      this.toastService.showFailed('Failed', 'Benefit Name and Description are mandatory');
    }

    console.log(this.NotCoverTableArray);

    $('#benefit_name_not_cover').val('');
    $('#benefit_description_not_cover').val('');


  }  

  removeaddonbaseArray(i:any){
    this.addonbaseArray.splice(i, 1);
    this.arrayAddOn.splice(i, 1);
    console.log(this.addonbaseArray);
    console.log(this.arrayAddOn);
  }

  removeNotCoverTableArray(i:any){
    this.NotCoverTableArray.splice(i, 1);
    console.log(this.NotCoverTableArray);
  }

  checkset_allowed_increase(){
    let val = $('#set_allowed_increase').is(':checked');    
    if(val){
      $('.set_allowed_increaseDiv').css('display', 'block');
    }else{
      $('.set_allowed_increaseDiv').css('display', 'none');
    }
  }

  checkset_allowed_decrease(){
    let val = $('#set_allowed_decrease').is(':checked');    
    if(val){
      $('.set_allowed_decreaseDiv').css('display', 'block');
    }else{
      $('.set_allowed_decreaseDiv').css('display', 'none');
    }
  }

  checkwill_there_be_any_premium(){
    let val = $('#will_there_be_any_premium').is(':checked');    
    if(val){
      $('.will_there_be_any_premiumDiv').css('display', 'block');
    }else{
      $('.will_there_be_any_premiumDiv').css('display', 'none');
    }
  }

  benefit_optional(){
    let val:any = this.benefit_info.controls['benefit_mandatory'].value;      
    if(val =="Optional"){
      $('.benefit_mandatory_row').css('display', 'flex');
    }else{
      $('.benefit_mandatory_row').css('display', 'none');
    }
  }

  showPopupAudit(){
    this.PopupAuditDetails = 'block';
  }

  /************************** EDIT Benefits **********************/
  showPopup_edit(id:any){    

    // console.log('id',id);

    this.getMultiSelectRelation();

    this.add_edit_flag = 1;
    this.update_id = id;
    this.PopupBenefits = "block";

    let val11:any = $('#benefit_name').val();
    $('#benefit_name_val').val(val11);

    let val22:any = $('#benefit_description').val();
    $('#benefit_description_val').val(val22);   

    let val1:any = this.arrayAddOn[id]['arrbase'][0]['benefit_id'];
    $('#benefit_name_val').val(val1);

    let val2:any = this.arrayAddOn[id]['arrbase'][0]['benefit_description'];
    $('#benefit_description_val').val(val2);

     let val3:any =  this.arrayAddOn[id]['arrbase'][0]['benefit_visible_from_SI'];

          console.log(val3);
          console.log(this.selectedItemsSI);

        //   for (let i = 0; i < val3.length; i++) {
        //     console.log(val3[i]['id']);

        //     let selectedItem = {
        //         id: parseInt(val3[i]['id']),
        //         name: val3[i]['name']
        //     };
            
        //     this.selectedItemsSI.push(selectedItem);
        // }

            setTimeout(() => {
            this.benefit_info.patchValue({
                addon_si_grade_dropdown: this.selectedItemsSI
            });

            // Trigger change detection after updating selectedItems
            this.cd.detectChanges();
        }, 1000);
            

    let val4:any =  this.arrayAddOn[id]['arrbase'][0]['benefit_mandatory'];
    $('input[name="benefit_mandatory"]').filter(`[value="${val4}"]`).prop('checked', true);

    let val6:any =  this.arrayAddOn[id]['arrbase'][0]['prorata_calculation_applicable'];
    $('input[name="prorata_calculation_applicable"]').filter(`[value="${val6}"]`).prop('checked', true);

    /**************************************************/ 
    // console.log(this.arrayAddOn);  
    
    console.log(this.checkedValues);


    let aa = this.arrayAddOn[id]['arrmultiple'];    
        
    for(var i = 0; i< aa.length; i++){      
      // console.log(aa[i]);
      let html = `
        <div id="this_Data_benefit_row_`+i+`" class="row mt-4" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">
              
        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="plan_name_`+i+`">Sub Plan Name</label>
          <select class="form-select" formControlName="plan_name_`+i+`" id="plan_name_`+i+`">
                <option value="0">Select Sub Plan Name</option>                
          </select>
        </div>

        <div class="col-sm-3 form-group mt-3 other_plan_name_div_`+i+`" style="display: none">
            <label class="bs-form-label" for="other_plan_name_`+i+`">Sub Plan Name (if Other option selected)</label>
            <input type="text" class="form-control" formControlName="other_plan_name_`+i+`" id="other_plan_name_`+i+`" placeholder="Sub Plan Name" value="`+aa[i]['other_plan_name']+`" />
        </div>
        
        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="feature_name_`+i+`">Feature Name </label>
            <input type="text" class="form-control" formControlName="feature_name_`+i+`" id="feature_name_`+i+`" placeholder="Feature Name" value="`+aa[i]['feature_name']+`" />
        </div>

        <div class="col-sm-6 form-group mt-3">
            <label class="bs-form-label" for="feature_description_`+i+`">Feature Description </label>
            <input type="text" class="form-control" formControlName="feature_description_`+i+`" id="feature_description_`+i+`" placeholder="Enter Content Here..." value="`+aa[i]['feature_description']+`" />
        </div>        

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="add_on_SI_`+i+`">Add On SI</label>
            <input type="text" class="form-control" formControlName="add_on_SI_`+i+`" id="add_on_SI_`+i+`" placeholder="Add On SI" value="`+aa[i]['add_on_SI']+`" />
        </div>     

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="cover_by_`+i+`">Cover By</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="cover_by_`+i+`" value="by_value" name="cover_by_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="cover_by_`+i+`" value="by_SI" name="cover_by_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="cover_type_`+i+`">Cover Type</label>
            <select class="form-select" formControlName="cover_type_`+i+`" id="cover_type_`+i+`">
                  <option value="0" selected>Select Cover Type</option>
            </select>
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="sum_insured_cap_`+i+`">Sum Insured Cap</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+i+`" value="Yes" name="sum_insured_cap_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+i+`" value="No" name="sum_insured_cap_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="premium_by_`+i+`">Premium By</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="premium_by_`+i+`" value="by_value" name="premium_by_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="premium_by_`+i+`" value="by_premium" name="premium_by_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="premium_value_`+i+`">Premium Value</label>
            <input type="text" class="form-control" formControlName="premium_value_`+i+`" id="premium_value_`+i+`" placeholder="Premium Value" value="`+aa[i]['premium_value']+`" />
        </div>

        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_comparison_`+i+`">Premium Comparision</label>
          <select class="form-select" formControlName="premium_comparison_`+i+`" id="premium_comparison_`+i+`">
                <option value="" selected>Select Premium Comparision</option>
                <option value="Low">Low</option>
                <option value="Lower">Lower</option>
                <option value="Lowest">Lowest</option>
                <option value="High">High</option>
                <option value="Higher">Higher</option>
                <option value="Highest">Highest</option>
          </select>
        </div>

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="premium_type_`+i+`">Premium Type</label>
            <select class="form-select" formControlName="premium_type_`+i+`" id="premium_type_`+i+`">
                  <option value="0" selected>Select Premium Type</option>
            </select>
        </div>

        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="tag_name_`+i+`">Tag Name</label>
          <input type="text" class="form-control" formControlName="tag_name_`+i+`" id="tag_name_`+i+`" placeholder="Tag Name" value="`+aa[i]['tag_name']+`" />
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="is_differential_`+i+`">Is Differential</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="is_differential_`+i+`" value="Yes" name="is_differential_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="is_differential_`+i+`" value="No" name="is_differential_`+i+`" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                    </div>
                </div>
            </div>
        </div>

          <div class="col-sm-12 mt-3">
            <div class="form-group" name="visible_from_SI_value[]" id="visible_from_SI_value_${i}">
              <label class="bs-form-label">Visible From SI Value</label><br>
            </div>
          </div>
        
        <div class="col-sm-2 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+i+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
        <div class="col-sm-12"><hr></div>
      </div>      
      `;

      $('.newbenefitData').append(html);

      $('head').append('<style>.form-check input[type="checkbox"] { display: inline-block; width: auto; margin-right: 5px; position: relative; padding: 10px;} </style><style>.form-check label { display: inline-block; vertical-align: middle; }</style><style>.form-check{position: relative; display: inline-block; margin-top: 0px; margin-bottom: 5px; padding-left: 5px; }</style>');

      var value_cover_by = aa[i]['cover_by'];
      if(value_cover_by == 'by_value'){
        $("input[name=cover_by_"+i+"][value=by_value]").attr('checked', 'checked');
      }else{
        $("input[name=cover_by_"+i+"][value=by_SI]").attr('checked', 'checked');
      }
      

      var value_sum_insured_cap = aa[i]['sum_insured_cap'];
      if(value_sum_insured_cap == 'Yes'){
        $("input[name=sum_insured_cap_"+i+"][value=Yes]").attr('checked', 'checked');
      }else{
        $("input[name=sum_insured_cap_"+i+"][value=No]").attr('checked', 'checked');
      }

      var value_premium_by = aa[i]['premium_by'];
      if(value_premium_by == 'by_value'){
        $("input[name=premium_by_"+i+"][value=by_value]").attr('checked', 'checked');
      }else{
        $("input[name=premium_by_"+i+"][value=by_premium]").attr('checked', 'checked');
      }

      var is_differential = aa[i]['is_differential'];
      if(is_differential == 'Yes'){
        $("input[name=is_differential_"+i+"][value=Yes]").attr('checked', 'checked');
      }else{
        $("input[name=is_differential_"+i+"][value=No]").attr('checked', 'checked');
      }
      
      var valcovertype = '#cover_type_' +i;
      $(valcovertype).val(aa[i]['cover_type']);

      var valpremium_type = '#premium_type_' +i;
      $(valpremium_type).val(aa[i]['premium_type']);

      var valplan_name = '#plan_name_' +i;
      $(valplan_name).val(aa[i]['plan_name']);

      var premium_comparison = '#premium_comparison_' +i;
      $(premium_comparison).val(aa[i]['premium_comparison']);

      //Dropdown
      for(var j=0; j<this.Covertype.length; j++){
        var val = '#' + 'cover_type_'+ i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Covertype[j]['value_id'])
            .text(this.Covertype[j]['name']));
      }

      //Dropdown
      for(var k=0; k<this.Premiumtype.length; k++){
        var val = '#' + 'premium_type_'+ i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Premiumtype[k]['value_id'])
            .text(this.Premiumtype[k]['name']));
      }

      //Dropdown
      for(var h=0; h<this.PlanName.length; h++){
        var val = '#' + 'plan_name_'+ i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.PlanName[h]['id'])
            .text(this.PlanName[h]['title']));
      } 
          if(aa[i]['visible_from_SI_value'] != undefined){
            var visibleSI = aa[i]['visible_from_SI_value'];
          }

      for (var m = 0; m < this.InfoArrayMultiple.length; m++) {
        var checkboxId = 'visible_from_SI_value_' + m + '_' + i;
        var checkboxLabel = this.InfoArrayMultiple[m]['name'];
        
        // Create checkbox element
        var checkbox = $('<input>').attr({
            type: 'checkbox',
            id: checkboxId,
            name: 'visible_from_SI_value_' + i,
            value: this.InfoArrayMultiple[m]['id']
        });

        for(var b=0; b < visibleSI.length; b++){          
          if(visibleSI[b] == this.InfoArrayMultiple[m]['id']){
            checkbox.attr('checked', 'checked');
          }
        }
        
        // Create label for checkbox
        var label = $('<label>').attr('for', checkboxId).text(checkboxLabel);
    
        // Create div to contain checkbox and label
        var containerDiv = $('<div>').addClass('form-check');
    
        // Append checkbox and label to container div
        containerDiv.append(checkbox).append(label);
    
        // Append container div to the target element
        $('#visible_from_SI_value_' + i).append(containerDiv);
  
      }
    
      let flag = this;
      $("#Data_benefit_row_"+i).on('click',function(e) {
        flag.removeRow_Benefit(e);
      });

      $("#add_on_SI_"+i).on('keypress',function(e) {
        flag.isnumberBenefit_benefit(e);
      });

      $("#premium_value_"+i).on('keypress',function(e) {
        flag.isnumberBenefit_benefit(e);
      });

      $("#plan_name_"+i).on('change',function(e) {
        // console.log(11)
        flag.plan_name(e);
      });

      $("#plan_name_"+i).trigger('change');     

      
      $("#visible_from_SI_value_"+i + " input[type='checkbox']").on('click', function(e) {
        var value = $(this).val(); 
        flag.onCheckboxChange(e, value, i);
    });

    }

    for(var i = 0; i< aa.length; i++){
      $('#cover_type_' + i).val(aa[i]['cover_type']);
      $('#premium_type_' + i).val(aa[i]['premium_type']);
      $('#plan_name_' + i).val(aa[i]['plan_name']);
      // $('#visible_from_SI_value_' + i).val(aa[i]['visible_from_SI_value']);
    }
      
  }

  savebenefit_update(){

    const delindex:any = this.update_id;
    if (delindex > -1) {
      this.arrayAddOn.splice(delindex, 1);
      this.addonbaseArray.splice(delindex, 1);
    }

    // const dynamicArrays: any = {};

    // console.log(this.checkedValues);

    // this.checkedValues.forEach(item => {
    //     if (dynamicArrays[item.cnt]) {
    //         dynamicArrays[item.cnt].push(item);
    //     } else {
    //         dynamicArrays[item.cnt] = [item];
    //     }
    // });

    // let result: string[][] = [];

    // // Get the maximum count value
    // const maxCount = Math.max(...Object.keys(dynamicArrays).map(Number));

    // // Initialize the result array with empty arrays up to the maximum count value
    // for (let i = 0; i <= maxCount; i++) {
    //     result.push([]);
    // }

    // // Populate the result array with values from the data object
    // Object.entries(dynamicArrays).forEach(([countStr, values]) => {
    //     const count = parseInt(countStr);
    //     (values as { cnt: number; value: string; }[]).forEach(({ value }) => {
    //         result[count].push(value);
    //     });
    // });
    
    // // Remove empty arrays
    // result = result.filter(arr => arr.length > 0);

    console.log(this.checkedValues);

    interface GroupedObject {
      cnt: number;
      values: string[];
    }

    // Create a map to group objects by their `cnt` values
    const grouped = this.checkedValues.reduce((acc: { [key: number]: GroupedObject }, obj) => {
      if (!acc[obj.cnt]) {
          acc[obj.cnt] = { cnt: obj.cnt, values: [] };
      }
      acc[obj.cnt].values.push(obj.value);
      return acc;
    }, {});

    // Convert the grouped objects into an array
    const resultArray: GroupedObject[] = Object.values(grouped);


    let visbleSICheckbox:any = [];

    for(var l=0; l < this.checkedValues.length; l++){
      visbleSICheckbox.push(this.checkedValues[l].value);
    }

    // console.log(visbleSICheckbox);
    this.PopupBenefits="none";
    let val1:any = $("select[id=benefit_name_val] option:selected").text();
    let val2:any = $('#benefit_description_val').val();
    let val3 = this.selectedItemsSI;
    let val4 = $('input[name="benefit_mandatory"]:checked').val();
    let val5 = $('input[name="prorata_calculation_applicable"]:checked').val();
    let val6 = $('#mandatory_not_selected').val();
    let val7 = $('#benefit_name_val').val();

    let arr:any = [];
    // console.log(this.cnt_BenefitData);
    for(var i = 0; i < this.cnt_BenefitData; i++){  
      if($('#feature_name_'+i).val() != undefined){
        // var index = i - 1;        

        arr[i] = {
          id:i,
          plan_name: $('#plan_name_'+i).val(),
          other_plan_name: $('#other_plan_name_'+i).val(),
          premium_comparison: $('#premium_comparison_'+i).val(),
          tag_name: $('#tag_name_'+i).val(),
          is_differential: $('input[name="is_differential_'+i+'"]:checked').val(),
          feature_name: $('#feature_name_'+i).val(), 
          feature_description:$('#feature_description_'+i).val(),
          add_on_SI: $('#add_on_SI_'+i).val(), 
          cover_by: $('input[name="cover_by_'+i+'"]:checked').val(), 
          cover_type: $('#cover_type_'+i).val(), 
          cover_type_text: $("select[id=cover_type_"+i+"] option:selected").text(),
          sum_insured_cap: $('input[name="sum_insured_cap_'+i+'"]:checked').val(),       
          premium_by: $('input[name="premium_by_'+i+'"]:checked').val(),
          premium_value: $('#premium_value_'+i).val(), 
          premium_type: $('#premium_type_'+i).val(),  
          premium_type_text: $("select[id=premium_type_"+i+"] option:selected").text(),         
          visible_from_SI_value: resultArray[i].values,
        };
      }        
    }

    this.addonbaseArray.push(
      {
        "id":this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,      
        "mandatory_not_selected": val6,      
        "benefit_id": val7,      
      }
    );

    let arrbase = [];

    arrbase.push(
      {
        "id":this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "benefit_mandatory": val4,
        "prorata_calculation_applicable": val5,      
        "mandatory_not_selected": val6,      
        "benefit_id": val7,      
      }
    );

    this.arrayAddOn.push({
      "arrbase": arrbase, "arrmultiple":arr
    });
    
    console.log(this.arrayAddOn);

    $('.newbenefitData').empty();
    // this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');
    this.cnt_BenefitData = 1;
    this.addonbasecount = this.addonbasecount + 1;

  }  

  /********************* Features ************************/

  showPopupFeatures(){

    this.feature_flag = 0;

    this.PopupFeatures = "block";
    this.getMultiSelectRelation();
    this.getCoverType();
    this.getPremiumType();

    let val1:any = $('#feature_desc').val();
    $('#feature_desc_val').val(val1);

    this.features_info.controls['to_hide_detail'].setValue('Yes');
    this.features_info.controls['relation_inclusive'].setValue('Yes');
    this.features_info.controls['sum_insured'].setValue('');
    this.features_info.controls['sum_insured_by'].setValue('by_value');
    this.features_info.controls['sum_insured_type'].setValue('0');
    this.features_info.controls['premium'].setValue('');
    this.features_info.controls['premium_by'].setValue('by_value');
    this.features_info.controls['premium_type'].setValue('0');
    this.features_info.controls['is_optional'].setValue('Yes');
    this.features_info.controls['contributions'].setValue('0');
    this.selectedItemsRelation = [];

  }

  savefeature(){

    this.PopupFeatures="none";

    let val1:any = $('#feature_desc_val').val();
    let val2:any = this.selectedItemsRelation;
    let val3 = $('#sum_insured_features').val();        
    let val4 = $('#sum_insured_type').val();        
    let val5 = $('input[name="to_hide_detail"]:checked').val();
    let val6 = $('input[name="relation_inclusive"]:checked').val();
    let val7 = $('input[name="sum_insured_by"]:checked').val();
    let val8 = $('input[name="is_optional"]:checked').val();
    let val9 = $('#premium_type').val();
    let val10 = $('input[name="premium_by"]:checked').val();
    let val11 = $('#premium').val();
    let val14 = $('#contributions').val();

    let val12:any = $("select[id=sum_insured_type] option:selected").text();
    let val13:any = $("select[id=premium_type] option:selected").text();

   
    this.FeatureTableArray.push(
      {
        
        "feature_desc": val1,
        "relations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "sum_insured_type_name": val12,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_type_name": val13,
        "premium_by": val10,
        "premium": val11,
        "contributions": val14,
        
      }
    )
  
    // show this array in table of added benefit
    console.log(this.FeatureTableArray);
    $('#feature_desc').val('');
    $('#feature_desc_val').val('');
    $('#relations').val('');
    $('#sum_insured_features').val('');
    $('#sum_insured_type').val('0');
    $('#premium_by').val('');
    $('#premium_type').val('0');

  } 

  showPopupFeatures_edit(id:any){

    this.feature_flag = 1;
    this.feature_update_id = id

    this.PopupFeatures = "block";
    
    
    let val1:any = this.FeatureTableArray[id]['feature_desc'];
    $('#feature_desc_val').val(val1);

    let val2:any =  this.FeatureTableArray[id]['to_hide_detail'];
    $('input[name="to_hide_detail"]').filter(`[value="${val2}"]`).prop('checked', true);

    let val3:any =  this.FeatureTableArray[id]['relation_inclusive'];
    $('input[name="relation_inclusive"]').filter(`[value="${val3}"]`).prop('checked', true);

    let val4:any = this.FeatureTableArray[id]['sum_insured'];
    $('#sum_insured_features').val(val4);

    let val5:any =  this.FeatureTableArray[id]['sum_insured_by'];
    $('input[name="sum_insured_by"]').filter(`[value="${val5}"]`).prop('checked', true);

    setTimeout(() => {
      this.getCoverType();
    }, 1000);

    let val6:any = this.FeatureTableArray[id]['sum_insured_type'];
    for(var i = 0; i < this.Covertype.length; i++){
      if(val6 == this.Covertype[i]['value_id']){
        this.features_info.controls['sum_insured_type'].setValue(val6);
      }
    }
  
    let val7:any = this.FeatureTableArray[id]['premium'];
    $('#premium').val(val7);

    let val8:any =  this.FeatureTableArray[id]['premium_by'];
    $('input[name="premium_by"]').filter(`[value="${val8}"]`).prop('checked', true);

    setTimeout(() => {
      this.getPremiumType();
    }, 1000);  

    let val9:any = this.FeatureTableArray[id]['premium_type'];
    for(var k = 0; k < this.Premiumtype.length; k++){
      if(val9 == this.Premiumtype[k]['value_id']){
        this.features_info.controls['premium_type'].setValue(val9);
      }
    }

    let val10:any =  this.FeatureTableArray[id]['is_optional'];
    $('input[name="is_optional"]').filter(`[value="${val10}"]`).prop('checked', true);

    let val12:any = this.FeatureTableArray[id]['contributions'];
    $('#contributions').val(val12);

    setTimeout(() => {
      this.getMultiSelectRelation();
    }, 1000);  

    this.selectedItemsRelation = [];
      let val17: any = this.FeatureTableArray[id]['relations'];

      console.log(val17);
      console.log(this.selectedItemsRelation);
      console.log(this.InfoArrayMultipleRelations);

      for (let i = 0; i < val17.length; i++) { 
          let selectedItem = {
              relation_id: val17[i]['relation_id'],
              relation: val17[i]['relation']
          };
            
          this.selectedItemsRelation.push(selectedItem);
      }

      setTimeout(() => {
          this.features_info.patchValue({
              relations: this.selectedItemsRelation
          });
          this.cd.detectChanges();
      }, 1000);

      console.log(this.selectedItemsRelation);
  }

  savefeature_update(){
    const delindex:any = this.feature_update_id;
    if (delindex > -1) {
      this.FeatureTableArray.splice(delindex, 1);
    }

    this.PopupFeatures="none";

    let val1:any = $('#feature_desc_val').val();
    let val2:any = this.selectedItemsRelation;
    let val3 = $('#sum_insured_features').val();        
    let val4 = $('#sum_insured_type').val();        
    let val5 = $('input[name="to_hide_detail"]:checked').val();
    let val6 = $('input[name="relation_inclusive"]:checked').val();
    let val7 = $('input[name="sum_insured_by"]:checked').val();
    let val8 = $('input[name="is_optional"]:checked').val();
    let val9 = $('#premium_type').val();
    let val10 = $('input[name="premium_by"]:checked').val();
    let val11 = $('#premium').val();
    let val14 = $('#contributions').val();

    let val12:any = $("select[id=sum_insured_type] option:selected").text();
    let val13:any = $("select[id=premium_type] option:selected").text();

   
    this.FeatureTableArray.push(
      {
        
        "feature_desc": val1,
        "relations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "sum_insured_type_name": val12,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_type_name": val13,
        "premium_by": val10,
        "premium": val11,
        "contributions": val14,
        
      }
    )

    console.log(this.FeatureTableArray);
    $('#feature_desc').val('');
    $('#feature_desc_val').val('');
    $('#relations').val('');
    $('#sum_insured_features').val('');
    $('#sum_insured_type').val('0');
    $('#premium_by').val('');
    $('#premium_type').val('0');

  }

  removeFeatureTableArray(i:any){
    this.FeatureTableArray.splice(i, 1);
    console.log(this.FeatureTableArray);
  }

}
