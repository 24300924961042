import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ClientCreationService } from './client-creation';
import { ToastService } from '../../toast.service';

  interface MemberType {
  id: number;
  name: string;
}
// import data from '../../assets/jsonfiles/country.json';
@Component({
  selector: 'app-policy-edit',
  templateUrl: './policy-edit.component.html',
  styleUrls: ['./policy-edit.component.css']
})

  export class PolicyEditComponent implements OnInit {

    public basicInfo:FormGroup;
    public familyInfo:FormGroup;
    public SIPremiumInfo:FormGroup;
    public contactInfo:FormGroup;
    public additionalInfo:FormGroup;
    public claimInfo:FormGroup;
    public step7Info:FormGroup;

    private stepper: Stepper;

    show = false;
    fullScreen = true;
    template = ``;

    basicInfoSubmitted: boolean = false;
    familyInfoSubmitted: boolean = false;
    SIPremiumInfoSubmitted: boolean = false;
    contactInfoSubmitted: boolean = false;
    additionalInfoSubmitted: boolean = false;
    claimInfoSubmitted: boolean = false;
    step7InfoSubmitted: boolean = false;

    country: any;
    countryCodes: any;
    statesarr1: any;
    statesarr2: any;
    pincode: any;

    FileDownload: any;

    resstatus = localStorage.getItem('resstatus');
    pagehide = localStorage.getItem('pagehide');
    useremail = localStorage.getItem('username');
    phonenumber = localStorage.getItem('mobile_no');

    validmsg: any;
    popup_title: any;
    listed: any;

    displayStyleThankyou = "none";

    /******************************/
    policyType: any = [];
    policySubType: any = [];
    parentpolicyType: any = [];
    parentpolicyTypeTopup: any = [];
    Insurer:any = [];
    TPA:any = [];
    employeeTabView:any = [];
    brokerBranchName:any = [];
    ClientBranchName:any = [];
    companyDetails:any = [];
    entityDetails:any = [];
    entityDetails_Insurer:any = [];
    cnt = 1;
    hr_info_arr: any = {};
    midterm_enrollement:any = [];
    co_insurer_perc_cnt: any = 0;
    co_insurer_perc_sum: any = 0;
    /******************************/

    /*******************************/
    allowedArr: any = {};
    cnt_allowed = 1;
    Membertype: any = [];
    coverType: any = [];
    SIType: any = [];
    SIBasis: any = [];
    premiumBasis: any = [];
    Spousemidterm: any = [];
    Childmidterm: any = [];
    memberType:any = [];    
    /*******************************/

    sumInsured_Basis: any;
    premiumType: any;
    Validataionflag:any = 0;


    /*******************************/
    roleDesignationType: any = [];
    emailID: any = [];
    /*******************************/

    documentType: any = [];
    document_file: any;
    policy_feature_document: any;
    uploadcnt:any = 1;
    uploadExtraArr: any = {};

    spousedata:any = {};
    ChilData: any = {};

    /*******************************/

    RowCount:any = 0;
    GenerateSICount:any = [];
    diffArr: any = [];
    diffval: any;
    cnt_custom: any = 1;
    CustomArr: any = {};

    /*******************************/

    cnt_add_benefit: any = 1;
    add_benefit_arr: any = {};

    cnt_broker: any = 1;
    brokerArr_step4: any = {};

    cnt_employer: any = 1;
    employer_step4Arr: any = {};

    cnt_tpa: any = 1;
    tpa_step4Arr: any = {};

    cnt_Insurer: any = 1;
    Insurer_step4Arr: any = {};

    installmentArr: any = [];

    entityDetails_e:any = [];
    entityDetails_tpa:any = [];

    relationDetails:any = [];

    displayinceptionmsg = "none";
    displayfamilymsg = "none";
    Standardmsgtoggle = "none";
    SuccessMsg = "none";
    Validation_Error_message:any;

    policysamplefile:any;
    downloadbuttontooltip:any;

    MonthlyEquitible:any = [];
    AdditionalCoverArray:any = [];
    NoOfTimesSalary:any = [];

    employee_file:any;
    emmployeefile_id:any = 0;
    grade_SI:any = [];
    grade_SP:any = [];
    family_SI:any = [];
    family_SP:any = [];

    family_group_SI:any = [];
    family_group_SP:any = [];

    unnamed:any = [];
    permily_age:any = [];

    step7file: any;

    contactroleArr:any = [];
    contactroleemployerArr:any = [];

    basepolicydropdown:any = [];

    edit_file_url:any;
    edit_file_urlName:any;
    edit_file_url1:any;
    edit_file_url1Name:any;

    downloadlink:any;

    postRateArray:any = [];
    premilyFilecheck :any  = false;

    min_enrollment_end_date:any;

    minDate:any;
    maxDate:any;
    afterstartselected:any;

    constructor(private fb: FormBuilder, private clientCreationService: ClientCreationService, private route: Router, private toastService: ToastService) {

      this.route.events.subscribe((e) => {
        if (this.resstatus == 'false' || this.resstatus == null) {
            this.route.navigate(['/home']);
        }
      });

    }

    ngOnInit(): void {

      this.initGroup();

    }

    initGroup(){

      this.show = true;

      setTimeout(() =>{
        this.show = false;
      },5000);

      let master_policies_id:any = localStorage.getItem('master_policies_id')
      localStorage.setItem('master_policies_id', master_policies_id);
      
      this.getdocumenttype();

      // this.getpolicytype();
      // this.getparentpolicytypes();
      this.getInsurer();
      this.getemployeeTabView();
      this.getbrokerBranchName();
      this.getcompanyDetails();
      this.getTPA();
      this.getmidtermEnrollement();

      this.getspousemidterm();
      this.getChildmidterm();

      this.getSItype();
      this.getSIBasis();
      this.getpremiumBasis();

      this.getroleDesignationType();
      this.getemailID();

      this.getRelations();

      // this.getStep1Data();
      // this.getStep2Data();
      // this.getStep3Data();
      // this.getStep4Data();
      // this.getStep5Data();
      // this.getStep6Data();
      // this.getStep7Data();

      this.getcontactrole();
      this.getcontactroleemployer();

      // Define Stepper
      const stepperElement = document.querySelector('#stepper1');
      if(stepperElement !== null){
      this.stepper = new Stepper(stepperElement , {
            linear: false,
            animation: true
          })

          stepperElement.addEventListener('shown.bs-stepper', (event) => {
            const customEvent = event as CustomEvent;
            const stepIndex = customEvent.detail.indexStep;
            this.handleStepChange(stepIndex);
          });
      }

      
      // Step 1
      this.basicInfo = this.fb.group({
        //basic details
        policy_number: new FormControl('', Validators.required),
        policy_name: new FormControl('', Validators.required),
        master_Group_Policy_No: new FormControl(''),
        policy_type_id: new FormControl('', Validators.required),
        policy_sub_type_id: new FormControl('', Validators.required),
        parent_policy_type_id: new FormControl(''),
        topup_parent_policy_type_id: new FormControl(''),
        insurer_id: new FormControl('',Validators.required),
        insurer_perc: new FormControl('',Validators.required),
        tpa_id: new FormControl(''),
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl('', Validators.required),
        employee_tab_view: new FormControl('', Validators.required),
        broker_commision: new FormControl('', Validators.required),
        co_oprate_buffer: new FormControl('', Validators.required),
        broker_branch_id: new FormControl(''),
        client_branch_id: new FormControl('0'),
        topup_setup: new FormControl(''),
        show_gst_flag: new FormControl('No'),
        hide_cover: new FormControl('', Validators.required),
        hide_premium: new FormControl('', Validators.required),
        show_enhance: new FormControl('', Validators.required),
        is_prorata_premium_allowed: new FormControl('', Validators.required),
        sum_with_Base_Policy: new FormControl('Yes', Validators.required),
        description: new FormControl(''),
        //company details
        company_id: new FormControl(''),
        sub_entity: new FormControl(''),
        broker_verification_needed: new FormControl(''),
        employer_verification_needed: new FormControl(''),
        //enrolment details
        enrollement_status: new FormControl(''),
        enrollment_window_close_mail_effective_date: new FormControl(''),
        midterm_enrollement: new FormControl(''),
        enrollment_allowed_days_mid_term: new FormControl(''),
        enrollment_start_date: new FormControl(''),
        enrollment_end_date: new FormControl(''),
      });

      // Step 2
      this.familyInfo = this.fb.group({
        no_of_members_allowed: new FormControl('', Validators.required),
        employee_included: new FormControl('Yes'),
        member_type: new FormControl('Self'),
        member_typeselect: new FormControl('0'),
        no_age: new FormControl(''),
        min_age: new FormControl(''),
        max_age: new FormControl(''),

        parent_cross_section: new FormControl(''),
        no_parent_parentInLaw: new FormControl(''),
        no_of_parents_or_parentsinlaw: new FormControl(''),

        //spouse
        no_age_spouse: new FormControl(''),
        min_age_spouse: new FormControl(''),
        max_age_spouse: new FormControl(''),
        marraige_certificate:new FormControl(''),
        no_of_spouse:new FormControl(''),
        midterm_allowed_for_spouse:new FormControl(''),
        spouse_enrolment_allowed_days:new FormControl(''),
        spouse_enrolment_considered:new FormControl(''),
        lgbt:new FormControl(''),

        //Child
        Child_age_depend:new FormControl(''),
        no_age_Children:new FormControl(''),
        min_age_Child:new FormControl(''),
        max_age_Child:new FormControl(''),
        birth_certificate:new FormControl(''),
        age_difference_Child:new FormControl(''),
        no_of_Child:new FormControl(''),
        midterm_allowed_for_Child:new FormControl(''),
        special_Child:new FormControl(''),
        unmarried_Child:new FormControl(''),
        adopted_Child:new FormControl(''),
        no_of_twin_child:new FormControl(''),
        child_enrolment_allowed_days:new FormControl(''),
        child_enrolment_considered:new FormControl(''),

        //Parent
        Parent_age_depend:new FormControl(''),
        no_age_Parent:new FormControl(''),
        min_age_parent:new FormControl(''),
        max_age_parent:new FormControl(''),
        age_difference_parent:new FormControl(''),
        no_of_Parents:new FormControl('0'),
        employee_allowed_Parents:new FormControl(''),

        //Parents In Law
        Parents_In_Law_age_depend:new FormControl(''),
        no_age_Parents_In_Law:new FormControl(''),
        min_age_parent_in_law:new FormControl(''),
        max_age_parent_in_law:new FormControl(''),
        age_difference_parent_in_law:new FormControl(''),
        no_of_Parents_In_Laws:new FormControl('0'),
        employee_allowed_Parents_In_Law:new FormControl(''),

        //Sibling
        no_of_Siblings:new FormControl(''),
        no_age_Sibling:new FormControl(''),
        min_age_sibling:new FormControl(''),
        max_age_sibling:new FormControl(''),

      });

      // Step 3
      this.SIPremiumInfo = this.fb.group({
        cover_type: new FormControl('', Validators.required),
        salary_type: new FormControl(''),
        basic_policy_mapping: new FormControl(''),

        SI_Type: new FormControl('', Validators.required),
        SI_Basis: new FormControl('', Validators.required),
        premium_Basis: new FormControl('', Validators.required),

        tax_status: new FormControl(),
        employee_premium_tax: new FormControl(''),

        tax_status1: new FormControl(),
        employee_premium_tax1: new FormControl(''),

        tax_status2: new FormControl(),
        employee_premium_tax2: new FormControl(''),

        tax_status3: new FormControl(),
        employee_premium_tax3: new FormControl(''),

        minimum_SI: new FormControl(''),
        maximum_SI: new FormControl(''),
        interval_between_min_max_SI: new FormControl(''),

        ca_employer: new FormControl(''),
        ca_employee: new FormControl(''),
        hide_employer_contribution: new FormControl(''),
        hide_employee_contribution: new FormControl(''),

        self_additional_employer: new FormControl(''),
        self_additional_employee: new FormControl(''),
        self_additional_premium: new FormControl(''),

        parent_in_laws_additional_employer: new FormControl(''),
        parent_in_laws_additional_employee: new FormControl(''),
        parent_in_laws_additional_premium: new FormControl(''),

        siblings_additional_employer: new FormControl(''),
        siblings_additional_employee: new FormControl(''),
        siblings_additional_premium: new FormControl(''),

        parent_additional_employer: new FormControl(''),
        parent_additional_employee: new FormControl(''),
        parent_additional_premium: new FormControl(''),

        spouse_additional_employer: new FormControl(''),
        spouse_additional_employee: new FormControl(''),
        spouse_additional_premium: new FormControl(''),

        children_additional_employer: new FormControl(''),
        children_additional_employee: new FormControl(''),
        children_additional_premium: new FormControl(''),

        special_child_contribution_all_employee: new FormControl(''),
        special_child_contribution_all_employer: new FormControl(''),
        special_child_contribution_all_premium: new FormControl(''),

        unmarried_child_contribution_all_employee: new FormControl(''),
        unmarried_child_contribution_all_employer: new FormControl(''),
        unmarried_child_contribution_all_premium: new FormControl(''),

        unmarried_child_contribution_additional_employee: new FormControl(''),
        unmarried_child_contribution_additional_employer: new FormControl(''),
        unmarried_child_contribution_additional_premium: new FormControl(''),

        special_child_contribution_additional_employee: new FormControl(''),
        special_child_contribution_additional_employer: new FormControl(''),
        special_child_contribution_additional_premium: new FormControl(''),

        use_flex:new FormControl(),
        payroll:new FormControl(),
        installment_allowed:new FormControl(),
        installment_prorata:new FormControl('Yes'),
        installment_type:new FormControl(),
        monthly_equitible_period:new FormControl(),

        additional_cover:new FormControl(),
        additional_benefit_label:new FormControl(),
        additional_benefit_limit:new FormControl(),

        no_of_times_salary:new FormControl(),
        lowest_SI_limit:new FormControl(),
        highest_SI_limit:new FormControl(),

        total_employee:new FormControl(),
        total_SI:new FormControl(),
        total_premium:new FormControl(),

        total_employee_unnamed:new FormControl(),
        gpa_individual_cover:new FormControl(),
        total_SI_unnamed:new FormControl(),
        total_premium_unnamed:new FormControl(),

        family_SI_file:new FormControl(),
        family_SP_file:new FormControl(),

        family_group_SI_file:new FormControl(),
        family_group_SP_file:new FormControl(),
      });

      // Step 4
      this.contactInfo = this.fb.group({
        b_role_designation_type: new FormControl('', Validators.required),
        b_emailID: new FormControl(null, Validators.required),
        b_contactname: new FormControl('', Validators.required),
        b_mobileno: new FormControl('', Validators.required),
        b_level: new FormControl('', Validators.required),

        e_role_designation_type: new FormControl('', Validators.required),
        e_emailID: new FormControl(null, Validators.required),
        e_contactname: new FormControl('', Validators.required),
        e_mobileno: new FormControl('', Validators.required),
        e_level: new FormControl('', Validators.required),

        tpa_role_designation_type: new FormControl(''),
        tpa_emailID: new FormControl(''),
        tpa_contactname: new FormControl(''),
        tpa_mobileno: new FormControl(''),
        tpa_level: new FormControl(''),

        insurer_id_step4: new FormControl(''),
        Insurer_emailID: new FormControl(''),
        Insurer_contactname: new FormControl(''),
        Insurer_mobileno: new FormControl(''),
        Insurer_level: new FormControl(''),

      });

      // Step 5
      this.additionalInfo = this.fb.group({
        add_benefit: new FormControl(''),
        opening_cd_balance: new FormControl(''),
        cd_balance_thrsold: new FormControl(''),
        inception_premium: new FormControl(''),
        inception_premium_installment: new FormControl(''),
        nominee_description: new FormControl(''),
        nomination_applicable: new FormControl('Yes'),
        nomination_mandatory: new FormControl('Yes'),
        declaration_mandatory: new FormControl('Yes'),
        allowed_members: new FormControl('Selected Members'),

      });

      // Step 6
      this.claimInfo = this.fb.group({
        document_name: new FormControl(''),
        document_type: new FormControl(''),
        document_mandatory: new FormControl('No'),
        no_of_back_days: new FormControl('', Validators.required),
        digital_claim: new FormControl('Yes', Validators.required),
        claim_intimate_mandatory: new FormControl('Yes', Validators.required),
      });

      // Step 7
      this.step7Info = this.fb.group({

      });


      const today = new Date();
      const yearBack = new Date(today);
      yearBack.setFullYear(today.getFullYear() - 1);
      const yearForward = new Date(today);
      yearForward.setFullYear(today.getFullYear() + 1);

      this.minDate = yearBack.toISOString().split('T')[0];
      this.maxDate = yearForward.toISOString().split('T')[0];

    }

    handleStepChange(stepIndex: any) {
      switch (stepIndex) {
        case 0:
          this.show = true;
          this.getStep1Data();
          this.getpolicytype();

          setTimeout(() => {
            this.show = false;
          }, 2000);

          break;
        case 1:
          this.show = true;
          this.getStep2Data();
          this.getMembertype();
          setTimeout(() => {
            this.show = false;
          }, 2000);

          break;
        case 2:
          this.show = true;
          this.getStep3Data();
          this.getCovertype();
          setTimeout(() => {
            this.show = false;
          }, 3000);

          break;
        case 3:
          this.show = true;
          this.getStep4Data();
          setTimeout(() => {
            this.show = false;
          }, 2000);
         
          break;

        case 4:
          this.show = true;

          this.getStep5Data();
          setTimeout(() => {
            this.show = false;
          }, 2000);
          break;

        case 5:
          this.show = true;

          this.getStep6Data();
          setTimeout(() => {
            this.show = false;
          }, 2000);
          
          break;
        case 6:
          this.show = true;

          this.getStep7Data();
          setTimeout(() => {
            this.show = false;
          }, 2000);

          break;
        default:
          console.warn(`No API function defined for step index ${stepIndex}`);
      }
    }

    isNumber(evt: any) {
      // Get the input value
      const inputValue: string = evt.target.value;
  
      // Check if the entered character is a number or a decimal point
      const charCode: number = (evt.which) ? evt.which : evt.keyCode;
  
      // Allow only numbers, decimal point, backspace, and delete keys
      if (
          (charCode >= 48 && charCode <= 57) || // Numbers 0-9
          charCode === 46 || // Decimal point
          charCode === 8 || // Backspace
          charCode === 46 || // Delete
          charCode === 37 || // Left arrow
          charCode === 39 || // Right arrow
          charCode === 9 // Tab
      ) {
          // Ensure only one decimal point is entered
          if (charCode === 46 && inputValue.indexOf('.') !== -1) {
              evt.preventDefault();
          }
          return true;
      } else {
          evt.preventDefault();
          return false;
      }
    }

    isNumberDays(evt: any): boolean {
      // Get the input value
      const inputValue: string = evt.target.value;
  
      // If the input value is 0, clear the field immediately
      if (inputValue === '0') {
          evt.target.value = '';
          return false; // Stop further processing since the field is cleared
      }
  
      // Check if the entered character is a number
      const charCode: number = (evt.which) ? evt.which : evt.keyCode;
  
      // Allow only numbers, backspace, delete, left arrow, right arrow, and tab keys
      if (
          (charCode >= 48 && charCode <= 57) || // Numbers 0-9
          charCode === 8 || // Backspace
          charCode === 37 || // Left arrow
          charCode === 39 || // Right arrow
          charCode === 9 // Tab
      ) {
          return true;
      } else {
          evt.preventDefault();
          return false;
      }
    }
    
    isNumberDays_2(evt: any): boolean {
      // Get the input value
      const inputValue: string = evt.target.value;        
  
      // Check if the entered character is a number
      const charCode: number = (evt.which) ? evt.which : evt.keyCode;
  
      // Allow only numbers, backspace, delete, left arrow, right arrow, and tab keys
      if (
          (charCode >= 48 && charCode <= 57) || // Numbers 0-9
          charCode === 8 || // Backspace
          charCode === 37 || // Left arrow
          charCode === 39 || // Right arrow
          charCode === 9 // Tab
      ) {
          return true;
      } else {
          evt.preventDefault();
          return false;
      }
    }

    onInput(event: any): void {
      const input = event.target.value;
      event.target.value = input.replace(/[^0-9]/g, '');
    }    

    checkNoDays(evt: any){
      if (evt.target.value == 0) {
        this.claimInfo.controls['no_of_back_days'].setValue('');
      }
    }


    /************ ALL GET Step 1 ***************/
    getpolicytype(){
      this.clientCreationService.getpolicytype()
        .subscribe((result) => {

          this.policyType = result.data;

        }, (error) => {

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
      });
    }
    getpolicySubtype(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_type_id":this.basicInfo.controls['policy_type_id'].value,
      }
      this.clientCreationService.getpolicySubtype(postdata)
        .subscribe((result) => {

          this.policySubType = result.data;

        }, (error) => {
            // console.log(result);
      });

      //Top up
      if(this.basicInfo.controls['policy_type_id'].value == '2'){
        $('.Sum_with_Base_Policy_Div').css('display', 'block');
        $('.master_Group_Policy_No_Div').css('display', 'block');
        $('.topup_setup_div').css('display', 'block');
      }else{
        $('.Sum_with_Base_Policy_Div').css('display', 'none');
        $('.master_Group_Policy_No_Div').css('display', 'none');
        $('.topup_setup_div').css('display', 'none');
      }

    }
    getparentpolicytypes(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "comp_type_id":12,
      }
      this.clientCreationService.getparentpolicytypes(postdata)
        .subscribe((result) => {

          this.parentpolicyType = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getparentpolicytypestopup(){

      let policy_sub_type: any  = this.basicInfo.controls['policy_sub_type_id'].value;
      var postdata: any;
 
       if(policy_sub_type == 4){
         postdata = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":13,
         }
       }
       if(policy_sub_type == 5){
         postdata = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":14,
         }
       }
       if(policy_sub_type == 6){
         postdata = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":15,
         }
       }
       
       this.clientCreationService.getparentpolicytypes(postdata)
         .subscribe((result) => {
 
           this.parentpolicyTypeTopup = result.data;
 
         }, (error) => {
             // console.log(result);
       });
     }
    getInsurer(){
      this.clientCreationService.getInsurer()
        .subscribe((result) => {

          this.Insurer = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getTPA(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
      }
      this.clientCreationService.getTPA(postdata)
        .subscribe((result) => {

          this.TPA = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getemployeeTabView(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":1,
      }
      this.clientCreationService.getemployeeTabView(postdata)
        .subscribe((result) => {

          this.employeeTabView = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getbrokerBranchName(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":4,
      }
      this.clientCreationService.getbrokerBranchName(postdata)
        .subscribe((result) => {

          this.brokerBranchName = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getclientBranchName(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id":this.basicInfo.controls['company_id'].value,
      }
      this.clientCreationService.getclientBranchName(postdata)
        .subscribe((result) => {

          this.ClientBranchName = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getcompanyDetails(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
      }
      this.clientCreationService.getcompanyDetails(postdata)
        .subscribe((result) => {

          this.companyDetails = result.data;

        }, (error) => {
            // console.log(result);
      });
    }
    getmidtermEnrollement(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":2,
      }
      this.clientCreationService.getmidtermEnrollement(postdata)
        .subscribe((result) => {

          this.midterm_enrollement = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    /************ ALL GET Step 2 ***************/

    getMembertype(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":7,
      }
      this.clientCreationService.getMembertype(postdata)
        .subscribe((result) => {

          this.Membertype = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getCovertype(){
      this.clientCreationService.getCovertype()
        .subscribe((result) => {

          this.coverType = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getSItype(){
      this.clientCreationService.getSItype()
        .subscribe((result) => {

          this.SIType = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getSIBasis(){
      this.clientCreationService.getSIBasis()
        .subscribe((result) => {

          this.SIBasis = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getpremiumBasis(){
      this.clientCreationService.getpremiumBasis()
        .subscribe((result) => {

          this.premiumBasis = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getroleDesignationType(){
      this.clientCreationService.getpremiumBasis()
        .subscribe((result) => {

          this.roleDesignationType = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getemailID(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "user_type_id":localStorage.getItem('user_type_id'),
      }
      this.clientCreationService.getemailID(postdata)
        .subscribe((result) => {

          this.emailID = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getdocumenttype(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
      }
      this.clientCreationService.getdocumenttype(postdata)
        .subscribe((result) => {

          this.documentType = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getspousemidterm(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":5,
      }
      this.clientCreationService.getspousemidterm(postdata)
        .subscribe((result) => {

          this.Spousemidterm = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getChildmidterm(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_comp_param_type_id":16,
      }
      this.clientCreationService.getspousemidterm(postdata)
        .subscribe((result) => {

          this.Childmidterm = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getEntity(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
      }
      this.clientCreationService.getEntity(postdata)
        .subscribe((result) => {

          this.entityDetails = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    getRelations(){
      this.clientCreationService.getRelations()
        .subscribe((result) => {

          this.relationDetails = result.data;

        }, (error) => {
            // console.log(result);
      });
    }

    /***** Add Co Inurer Row *****/
    hr_check(){
      const valchecked = $("#hr_info").is(":checked");
      $('.newhr_row').html("");
      this.cnt = 1;
      if(valchecked == true){
        this.addHrRow();
        $("#adrowdiv").css('display', 'block');
        $("#showaddrow").css('display', 'block');
      }else{
        $("#adrowdiv").css('display', 'none');
        $("#showaddrow").css('display', 'none');
      }
    }
    addHrRow() {

      $("#adrowdiv").css('display', 'block');

      if(this.co_insurer_perc_sum > 100){
        $(".Insurersum").css('display', 'block');
      }else{

        let html = `
        <div id="hr_info_row`+ this.cnt + `" class="row mt-3">

          <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="co_insurer_`+ this.cnt + `">Co Insurer </label>
                  <select class="form-select" formControlName="co_insurer_`+ this.cnt + `" id="co_insurer_`+ this.cnt + `">
                    <option value="" selected>Select Co Insurer</option>
                  </select>
              </div>
          </div>

          <div class="col-sm-4">
              <div class="form-group">
                  <label class="bs-form-label" for="co_insurer_perc_`+ this.cnt + `">Co Insurer (%) </label>
                  <input type="text" class="form-control" formControlName="co_insurer_perc_`+ this.cnt + `" id="co_insurer_perc_` + this.cnt + `" maxlength="5" placeholder="Co Insurer (%)" />
              </div>
          </div>

          <div class="col-sm-4 form-group">
              <img src="../../../assets/img/common icons/trash.png" id="hr_row`+ this.cnt + `" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newhr_row').append(html);

        for(var i=0; i<this.Insurer.length; i++){
          var val = '#' + 'co_insurer_'+ this.cnt;
          $(val)
            .append($("<option></option>")
              .attr("value", this.Insurer[i]['insure_comp_id'])
              .text(this.Insurer[i]['name']));
        }

        // this.basicInfo.addControl("co_insurer_" + this.cnt, new FormControl(''));
        // this.basicInfo.addControl("co_insurer_perc_" + this.cnt, new FormControl(''));

        var co_insurer = "co_insurer_" + this.cnt;
        var co_insurer_perc = "co_insurer_perc_" + this.cnt;

        this.hr_info_arr[this.cnt] = { co_insurer: "", co_insurer_perc: "" };

        let flag = this;
        let cntflag = flag.cnt;
        $("#hr_row" + this.cnt).on('click', function (e:any) {
          flag.removeBenefitRow(e, cntflag);
        });

        $("#co_insurer_perc_" + this.cnt).on('blur', function (e:any) {
          flag.ishrperc(e, cntflag);
        });

        $("#co_insurer_perc_" + this.cnt).on('keydown', function (e:any) {
          flag.checkaddmoreDiv(e, cntflag);
        });

        $("#co_insurer_perc_" + this.cnt).on('keyup', function (e:any) {
          flag.checkaddmoreDiv(e, cntflag);
        });

        this.cnt++;

        $(".Insurersum").css('display', 'none');

      }

    }

    removeBenefitRow(e: any, cntflag: any){

      var val = '#co_insurer_perc_' + cntflag;
      let value2:any = $(val).val();

      this.co_insurer_perc_sum -= parseInt(value2);
      // console.log(this.co_insurer_perc_sum);

      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#hr_info_row' + id;
      $(idstr).remove();

      if(this.co_insurer_perc_sum <= 100){
        $(".Insurersum").css('display', 'none');
      }else{
        $(".Insurersum").css('display', 'block');
      }

      let cnt:any = this.cnt;
      // console.log(this.cnt);
      this.cnt = parseInt(cnt) - 1;
      // console.log("cnt ==> ", this.cnt);

      // let sum = parseInt(this.co_insurer_perc_sum) - parseInt(e.target.value);
      // console.log(sum);
    }
    fn_onchangeinsurerpercentage(){
      let oneval = parseFloat($("#insurer_perc").val() as string || '');

      if (oneval > 100) {
          $("#insurer_perc").val('');
      }

      if(oneval == 100 || oneval > 100){
        $(".hr_infocheck").css('display','none');
      }else{
        $(".hr_infocheck").css('display','block');

      }

    }
    ishrperc(evt: any, cntflag:any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }

      //Sum
      // debugger;
      // let valper:any = $("#co_insurer_perc_" + cntflag).val();

      // if(this.co_insurer_perc_sum <= 100){

      //   if(parseInt(valper) <= 100){

      //     this.co_insurer_perc_sum += parseInt(valper);
      //     if(this.co_insurer_perc_sum <= 100){
      //       console.log('i am in SUM==>',parseInt(this.co_insurer_perc_sum));
      //       $(".Insurersum").css('display', 'none');

      //     }else{

      //       console.log('i am in minus==>',parseInt(this.co_insurer_perc_sum));
      //       this.co_insurer_perc_sum -= parseInt(valper);
      //       var str = evt.target.id;
      //       var id = str.charAt(str.length -1 );
      //       var idstr = '#hr_info_row' + id;
      //       $(idstr).remove();
      //       $(".Insurersum").css('display', 'block');
      //     }

      //   }else{

      //   }

      // }else{

      // }

      return true;
    }
    checkaddmoreDiv(e: any, cntflag: any){

      let sumcnt: number = parseFloat($('#insurer_perc').val() as string || '0');
      let valfinal: number = 0;
      
      for (let i = 1; i < this.cnt; i++) {
          let id: string = '#co_insurer_perc_' + i;
          let val: number = parseFloat($(id).val() as string || '0');
          valfinal += val;
      }
      
      // Sum the values with decimals
      sumcnt += valfinal;
      
      if (sumcnt >= 100) {  // sumcnt == 100 or sumcnt > 100
          $(".adrowdiv").css('display', 'none');
      } else {
          $(".adrowdiv").css('display', 'block');
      }
      
      // console.log("sumcnt from dynamic controls ==> ",sumcnt);

    }

    /******************************************/

    isNumber_tab2(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }

      // console.log(this.familyInfo.controls['zipcode'].value.length);

      if(this.familyInfo.controls['zipcode'].value.length == 6){

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "pincode":this.familyInfo.controls['zipcode'].value
        }

        this.clientCreationService.getcountry(postdata)
          .subscribe((result: any) => {
            console.log(result);
            if (result.status == true) {

              // console.log(result.data);

              this.familyInfo.controls['country'].setValue(result.data[0]['country_name']);
              this.familyInfo.controls['state'].setValue(result.data[0]['state_name']);
              this.familyInfo.controls['city'].setValue(result.data[0]['districtName']);

            } else {
              // console.log(result);
            }
          }, (error) => {

        });

      }else{
        this.familyInfo.controls['country'].setValue('');
        this.familyInfo.controls['state'].setValue('');
        this.familyInfo.controls['city'].setValue('');
      }

      return true;
    }

    isNumber_addr_repeat(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }

      // console.log(this.familyInfo.controls['c_zipcode'].value.length);

      if(this.familyInfo.controls['c_zipcode'].value.length == 6){

        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "pincode":this.familyInfo.controls['c_zipcode'].value
        }

        this.clientCreationService.getcountry(postdata)
          .subscribe((result: any) => {
            console.log(result);
            if (result.status == true) {
              // console.log(result);

              this.familyInfo.controls['c_country'].setValue(result.data[0]['country_name']);
              this.familyInfo.controls['c_state'].setValue(result.data[0]['state_name']);
              this.familyInfo.controls['c_city'].setValue(result.data[0]['districtName']);

            } else {
              // console.log(result);
            }
          }, (error) => {

        });

      }else{
        this.familyInfo.controls['c_country'].setValue('');
        this.familyInfo.controls['c_state'].setValue('');
        this.familyInfo.controls['c_city'].setValue('');
      }

      return true;
    }

    closealert(){
      $("#validmsg").css('display', 'none');
      $("#errormsg").css('display', 'none');
    }

    get getBasicInformationFormControls() {
      return this.basicInfo.controls;
    }

    get getfamilyInformationFormControls() {
      return this.familyInfo.controls;
    }

    get getSIPremiumInformationFormControls() {
      return this.SIPremiumInfo.controls;
    }

    get getContactInformationFormControls() {
      return this.contactInfo.controls;
    }

    get getAdditionalInformationFormControls() {
      return this.additionalInfo.controls;
    }

    get getClaimInformationFormControls() {
      return this.claimInfo.controls;
    }

    get getStep7InformationFormControls() {
      return this.step7Info.controls;
    }


    loadexpirydate() {
      var policy_date = this.basicInfo.controls['start_date'].value;
      var parts = policy_date.match(/(\d+)/g);
      var d = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
  
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
  
      // Determine the next year
      let nextYear = year + 1;
  
      // Create a new Date object for the same month and day of the next year
      var newdate = new Date(nextYear, month, day);
  
      // Subtract one day to get the end date
      newdate.setDate(newdate.getDate() - 1);
  
      // Format date to YYYY-MM-DD
      var dateString = new Date(newdate.getTime() - newdate.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
  
      this.basicInfo.controls['end_date'].setValue(dateString);
      this.afterstartselected = dateString;
    }  

    // loadexpirydate(){

    //   var policy_date = this.basicInfo.controls['start_date'].value;
    //   var parts = policy_date.match(/(\d+)/g);
    //   // var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
    //   // var newdate = new Date(d.getTime() + 365*24*60*60*1000);

    //   // // check whether start date is in leap year
    //   // let year:any = policy_date.substring(0,4);
    //   // // let leapyear:any = (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0));
    //   // // if(leapyear == true){
    //   // //   newdate.setDate(newdate.getDate() + 1);
    //   // // }
    //   // var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];
    //   // this.basicInfo.controls['end_date'].setValue(dateString);
    //   var d = new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
    //   var newdate = new Date(d);
    //   newdate.setDate(newdate.getDate() + 365);

    //   var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    //   this.basicInfo.controls['end_date'].setValue(dateString);

    // }

    getcompanytype(){

      var val = this.SIPremiumInfo.controls['type_of_firm'].value;
      if(val == "Private Limited Company" || val == "Public Limited Company" || val == "Limited Liability Partnership"){
        $('#listeddiv').css('display','block');
      }else{
        $('#listeddiv').css('display','none');
      }

    }

    // Stepper Next Event
    next(step: number) {
      if (step == 1) {
        this.basicInfoSubmitted = true;
        if (this.basicInfoSubmitted && this.basicInfo.valid) {
          $('.stepper1_div').css('pointer-events', 'auto !important');
          $('#stepper1_circle').css('border', 'solid 3px #00d108');

            let sumcnt: number = parseFloat($('#insurer_perc').val() as string || '0');
            let valfinal: number = 0;

            for (let i = 1; i < this.cnt; i++) {
                let id: string = '#co_insurer_perc_' + i;
                console.log(id);
                let val: number = parseFloat($(id).val() as string || '0');
                valfinal += val;
            }

            // Add the final values together
            sumcnt += valfinal;

            // Check if the sum is equal to 100
            if (sumcnt !== 100) {
                $(".Insurersum").css('display', 'block');
                $("#adrowdiv").css('display', 'block');
                this.toastService.showWarning('Warning', 'Insurer Percent should be equal to 100')
            } else {
                $(".Insurersum").css('display', 'none');
                $("#adrowdiv").css('display', 'none');
                this.onSubmit_first();
            }

        }
      }
      if (step == 2) {
        this.familyInfoSubmitted = true;
        if (this.familyInfoSubmitted && this.familyInfo.valid) {
          $('#stepper2_div').css('pointer-events', 'auto !important');
          $('#stepper2_circle').css('border', 'solid 3px #00d108');

          let no_of_members_allowed:any = $('#no_of_members_allowed').val();
          let no_of_spouse:any = $('#no_of_spouse').val();
          let no_of_Child:any = $('#no_of_Child').val();
          let no_of_Parents:any = $('#no_of_Parents').val();
          let no_of_Parents_In_Laws:any = $('#no_of_Parents_In_Laws').val();
          let no_of_Siblings:any = $('#no_of_Siblings').val();
          let no_of_parent_or_parentinLaw:any = $('#no_of_parents_or_parentsinlaw').val();

          // let no_parent_parentInLaw_checkbox:any = $('#no_parent_parentInLaw').prop('checked');

          //
          let total_sum:any = 0;



          if(no_of_spouse != ''){
            total_sum = parseInt(total_sum) + parseInt(no_of_spouse);
          }

          if(no_of_Child != ''){
            total_sum = parseInt(total_sum) + parseInt(no_of_Child);
          }

          if (no_of_parent_or_parentinLaw!== '') {
              total_sum = parseInt(total_sum) + parseInt(no_of_parent_or_parentinLaw);
              // this.familyInfo.controls['no_of_Parents_In_Laws'].setValue(0);
              // this.familyInfo.controls['no_of_Parents'].setValue(0);
          }

          if (no_of_Parents_In_Laws !== '' || no_of_Parents_In_Laws > 0) {
              total_sum = parseInt(total_sum) + parseInt(no_of_Parents_In_Laws);
          }
          if (no_of_Parents !== '' || no_of_Parents > 0) {
              total_sum = parseInt(total_sum) + parseInt(no_of_Parents);
          }

          if(no_of_Siblings != ''){
            total_sum = parseInt(total_sum) + parseInt(no_of_Siblings);
          }

          if(this.familyInfo.controls['employee_included'].value == 'Yes'){
            total_sum = parseInt(total_sum) + 1;
          }

          // console.log(total_sum);
          // console.log(no_of_members_allowed);

          // if(no_of_members_allowed != ''){
          //   if(total_sum != no_of_members_allowed){
          //     // this.displayfamilymsg = "block";
          //     this.toastService.showFailed('Failed', 'Sum of below fields is same as No. of Allowed Members count!!');

          //   }else{
              // this.onSubmit_second();
          //   }
          // }

          if (!this.validateAges()) {
            
          }else{
            this.onSubmit_second();
          }

          if($('.spouseDiv').css('display') == 'block'){
            $(".tabSpouse").css('display', 'block');
          }else{
            $(".tabSpouse").css('display', 'none');
          }

          if($('.ParentDiv').css('display') == 'block'){
            $(".tabParent").css('display', 'block');
          }else{
            $(".tabParent").css('display', 'none');
          }

          if($('.ChildrenDiv').css('display') == 'block'){
            if($('#special_Child').is(':checked')){
              $(".tabSpecialChild").css('display', 'block');
              this.SIPremiumInfo.controls['special_child_contribution_all_employer'].setValue('100'); 
              this.SIPremiumInfo.controls['special_child_contribution_all_employee'].setValue('0'); 
              // $("#special_child_contribution_all_employer").val('100');
              // $("#special_child_contribution_all_employee").val('0');

            }
            if($('#unmarried_Child').is(':checked')){
              $(".tabUnmarriedChildren").css('display', 'block');
              this.SIPremiumInfo.controls['unmarried_child_contribution_all_employer'].setValue('100'); 
              this.SIPremiumInfo.controls['unmarried_child_contribution_all_employee'].setValue('0'); 
              // $("#unmarried_child_contribution_all_employer").val('100');
              // $("#unmarried_child_contribution_all_employee").val('0');
            }
            $(".tabChildren").css('display', 'block');
          }else{
            $(".tabChildren").css('display', 'none');
            $(".tabSpecialChild").css('display', 'none');
            $(".tabUnmarriedChildren").css('display', 'none');

          }

          if($('.Parents_In_LawDiv').css('display') == 'block'){
            $(".tabParent_In_Laws").css('display', 'block');
          }else{
            $(".tabParent_In_Laws").css('display', 'none');
          }

          if($('.SiblingDiv').css('display') == 'block'){
            $(".tabSiblings").css('display', 'block');
          }else{
            $(".tabSiblings").css('display', 'none');
          }

        }
      }
      if (step == 3) {
        this.SIPremiumInfoSubmitted = true;
        if (this.SIPremiumInfoSubmitted && this.SIPremiumInfo.valid) {
          if((parseFloat(this.SIPremiumInfo.controls['ca_employer'].value)+parseFloat(this.SIPremiumInfo.controls['ca_employee'].value))!=100){
              this.Validation_Error_message = 'Premium contribution total must be 100';
              // this.Standardmsgtoggle = "block";
              this.toastService.showFailed('Failed', 'Premium contribution total must be 100');

              return;
          }else{
            this.Validation_Error_message = '';
            // this.Standardmsgtoggle = "none";
          }
        }

          if ($('.tabSpecialChild').css('display') === 'block') {
            if((parseFloat(this.SIPremiumInfo.controls['special_child_contribution_all_employer'].value)+parseFloat(this.SIPremiumInfo.controls['special_child_contribution_all_employee'].value))!=100){
                this.Validation_Error_message = 'Premium contribution total must be 100';                
                this.toastService.showFailed('Failed', 'SpecialChild Premium contribution total must be 100');
                return;
            }else{
              this.Validation_Error_message = '';
              this.Standardmsgtoggle = "none";
            }            
          }   
          
          if ($('.tabUnmarriedChildren').css('display') === 'block') {
            if((parseFloat(this.SIPremiumInfo.controls['unmarried_child_contribution_all_employer'].value)+parseFloat(this.SIPremiumInfo.controls['unmarried_child_contribution_all_employee'].value))!=100){
              this.Validation_Error_message = 'Premium contribution total must be 100';                
              this.toastService.showFailed('Failed', 'Unmarried Children Premium contribution total must be 100');
              return;
            }else{
              this.Validation_Error_message = '';
            }
          }

          this.onSubmit_third();

          /** GOING TO NEXT STEP CODE IS SHIFTER IN onSubmit_third function **/
      }
      if (step == 4) {
        this.contactInfoSubmitted = true;
        if (this.contactInfoSubmitted && this.contactInfo.valid) {
          $('#stepper4_div').css('pointer-events', 'auto !important');
          $('#stepper4_circle').css('border', 'solid 3px #00d108');
          this.onSubmit_fourth();
        }
      }
      if (step == 5) {
        this.additionalInfoSubmitted = true;
        if (this.additionalInfoSubmitted && this.additionalInfo.valid) {
          $('#stepper5_div').css('pointer-events', 'auto !important');
          $('#stepper5_circle').css('border', 'solid 3px #00d108');

          //
          let inception_premium:any = $('#inception_premium').val();
          let installment_sum:any = 0;
          for(var i = 0; i < this.installmentArr.length; i++){
            let val:any = $('#installment_amount_'+i).val();
            if(val != ''){
              installment_sum += parseFloat(val);
            }else{
              installment_sum = installment_sum;
            }
          }

          if(installment_sum != inception_premium){
            // this.displayinceptionmsg = "block";
            this.toastService.showFailed('Failed', 'Your Installment Amount Sum is greater than or less than Premium Amount !!');
          }else{
            this.displayinceptionmsg = "none";
            this.onSubmit_fivth();
          }

        }
      }
      if (step == 6) {
        this.claimInfoSubmitted = true;
        if (this.claimInfoSubmitted && this.claimInfo.valid) {
          // console.log(this.uploadExtraArr);
          $('#stepper6_div').css('pointer-events', 'auto !important');
          $('#stepper6_circle').css('border', 'solid 3px #00d108');

          let docMandatory = this.claimInfo.controls['document_mandatory'].value
          if(docMandatory == "Yes" && (this.uploadcnt == 1 || this.uploadcnt == 0)){
            this.toastService.showFailed('Error', 'Upload Document');
          }else{

            let flagFile: any = 0;
            let flagInput: any = 0;
            let flagSelect: any = 0;

            const fileBoxes = document.querySelectorAll('.uploadField_required') as NodeListOf<HTMLInputElement>;
            const inputBoxes = document.querySelectorAll('.uploadField_required') as NodeListOf<HTMLInputElement>;
            const selectBoxes = document.querySelectorAll('.select_required') as NodeListOf<HTMLSelectElement>;
            
            fileBoxes.forEach(inputBox => {
              if (inputBox.type === 'file') {
                if (!inputBox.files || inputBox.files.length === 0) {
                  flagFile = 1; 
                }
              }               
            });

            inputBoxes.forEach(inputBox => {
              if (inputBox.type === 'text') {
                if (inputBox.value === '') {
                  flagInput = 1;                   
                }
              }
            });  
            
            selectBoxes.forEach(selectBox => {
                if (selectBox.value == '') {
                  flagSelect = 1;                
                }
            });

            if(flagInput == 0 && flagSelect == 0 && flagFile == 0){
              this.onSubmit_sixth();
            }else{
              if(flagFile == 1){
                this.toastService.showFailed('Required', 'Upload Document');
              }
              if(flagInput == 1){
                this.toastService.showFailed('Required', 'Document Name is empty');
              }
              if(flagSelect == 1){
                this.toastService.showFailed('Required', 'Document Type is empty');
              }                            
            }

          }
        }
      }
      if (step == 7) {
        this.step7InfoSubmitted = true;
        if (this.step7InfoSubmitted && this.step7Info.valid) {
          $('#stepper7_div').css('pointer-events', 'auto !important');
          $('#stepper7_circle').css('border', 'solid 3px #00d108');
          this.onSubmit_seventh();
        }
      }
    }

    validateAges() {      
      // Spouse validation      
      if ($('.spouseDiv').css('display') === 'block' && $('.Div_no_age_spouse').css('display') === 'block') {
        var minAgeSpouse = $('#min_age_spouse').val();
        var maxAgeSpouse = $('#max_age_spouse').val();

        if (minAgeSpouse =='' || maxAgeSpouse =='') {
          this.toastService.showFailed('Failed', 'Please provide both min and max age for Spouse');
          return false;
        }
      }

      // Children validation
      if ($('.ChildrenDiv').css('display') === 'block' && $('.Div_no_age_Children').css('display') === 'block') {
        var minAgeChild = $('#min_age_Child').val();
        var maxAgeChild = $('#max_age_Child').val();

        if (minAgeChild =='' || maxAgeChild =='') {
          this.toastService.showFailed('Failed', 'Please provide both min and max age for Children');
          return false;
        }
      }

      // Parent validation
      if ($('.ParentDiv').css('display') === 'block' && $('.Div_no_age_Parent').css('display') === 'block') {
        var minAgeParent = $('#min_age_parent').val();
        var maxAgeParent = $('#max_age_parent').val();

        if (minAgeParent =='' || maxAgeParent =='') {
            this.toastService.showFailed('Failed', 'Please provide both min and max age for Parents');
            return false;
        }
      }

      // Parents-In-Law validation
      if ($('.Parents_In_LawDiv').css('display') === 'block' && $('.Div_no_age_Parents_In_Law').css('display') === 'block') {
        var minAgeParentInLaw = $('#min_age_parent_in_law').val();
        var maxAgeParentInLaw = $('#max_age_parent_in_law').val();

        if (minAgeParentInLaw =='' || maxAgeParentInLaw =='') {
            this.toastService.showFailed('Failed', 'Please provide both min and max age for Parents In Laws');
            return false;
        }
      }

      // Sibling validation
      if ($('.SiblingDiv').css('display') === 'block' && $('.Div_no_age_Sibling').css('display') === 'block') {
        var minAgeSibling = $('#min_age_sibling').val();
        var maxAgeSibling = $('#max_age_sibling').val();

        if (minAgeSibling =='' || maxAgeSibling =='') {            
            this.toastService.showFailed('Failed', 'Please provide both min and max age for Siblings');
            return false;
        }
      }

      // If all validations pass
      return true;
    }

    ondownload(val: any){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      if(val == "policyfeatures"){
        link.setAttribute('href', this.policysamplefile);
        link.setAttribute('download', 'policyfeatures_'+localStorage.getItem('master_policies_id')+'.xls');
      }

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_uploaded(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', this.step7file);
      link.setAttribute('download', 'Uploaded_File.xls');

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    // Stepper Previous Event
    previous() {
      this.stepper.previous();
    }

    // Show / Hide Address block
    ifradioclicked(){

      if($('#yes_as_register').is(':checked')) {
        $("#yes_or_no_div").css("display","none");
      }else{
        $("#yes_or_no_div").css("display","block");
      }

    }

    // Submit 1
    onSubmit_first() {

      let Co_Insurer:any = false;

      $('#step1button').prop('disabled', true);

      setTimeout(() => {
        $('#step1button').prop('disabled', false);
      }, 3000);

      let arr:any = [];

      /********* HR Array ********/
      for(var i = 1; i < this.cnt; i++){
        this.hr_info_arr[i] = {co_insurer: $('#co_insurer_'+i).val(), co_insurer_perc:$('#co_insurer_perc_'+i).val()};
        arr.push($('#co_insurer_'+i+' option:selected').text());
      }

      // Define the type for the counts object
      interface Counts {
        [key: string]: number;
      }

      const counts: Counts = arr.reduce((acc: Counts, item: string) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});

      const duplicates = Object.entries(counts).filter(([item, count]) => count > 1);

      if (duplicates.length > 0) {
        // console.log('Duplicates found:');
        this.toastService.showFailed('Failed', 'Co Insurer are same');  
        Co_Insurer = true;
        duplicates.forEach(([item, count]) => {
            console.log(`Item: "${item}", Count: ${count}`);
        });
      } else {
        console.log('No duplicates found.');
      }
      
      for (const key in this.hr_info_arr) {
          if (this.hr_info_arr[key].co_insurer == this.basicInfo.controls['insurer_id'].value) {              
              this.toastService.showFailed('Failed', 'Co Insurer are same');  
              Co_Insurer = true;
          }
      }

      let object = this.hr_info_arr;

      for (let key in object) {
          if (object.hasOwnProperty(key)) {
              let coInsurer = object[key]['co_insurer'];
              let coInsurerPerc = object[key]['co_insurer_perc'];

              if (coInsurer === undefined || coInsurer === null || coInsurer === '' ||
                  coInsurerPerc === undefined || coInsurerPerc === null || coInsurerPerc === '') {
                  delete object[key];
              }
          }
      }

      //
      let checkbox:any = document.getElementById("broker_verification_needed");
      let broker_verification_needed:any;
      if ( checkbox.checked ) {
        broker_verification_needed = true;
      } else {
        broker_verification_needed = false;
      }

      //
      let checkbox2:any = document.getElementById("employer_verification_needed");
      let employer_verification_needed:any;
      if ( checkbox2.checked ) {
        employer_verification_needed = true;
      } else {
        employer_verification_needed = false;
      }

      //
      let checkbox3:any = document.getElementById("enrollement_status");
      let enrollement_status:any;
      if ( checkbox3.checked ) {
        enrollement_status = true;
      } else {
        enrollement_status = false;
      }

      if(Co_Insurer == false){
      
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "broker_id":localStorage.getItem('broker_id'),
          //basic details
          policy_number: this.basicInfo.controls['policy_number'].value,
          policy_name: this.basicInfo.controls['policy_name'].value,
          policy_type_id: this.basicInfo.controls['policy_type_id'].value,
          policy_sub_type_id: this.basicInfo.controls['policy_sub_type_id'].value,
          master_Group_Policy_No: this.basicInfo.controls['master_Group_Policy_No'].value,
          parent_policy_type_id: this.basicInfo.controls['parent_policy_type_id'].value,
          topup_parent_policy_type_id: this.basicInfo.controls['topup_parent_policy_type_id'].value,
          insurer_id: this.basicInfo.controls['insurer_id'].value,
          insurer_perc: this.basicInfo.controls['insurer_perc'].value,
          tpa_id: this.basicInfo.controls['tpa_id'].value,
          start_date: this.basicInfo.controls['start_date'].value,
          end_date: this.basicInfo.controls['end_date'].value,
          employee_tab_view: this.basicInfo.controls['employee_tab_view'].value,
          broker_commision: this.basicInfo.controls['broker_commision'].value,
          co_oprate_buffer: this.basicInfo.controls['co_oprate_buffer'].value,
          broker_branch_id: this.basicInfo.controls['broker_branch_id'].value,
          client_branch_id: this.basicInfo.controls['client_branch_id'].value,
          topup_setup: $("input[name='topup_setup']:checked").val(),
          // show_gst_flag: this.basicInfo.controls['show_gst_flag'].value,
          show_gst_flag:'No',
          hide_cover: this.basicInfo.controls['hide_cover'].value,
          hide_premium: this.basicInfo.controls['hide_premium'].value,
          show_enhance: this.basicInfo.controls['show_enhance'].value,
          is_prorata_premium_allowed: this.basicInfo.controls['is_prorata_premium_allowed'].value,
          sum_with_Base_Policy: this.basicInfo.controls['sum_with_Base_Policy'].value,
          co_insurer_details: JSON.stringify(this.hr_info_arr),
          description: this.basicInfo.controls['description'].value,
          //company details
          company_id: this.basicInfo.controls['company_id'].value,
          sub_entity: this.basicInfo.controls['sub_entity'].value,
          broker_verification_needed: broker_verification_needed,
          employer_verification_needed: employer_verification_needed,
          //enrolment details
          enrollement_status: enrollement_status,
          // enrollment_window_close_mail_effective_date: this.basicInfo.controls['enrollment_window_close_mail_effective_date'].value,
          midterm_enrollement: this.basicInfo.controls['midterm_enrollement'].value,
          enrollment_allowed_days_mid_term: this.basicInfo.controls['enrollment_allowed_days_mid_term'].value,
          enrollment_start_date: this.basicInfo.controls['enrollment_start_date'].value,
          enrollment_end_date: this.basicInfo.controls['enrollment_end_date'].value,
          master_policies_id: localStorage.getItem('master_policies_id'),
        };

        console.log(postdata);

        this.clientCreationService.clientCreate(postdata, localStorage.getItem('Token'))
          .subscribe((result: any) => {
            console.log(result);
            this.popup_title = result.status;

            if ( this.popup_title ===true) {
              // console.log(result);

              localStorage.setItem('master_policies_id', result.data.master_policies_id);

              this.validmsg = result.message;
              this.toastService.showSuccess('Success', 'Details Updated Successfully!');        
              setTimeout(() => {
                this.stepper.next();
                $(window).scrollTop(0);

              }, 3000);

              //Call Edit step 2
              // this.getStep2Data();

              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            this.toastService.showFailed('Failed', 'There was some error');

            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });
      }
    }

  // Submit 2
    onSubmit_second() {

      $('#step2button').prop('disabled', true);

      setTimeout(() => {
        $('#step2button').prop('disabled', false);
      }, 3000);

      let ParentData;
      let Parents_In_LawDivData;
      let SiblingDivData;
      let selfData;

      // console.log(this.memberType);

      const uniqueArray = this.memberType.filter((element: any, index: any) => {
        return this.memberType.indexOf(element) === index;
      });

      // console.log(uniqueArray);

      let checkbox_self:any = document.getElementById("no_age");
      let no_age_Self:any;
      if ( checkbox_self.checked ) {
        no_age_Self = true;
        this.familyInfo.controls['min_age'].setValue('0');
        this.familyInfo.controls['max_age'].setValue('0');
      } else {
        no_age_Self = false;
      }

      let checkbox_spouse_age:any = document.getElementById("no_age_spouse");
      let no_age_Spouse:any;
      if ( checkbox_spouse_age.checked ) {
        no_age_Spouse = true;
        this.familyInfo.controls['min_age_spouse'].setValue('0');
        this.familyInfo.controls['max_age_spouse'].setValue('0');
      } else {
        no_age_Spouse = false;
      }

      let checkbox_spouse_marraige_cer:any = document.getElementById("marraige_certificate");
      let marraige_certificate:any;
      if ( checkbox_spouse_marraige_cer.checked ) {
        marraige_certificate = true;
      } else {
        marraige_certificate = false;
      }

      let checkbox_midterm_allowed:any = document.getElementById("midterm_allowed_for_spouse");
      let midterm_allowed_for_spouse:any;
      if ( checkbox_midterm_allowed.checked ) {
        midterm_allowed_for_spouse = true;
      } else {
        midterm_allowed_for_spouse = false;
        this.familyInfo.controls['spouse_enrolment_allowed_days'].setValue('');
        this.familyInfo.controls['spouse_enrolment_considered'].setValue('');

      }

      let checkbox_lgbt:any = document.getElementById("lgbt");
      let lgbt:any;
      if ( checkbox_lgbt.checked ) {
        lgbt = true;
      } else {
        lgbt = false;
      }

      let val15: any = this.memberType.indexOf('15') !== -1;
      if (val15 == true) {
        this.spousedata = {

          no_age_spouse: no_age_Spouse,
          min_age_spouse: this.familyInfo.controls['min_age_spouse'].value,
          max_age_spouse: this.familyInfo.controls['max_age_spouse'].value,
          marraige_certificate: marraige_certificate,
          no_of_spouse: this.familyInfo.controls['no_of_spouse'].value,
          midterm_allowed_for_spouse: midterm_allowed_for_spouse,
          spouse_enrolment_allowed_days: this.familyInfo.controls['spouse_enrolment_allowed_days'].value,
          spouse_enrolment_considered: this.familyInfo.controls['spouse_enrolment_considered'].value,
          is_lgbtq: lgbt,
        }
      }else{
        this.spousedata = {};
      }

      let checkbox_no_age_child:any = document.getElementById("no_age_Children");
      let no_age_Children:any;
      if ( checkbox_no_age_child.checked ) {
        no_age_Children = true;
        this.familyInfo.controls['min_age_Child'].setValue('0');
        this.familyInfo.controls['max_age_Child'].setValue('0');
      } else {
        no_age_Children = false;
      }

      let checkbox_birth_cer:any = document.getElementById("birth_certificate");
      let birth_certificate:any;
      if ( checkbox_birth_cer.checked ) {
        birth_certificate = true;
      } else {
        birth_certificate = false;
      }

      let checkbox_child_midterm:any = document.getElementById("midterm_allowed_for_Child");
      let midterm_allowed_for_Child:any;
      if ( checkbox_child_midterm.checked ) {
        midterm_allowed_for_Child = true;
      } else {
        midterm_allowed_for_Child = false;
      }

      let checkbox_splchild:any = document.getElementById("special_Child");
      let special_Child:any;
      if ( checkbox_splchild.checked ) {
        special_Child = true;
      } else {
        special_Child = false;
      }

      let checkbox_unmarraied_child:any = document.getElementById("unmarried_Child");
      let unmarried_Child:any;
      if ( checkbox_unmarraied_child.checked ) {
        unmarried_Child = true;
      } else {
        unmarried_Child = false;
      }

      let checkbox_adopted_child:any = document.getElementById("adopted_Child");
      let adopted_Child:any;
      if ( checkbox_adopted_child.checked ) {
        adopted_Child = true;
      } else {
        adopted_Child = false;
      }

      let val16: any = this.memberType.indexOf('16') !== -1;
      if (val16 == true) {
        this.ChilData = {
          Child_age_depend: this.familyInfo.controls['Child_age_depend'].value,
          no_age_Children: no_age_Children,
          min_age_Child: this.familyInfo.controls['min_age_Child'].value,
          max_age_Child: this.familyInfo.controls['max_age_Child'].value,
          birth_certificate: birth_certificate,
          age_difference_Child: this.familyInfo.controls['age_difference_Child'].value,
          no_of_Child: this.familyInfo.controls['no_of_Child'].value,
          midterm_allowed_for_Child: midterm_allowed_for_Child,
          special_Child: special_Child,
          unmarried_Child: unmarried_Child,
          adopted_Child: adopted_Child,
          no_of_twin_child: this.familyInfo.controls['no_of_twin_child'].value,
          child_enrolment_allowed_days: this.familyInfo.controls['child_enrolment_allowed_days'].value,
          child_enrolment_considered: this.familyInfo.controls['child_enrolment_considered'].value,
        }
      } else {
        this.ChilData = {};
      }

      let checkbox_no_age_parent:any = document.getElementById("no_age_Parent");
      let no_age_Parent:any;
      if ( checkbox_no_age_parent.checked ) {
        no_age_Parent = true;
        this.familyInfo.controls['min_age_parent'].setValue('0');
        this.familyInfo.controls['max_age_parent'].setValue('0');
      } else {
        no_age_Parent = false;
      }

      let val17: any = this.memberType.indexOf('17') !== -1;
      console.log(val17);
      if (val17 == true) {
        ParentData = {
          Parent_age_depend: this.familyInfo.controls['Parent_age_depend'].value,
          no_age_Parent: no_age_Parent,
          min_age_parent: this.familyInfo.controls['min_age_parent'].value,
          max_age_parent: this.familyInfo.controls['max_age_parent'].value,
          age_difference_parent: this.familyInfo.controls['age_difference_parent'].value,
          no_of_Parents: this.familyInfo.controls['no_of_Parents'].value,
          employee_allowed_Parents: this.familyInfo.controls['employee_allowed_Parents'].value,
        }
      } else {
        ParentData = {};
      }

      let checkbox_no_age_parentInLaw:any = document.getElementById("no_age_Parents_In_Law");
      let no_age_Parents_In_Law:any;
      if ( checkbox_no_age_parentInLaw.checked ) {
        no_age_Parents_In_Law = true;
        this.familyInfo.controls['min_age_parent_in_law'].setValue('0');
        this.familyInfo.controls['max_age_parent_in_law'].setValue('0');
      } else {
        no_age_Parents_In_Law = false;
      }

      let val18: any = this.memberType.indexOf('18') !== -1;
      if (val18 == true) {
        Parents_In_LawDivData = {
          Parents_In_Law_age_depend: this.familyInfo.controls['Parents_In_Law_age_depend'].value,
          no_age_Parents_In_Law: no_age_Parents_In_Law,
          min_age_parent_in_law: this.familyInfo.controls['min_age_parent_in_law'].value,
          max_age_parent_in_law: this.familyInfo.controls['max_age_parent_in_law'].value,
          age_difference_parent_in_law: this.familyInfo.controls['age_difference_parent_in_law'].value,
          no_of_Parents_In_Laws: this.familyInfo.controls['no_of_Parents_In_Laws'].value,
          employee_allowed_Parents_In_Law: this.familyInfo.controls['employee_allowed_Parents_In_Law'].value,
        }
      } else {
        Parents_In_LawDivData = {};
      }

      let checkbox_no_age_Sibling:any = document.getElementById("no_age_Sibling");
      let no_age_Sibling:any;
      if ( checkbox_no_age_Sibling.checked ) {
        no_age_Sibling = true;
        this.familyInfo.controls['min_age_sibling'].setValue('0');
        this.familyInfo.controls['max_age_sibling'].setValue('0');
      } else {
        no_age_Sibling = false;
      }



      let val19: any = this.memberType.indexOf('19') !== -1;
      if (val19 == true) {
        SiblingDivData = {
          no_of_Siblings: this.familyInfo.controls['no_of_Siblings'].value,
          no_age_Sibling: no_age_Sibling,
          min_age_sibling: this.familyInfo.controls['min_age_sibling'].value,
          max_age_sibling: this.familyInfo.controls['max_age_sibling'].value,
        }
      } else {
        SiblingDivData = {};
      }

      // if (this.familyInfo.controls['employee_included'].value == 'Yes') {
        selfData = {
          member_type: this.familyInfo.controls['member_type'].value,
          no_age: no_age_Self,
          min_age: this.familyInfo.controls['min_age'].value,
          max_age: this.familyInfo.controls['max_age'].value,
        }
      // } else {
      //   selfData = {};
      // }

      let checkbox_parent_cross_section:any = document.getElementById("parent_cross_section");
      let parent_cross_section:any;
      if ( checkbox_parent_cross_section.checked ) {
        parent_cross_section = true;
      } else {
        parent_cross_section = false;
      }

      let no_parent_parentInLawCheck:any =this.familyInfo.controls['no_of_parents_or_parentsinlaw'].value;
      let no_parent_parentInLaw:any;
      if (no_parent_parentInLawCheck > 0 ) {
        no_parent_parentInLaw = true;
      } else {
        no_parent_parentInLaw = false;
      }

      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "parent_id": this.basicInfo.controls['company_id'].value,
        "broker_id": localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        no_of_members_allowed: this.familyInfo.controls['no_of_members_allowed'].value,
        employee_included: "Yes",
        parent_cross_section: parent_cross_section,
        parent_parentinlaworcondition: no_parent_parentInLaw,
        no_of_parents_or_parentsinlaw:  $('#no_of_parents_or_parentsinlaw').val(),
        member_typeselect: JSON.stringify(uniqueArray),

        selfData: selfData,
        spouseData: this.spousedata,
        chilData: this.ChilData,
        parentData: ParentData,
        Parents_In_LawData: Parents_In_LawDivData,
        siblingData: SiblingDivData,

      };

      console.log(postdata);
      console.log(this.popup_title);

      this.clientCreationService.Step2_update(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          console.log(result.status);
          this.popup_title = result.status;

          if (this.popup_title ===true) {
            // console.log(result);
            this.validmsg = result.message;
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        
            setTimeout(() => {
              this.stepper.next();
              $(window).scrollTop(0);

            }, 3000);

             //Call Edit step 3
            //  this.getStep3Data();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          this.toastService.showFailed('Failed', 'There was some error');

          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
        });

    }

    // Submit 3
    onSubmit_third() {

      $('#step3button').prop('disabled', true);

      setTimeout(() => {
        $('#step3button').prop('disabled', false);
      }, 3000);

      /********* Array ********/
      let SIArr: any = {};
      for(var i = 0; i < this.GenerateSICount.length; i++){
        // console.log($('#sum_insured_'+i).val());
        SIArr[i] = {sum_insured: $('#sum_insured_'+i).val(), premium:$('#premium_'+i).val(), premium_tax:$('#premium_tax_'+i).val(), premium_total:$('#premium_total_'+i).val()};
      }

      let object = SIArr;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key]['sum_insured'] === undefined || object[key]['premium'] === null || object[key]['premium_tax'] === null || object[key]['premium_total'] === null){
              console.log(object[key]['sum_insured']);
                delete object[key];
            }
        }
      }

      /********* Array ********/
      // for(var i = 1; i < this.cnt_custom; i++){
      //   this.CustomArr[i] = {sum_insured: $('#sum_insured_custom_'+i).val(), premium:$('#premium_custom_'+i).val(), premium_tax:$('#premium_tax_custom_'+i).val(), premium_total:$('#premium_total_custom_'+i).val()};
      // }

      // let objectmetroArr = this.CustomArr;
      // for (var key in objectmetroArr) {
      //   if (objectmetroArr.hasOwnProperty(key)) {
      //     if (objectmetroArr[key]['sum_insured'] === undefined || object[key]['sum_insured'] === ''){
      //         delete objectmetroArr[key];
      //     }
      //   }
      // }

      for (var i = 1; i < this.cnt_custom; i++) {
        this.CustomArr[i] = {
            sum_insured: $('#sum_insured_custom_' + i).val(),
            premium: $('#premium_custom_' + i).val(),
            premium_tax: $('#premium_tax_custom_' + i).val(),
            premium_total: $('#premium_total_custom_' + i).val()
        };
    }
    
    let objectmetroArr = this.CustomArr;
    for (var key in objectmetroArr) {
        if (objectmetroArr.hasOwnProperty(key)) {
            if (objectmetroArr[key]['sum_insured'] === undefined || objectmetroArr[key]['sum_insured'] === '') {
                delete objectmetroArr[key];
            }
        }
    }

      let tabSelected:any;
      var ref_this:any = $('#myTab .CA-tab').hasClass('active');
      if(ref_this == true){
        tabSelected = 1;
      }else{
        tabSelected = 2;
      }

      // Spouse
      let spouse_details:any;
      if($('.spouseDiv').css('display') == 'block'){
        spouse_details = {
          "spouse_additional_employer": this.SIPremiumInfo.controls['spouse_additional_employer'].value,
          "spouse_additional_employee": this.SIPremiumInfo.controls['spouse_additional_employee'].value,
          "spouse_additional_premium": this.SIPremiumInfo.controls['spouse_additional_premium'].value,
        }
      }else{
        spouse_details = {};
      }


      // Parent
      let parent_details:any;
      if($('.ParentDiv').css('display') == 'block'){
        parent_details = {
          "parent_additional_employer": this.SIPremiumInfo.controls['parent_additional_employer'].value,
          "parent_additional_employee": this.SIPremiumInfo.controls['parent_additional_employee'].value,
          "parent_additional_premium": this.SIPremiumInfo.controls['parent_additional_premium'].value,
        }
      }else{
        parent_details = {};
      }

      // Children
      let children_details:any;
      let special_child_contribution_all_employee:any;
      let unmarried_child_contribution_all_employee:any;
      let special_child_contribution_additional_employee:any;
      let unmarried_child_contribution_additional_employee:any;

      if($('.ChildrenDiv').css('display') == 'block'){

        if($('.tabSpecialChild').css('display') == 'block'){
          special_child_contribution_all_employee = {
            "special_child_contribution_all_employee": $('#special_child_contribution_all_employee').val(),
            "special_child_contribution_all_employer": $('#special_child_contribution_all_employer').val(),
            "special_child_contribution_all_premium": this.SIPremiumInfo.controls['special_child_contribution_all_premium'].value,
          }
        }

        
        if($('.tabUnmarriedChildren').css('display') == 'block'){
          unmarried_child_contribution_all_employee = {
            "unmarried_child_contribution_all_employee":  $('#unmarried_child_contribution_all_employee').val(),
            "unmarried_child_contribution_all_employer":$('#unmarried_child_contribution_all_employer').val(),
            "unmarried_child_contribution_all_premium": this.SIPremiumInfo.controls['unmarried_child_contribution_all_premium'].value,
          }
        }

        children_details = {
          "children_additional_employer": this.SIPremiumInfo.controls['children_additional_employer'].value,
          "children_additional_employee": this.SIPremiumInfo.controls['children_additional_employee'].value,
          "children_additional_premium": this.SIPremiumInfo.controls['children_additional_premium'].value,
        }
       
       

        /*********************************** ADDITIONAL ********************************************************* */

        special_child_contribution_additional_employee = {
          "special_child_contribution_additional_employee": this.SIPremiumInfo.controls['special_child_contribution_additional_employee'].value,
          "special_child_contribution_additional_employer": this.SIPremiumInfo.controls['special_child_contribution_additional_employer'].value,
          "special_child_contribution_additional_premium": this.SIPremiumInfo.controls['special_child_contribution_additional_premium'].value,
        }
        unmarried_child_contribution_additional_employee = {
          "unmarried_child_contribution_additional_employee": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employee'].value,
          "unmarried_child_contribution_additional_employer": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employer'].value,
          "unmarried_child_contribution_additional_premium": this.SIPremiumInfo.controls['unmarried_child_contribution_additional_premium'].value,
        }
      }else{
        children_details = {};
        special_child_contribution_all_employee= {};
        unmarried_child_contribution_all_employee = {};
        unmarried_child_contribution_additional_employee= {};
        special_child_contribution_additional_employee = {};
      }

      // Parents In Law
      let parent_in_laws_details:any;
      if($('.Parents_In_LawDiv').css('display') == 'block'){
        parent_in_laws_details = {
          "parent_in_laws_additional_employer": this.SIPremiumInfo.controls['parent_in_laws_additional_employer'].value,
          "parent_in_laws_additional_employee": this.SIPremiumInfo.controls['parent_in_laws_additional_employee'].value,
          "parent_in_laws_additional_premium": this.SIPremiumInfo.controls['parent_in_laws_additional_premium'].value,
        }
      }else{
        parent_in_laws_details = {};
      }

      // Siblings
      let siblings_details:any;
      if($('.SiblingDiv').css('display') == 'block'){
        siblings_details = {
          "siblings_additional_employer": this.SIPremiumInfo.controls['siblings_additional_employer'].value,
          "siblings_additional_employee": this.SIPremiumInfo.controls['siblings_additional_employee'].value,
          "siblings_additional_premium": this.SIPremiumInfo.controls['siblings_additional_premium'].value,
        }
      }else{
        siblings_details = {};
      }

      let MonthlyEquitible:any
      if(this.MonthlyEquitible.length != 0){
        MonthlyEquitible = this.MonthlyEquitible;
      }else{
        MonthlyEquitible = '';
      }

      let NoOfTimesSalary:any
      if(this.NoOfTimesSalary.length != 0){
        NoOfTimesSalary = this.NoOfTimesSalary;
      }else{
        NoOfTimesSalary = '';
      }

      let AdditionalCoverArray:any
      if(this.AdditionalCoverArray.length != 0){
        AdditionalCoverArray = this.AdditionalCoverArray;
      }else{
        AdditionalCoverArray = '';
      }

      let SIbasis:any = this.SIPremiumInfo.controls['SI_Basis'].value;
      let premiumBasis:any = this.SIPremiumInfo.controls['premium_Basis'].value;
    
      let tax_status:any;
      let employee_premium_tax:any;
      if(SIbasis == "1"){
        tax_status = $("input[name='tax_status']:checked").val();
        if(tax_status == 'included'){
          employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax'].value;
        }else{
          employee_premium_tax = 0;
        }
      }
      if(SIbasis == "3"){
        tax_status = $("input[name='tax_status3']:checked").val();
        if(tax_status == 'included'){
          employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax3'].value;
        }else{
          employee_premium_tax = 0;
        }
        }
      if(SIbasis == "5"){
        tax_status = $("input[name='tax_status2']:checked").val();
        if(tax_status == 'included'){
          employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax2'].value;
        }else{
          employee_premium_tax = 0;
        }
      }
      if(SIbasis == "17"){
        tax_status = $("input[name='tax_status3']:checked").val();
        if(tax_status == 'included'){
          employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax3'].value;
        }else{
          employee_premium_tax = 0;
        }
      }
      if(SIbasis == "18"){
        tax_status = $("input[name='tax_status']:checked").val();
        if(tax_status == 'included'){
          employee_premium_tax = this.SIPremiumInfo.controls['employee_premium_tax'].value;
        }else{
          employee_premium_tax = 0;
        }
      }

      if(SIbasis == this.sumInsured_Basis && premiumBasis == this.premiumType){
        this.Validataionflag = 0;
      }else{
        this.Validataionflag = 1;
      }

      if(this.Validataionflag == 1){
        if(SIbasis == "3" && premiumBasis == "4" || SIbasis == "3" && premiumBasis == "15"){
          if(this.grade_SI == "" || this.grade_SI == undefined || this.grade_SI == null || this.grade_SI == 0){
            this.Validation_Error_message = 'Please upload the Grade Sum Insured file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the Grade Sum Insured file');

            $('#gradefile_error').css('display', 'block');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid red');
            return;
          }else{
            $('#gradefile_error').css('display', 'none');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            // this.Standardmsgtoggle = "none";
          }

          if(this.grade_SP == "" || this.grade_SP == undefined || this.grade_SP == null || this.grade_SP == 0){
            this.Validation_Error_message = 'Please upload the Grade Premium file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the Grade Premium file');

            $('#gradefile_SP_error').css('display', 'block');
            $('#custom-file-upload_grade_SP_file').css('border', '1px solid red');
            return;
          }else{
            $('#gradefile_SP_error').css('display', 'none');
            $('#custom-file-upload_grade_SP_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }         
        }

        if(SIbasis == "19" && premiumBasis == "22"){
          if(this.family_group_SI == "" || this.family_group_SI == undefined || this.family_group_SI == null || this.family_group_SI == 0){
            this.Validation_Error_message = 'Please upload the Family Group Sum Insured file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the Family Group Sum Insured file');

            $('#family_Group_SIfile_error').css('display', 'block');
            $('#custom-file-upload_family_group_SI_file').css('border', '1px solid red');
            return;
          }else{
            $('#family_Group_SIfile_error').css('display', 'none');
            $('#custom-file-upload_family_group_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }

          if(this.family_group_SP == "" || this.family_group_SP == undefined || this.family_group_SP == null || this.family_group_SP == 0){
            this.Validation_Error_message = 'Please upload the Family Group Premium file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the Family Group Premium file');

            $('#family_Group_SPfile_error').css('display', 'block');
            $('#custom-file-upload_family_group_SP_file').css('border', '1px solid red');
            return;
          }else{
            $('#family_Group_SPfile_error').css('display', 'none');
            $('#custom-file-upload_family_group_SP_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }         
        }

        if(SIbasis == "8" && premiumBasis == "13"){
          if(this.family_SI == "" || this.family_SI == undefined || this.family_SI == null || this.family_SI == 0){
            this.Validation_Error_message = 'Please upload the Family Construct Wise Sum Insured file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the FamilyConstruct Wise Sum Insured file');

            $('#family_constructSI_file_error').css('display', 'block');
            $('#custom-file-upload_family_SI_file').css('border', '1px solid red');
            return;
          }else{
            $('#family_constructSI_file_error').css('display', 'none');
            $('#custom-file-upload_family_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }

          if(this.family_SP == "" || this.family_SP == undefined || this.family_SP == null || this.family_SP == 0){
            this.Validation_Error_message = 'Please upload the Family Constrct Wise Premium file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the FamilyConstruct Wise Premium file');

            $('#family_constructSP_file_error').css('display', 'block');
            $('#custom-file-upload_family_SP_file').css('border', '1px solid red');
            return;
          }else{
            $('#family_constructSP_file_error').css('display', 'none');
            $('#custom-file-upload_family_SP_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }         
        }

        if(SIbasis == '5' && premiumBasis == '5'){
          if(this.permily_age == "" || this.permily_age == undefined || this.permily_age == null || this.permily_age == 0){
            this.Validation_Error_message = 'Please upload the Permily Age file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the  Permily Age file');

            $('#permilyfile_error').css('display', 'block');
            $('#custom-file-upload_permily_age_file').css('border', '1px solid red');
            return;
          }else{
            $('#permilyfile_error').css('display', 'none');
            $('#custom-file-upload_grade_SI_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          }
        }

        if((SIbasis == '1' && premiumBasis == '2') ||(SIbasis == '1' && premiumBasis == '8') || (SIbasis == '1' && premiumBasis == '9') || (SIbasis == '1' && premiumBasis == '13') || (SIbasis == '1' && premiumBasis == '15') || (SIbasis == '3' && premiumBasis == '1')|| (SIbasis == '18' && premiumBasis == '21')){
          if(this.employee_file == "" || this.employee_file == undefined || this.employee_file == null || this.employee_file == 0){
            this.Validation_Error_message = 'Please upload the Premium file';
            // this.Standardmsgtoggle = "block";
            this.toastService.showFailed('Failed', 'Please upload the Premium file');

            $('#premiumfile_error').css('display', 'block');
            $('#custom-file-upload_emplyee_age_file').css('border', '1px solid red');
            return;
          }else{
            $('#premiumfile_error').css('display', 'none');
            $('#custom-file-upload_emplyee_age_file').css('border', '1px solid #dee2e6');
            this.Validation_Error_message = '';
            this.Standardmsgtoggle = "none";
          
          }
         
        }
        
      }

      let special_child_contribution_all_premium = $('#special_child_contribution_all_premium').val();
      let unmarried_child_contribution_all_premium = $('#unmarried_child_contribution_all_premium').val();

      
      if($('.tabSpecialChild').css('display') == 'block'){
        if(special_child_contribution_all_premium === '' || special_child_contribution_all_premium == null){
          $('#special_child_contribution_all_premium_error').css('display', 'block');
          $('#special_child_contribution_all_premium').css('border', '1px solid red');

          return;
        }else{
          $('#special_child_contribution_all_premium_error').css('display', 'none');
          $('#special_child_contribution_all_premium').css('border', '1px solid #dee2e6');

        }
        
      }

      if($('.tabUnmarriedChildren').css('display') == 'block'){
        if(unmarried_child_contribution_all_premium === '' || unmarried_child_contribution_all_premium == null){
          $('#unmarried_child_contribution_all_premium_error').css('display', 'block');
          $('#unmarried_child_contribution_all_premium').css('border', '1px solid red');

          return;
        }else{
          $('#unmarried_child_contribution_all_premium_error').css('display', 'none');
          $('#unmarried_child_contribution_all_premium').css('border', '1px solid #dee2e6');

        }
        
      }

      let checkbox_payroll:any = document.getElementById("payroll");
      let payroll:any;
      if ( checkbox_payroll.checked ) {
        payroll = true;
      } else {
        payroll = false;
        this.SIPremiumInfo.controls['installment_allowed'].setValue(false);
      }

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        "is_update": 1,

        "cover_type": this.SIPremiumInfo.controls['cover_type'].value,
        "salary_type": this.SIPremiumInfo.controls['salary_type'].value,
        "SI_Type": this.SIPremiumInfo.controls['SI_Type'].value,
        "SI_Basis": this.SIPremiumInfo.controls['SI_Basis'].value,
        "premium_Basis": this.SIPremiumInfo.controls['premium_Basis'].value,

        "SIArr": JSON.stringify(SIArr),
        "SIArr_custom": JSON.stringify(this.CustomArr),
        "MonthlyEquitible": JSON.stringify(MonthlyEquitible),
        "NoOfTimesSalary": JSON.stringify(NoOfTimesSalary),

        "additional_cover":this.SIPremiumInfo.controls['additional_cover'].value,
        "AdditionalCover": JSON.stringify(AdditionalCoverArray),

        "minimum_SI": this.SIPremiumInfo.controls['minimum_SI'].value,
        "maximum_SI": this.SIPremiumInfo.controls['maximum_SI'].value,
        "interval_between_min_max_SI": this.SIPremiumInfo.controls['interval_between_min_max_SI'].value,

        "ca_employer": this.SIPremiumInfo.controls['ca_employer'].value,
        "ca_employee": this.SIPremiumInfo.controls['ca_employee'].value,
        "hide_employer_contribution": this.SIPremiumInfo.controls['hide_employer_contribution'].value,
        "hide_employee_contribution": this.SIPremiumInfo.controls['hide_employee_contribution'].value,

        "self_additional_employer": this.SIPremiumInfo.controls['self_additional_employer'].value,
        "self_additional_employee": this.SIPremiumInfo.controls['self_additional_employee'].value,
        "self_additional_premium": this.SIPremiumInfo.controls['self_additional_premium'].value,

        "spouse_details": spouse_details,
        "parent_details": parent_details,
        "children_details": children_details,
        "special_child_contribution_all_employee": JSON.stringify(special_child_contribution_all_employee),
        "unmarried_child_contribution_all_employee": JSON.stringify(unmarried_child_contribution_all_employee),
        "special_child_contribution_additional_employee": special_child_contribution_additional_employee,
        "unmarried_child_contribution_additional_employee": unmarried_child_contribution_additional_employee,

        "parent_in_laws_details": parent_in_laws_details,
        "siblings_details": siblings_details,

        "use_flex":this.SIPremiumInfo.controls['use_flex'].value,
        "payroll":payroll,
        "installment_allowed":this.SIPremiumInfo.controls['installment_allowed'].value,
        "installment_prorata":this.SIPremiumInfo.controls['installment_prorata'].value,
        "installment_type":$("input[name='installment_type']:checked").val(),
        "monthly_equitible_period":this.SIPremiumInfo.controls['monthly_equitible_period'].value,

        "tabSelected": tabSelected,

        "emmployeefile_id": this.emmployeefile_id,
        "tax_status": tax_status,
        "employee_premium_tax": employee_premium_tax,

        "employee_file": this.employee_file,

        "grade_SI_file": this.grade_SI,
        "grade_SP_file": this.grade_SP,

        "family_SI_file": this.family_SI,
        "family_SP_file": this.family_SP,

        "family_group_SI_file": this.family_group_SI,
        "family_group_SP_file": this.family_group_SP,

        "unnamed_file": this.unnamed,
        "total_employee": this.SIPremiumInfo.controls['total_employee'].value,
        "total_SI": this.SIPremiumInfo.controls['total_SI'].value,
        "total_premium": this.SIPremiumInfo.controls['total_premium'].value,

        "total_employee_unnamed": this.SIPremiumInfo.controls['total_employee_unnamed'].value,
        "gpa_individual_cover": this.SIPremiumInfo.controls['gpa_individual_cover'].value,
        "total_SI_unnamed": this.SIPremiumInfo.controls['total_SI_unnamed'].value,
        "total_premium_unnamed": this.SIPremiumInfo.controls['total_premium_unnamed'].value,

        "permily_age": this.permily_age,

      };

      // console.log(postdata);
               this.clientCreationService.Step3_update(postdata, localStorage.getItem('Token'))
               .subscribe((result: any) => {
                 console.log(result);
                 console.log(result.status);
                 this.popup_title = result.status;

                  if (this.popup_title=== true) {
                    // console.log(result);
                    this.premilyFilecheck = false;
                    this.validmsg = result.message;
                    // this.SuccessMsg = "block";
                    this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

                    setTimeout(() => {
                      this.stepper.next();
                      $(window).scrollTop(0);

                    }, 3000);


                    $('#stepper3_div').css('pointer-events', 'auto !important');
                    $('#stepper3_circle').css('border', 'solid 3px #00d108');

                    //Call Edit step 4
                    // this.getStep4Data();

                  }else {
                    this.premilyFilecheck = true;
                  }
               }, (error) => {
                  this.premilyFilecheck = true;
                  // this.primilyFile_check();
                  this.validmsg = error.error.message;
                  // this.SuccessMsg = "block";
                  this.toastService.showFailed('Failed', 'There was some error');
             });

    }

    bEmailId: any;
    eEmailId: any;
    //  primilyFile_check(){
    //     if(this.NoOfTimesSalary.length != this.postRateArray.length){
    //       if(this.premilyFilecheck == true){
    //         this.Validation_Error_message = 'Please upload the Permily Age file';
    //         this.Standardmsgtoggle = "block";
    //         $('#permilyfile_error').css('display', 'block');
    //         $('#custom-file-upload_permily_age_file').css('border', '1px solid red');
    //     }
    //   }else{
    //     $('#step3btn').removeAttr('disabled');

    //   }
    // }

    // Submit 4
    onSubmit_fourth() {

      $('#step4button').prop('disabled', true);

      setTimeout(() => {
        $('#step4button').prop('disabled', false);
      }, 3000);

      /********* Array ********/
      let object = this.brokerArr_step4;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          if (object[key]['b_role_designation_type'] === undefined || object[key]['b_role_designation_type'] === null || object[key]['b_role_designation_type'] === '' || object[key]['b_emailID'] === null || object[key]['b_emailID_ID'] === null) {
                delete object[key];
            }
        }
      }

      for(var i = 1; i < this.cnt_broker; i++){

        let emailval_b = '#b_emailID_'+i+' option:selected';
        console.log(emailval_b);
        this.brokerArr_step4[i] = {b_role_designation_type: $('#b_role_designation_type_'+i).val(), b_emailID:$(emailval_b).text(), b_emailID_ID:$('#b_emailID_'+i).val(), b_contactname:$('#b_contactname_'+i).val(), b_mobileno:$('#b_mobileno_'+i).val(), b_level:$('#b_level_'+i).val()};
      }

      for(var i = 1; i < this.cnt_employer; i++){
        let emailval_c = '#e_emailID_'+i+' option:selected';
        this.employer_step4Arr[i] = {e_role_designation_type: $('#e_role_designation_type_'+i).val(), e_emailID:$(emailval_c).text(), e_emailID_ID:$('#e_emailID_'+i).val(), e_contactname:$('#e_contactname_'+i).val(), e_mobileno:$('#e_mobileno_'+i).val(), e_level:$('#e_level_'+i).val()};
      }

      for(var i = 1; i < this.cnt_tpa; i++){
        this.tpa_step4Arr[i] = {tpa_role_designation_type: $('#tpa_role_designation_type_'+i).val(), tpa_emailID:$('#tpa_emailID_'+i).val(), tpa_contactname:$('#tpa_contactname_'+i).val(), tpa_mobileno:$('#tpa_mobileno_'+i).val(), tpa_level:$('#tpa_level_'+i).val()};
      }

      for(var i = 1; i < this.cnt_Insurer; i++){
        this.Insurer_step4Arr[i] = {step4_insurer_id: $('#step4_insurer_id_'+i).val(), Insurer_emailID:$('#Insurer_emailID_'+i).val(), Insurer_contactname:$('#Insurer_contactname_'+i).val(), Insurer_mobileno:$('#Insurer_mobileno_'+i).val(), Insurer_level:$('#Insurer_level_'+i).val()};
      }


      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        "b_role_designation_type":this.contactInfo.controls['b_role_designation_type'].value,
        "b_emailID":this.selectedUserName,
        // "b_emailID_ID":$('#b_emailID').val(),
        "b_emailID_ID":this.bEmailId,
        "b_contactname":this.contactInfo.controls['b_contactname'].value,
        "b_mobileno":this.contactInfo.controls['b_mobileno'].value,
        "b_level":this.contactInfo.controls['b_level'].value,

        "e_role_designation_type":this.contactInfo.controls['e_role_designation_type'].value,
        "e_emailID":this.empSelectedName,
        "e_emailID_ID":this.eEmailId,
        "e_contactname":this.contactInfo.controls['e_contactname'].value,
        "e_mobileno":this.contactInfo.controls['e_mobileno'].value,
        "e_level":this.contactInfo.controls['e_level'].value,

        "tpa_role_designation_type":this.contactInfo.controls['tpa_role_designation_type'].value,
        "tpa_emailID":this.contactInfo.controls['tpa_emailID'].value,
        "tpa_contactname":this.contactInfo.controls['tpa_contactname'].value,
        "tpa_mobileno":this.contactInfo.controls['tpa_mobileno'].value,
        "tpa_level":this.contactInfo.controls['tpa_level'].value,

        "insurer_id_step4":this.contactInfo.controls['insurer_id_step4'].value,
        "insurer_emailID":this.contactInfo.controls['Insurer_emailID'].value,
        "insurer_contactname":this.contactInfo.controls['Insurer_contactname'].value,
        "insurer_mobileno":this.contactInfo.controls['Insurer_mobileno'].value,
        "insurer_level":this.contactInfo.controls['Insurer_level'].value,

        "brokerArr": JSON.stringify(this.brokerArr_step4),
        "employerArr": JSON.stringify(this.employer_step4Arr),
        "tpaArr": JSON.stringify(this.tpa_step4Arr),
        "insurerArr": JSON.stringify(this.Insurer_step4Arr),
      }

      console.log(postdata);

      this.clientCreationService.Step4_update(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          console.log(result.status);
          this.popup_title = result.status;

          if (this.popup_title=== true) {
            // console.log(result);
            this.validmsg = result.message;
            // this.SuccessMsg = "block";
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              this.stepper.next();
              $(window).scrollTop(0);

            }, 3000);

            //Call Edit step 5
            // this.getStep5Data();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          // this.SuccessMsg = "block";
          this.toastService.showFailed('Failed', 'There was some error');

          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

    // Submit 5
    onSubmit_fivth() {

      $('#step5button').prop('disabled', true);

      setTimeout(() => {
        $('#step5button').prop('disabled', false);
      }, 3000);

      /********* Benefit Array ********/
      // for(var i = 1; i < this.cnt_add_benefit; i++){
      //   this.add_benefit_arr[i] = {label: $('#label_'+i).val(), limit:$('#limit_'+i).val()};
      // }

      // let object = this.add_benefit_arr;
      // for (var key in object) {
      //   if (object.hasOwnProperty(key)) {
      //       if (object[key]['label'] === undefined || object[key]['limit'] === null){
      //           delete object[key];
      //       }
      //   }
      // }

      let installment_arr:any = {};
      for(var i = 0; i < this.installmentArr.length; i++){
        installment_arr[i] = {installment: 'Installment '+ i, installment_amount:$('#installment_amount_'+i).val()};
      }

      let relationDetails_arr:any = {};
      let relarr:any = [];
      for(var i = 0; i < this.relationDetails.length; i++){
        let valchecked:any = $('#relation_'+this.relationDetails[i]['id']).is(":checked");
        if(valchecked == false){
          // relationDetails_arr[i] = {$('#relation_'+i).val()};
        }else{
          let val:any = this.relationDetails[i]['id'];
          relarr.push(val);
        }
      }

      relationDetails_arr = {
        "id" : relarr
      }

      // console.log(relationDetails_arr);

      let nomination_applicable = this.additionalInfo.controls['nomination_applicable'].value;
      let allowed_members = this.additionalInfo.controls['allowed_members'].value;
      var postdata:any;

      let allowvalue;
      if(this.additionalInfo.controls['allowed_members'].value == "Selected Members"){
        allowvalue = 2;
      }else{
        allowvalue = 1;
      }

      if(nomination_applicable == "Yes"){

        if(allowed_members == "Selected Members"){
          postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "parent_id":this.basicInfo.controls['company_id'].value,
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),

            "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
            "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
            "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,
            "nominee_description": this.additionalInfo.controls['nominee_description'].value,

            // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
            "installment_arr": JSON.stringify(installment_arr),
            "relationDetails_arr": JSON.stringify(relationDetails_arr),
            "confirm_policy":$('#confirm_policy').prop('checked') ? 1 : 0,
            "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
            "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
            "inception_premium":this.additionalInfo.controls['inception_premium'].value,
            "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
            "allowed_members":allowvalue,
          }
        }else{
          postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "parent_id":this.basicInfo.controls['company_id'].value,
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),

            "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
            "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
            "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,

            // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
            "installment_arr": JSON.stringify(installment_arr),
            "relationDetails_arr": {},
            "confirm_policy":$('#confirm_policy').prop('checked') ? 1 : 0,
            "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
            "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
            "inception_premium":this.additionalInfo.controls['inception_premium'].value,
            "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
            "allowed_members":allowvalue,
          }
        }

      }else{

        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "parent_id":this.basicInfo.controls['company_id'].value,
          "broker_id":localStorage.getItem('broker_id'),
          "master_policies_id": localStorage.getItem('master_policies_id'),

          "nomination_applicable": this.additionalInfo.controls['nomination_applicable'].value,
          "nomination_mandatory": this.additionalInfo.controls['nomination_mandatory'].value,
          "declaration_mandatory": this.additionalInfo.controls['declaration_mandatory'].value,

          // "add_benefit_arr": JSON.stringify(this.add_benefit_arr),
          "installment_arr": JSON.stringify(installment_arr),
          "relationDetails_arr": {},
          "confirm_policy":$('#confirm_policy').prop('checked') ? 1 : 0,
          "opening_cd_balance":this.additionalInfo.controls['opening_cd_balance'].value,
          "cd_balance_thrsold":this.additionalInfo.controls['cd_balance_thrsold'].value,
          "inception_premium":this.additionalInfo.controls['inception_premium'].value,
          "inception_premium_installment":this.additionalInfo.controls['inception_premium_installment'].value,
          "allowed_members":'',
        }

      }


      console.log(postdata);

      this.clientCreationService.Step5_update(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          this.popup_title = result.status;

          if (this.popup_title=== true) {
            // console.log(result);

            this.validmsg = result.message;
            // this.SuccessMsg = "block";
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              this.stepper.next();
              $(window).scrollTop(0);

            }, 3000);

            //Call Edit step 6
            // this.getStep6Data();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          // this.SuccessMsg = "block";
          this.toastService.showFailed('Failed', 'There was some error');

          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

    // Submit 6
    onSubmit_sixth() {

      $('#step6button').prop('disabled', true);

      setTimeout(() => {
        $('#step6button').prop('disabled', false);
      }, 3000);

      // console.log("uploadcnt====>",this.uploadcnt);
      let document_fields:any = [];
      for(var i = 1; i < this.uploadcnt; i++){

        var idval = $('#idval_'+i).val();
        var a = $('#document_name_'+i).val();
        var b = $('#document_type_'+i).val();
        var c = $('#document_mandatory_'+i).val();

        // console.log(idval, a , b, c);

        if(a != undefined){

          if(idval != undefined){
            document_fields.push({
              claim_doc_id:idval,
              document_name:a,
              document_type:b,
              document_mandatory:c,
            });
          }else{
            document_fields.push({
              claim_doc_id:0,
              document_name:a,
              document_type:b,
              document_mandatory:c,
            });
          }

        }

      }

      // let docMandatory = this.claimInfo.controls['document_mandatory'].value
      // console.log(docMandatory);
      // if(docMandatory == 0){

      // }

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        // "document_file": this.document_file,
        "uploadExtraArr": this.uploadExtraArr,
        // "document_name": this.claimInfo.controls['document_name'].value,
        // "document_type": this.claimInfo.controls['document_type'].value,
        "document_mandatory": this.claimInfo.controls['document_mandatory'].value,
        "no_of_back_days": this.claimInfo.controls['no_of_back_days'].value,
        "digital_claim": this.claimInfo.controls['digital_claim'].value,
        "claim_intimate_mandatory": this.claimInfo.controls['claim_intimate_mandatory'].value,
        "document_fields": JSON.stringify(document_fields),
        "is_update": "1"
      }

      // console.log(postdata);

      this.clientCreationService.Step6_update(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          // this.popup_title = result.status;

          if (result.status == true) {
            // console.log(result);
            this.validmsg = result.message;
            // this.SuccessMsg = "block";
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              this.stepper.next();
              $(window).scrollTop(0);

            }, 3000);

             //Call Edit step 7
            //  this.getStep7Data();

           
          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          // this.SuccessMsg = "block";
          this.toastService.showFailed('Failed', 'There was some error');

          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

    // Submit 7
    onSubmit_seventh() {

      $('#step7button').prop('disabled', true);

      setTimeout(() => {
        $('#step7button').prop('disabled', false);
      }, 3000);

      // let installment_arr:any = {};
      // for(var i = 1; i < this.installmentArr.length; i++){
      //   installment_arr[i] = {installment: i + 'Installment', installment_amount:$('#installment_amount_'+i).val()};
      // }

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":this.basicInfo.controls['company_id'].value,
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),

        "is_update": 1,

        "policy_feature_document": this.policy_feature_document
      }

      this.clientCreationService.Step7_update(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
      
          if (result.status == true) {
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');        

            setTimeout(() => {
              this.stepper.next();
              $(window).scrollTop(0);

              this.route.navigate(['listpolicies']);
            }, 3000);


          } else {
            // console.log(result);
          }
        }, (error) => {
          this.toastService.showFailed('Failed', 'There was some error');
      });

    }

    closePopup() {
      this.displayinceptionmsg = "none";
      this.displayfamilymsg = "none";
      this.Standardmsgtoggle="none";
      this.SuccessMsg="none";
    }

    companyID(){
      this.getEntity();
      var atLeastOneIsChecked = $('#company_id').val();
      if(atLeastOneIsChecked != ''){
        $('.company_idDiv').css('display', 'block');
        $('.cdetails').css('margin-top', '18px');
      }else{
        $('.company_idDiv').css('display', 'none');
        $('.cdetails').css('margin-top', '0px');
      }
    }

    /***************************************/
    /***** Add Field *****/
    addBenefitField(){

      let html = `
      <div id="this_benefit_row_`+this.cnt_allowed+`" class="row">
        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="member_type_`+this.cnt_allowed+`">Member type </label>
            <select class="form-select" formControlName="member_type_`+this.cnt_allowed+`" id="member_type_`+this.cnt_allowed+`">
                  <option value="" selected>Select Member Type</option>
            </select>
        </div>
        <div class="col-sm-2 form-group mt-3">
            <div class="form-check" style="margin-top: 30px;">
                <input type="checkbox" formControlName="no_age_`+this.cnt_allowed+`" id="no_age_`+this.cnt_allowed+`" class="form-check-input ng-valid ng-dirty ng-touched" style="margin-left: 0px; margin-top: 0px; cursor: pointer;">
                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">No Age Limit</label>
            </div>
        </div>
        <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
            <label class="bs-form-label" for="min_age_`+this.cnt_allowed+`">Min Age <span class="redstar">*</span></label>
            <input type="text" class="form-control" formControlName="min_age_`+this.cnt_allowed+`" id="min_age_`+this.cnt_allowed+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 noAgeDiv_`+this.cnt_allowed+`">
            <label class="bs-form-label" for="max_age_`+this.cnt_allowed+`">Max Age <span class="redstar">*</span></label>
            <input type="text" class="form-control" formControlName="max_age_`+this.cnt_allowed+`" id="max_age_`+this.cnt_allowed+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt_allowed+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newbenifitField').append(html);

      for(var i=0; i<this.Membertype.length; i++){
        var val = '#' + 'member_type_'+ this.cnt;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Membertype[i]['id'])
            .text(this.Membertype[i]['name']));
      }

      // this.familyInfo.addControl("member_type_"+this.cnt_allowed, new FormControl(''));
      // this.familyInfo.addControl("no_age_"+this.cnt_allowed, new FormControl(''));
      // this.familyInfo.addControl("min_age_"+this.cnt_allowed, new FormControl(''));
      // this.familyInfo.addControl("max_age_"+this.cnt_allowed, new FormControl(''));

      this.allowedArr[this.cnt_allowed] = {member_type: "", no_age: "", min_age: "", max_age: ""};

      let flag = this;
      $("#benefit_row_"+this.cnt_allowed).on('click',function(e) {
        flag.removeRow(e);
      });

      $("#no_age_"+this.cnt_allowed).on('change',function(e) {
        flag.isnoage(e);
      });

      $("#min_age_"+this.cnt_allowed).on('keyup',function(e) {
        flag.isnumberBenefit(e);
      });

      $("#max_age_"+this.cnt_allowed).on('keyup',function(e) {
        flag.isnumberBenefit(e);
      });

      this.cnt_allowed++;

    }

    removeRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_benefit_row_' + id;
      $(idstr).remove();

    }

    isnumberBenefit(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    isnoage(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      let valchecked:any = $("#" + str).is(":checked");
      var value = '.noAgeDiv_' + id;
      if(valchecked == false){
        $(value).css('display', 'block');
      }else{
        $(value).css('display', 'none');
      }
    }
    /***************************************/

    member_data_change(event: any) {
      if (event.target.files.length > 0) {

        this.document_file = event.target.files[0];
        console.log(event.target.files[0])

        $("#custom-file-upload_document_file").empty();
        $('#custom-file-upload_document_file').html(event.target.files[0]['name']);
        $('#custom-file-upload_document_file').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_document_file').empty();
        $('#small_document_file').html('');
        $('#small_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    /***** Add Upload Field *****/
    addUploadField(doc_man:any, document_name:any, document_type:any, document_files:any, idval:any){

      if(doc_man == "" && document_name == "" && document_type == "" && document_files == "" && idval == ""){
        let html = `
        <div class="row mt-3" id="this_upload_row_`+this.uploadcnt+`">
          <div class="col-sm-12"><hr></div>
          <div class="col-sm-3">
              <div class="form-group">
                  <label class="bs-form-label" for="document_name_`+this.uploadcnt+`">Document Name <span class="redstar">*</span></label>
                  <input type="text" class="form-control uploadField_required" id="document_name_`+this.uploadcnt+`" placeholder="Document Name" />
              </div>
          </div>
          <div class="col-sm-3">
              <div class="form-group">
                  <label class="bs-form-label" for="document_type_`+this.uploadcnt+`">Document Type <span class="redstar">*</span></label>
                  <select class="form-select select_required" id="document_type_`+this.uploadcnt+`">
                      <option value="" selected>Select Document Type</option>
                  </select>
              </div>
          </div>
          <div class="col-sm-3">
              <div class="form-group">
                  <label class="bs-form-label" for="document_mandatory_`+this.uploadcnt+`">Mandatory</label>
                  <select class="form-select" id="document_mandatory_`+this.uploadcnt+`">
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
              </div>
          </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="bs-form-label" for="upload_field_` + this.uploadcnt + `">Document <span class="redstar">*</span></label>
                    <label for="file_field_extra` + this.uploadcnt + `" id="custom-file-upload_` + this.uploadcnt + `" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer; padding: 5px;">
                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; 
                        <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; 
                        <span style="color:#AAAAAA">or drop file here</span>
                    </label>
                    <input id="file_field_extra` + this.uploadcnt + `" type="file" accept=".xls, .xlsx, .csv, .txt" class="extrafile uploadField_required" enctype="multipart/form-data" style="display: none" />
                    <input id="` + this.uploadcnt + `" type="hidden" class="filehidden" />
                </div>
                <small style="font-size: 12px; color:#605D62" id="small_extra` + this.uploadcnt + `">Upload only .xls, .xlsx, .csv, .txt files</small>
            </div>


          <div class="col-sm-1 form-group pt-2">
                <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>        
        </div>
        `;
        $(html).insertBefore('.newuploadField4');

      }else{

        // console.log('Now I am addUploadField - ' + idval);

        var elementExists = document.getElementById('this_upload_row_'+this.uploadcnt);
        if(elementExists == null){

          if(document_files == ''){
            let html = `
            <div class="row mt-3" id="this_upload_row_`+this.uploadcnt+`">
              <div class="col-sm-12"><hr></div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_name_`+this.uploadcnt+`">Document Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" id="document_name_`+this.uploadcnt+`" value="`+document_name+`" placeholder="Document Name" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_type_`+this.uploadcnt+`">Document Type <span class="redstar">*</span></label>
                    <select class="form-select" id="document_type_`+this.uploadcnt+`">
                        <option value="" selected>Select Document Type</option>
                    </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_mandatory_`+this.uploadcnt+`">Mandatory</label>
                    <select class="form-select" id="document_mandatory_`+this.uploadcnt+`" value="`+doc_man+`">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
              </div>
              <div class="col-sm-2 pt-4">
                <div class="form-group">

                </div>
              </div>
              <div class="col-sm-1 form-group pt-2">
                    <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
              </div>
              <input id="idval_`+this.uploadcnt+`" type="hidden" value="`+idval+`" />              
            </div>
          `;
          $(html).insertBefore('.newuploadField4');
          }else{
            let html = `
            <div class="row mt-3" id="this_upload_row_`+this.uploadcnt+`">
              <div class="col-sm-12"><hr></div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_name_`+this.uploadcnt+`">Document Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" id="document_name_`+this.uploadcnt+`" value="`+document_name+`" placeholder="Document Name" />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_type_`+this.uploadcnt+`">Document Type <span class="redstar">*</span></label>
                    <select class="form-select" id="document_type_`+this.uploadcnt+`">
                        <option value="" selected>Select Document Type</option>
                    </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                    <label class="bs-form-label" for="document_mandatory_`+this.uploadcnt+`">Mandatory</label>
                    <select class="form-select" id="document_mandatory_`+this.uploadcnt+`" value="`+doc_man+`">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
              </div>
              <div class="col-sm-2 pt-4">
                <div class="form-group">
                    <a class="bs-form-label" href="`+document_files+`" target="_blank" style="color: #000;"><b>Document Link</b></a>
                </div>
              </div>
              <div class="col-sm-1 form-group pt-2">
                    <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
              </div>
              <input id="idval_`+this.uploadcnt+`" type="hidden" value="`+idval+`" />              
            </div>
          `;
          $(html).insertBefore('.newuploadField4');
          }

        }
      }

      $('#document_mandatory_yes_' +this.uploadcnt).val("Yes");

      for(var i=0; i<this.documentType.length; i++){
        var val = '#' + 'document_type_'+ this.uploadcnt;
        $(val)
          .append($("<option></option>")
            .attr("value", this.documentType[i]['id'])
            .text(this.documentType[i]['document_type']));

        if(this.documentType[i]['id'] == document_type){
          // console.log("========>",val+' option[value="'+document_type+'"]');
          $(val).val(this.documentType[i]['id']);
        }
      }

      //doc man
      for(var i=0; i<this.documentType.length; i++){
        var val = '#' + 'document_mandatory_'+ this.uploadcnt;
        if(doc_man == 1){
          $(val).val('Yes');
        }else{
          $(val).val('No');
        }
      }

      var uploadcnt = this.uploadcnt;
      const extraUploadArr: any = {};

      $('.extrafile').on("change", function(event: any, extrafileArr: []){

        // console.log(uploadcnt);

        if (event.target.files.length > 0) {

          extrafileArr = event.target.files[0];

          $("#custom-file-upload_" + uploadcnt).empty();
          $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
          $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

          var iSize = Math.round(event.target.files[0]['size'] / 1024);
          // console.log(iSize);
          iSize = (Math.round(iSize * 100) / 100);

          $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

          $('#small_extra'+ uploadcnt).empty();
          $('#small_extra'+ uploadcnt).html('');
          $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
        }

        console.log(extrafileArr);

        extraUploadArr[uploadcnt] = extrafileArr;
        console.log(extraUploadArr);

      });

      // console.log(extraUploadArr);
      this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
      // console.log(this.uploadExtraArr);

      let flag = this;
      $("#upload_row_"+this.uploadcnt).on('click',function(e) {
        flag.removeUploadRow(e);
      });

      this.uploadcnt++;

    }

    removeUploadRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_upload_row_' + id;
      $(idstr).remove();
      delete this.uploadExtraArr[id];
      this.uploadcnt = parseInt(this.uploadcnt) - 1;
    }

    /*****************************************/
    policyfeaturedocument(event: any){
      if(event.target.files.length > 0){
        this.policy_feature_document = event.target.files[0];
        // console.log(this.policy_feature_document);
        $("#features-file-upload_document_file").empty();
        $('#features-file-upload_document_file').html(event.target.files[0]['name']);
        $('#features-file-upload_document_file').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#policyfeatures_document_file').empty();
        $('#policyfeatures_document_file').html('');
        $('#policyfeatures_document_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');

      }
    }

    enrollement_status(){
      var atLeastOneIsChecked = $('#enrollement_status').is(':checked');
      if(atLeastOneIsChecked == true){
        $('.enrollement_statusDiv').css('display', 'flex');
      }else{
        // $('#enrollment_window_close_mail_effective_date').val('');
        $('.enrollement_statusDiv').css('display', 'none');
      }
    }

    fnss(val1:any,val:any){
      if($('#'+val1).is(':checked')){
        $('#'+val).val("Yes");
      } else {
        $('#'+val).val("No");
      }

    }

    radio_yesno(val:any, text: any){

      if(text == 'yes'){

        $('#'+val+'_'+text).css('background', '#000');
        $('#'+val+'_'+text).css('color', '#fff');
        $('#'+val+'_no').css('background', '#f1f1f1');
        $('#'+val+'_no').css('color', '#000');

      }else{
        $('#'+val+'_'+text).css('background', '#9e9e9e');
        $('#'+val+'_'+text).css('color', '#fff');
        $('#'+val+'_yes').css('background', '#f1f1f1');
        $('#'+val+'_yes').css('color', '#000');

      }
    }

    addNewDoc(){
      let docMandatory = this.claimInfo.controls['document_mandatory'].value;
      if(docMandatory == 'Yes'){
        $('#addDoc').css('display', 'block');
      }else{
        $('#addDoc').css('display', 'none');
      }
    }

    employeeIncluded(){
      let valchecked:any = this.familyInfo.controls['employee_included'].value;
      if(valchecked == "No"){
        $(".member_typeNo").css('display', 'flex');
        $(".member_typeYes").css('display', 'none');
      }else{
        $(".member_typeNo").css('display', 'none');
        $(".member_typeYes").css('display', 'flex');
      }
    }

    noAge(val:any){
      let valchecked:any = $("#" + val).is(":checked");
      if(valchecked == false){
        $(".Div_" + val).css('display', 'block');
      }else{
        $(".Div_" + val).css('display', 'none');
      }
    }

    no_parent_parentinLaw(){
      let valchecked:any = $("#no_parent_parentInLaw").is(":checked");

      if(valchecked == true || valchecked == 1){
        $(".div_no_parent_parentInLaw").css('display', 'flex');
      }else{
        $(".div_no_parent_parentInLaw").css('display', 'none');

      }
    }

    memberTypeselect(){

      let valchecked:any = this.familyInfo.controls['member_typeselect'].value;
      let valAsNumber: number = Number(valchecked);

      this.memberType.indexOf(valchecked) === -1 ? this.memberType.push(valchecked) : console.log("This item already exists");
     
      if(valchecked == 15){
        if($('.spouseDiv:visible').length == 0){
          $(".spouseDiv").css('display', 'block');
        }
      }

      if(valchecked == 16){
        if($('.ChildrenDiv:visible').length == 0){
          $(".ChildrenDiv").css('display', 'block');
        }
      }

      if(valchecked == 17){
        if($('.ParentDiv:visible').length == 0){
          $(".ParentDiv").css('display', 'block');
        }
      }

      if(valchecked == 18){
        if($('.Parents_In_LawDiv:visible').length == 0){
          $(".Parents_In_LawDiv").css('display', 'block');
        }
      }

      if(valchecked == 19){
        if($('.SiblingDiv:visible').length == 0){
          $(".SiblingDiv").css('display', 'block');
        }
      }

      if ($('.ParentDiv').css('display') === 'block' && $('.Parents_In_LawDiv').css('display') === 'block') {
        $('.Parents_addional_div').css('display', 'block');
        $('.no_of_parent_in_law').css('display', 'none');
        $('.no_of_parents').css('display', 'none');
        this.familyInfo.controls['no_of_Parents_In_Laws'].setValue(0);
        this.familyInfo.controls['no_of_Parents'].setValue(0);
    } else {
        $('.Parents_addional_div').css('display', 'none');
        $('.no_of_parent_in_law').css('display', 'flex');
        $('.no_of_parents').css('display', 'flex');
        this.familyInfo.controls['no_of_parents_or_parentsinlaw'].setValue(0);
    }

      this.Membertype = this.Membertype.filter((item: MemberType) => item.id !== valAsNumber);
      // console.log(this.memberType);
            
      this.familyInfo?.get('member_typeselect')?.setValue('0');


    }

    midterm_allowed_for_spouse(){
      let valchecked:any = $("#midterm_allowed_for_spouse").is(":checked");

      if(valchecked == true){
        $("#midterm_allowed_for_spouseDiv").css('display', 'block');
      }else{
        $("#midterm_allowed_for_spouseDiv").css('display', 'none');
      }
    }

    midterm_allowed_for_child(){
      let valchecked:any = $("#midterm_allowed_for_Child").is(":checked");

      if(valchecked == true){
        $("#midterm_allowed_for_childDiv").css('display', 'block');
      }else{
        $("#midterm_allowed_for_childDiv").css('display', 'none');
      }
    }

    removeDIV(val:any){
      if(val == 'spouse'){
        $('.spouseDiv').css('display', 'none');
        $('#no_of_spouse').val('');
        $('#min_age_spouse').val('');
        $('#max_age_spouse').val('');
        $('#no_age_spouse').prop('checked', false);
        $('#marraige_certificate').prop('checked', false);
        $('#lgbt').prop('checked', false);
        $('#midterm_allowed_for_spouse').prop('checked', false);
        $('#midterm_allowed_for_spouseDiv').css('display', 'none');
        $('.Div_no_age_spouse').css('display', 'flex');
        $('#spouse_enrolment_allowed_days').val('');
        $('#spouse_enrolment_considered').val('');

        this.memberType.indexOf(15) === -1 ? this.memberType.pop(15) : '';
        this.Membertype.push({
          "id": 15,
          "name": "Spouse/Partner"
      });
      }
      if(val == 'Children'){
        $('.ChildrenDiv').css('display', 'none');
        $('#Child_age_depend').val('');
        $('#no_of_Child').val('');
        $('#no_age_Children').prop('checked', false);
        $('#birth_certificate').prop('checked', false);
        $('#midterm_allowed_for_Child').prop('checked', false);
        $('#special_Child').prop('checked', false);
        $('#unmarried_Child').prop('checked', false);
        $('#adopted_Child').prop('checked', false);
        $('#min_age_Child').val('');
        $('#max_age_Child').val('');
        $('#age_difference_Child').val('');
        $('#child_enrolment_allowed_days').val('');
        $('#child_enrolment_considered').val('');
        $('#no_of_twin_child').val('');
        $('#midterm_allowed_for_childDiv').css('display', 'none');
        $('.Div_no_age_Children').css('display', 'flex');

        this.memberType.indexOf(15) === -1 ? this.memberType.pop(16) : '';
        this.Membertype.push({
          "id": 16,
          "name": "Children"
      });
      }
      if(val == 'Parent'){
        $('.ParentDiv').css('display', 'none');
        $('#no_of_Parents').val('');
        $('#Parent_age_depend').val('');
        $('#min_age_parent').val('');
        $('#max_age_parent').val('');
        $('#age_difference_parent').val('');
        $('#no_age_Parent').prop('checked', false);
        this.familyInfo.controls['employee_allowed_Parents'].setValue('');
        $('.Div_no_age_Parent').css('display', 'flex');

        this.memberType.indexOf(15) === -1 ? this.memberType.pop(17) : '';
        this.Membertype.push({
          "id": 17,
          "name": "Parents"
      });
      }
      if(val == 'Parents_In_Law'){
        $('.Parents_In_LawDiv').css('display', 'none');
        $('#no_of_Parents_In_Laws').val('');
        $('#Parents_In_Law_age_depend').val('');
        $('#min_age_parent_in_law').val('');
        $('#max_age_parent_in_law').val('');
        $('#age_difference_parent_in_law').val('');
        $('#no_age_Parents_In_Law').prop('checked', false);
        $('.Div_no_age_Parents_In_Law').css('display', 'flex');
        this.familyInfo.controls['employee_allowed_Parents_In_Law'].setValue('');

        this.memberType.indexOf(15) === -1 ? this.memberType.pop(18) : '';
        this.Membertype.push({
          "id": 18,
          "name": "Parents in law"
      });
      }
      if(val == 'Sibling'){
        $('.SiblingDiv').css('display', 'none');
        $('#no_of_Siblings').val('');
        $('#min_age_sibling').val('');
        $('#max_age_sibling').val('');
        $('.Div_no_age_Sibling').css('display', 'flex');
        $('#no_age_Sibling').prop('checked', false);

        this.memberType.indexOf(15) === -1 ? this.memberType.pop(19) : '';
        this.Membertype.push({
          "id": 19,
          "name": "Siblings"
      });
      }

      if(val == 'Parent'){
        $('.Parents_addional_div').css('display', 'none');
        $('.no_of_parent_in_law').css('display', 'flex');
        this.familyInfo.controls['no_of_parents_or_parentsinlaw'].setValue(0);
        this.memberType.indexOf(15) === -1 ? this.memberType.pop(17) : '';
      }
      if(val == 'Parents_In_Law'){
        $('.Parents_addional_div').css('display', 'none');
        $('.no_of_parents').css('display', 'flex');
        this.familyInfo.controls['no_of_parents_or_parentsinlaw'].setValue(0);
        this.memberType.indexOf(15) === -1 ? this.memberType.pop(18) : '';
      }

    }

    Sum_Insured_BasisDiv(){
      let valchecked:any = $("#SI_Basis").val();

      if(valchecked == 1){
        $(".Sum_Insured_BasisDiv").css('display', 'block');
      }else{
        $(".Sum_Insured_BasisDiv").css('display', 'none');
      }

      let SI_Basis:any = $('#SI_Basis').val();
      let premium_Basis:any = $('#premium_Basis').val();

      // console.log(SI_Basis);
      // console.log(premium_Basis);

      this.emmployeefile_id = premium_Basis;

      if(SI_Basis == '1' && premium_Basis == '2' || SI_Basis == '1' && premium_Basis == '8' || SI_Basis == '1' && premium_Basis == '9' || SI_Basis == '1' && premium_Basis == '13' || SI_Basis == '1' && premium_Basis == '15' || SI_Basis == '3' && premium_Basis == '1' || SI_Basis == '18' && premium_Basis == '21'){
        $('.employee_age_div').css('display', 'block');
      }else{
        $('.employee_age_div').css('display', 'none');
      }

      if(SI_Basis == '3' && premium_Basis == '4' || SI_Basis == '3' && premium_Basis == '2'|| SI_Basis == '3' && premium_Basis == '15'){
        $('.grade_div').css('display', 'flex');
      }else{
        $('.grade_div').css('display', 'none');
      }

      if(SI_Basis == '8' && premium_Basis == '13'){
        $('.family_construct_div').css('display', 'flex');
      }else{
        $('.family_construct_div').css('display', 'none');
      }

      if(SI_Basis == '19' && premium_Basis == '22'){
        $('.family_group_div').css('display', 'flex');
      }else{
        $('.family_group_div').css('display', 'none');
      }

      if(SI_Basis == '5' && premium_Basis == '5'){
        $('.no_times_salary_div').css('display', 'flex');
      }else{
        $('.no_times_salary_div').css('display', 'none');
      }

      if(SI_Basis == '17' && premium_Basis == '19'){
        $('.unnamedDiv').css('display', 'block');
      }else{
        $('.unnamedDiv').css('display', 'none');
      }

      if(SI_Basis == '17' && premium_Basis == '20'){
        $('.unnamed_unnamedDiv').css('display', 'block');
      }else{
        $('.unnamed_unnamedDiv').css('display', 'none');
      }

      if(SI_Basis == '1' && premium_Basis == '1'){
        $(".Sum_Insured_BasisDiv").css('display', 'block');
      }else{
        $(".Sum_Insured_BasisDiv").css('display', 'none');
      }

      if(SI_Basis == '1' && premium_Basis == '1'){
        $(".SITable").css('display', 'block');
      }else{
        $(".SITable").css('display', 'none');
      }

    }

    GenerateSI(){

      let SI_Basis:any = $('#SI_Basis').val();
      let premium_Basis:any = $('#premium_Basis').val();
      
      // let first:any = $("#minimum_SI").val();
      // let second:any = $("#maximum_SI").val();
      // let interval:any = $("#interval_between_min_max_SI").val();

      let first:any = this.SIPremiumInfo.controls['minimum_SI'].value;
      let second:any = this.SIPremiumInfo.controls['maximum_SI'].value;
      let interval:any =this.SIPremiumInfo.controls['interval_between_min_max_SI'].value;


      // Calculate the difference between the start and end numbers
      const difference = Math.abs(first - second);
      this.RowCount = 0;

      // Calculate the count by dividing the difference by the interval and rounding down
      if(this.RowCount == 0){
        this.RowCount = Math.floor(difference / interval);
      }else{
        this.RowCount = parseInt(this.RowCount) + Math.floor(difference / interval);
      }
      // console.log("GenerateSICount count", this.GenerateSICount.length)

      this.RowCount = parseInt(this.RowCount) + 1;

      for(var i=0; i<this.RowCount; i++){

        if(this.GenerateSICount.length == 0){
          this.diffval = parseInt(first);
          this.GenerateSICount.push(this.diffval);
        }else{

          this.diffval = parseInt(this.diffval) + parseInt(interval);
          this.diffArr.push(this.diffval);

          let arrval:any = this.GenerateSICount[this.GenerateSICount.length - 1];
          let val = parseInt(arrval) + 1;
          this.GenerateSICount.push(this.diffval);
        }
      }

      // console.log("Diff array", this.diffArr);

      if(this.GenerateSICount.length == 0){
        $(".SITable").css('display', 'none');
      }else{
        $(".SITable").css('display', 'block');
      }

      console.log(this.GenerateSICount);
      // $("#minimum_SI").val('');
      // $("#maximum_SI").val('');
      // $("#interval_between_min_max_SI").val('');

    }

    SIRemove(id:any, index:any){
      this.RowCount = parseInt(this.RowCount) - 1;
      var idstr = '#' + id;
      $(idstr).remove();
      this.GenerateSICount.splice(index, 1);
      console.log(this.GenerateSICount);
      console.log(this.RowCount);
    }

    payroll(){
      let valchecked:any = $("#payroll").is(":checked");
      let valchecked_IA:any = $("#installment_allowed").is(":checked");
      if(valchecked == true){
        if(valchecked_IA == true){
          $(".installment_allowed").css('display', 'block');
        }
        $(".payroll").css('display', 'block');
      }else{
        $(".payroll").css('display', 'none');
        this.MonthlyEquitible = [];
        $("#installment_allowed").prop('checked', false);

        if(valchecked_IA == true){
          $(".installment_allowed").css('display', 'none');
        }
      }
    }

    installment_allowed(){
      let valchecked:any = $("#installment_allowed").is(":checked");
      if(valchecked == true){
        $(".installment_allowed").css('display', 'block');
      }else{
        $(".installment_allowed").css('display', 'none');
      }
    }

    additional_cover(){
      let valchecked:any = $("#additional_cover").is(":checked");
      if(valchecked == true){
        $(".additional_cover").css('display', 'block');
      }else{
        $(".additional_cover").css('display', 'none');
      }
    }

    sum_insured_Total(i:any){

      let premium:any = $('#premium_'+i).val();
      let premium_tax:any = $('#premium_tax_'+i).val();

      if(premium != '' && premium_tax != ''){

        premium_tax = parseInt(premium_tax) / 100;
        let premium_total:any = parseInt(premium) * parseFloat(premium_tax);

        let finaltotal:any = premium_total = parseInt(premium) + parseInt(premium_total);

        if(finaltotal != '' || finaltotal != null || finaltotal != undefined){
          $('#premium_total_'+i).val(finaltotal);
        }

      }

    }

    /***** Add Benfit step 5 Row *****/
    addbenefit(){
      const valchecked = $("#add_benefit").is(":checked");
      $('.add_benefit_row').html("");
      this.cnt_add_benefit = 1;
      if(valchecked == true){
        this.add_benefitRow();
        $("#add_benefitdiv").css('display', 'block');
        $("#showadd_benefit").css('display', 'block');
      }else{
        $("#add_benefitdiv").css('display', 'none');
        $("#showadd_benefit").css('display', 'none');
      }
    }

    add_benefitRow() {

      $("#add_benefitdiv").css('display', 'block');

      let html = `
      <div id="add_benefit_row`+ this.cnt_add_benefit + `" class="row mt-3">

        <div class="col-sm-4">
            <div class="form-group">
                <label class="bs-form-label" for="label_`+ this.cnt_add_benefit + `">Add Label </label>
      <input type="text" class="form-control" formControlName="label_`+ this.cnt_add_benefit + `" id="label_` + this.cnt_add_benefit + `" placeholder="Add Label" />
            </div>
        </div>

        <div class="col-sm-4">
            <div class="form-group">
                <label class="bs-form-label" for="limit_`+ this.cnt_add_benefit + `">Add Limit </label>
                <input type="text" class="form-control" formControlName="limit_`+ this.cnt_add_benefit + `" id="limit_` + this.cnt_add_benefit + `" maxlength="3" placeholder="Add Limit" />
            </div>
        </div>

        <div class="col-sm-4 form-group">
            <img src="../../../assets/img/common icons/trash.png" id="remove_benefit_row`+ this.cnt_add_benefit + `" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.add_benefit_row').append(html);

      // this.basicInfo.addControl("label_" + this.cnt_add_benefit, new FormControl(''));
      // this.basicInfo.addControl("limit_" + this.cnt_add_benefit, new FormControl(''));

      var co_insurer = "label_" + this.cnt_add_benefit;
      var label_perc = "limit_" + this.cnt_add_benefit;

      this.add_benefit_arr[this.cnt_add_benefit] = { co_insurer: "", label_perc: "" };

      let flag = this;
      let cnt_add_benefitflag = flag.cnt_add_benefit;
      $("#remove_benefit_row" + this.cnt_add_benefit).on('click', function (e:any) {
        flag.removeaddBenefitRow(e, cnt_add_benefitflag);
      });

      $("#limit_" + this.cnt_add_benefit).on('keyup', function (e:any) {
        flag.isaddBenefit(e);
      });

      this.cnt_add_benefit++;

    }

    removeaddBenefitRow(e: any, cnt_add_benefitflag: any){
      var val = '#limit_' + cnt_add_benefitflag;
      let value2:any = $(val).val();

      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#add_benefit_row' + id;
      $(idstr).remove();

    }

    isaddBenefit(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }

      return true;
    }

    // Step 5
    /***** Add SI Field *****/
    addSICustomField() {

      let html = `
      <div id="this_Custom_row_`+ this.cnt_custom + `" class="row">
        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label">Sum Insured</label>
          <input type="text" class="form-control" id="sum_insured_custom_`+ this.cnt_custom + `" placeholder="Sum Insured" />
        </div>
        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label">Premium</label>
          <input type="text" class="form-control" id="premium_custom_`+ this.cnt_custom + `" placeholder="Premium" />
        </div>
        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label">Premium Tax</label>
          <input type="text" class="form-control" id="premium_tax_custom_`+ this.cnt_custom + `" placeholder="Premium Tax" />
        </div>
        <div class="col-sm-2 form-group mt-3">
          <label class="bs-form-label">Premium Total</label>
          <input type="text" class="form-control" id="premium_total_custom_`+ this.cnt_custom + `" placeholder="Premium Total" />
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="custom_row_`+ this.cnt_custom + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newCustomField').append(html);

      // this.SIPremiumInfo.addControl("sum_insured_custom_" + this.cnt_custom, new FormControl(''));
      // this.SIPremiumInfo.addControl("premium_custom_" + this.cnt_custom, new FormControl(''));
      // this.SIPremiumInfo.addControl("premium_tax_custom_" + this.cnt_custom, new FormControl(''));
      // this.SIPremiumInfo.addControl("premium_total_custom_" + this.cnt_custom, new FormControl(''));

      var sum_insured = "sum_insured_custom_" + this.cnt_custom;
      var premium = "premium_custom_" + this.cnt_custom;
      var premium_tax = "premium_tax_custom_" + this.cnt_custom;
      var premium_total = "premium_total_custom_" + this.cnt_custom;

      this.CustomArr[this.cnt_custom] = { sum_insured: "", premium: "", premium_tax: "", premium_total: "" };

      let flag = this;
      $("#custom_row_" + this.cnt_custom).on('click', function (e) {
        flag.removeSICustomRow(e);
      });

      $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
        flag.isnumberSICustom(e);
      });

      $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
        flag.isnumberSICustom(e);
      });

      $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
        flag.sum_insured_Total_premium(e);
      });

      $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
        flag.sum_insured_Total_premium(e);
      });

      $("#premium_custom_" + this.cnt_custom).on('keydown', function (e) {
        flag.sum_insured_Total_premium(e);
      });

      $("#premium_tax_custom_" + this.cnt_custom).on('keydown', function (e) {
        flag.sum_insured_Total_premium(e);
      });

      this.cnt_custom++;

    }

    removeSICustomRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_Custom_row_' + id;
      $(idstr).remove();

    }

    isnumberSICustom(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    sum_insured_Total_premium(evt: any){

      var str = evt.target.id;
      var id = str.charAt(str.length -1 );
      let premium:any = $('#premium_custom_'+id).val();
      let premium_tax:any = $('#premium_tax_custom_'+id).val();

      if(premium != '' && premium_tax != ''){

        premium_tax = parseInt(premium_tax) / 100;
        let premium_total:any = parseInt(premium) * parseFloat(premium_tax);

        let finaltotal:any = premium_total = parseInt(premium) + parseInt(premium_total);

        if(finaltotal != '' || finaltotal != null || finaltotal != undefined){
          $('#premium_total_custom_'+id).val(finaltotal);
        }

      }

    }

    // Step 4
    /***** Add Broker Field *****/
    addSIbrokerField() {

      if(this.cnt_broker < 3){

        let html = `
        <div id="this_broker_row_`+ this.cnt_broker + `" class="row">
          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="b_role_designation_type_`+ this.cnt_broker + `">Role/Designation Type <span class="redstar">*</span></label>
              <select class="form-select" formControlName="b_role_designation_type_`+ this.cnt_broker + `" id="b_role_designation_type_`+ this.cnt_broker + `">
                <option value="" selected>Select Role/Designation Type</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="b_emailID">Email ID <span class="redstar">*</span></label>
              <select class="form-select" id="b_emailID_`+ this.cnt_broker + `">
                <option value="" selected>Select Email ID</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="b_contactname">Name <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="b_contactname_`+ this.cnt_broker + `" id="b_contactname_`+ this.cnt_broker + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="b_mobileno">Mobile No. <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="b_mobileno_`+ this.cnt_broker + `" id="b_mobileno_`+ this.cnt_broker + `" maxlength="10"/>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="b_level">Level <span class="redstar">*</span></label>
              <select class="form-select" formControlName="b_level_`+ this.cnt_broker + `" id="b_level_`+ this.cnt_broker + `">
                <option value="" selected>Select Level</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
              </select>
            </div>
          </div>
          <div class="col-sm-1 form-group mt-3 pt-2">
              <img src="../../../assets/img/common icons/trash.png" id="broker_row_`+ this.cnt_broker + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newbrokerField').append(html);

        for(var i=0; i<this.contactroleArr.length; i++){
          var val = '#' + 'b_role_designation_type_'+ this.cnt_broker;
          $(val)
            .append($("<option></option>")
              .attr("value", this.contactroleArr[i]['user_type_id'])
              .text(this.contactroleArr[i]['tag_name']));
        }

        // this.SIPremiumInfo.addControl("b_role_designation_type_" + this.cnt_broker, new FormControl(''));
        // this.SIPremiumInfo.addControl("b_emailID_" + this.cnt_broker, new FormControl(''));
        // this.SIPremiumInfo.addControl("b_contactname_" + this.cnt_broker, new FormControl(''));
        // this.SIPremiumInfo.addControl("b_mobileno_" + this.cnt_broker, new FormControl(''));

        this.brokerArr_step4[this.cnt_broker] = { b_role_designation_type: "", b_contactname: "", b_emailID_tax: "", b_mobileno: "" };

        let flag = this;
        $("#broker_row_" + this.cnt_broker).on('click', function (e) {
          flag.removeSIbrokerRow(e);
        });

        $("#b_role_designation_type_" + this.cnt_broker).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          //clear
          var idclear = flag.cnt_broker - 1;
          var valclear = '#' + 'b_emailID_'+ idclear;
          $(valclear).empty();

          //First Value append
          var id_firstval = flag.cnt_broker - 1;
          var val_firstval = '#' + 'b_emailID_'+ id_firstval;
          $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

          // Load other option values
          var postdata2 = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#b_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "policy_id": localStorage.getItem('master_policies_id'),
          };

          flag.clientCreationService.getDesignationType(postdata2)
            .subscribe((result) => {

              for(var i=0; i<result.data.length; i++){
                var id = flag.cnt_broker - 1;
                var val = '#' + 'b_emailID_'+ id;
                $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['username']));
              }

            }, (error) => {

          });

        });

        $("#b_emailID_" + this.cnt_broker).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#b_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "policy_id": localStorage.getItem('master_policies_id'),
          }
          flag.clientCreationService.getDesignationType(postdata)
            .subscribe((result) => {

              let valchecked:any = $("#b_emailID_" + id).val();

              for(var i=0; i < result.data.length; i++){
                if(result.data[i]['user_id'] ==  valchecked){
                  $("#b_contactname_" + id).val(result.data[i]['name']);
                  $("#b_mobileno_" + id).val(result.data[i]['mobile_no']);
                }else{

                }
              }

            }, (error) => {

          });

        });

        $("#b_mobileno_" + this.cnt_broker).on('keyup', function (e) {
          flag.isnumberSIbroker(e);
        });

        $("#b_emailID_" + this.cnt_broker).on('keyup', function (e) {
          flag.appendbrokerdetails(e);
        });

        this.cnt_broker++;

      }

    }

    removeSIbrokerRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_broker_row_' + id;
      $(idstr).remove();

      this.cnt_broker = parseInt(this.cnt_broker) - 1;
      console.log(this.cnt_broker);

    }

    isnumberSIbroker(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    appendbrokerdetails(evt: any){
      var str = evt.target.id;
      var id = str.charAt(str.length -1 );

      let valchecked:any = this.contactInfo.controls['b_emailID_'+id].value;
      for(var i=0; i < this.entityDetails.length; i++){
        if(this.entityDetails[i]['username'] ==  valchecked){
          this.contactInfo.controls['b_contactname_' + id].setValue(this.entityDetails[i]['name']);
          this.contactInfo.controls['b_mobileno_' + id].setValue(this.entityDetails[i]['mobile_no']);
        }else{
          this.contactInfo.controls['b_contactname_' + id].setValue('');
          this.contactInfo.controls['b_mobileno_' + id].setValue('');
        }
      }

    }

    // Step 4
    /***** Add Employer Field *****/
    addSIemployerField() {

      if(this.cnt_employer < 3){

        let html = `
        <div id="this_employer_row_`+ this.cnt_employer + `" class="row">
          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_role_designation_type_`+ this.cnt_employer + `">Role/Designation Type <span class="redstar">*</span></label>
              <select class="form-select" formControlName="e_role_designation_type_`+ this.cnt_employer + `" id="e_role_designation_type_`+ this.cnt_employer + `">
                <option value="" selected>Select Role/Designation Type</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_emailID">Email ID <span class="redstar">*</span></label>
              <select class="form-select" id="e_emailID_`+ this.cnt_employer + `">
                <option value="" selected>Select Email ID</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_contactname">Name <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="e_contactname_`+ this.cnt_employer + `" id="e_contactname_`+ this.cnt_employer + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_mobileno">Mobile No. <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="e_mobileno_`+ this.cnt_employer + `" id="e_mobileno_`+ this.cnt_employer + `" maxlength="10"/>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_level">Level <span class="redstar">*</span></label>
              <select class="form-select" formControlName="e_level_`+ this.cnt_employer + `" id="e_level_`+ this.cnt_employer + `">
                <option value="" selected>Select Level</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
              </select>
            </div>
          </div>
          <div class="col-sm-1 form-group mt-3 pt-2">
              <img src="../../../assets/img/common icons/trash.png" id="employer_row_`+ this.cnt_employer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newemployerField').append(html);

        for(var i=0; i<this.contactroleemployerArr.length; i++){
          var val = '#' + 'e_role_designation_type_'+ this.cnt_employer;
          $(val)
            .append($("<option></option>")
              .attr("value", this.contactroleemployerArr[i]['user_type_id'])
              .text(this.contactroleemployerArr[i]['tag_name']));
        }

        // this.SIPremiumInfo.addControl("e_role_designation_type_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_emailID_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_contactname_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_mobileno_" + this.cnt_employer, new FormControl(''));

        this.employer_step4Arr[this.cnt_employer] = { e_role_designation_type: "", e_contactname: "", e_emailID_tax: "", e_mobileno: "" };

        let flag = this;
        $("#employer_row_" + this.cnt_employer).on('click', function (e) {
          flag.removeSIemployerRow(e);
        });

        $("#e_role_designation_type_" + this.cnt_employer).on('change', function (e) {

          var str:any = e.target.id;
          var id = str.charAt(str.length -1 );

          //clear
          var idclear = flag.cnt_employer - 1;
          var valclear = '#' + 'e_emailID_'+ idclear;
          $(valclear).empty();

          //First Value append
          var id_firstval = flag.cnt_employer - 1;
          var val_firstval = '#' + 'e_emailID_'+ id_firstval;
          $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

          // Load other option values
          var postdata2 = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#e_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),
          };

          flag.clientCreationService.getDesignationType_e(postdata2)
            .subscribe((result) => {

              for(var i=0; i<result.data.length; i++){
                var id = flag.cnt_employer - 1;
                var val = '#' + 'e_emailID_'+ id;
                $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['username']));
              }

            }, (error) => {

          });

        });

        $("#e_emailID_" + this.cnt_employer).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#e_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),
          }
          flag.clientCreationService.getDesignationType_e(postdata)
            .subscribe((result) => {

              let valchecked:any = $("#e_emailID_" + id).val();

              for(var i=0; i < result.data.length; i++){
                if(result.data[i]['user_id'] ==  valchecked){
                  $("#e_contactname_" + id).val(result.data[i]['name']);
                  $("#e_mobileno_" + id).val(result.data[i]['mobile_no']);
                }else{

                }
              }

            }, (error) => {

          });

        });

        $("#e_mobileno_" + this.cnt_employer).on('keyup', function (e) {
          flag.isnumberSIemployer(e);
        });

        this.cnt_employer++;

      }

    }

    removeSIemployerRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_employer_row_' + id;
      $(idstr).remove();

      this.cnt_employer = parseInt(this.cnt_employer) - 1;

    }

    isnumberSIemployer(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    // Step 4
    /***** Add TPA Field *****/
    addtpaField() {

      if(this.cnt_tpa < 3){

        let html = `
        <div id="this_tpa_row_`+ this.cnt_tpa + `" class="row">
          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="tpa_role_designation_type_`+ this.cnt_tpa + `">Role/Designation Type </label>
              <select class="form-select" formControlName="tpa_role_designation_type_`+ this.cnt_tpa + `" id="tpa_role_designation_type_`+ this.cnt_tpa + `">
                <option value="" selected>Select Role/Designation Type</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="tpa_emailID">Email ID </label>
              <input type="text" class="form-control" formControlName="tpa_emailID_`+ this.cnt_tpa + `" id="tpa_emailID_`+ this.cnt_tpa + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="tpa_contactname">Name </label>
              <input type="text" class="form-control" formControlName="tpa_contactname_`+ this.cnt_tpa + `" id="tpa_contactname_`+ this.cnt_tpa + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="tpa_mobileno">Mobile No. </label>
              <input type="text" class="form-control" formControlName="tpa_mobileno_`+ this.cnt_tpa + `" id="tpa_mobileno_`+ this.cnt_tpa + `" maxlength="10" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="tpa_level">Level <span class="redstar">*</span></label>
              <select class="form-select" formControlName="tpa_level_`+ this.cnt_tpa + `" id="tpa_level_`+ this.cnt_tpa + `">
                <option value="" selected>Select Level</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
              </select>
            </div>
          </div>
          <div class="col-sm-1 form-group mt-3 pt-2">
              <img src="../../../assets/img/common icons/trash.png" id="tpa_row_`+ this.cnt_tpa + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newtpaField').append(html);


        for(var i=0; i<this.TPA.length; i++){
          var val = '#' + 'tpa_role_designation_type_'+ this.cnt_tpa;
          $(val)
            .append($("<option></option>")
              .attr("value", this.TPA[i]['tpa_id'])
              .text(this.TPA[i]['name']));
        }

        for(var i=0; i<this.emailID.length; i++){
          var val = '#' + 'tpa_emailID_'+ this.cnt_tpa;
          $(val)
            .append($("<option></option>")
              .attr("value", this.emailID[i]['user_id'])
              .text(this.emailID[i]['username']));
        }

        // this.SIPremiumInfo.addControl("tpa_role_designation_type_" + this.cnt_tpa, new FormControl(''));
        // this.SIPremiumInfo.addControl("tpa_emailID_" + this.cnt_tpa, new FormControl(''));
        // this.SIPremiumInfo.addControl("tpa_contactname_" + this.cnt_tpa, new FormControl(''));
        // this.SIPremiumInfo.addControl("tpa_mobileno_" + this.cnt_tpa, new FormControl(''));

        var tpa_role_designation_type_ = "tpa_role_designation_type_" + this.cnt_tpa;
        var b_emailID = "tpa_emailID_" + this.cnt_tpa;
        var tpa_contactname_ = "tpa_contactname_" + this.cnt_tpa;
        var b_mobileno = "tpa_mobileno_" + this.cnt_tpa;

        this.tpa_step4Arr[this.cnt_tpa] = { tpa_role_designation_type_: "", tpa_contactname_: "", tpa_emailID_: "", tpa_mobileno_: "" };

        let flag = this;
        $("#tpa_row_" + this.cnt_tpa).on('click', function (e) {
          flag.removeSItpaRow(e);
        });

        $("#tpa_mobileno_" + this.cnt_tpa).on('keyup', function (e) {
          flag.isnumberSItpa(e);
        });

        $("#tpa_role_designation_type_" + this.cnt_tpa).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          var tpa_role_designation_type = "tpa_role_designation_type_" + id;
          var b_emailID = "tpa_emailID_" + id;
          var tpa_contactname_ = "tpa_contactname_" + id;
          var b_mobileno = "tpa_mobileno_" + id;

          let tpa_id:any = $('#tpa_role_designation_type_' + id).val();
          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "id":tpa_id
          };
          flag.clientCreationService.InfoSingle(postdata)
            .subscribe((result) => {

              flag.entityDetails_tpa = result.data[0];

              $('#tpa_emailID_' + id).val(flag.entityDetails_tpa.email_1);
              $('#tpa_contactname_' + id).val(flag.entityDetails_tpa.name);
              $('#tpa_mobileno_' + id).val(flag.entityDetails_tpa.contact_1);

            }, (error) => {
                // console.log(result);

                flag.entityDetails_tpa = [];
          });
        });

        flag.cnt_tpa++;

      }

    }

    removeSItpaRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_tpa_row_' + id;
      $(idstr).remove();

      this.cnt_tpa = parseInt(this.cnt_tpa) - 1;

    }

    isnumberSItpa(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    installments(e:any){

      if(e.target.value != '' && e.target.value != 0){
        $('.InstallmentDiv').css('display','block');
      }else{
        $('.InstallmentDiv').css('display','none');
      }

      this.installmentArr = [];

      for(var i=0; i<e.target.value; i++){
        this.installmentArr.push(i);
      }


    }

    b_designationType(){
      let valchecked:any = this.contactInfo.controls['b_role_designation_type'].value;
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "user_type_id":$('#b_role_designation_type').val(),
        "broker_id":localStorage.getItem('broker_id'),
      }
      this.clientCreationService.getDesignationType(postdata)
        .subscribe((result) => {

          this.entityDetails = result.data;

          console.log(this.entityDetails);

        }, (error) => {

          this.entityDetails = [];
            // console.log(result);
      });

    }

    selectedUserName: any;

    b_emailID(){
      let valchecked:any = this.contactInfo.controls['b_emailID'].value;

      // var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // // console.log(regex.test(valchecked));

      // if(regex.test(valchecked) == false){
      //   $('#valid_b_emailID').css('display', 'block');
      // }else{
      //   $('#valid_b_emailID').css('display', 'none');
      // }

      for(var i=0; i < this.entityDetails.length; i++){
        if(this.entityDetails[i]['user_id'] ==  valchecked){
          this.contactInfo.controls['b_contactname'].setValue(this.entityDetails[i]['name']);
          this.contactInfo.controls['b_mobileno'].setValue(this.entityDetails[i]['mobile_no']);
          this.selectedUserName = this.entityDetails[i]['username'];
        }
      }
    }

    e_designationType(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "user_type_id":$('#e_role_designation_type').val(),
        "master_policies_id":localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getDesignationType_e(postdata)
        .subscribe((result) => {

          this.entityDetails_e = result.data;

        }, (error) => {

          this.entityDetails_e = [];
            // console.log(result);
      });

    }

    empSelectedName: any;

    e_emailID(){
      let valchecked:any = this.contactInfo.controls['e_emailID'].value;

      // var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // // console.log(regex.test(valchecked));

      // if(regex.test(valchecked) == false){
      //   $('#valid_e_emailID').css('display', 'block');
      // }else{
      //   $('#valid_e_emailID').css('display', 'none');
      // }

      for(var i=0; i < this.entityDetails_e.length; i++){
        if(this.entityDetails_e[i]['user_id'] ==  valchecked){
          this.contactInfo.controls['e_contactname'].setValue(this.entityDetails_e[i]['name']);
          this.contactInfo.controls['e_mobileno'].setValue(this.entityDetails_e[i]['mobile_no']);
          this.empSelectedName = this.entityDetails_e[i]['username'];

        }
      }
    }

    tpa_emailID(){
      let tpa_id:any = this.contactInfo.controls['tpa_role_designation_type'].value;
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id":tpa_id
      };
      this.clientCreationService.InfoSingle(postdata)
        .subscribe((result) => {

          this.entityDetails_tpa = result.data[0];

          this.contactInfo.controls['tpa_emailID'].setValue(this.entityDetails_tpa.email_1);
          this.contactInfo.controls['tpa_contactname'].setValue(this.entityDetails_tpa.name);
          this.contactInfo.controls['tpa_mobileno'].setValue(this.entityDetails_tpa.contact_1);

        }, (error) => {
            // console.log(result);

            this.entityDetails_tpa = [];
      });
    }

    Insurer_emailID(){
      let Insurer_id:any = this.contactInfo.controls['insurer_id_step4'].value;
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id":Insurer_id
      };
      this.clientCreationService.InfoSingle_Insurer(postdata)
        .subscribe((result) => {

          this.entityDetails_Insurer = result.data[0];

          this.contactInfo.controls['Insurer_emailID'].setValue(this.entityDetails_Insurer.contact_email);
          this.contactInfo.controls['Insurer_contactname'].setValue(this.entityDetails_Insurer.name);
          this.contactInfo.controls['Insurer_mobileno'].setValue(this.entityDetails_Insurer.contact_number);

        }, (error) => {
            // console.log(result);

            this.entityDetails_Insurer = [];
      });
    }

    checkInsuereemail(e:any){
      var email = e.target.value;
      var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
      if (emailPattern.test(email)) {
        
      }else{
        this.toastService.showFailed('Failed', 'Please enter valid email format.');
        $('#Insurer_emailID').val('');
      }
    }

    checkAllowed_MembersDIV(){
      let value:any = this.additionalInfo.controls['allowed_members'].value;
      if(value == "Selected Members"){
        $('.Allowed_MembersDIV').css('display', 'block');
      }else{
        $('.Allowed_MembersDIV').css('display', 'none');
      }
    }

    checkNominee(){
      let value:any = this.additionalInfo.controls['nomination_applicable'].value;
      if(value == "Yes"){
        $('.nomineeDIV').css('display', 'block');

        let value:any = this.additionalInfo.controls['allowed_members'].value;
        if(value == "Selected Members"){
          $('.Allowed_MembersDIV').css('display', 'block');
        }else{
          $('.Allowed_MembersDIV').css('display', 'none');
        }

      }else{
        $('.nomineeDIV').css('display', 'none');
      }
    }

    monthlyPeriod(){
      let value:any = this.SIPremiumInfo.controls['monthly_equitible_period'].value;
      if(value != 0 && value != '' && value != null){
        $('.monthlyPeriod').css('display', 'block');
        this.MonthlyEquitible.push(value);
        $('#monthly_equitible_period').val('');
      }
      // console.log(this.MonthlyEquitible);

    }

    additionalCover(){
      let value:any = this.SIPremiumInfo.controls['additional_benefit_label'].value;
      let limit:any = this.SIPremiumInfo.controls['additional_benefit_limit'].value;
      if(value != 0 && value != '' && value != null){
        $('.additionalCover').css('display', 'block');
        this.AdditionalCoverArray.push(
          {"label": value, "limit": limit},
        );
      }
      // console.log(this.AdditionalCoverArray);

    }

    no_of_times_salary(){

      let value:any = this.SIPremiumInfo.controls['no_of_times_salary'].value;
      let lowest_SI_limit:any = this.SIPremiumInfo.controls['lowest_SI_limit'].value;
      let highest_SI_limit:any = this.SIPremiumInfo.controls['highest_SI_limit'].value;

      if(value != 0 || value != '' || value != null){
        $('.no_of_times_salary').css('display', 'block');
        this.NoOfTimesSalary.push({
          "no_of_times_salary" : value,
          "lowest_SI_limit": lowest_SI_limit,
          "highest_SI_limit": highest_SI_limit,
        });
      }
      // console.log(this.NoOfTimesSalary);

    }

    popmonthlyPeriod(item:any){
      var index = this.MonthlyEquitible.indexOf(item);
      if (index !== -1) {
        this.MonthlyEquitible.splice(index, 1);
      }
      // console.log(this.MonthlyEquitible);
    }

    popNoOfTimesSalary(item:any){
      // console.log(item);

      let index = this.NoOfTimesSalary.findIndex((row: { no_of_times_salary: any; }) => row.no_of_times_salary === item);
      console.log(index);
      if (index !== -1) {
        this.NoOfTimesSalary.splice(index, 1);
      }
      // console.log(this.NoOfTimesSalary);
    }

    popadditionalCoverArray(item:any){
      let index = this.AdditionalCoverArray.findIndex((row: { label: any; }) => row.label === item);
      // console.log(index);
      if (index !== -1) {
        this.AdditionalCoverArray.splice(index, 1);
      }
      // console.log(this.AdditionalCoverArray);
    }

    // Step 3
    showpremiumBasis(){

      let SI_Basis:any = $('#SI_Basis').val();
      let premium_Basis:any = $('#premium_Basis').val();

      // console.log(SI_Basis);
      // console.log(premium_Basis);

      this.emmployeefile_id = premium_Basis;

      if(SI_Basis == '1' && premium_Basis == '2' || SI_Basis == '1' && premium_Basis == '8' || SI_Basis == '1' && premium_Basis == '9' || SI_Basis == '1' && premium_Basis == '13' || SI_Basis == '1' && premium_Basis == '15' || SI_Basis == '3' && premium_Basis == '1' || SI_Basis == '18' && premium_Basis == '21'){
        $('.employee_age_div').css('display', 'block');
      }else{
        $('.employee_age_div').css('display', 'none');
      }

      if(SI_Basis == '3' && premium_Basis == '4' || SI_Basis == '3' && premium_Basis == '2'|| SI_Basis == '3' && premium_Basis == '15'){
        $('.grade_div').css('display', 'flex');
      }else{
        $('.grade_div').css('display', 'none');
      }

      if(SI_Basis == '8' && premium_Basis == '13'){
        $('.family_construct_div').css('display', 'flex');
      }else{
        $('.family_construct_div').css('display', 'none');
      }

      if(SI_Basis == '19' && premium_Basis == '22'){
        $('.family_group_div').css('display', 'flex');
      }else{
        $('.family_group_div').css('display', 'none');
      }

      if(SI_Basis == '5' && premium_Basis == '5'){
        $('.no_times_salary_div').css('display', 'flex');
      }else{
        $('.no_times_salary_div').css('display', 'none');
      }

      if(SI_Basis == '17' && premium_Basis == '19'){
        $('.unnamedDiv').css('display', 'block');
      }else{
        $('.unnamedDiv').css('display', 'none');
      }

      if(SI_Basis == '17' && premium_Basis == '20'){
        $('.unnamed_unnamedDiv').css('display', 'block');
      }else{
        $('.unnamed_unnamedDiv').css('display', 'none');
      }

      if(SI_Basis == '3' && premium_Basis == '1' || SI_Basis == '1' && premium_Basis == '1'){
        $(".Sum_Insured_BasisDiv").css('display', 'block');
      }else{
        $(".Sum_Insured_BasisDiv").css('display', 'none');
      }

    }

    employee_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('premiumfile_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('premiumfile_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.employee_file = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_emplyee_age_file").empty();
            $('#custom-file-upload_emplyee_age_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_emplyee_age_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_emplyee_age_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_emplyee_age_file').empty();
            $('#small_emplyee_age_file').html('');
            $('#small_emplyee_age_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }            
    }

    grade_SI_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('gradefile_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('gradefile_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.grade_SI = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_grade_SI_file").empty();
            $('#custom-file-upload_grade_SI_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_grade_SI_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_grade_SI_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_grade_SI_file').empty();
            $('#small_grade_SI_file').html('');
            $('#small_grade_SI_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }            
    }

    family_SI_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('family_constructSI_file_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('family_constructSI_file_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.family_SI = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_family_SI_file").empty();
            $('#custom-file-upload_family_SI_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_family_SI_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_family_SI_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_family_SI_file').empty();
            $('#small_family_SI_file').html('');
            $('#small_family_SI_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }
            
    }

    family_SP_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('family_constructSP_file_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('family_constructSP_file_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.family_SP = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_family_SP_file").empty();
            $('#custom-file-upload_family_SP_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_family_SP_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_family_SP_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_family_SP_file').empty();
            $('#small_family_SP_file').html('');
            $('#small_family_SP_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }            
    }

    family_Group_SI_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('family_Group_SIfile_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('family_Group_SIfile_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.family_group_SI = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_family_group_SI_file").empty();
            $('#custom-file-upload_family_group_SI_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_family_group_SI_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_family_group_SI_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_family_group_SI_file').empty();
            $('#small_family_group_SI_file').html('');
            $('#small_family_group_SI_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }            
    }
   
    family_Group_SP_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('family_Group_SPfile_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('family_Group_SPfile_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.family_group_SP = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_family_group_SP_file").empty();
            $('#custom-file-upload_family_group_SP_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_family_group_SP_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_family_group_SP_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_family_group_SP_file').empty();
            $('#small_family_group_SP_file').html('');
            $('#small_family_group_SP_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }
            
    }

    permily_age_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('permilyfile_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('permilyfile_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.permily_age = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_permily_age_file").empty();
            $('#custom-file-upload_permily_age_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_permily_age_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_permily_age_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_permily_age_file').empty();
            $('#small_permily_age_file').html('');
            $('#small_permily_age_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }
            
    }

    grade_SP_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('gradefile_SP_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('gradefile_SP_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.grade_SP = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_grade_SP_file").empty();
            $('#custom-file-upload_grade_SP_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_grade_SP_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_grade_SP_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_grade_SP_file').empty();
            $('#small_grade_SP_file').html('');
            $('#small_grade_SP_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }
            
    }

    unnamed_file_change(event: any) {

      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
          
          if (!allowedTypes.includes(file.type)) {
              // Show an error message and clear the input
              const errorElement = document.getElementById('unnamed_error');
              if (errorElement) {
                  errorElement.style.display = 'block';
              }
              input.value = ''; // Reset the input
              return;
          }

          // Hide the error message if the file type is valid
          const errorElement = document.getElementById('unnamed_error');
          if (errorElement) {
              errorElement.style.display = 'none';
          }

          // Proceed with the file upload or other logic
          if (event.target.files.length > 0) {

            this.unnamed = event.target.files[0];
            // console.log(event.target.files[0])
    
            $("#custom-file-upload_unnamed_file").empty();
            $('#custom-file-upload_unnamed_file').html(event.target.files[0]['name']);
            $('#custom-file-upload_unnamed_file').css('text-align', 'left');
    
            var iSize = Math.round(event.target.files[0]['size'] / 1024);
            // console.log(iSize);
            iSize = (Math.round(iSize * 100) / 100);
    
            $('#custom-file-upload_unnamed_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
    
            $('#small_unnamed_file').empty();
            $('#small_unnamed_file').html('');
            $('#small_unnamed_file').append('<span style="color:#0FCA65; letter-spacing: 0.5px;position: relative;">Uploaded successfully</span>');
          }
      }
            
    }

    ondownload_employee(){
      let SI_Basis:any = $('#SI_Basis').val();
      let premium_Basis:any = $('#premium_Basis').val();

      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      if(SI_Basis == '1' && premium_Basis == '2'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/flat_employee_age_wise_si_premium_sample.xlsx');
        link.setAttribute('download', `flat_employee_age_wise_si_premium_sample.xls`);
      }
      if(SI_Basis == '1' && premium_Basis == '8'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/flat_member_age_wise_si_premium_sample.xlsx');
        link.setAttribute('download', `flat_member_age_wise_si_premium_sample.xls`);
      }
      if(SI_Basis == '1' && premium_Basis == '9'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/flat_member_wise_si_premium_sample.xlsx');
        link.setAttribute('download', `flat_member_wise_si_premium_sample.xls`);
      }
      if(SI_Basis == '1' && premium_Basis == '13'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/flat_family_construct_wise_si_premium_sample.xlsx');
        link.setAttribute('download', `flat_family_construct_wise_si_premium_sample.xls`);
      }
      if(SI_Basis == '1' && premium_Basis == '15'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/flat_family_construct_wise_si_premium_sample.xlsx');
        link.setAttribute('download', `flat_new_grade_with_family_construct_wise_si_premium_sample.xls`);
      }
      if(SI_Basis == '3' && premium_Basis == '2'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/employee_age_wise_premium_8.xlsx');
        link.setAttribute('download', `employee_age_wise_premium_8.xls`);
      }
      if(SI_Basis == '3' && premium_Basis == '4'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_premium_rater_base.xlsx');
        link.setAttribute('download', `grade_wise_si_premium_rater_base.xlsx`);
      }
      if(SI_Basis == '5' && premium_Basis == '5'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/permilli_age_wise_si_rater_11.xlsx');
        link.setAttribute('download', `permilli_age_wise_si_rater_11.xls`);
      }
      if(SI_Basis == '3' && premium_Basis == '1'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/grade_flat_file.xlsx');
        link.setAttribute('download', `Grade_flat_file.xls`);
      }
      if(SI_Basis == '18' && premium_Basis == '21'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/Relation_Perlife _diff _SI_premiumwithAgeBandInfo.csv');
        link.setAttribute('download', `Relation_Perlife _diff _SI_premiumwithAgeBand_Sample.csv`);
      }
      if(SI_Basis == '3' && premium_Basis == '15'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/Grade_Premium_Rater_New_Construct.xlsx');
        link.setAttribute('download', `Grade_Premium_Rater_New_Construct.xlsx`);
      }

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_grade_SI(){

      let SI_Basis:any = $('#SI_Basis').val();
      let premium_Basis:any = $('#premium_Basis').val();
      
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_base.xlsx');
      link.setAttribute('download', `grade_wise_si_base.xlsx`);

      if(SI_Basis == '3' && premium_Basis == '15'){
        link.setAttribute('href', '../../assets/Member Data Sample Files/Grade_Sum_Insured_New_Construct.xlsx');
        link.setAttribute('download', `Grade_Sum_Insured_New_Construct.xlsx`);
      }

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_grade_SP(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/grade_wise_si_premium_rater_10.xlsx');
      link.setAttribute('download', `grade_wise_si_premium_rater_10.xls`);

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_family_SI(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/sum_insured_basis.xlsx');
      link.setAttribute('download', `sum_insured_basis.xlsx`);;

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_family_SP(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/premium_rates.xlsx');
      link.setAttribute('download', `premium_rates.xlsx`);;

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_family_Group_SI(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/family_group_suminsured.xlsx');
      link.setAttribute('download', `family_group_suminsured.xlsx`);;

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    ondownload_family_Group_SP(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/family_group_premium.xlsx');
      link.setAttribute('download', `family_group_premium.xlsx`);;

      document.body.appendChild(link);
      link.click();
      link.remove();
    }


    ondownload_unnamed(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', '../../assets/Member Data Sample Files/unnamed_employee_count_sample.xlsx');
      link.setAttribute('download', `unnamed_employee_count_sample.xls`);

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    checktax_employee(val:any){
      if(val == 0){
        $('.employee_tax_age_div').css('display', 'none');
      }else{
        $('.employee_tax_age_div').css('display', 'block');
      }
    }

    checktax_employee_step4(val:any){
      if(val == 0){
        $('.employee_tax_age_div_4').css('display', 'none');
      }else{
        $('.employee_tax_age_div_4').css('display', 'block');
      }
    }

    checkmidterm_enrollement(){

      let val:any = $('#midterm_enrollement').val();

      if(val == '26'){
        $('.Divenrollment_allowed_days_mid_term').css('display', 'none');
      }else{
        $('.Divenrollment_allowed_days_mid_term').css('display', 'block');
      }
    }

    checkpolicy_sub_type_id(){


      let val:any = $('#policy_sub_type_id').val();
      if(val == 2 || val == 3 || val == 5 || val == 6){
        $('.checkTPA').css('display', 'none');
        $('.TPADiv').css('display', 'none');
        $('.InsurerDiv').css('display', 'block');
      }else{
        $('.checkTPA').css('display', 'block');
        $('.TPADiv').css('display', 'block');
        $('.InsurerDiv').css('display', 'none');
      }
      if(val == 1){
        this.getparentpolicytypes();

        $('.parantpolicyDiv').css('display', 'block');
      }else{
        $('.parantpolicyDiv').css('display', 'none');
      }
      if(val == 4 || val == 5 || val == 6){
        this.getparentpolicytypestopup();

        $('.parantpolicyTopupDiv').css('display', 'block');
      }else{
        $('.parantpolicyTopupDiv').css('display','none');
      }


      let parentId;
      for(var i = 0; i < this.policySubType.length; i++){
        if(this.policySubType[i]['id'] == $('#policy_sub_type_id').val()){
          parentId = this.policySubType[i]['parent_id']
        }
      }
      console.log("parentId", parentId);

      //Sum with base API call
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "parent_id":parentId,
        "company_id":this.basicInfo.controls['company_id'].value,
      }
      this.clientCreationService.basepolicydropdown(postdata)
        .subscribe((result) => {

          this.basepolicydropdown = result.data;

        }, (error) => {
            // console.log(result);
      });

    }

    /***** Add Insurer Field *****/
    addInsurerField() {

      if(this.cnt_Insurer < 3){

        let html = `
        <div id="this_Insurer_row_`+ this.cnt_Insurer + `" class="row">
          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="step4_insurer_id_`+ this.cnt_Insurer + `">Insurer </label>
              <select class="form-select" formControlName="step4_insurer_id_`+ this.cnt_Insurer + `" id="step4_insurer_id_`+ this.cnt_Insurer + `">
                <option value="" selected>Select Insurer</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="Insurer_emailID">Email ID </label>
              <input type="text" class="form-control" formControlName="Insurer_emailID_`+ this.cnt_Insurer + `" id="Insurer_emailID_`+ this.cnt_Insurer + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="Insurer_contactname">Name </label>
              <input type="text" class="form-control" formControlName="Insurer_contactname_`+ this.cnt_Insurer + `" id="Insurer_contactname_`+ this.cnt_Insurer + `" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="Insurer_mobileno">Mobile No. </label>
              <input type="text" class="form-control" formControlName="Insurer_mobileno_`+ this.cnt_Insurer + `" id="Insurer_mobileno_`+ this.cnt_Insurer + `" maxlength="10" />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="Insurer_level">Level </label>
              <select class="form-select" formControlName="Insurer_level_`+ this.cnt_Insurer + `" id="Insurer_level_`+ this.cnt_Insurer + `">
                <option value="" selected>Select Level</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
              </select>
            </div>
          </div>
          <div class="col-sm-1 form-group mt-3 pt-2">
              <img src="../../../assets/img/common icons/trash.png" id="Insurer_row_`+ this.cnt_Insurer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newInsurerField').append(html);


        for(var i=0; i<this.Insurer.length; i++){
          var val = '#' + 'step4_insurer_id_'+ this.cnt_Insurer;
          $(val)
            .append($("<option></option>")
              .attr("value", this.Insurer[i]['insure_comp_id'])
              .text(this.Insurer[i]['name']));
        }

        for(var i=0; i<this.emailID.length; i++){
          var val = '#' + 'Insurer_emailID_'+ this.cnt_Insurer;
          $(val)
            .append($("<option></option>")
              .attr("value", this.emailID[i]['user_id'])
              .text(this.emailID[i]['username']));
        }

        // this.SIPremiumInfo.addControl("step4_insurer_id_" + this.cnt_Insurer, new FormControl(''));
        // this.SIPremiumInfo.addControl("Insurer_emailID_" + this.cnt_Insurer, new FormControl(''));
        // this.SIPremiumInfo.addControl("Insurer_contactname_" + this.cnt_Insurer, new FormControl(''));
        // this.SIPremiumInfo.addControl("Insurer_mobileno_" + this.cnt_Insurer, new FormControl(''));

        var step4_insurer_id_ = "step4_insurer_id_" + this.cnt_Insurer;
        var b_emailID = "Insurer_emailID_" + this.cnt_Insurer;
        var Insurer_contactname_ = "Insurer_contactname_" + this.cnt_Insurer;
        var b_mobileno = "Insurer_mobileno_" + this.cnt_Insurer;

        this.Insurer_step4Arr[this.cnt_Insurer] = { step4_insurer_id_: "", Insurer_contactname_: "", Insurer_emailID_: "", Insurer_mobileno_: "" };

        let flag = this;
        $("#Insurer_row_" + this.cnt_Insurer).on('click', function (e) {
          flag.removeSIInsurerRow(e);
        });

        $("#Insurer_mobileno_" + this.cnt_Insurer).on('keyup', function (e) {
          flag.isnumberSIInsurer(e);
        });

        $("#step4_insurer_id_" + this.cnt_Insurer).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          var step4_insurer_id = "step4_insurer_id_" + id;
          var b_emailID = "Insurer_emailID_" + id;
          var Insurer_contactname_ = "Insurer_contactname_" + id;
          var b_mobileno = "Insurer_mobileno_" + id;

          let Insurer_id:any = $('#step4_insurer_id_' + id).val();
          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "id":Insurer_id
          };
          flag.clientCreationService.InfoSingle_Insurer(postdata)
            .subscribe((result) => {

              flag.entityDetails_Insurer = result.data[0];

              $('#Insurer_emailID_' + id).val(flag.entityDetails_Insurer.contact_email);
              $('#Insurer_contactname_' + id).val(flag.entityDetails_Insurer.name);
              $('#Insurer_mobileno_' + id).val(flag.entityDetails_Insurer.contact_number);

            }, (error) => {
                // console.log(result);

                flag.entityDetails_Insurer = [];
          });
        });

        flag.cnt_Insurer++;

      }

    }

    removeSIInsurerRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_Insurer_row_' + id;
      $(idstr).remove();

      this.cnt_Insurer = parseInt(this.cnt_Insurer) - 1;

    }

    isnumberSIInsurer(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    }

    /************ Edit GET Step 1 ***************/
    getStep1Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep1Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          // console.log(result.data);

          // Policy Sub Type API Call
          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "policy_type_id":result.data[0]['policy_type_idfk'],
          }
          this.clientCreationService.getpolicySubtype(postdata)
            .subscribe((result) => {

              this.policySubType = result.data;

            }, (error) => {
                // console.log(result);
          });

          //basic details
          this.basicInfo.controls['policy_number'].setValue(result.data[0]['policy_number']);
          this.basicInfo.controls['policy_name'].setValue(result.data[0]['policy_name']);
          this.basicInfo.controls['policy_type_id'].setValue(result.data[0]['policy_type_idfk']);

          if(result.data[0]['policy_type_idfk'] == '2'){
            $('.Sum_with_Base_Policy_Div').css('display', 'block');
            $('.master_Group_Policy_No_Div').css('display', 'block');
            $('.topup_setup_div').css('display', 'block');
          }else{
            $('.Sum_with_Base_Policy_Div').css('display', 'none');
            $('.master_Group_Policy_No_Div').css('display', 'none');
            $('.topup_setup_div').css('display', 'none');
          }

          this.basicInfo.controls['policy_sub_type_id'].setValue(result.data[0]['policy_sub_type_idfk']);


          setTimeout(() => {

            let parentId;
            // console.log("this.policySubType", this.policySubType);
            for(var i = 0; i < this.policySubType.length; i++){
              console.log(this.policySubType[i]['id'], result.data[0]['policy_sub_type_idfk']);
              if(this.policySubType[i]['id'] == result.data[0]['policy_sub_type_idfk']){
                parentId = this.policySubType[i]['parent_id']
              }
            }
            // console.log("parentId", parentId);

            //Sum with base API call
            var postdata2 = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "parent_id":parentId,
              "company_id":result.data[0]['company_id'],
            }
            this.clientCreationService.basepolicydropdown(postdata2)
              .subscribe((result) => {

                this.basepolicydropdown = result.data;

              }, (error) => {
                  // console.log(result);
            });


            console.log("topup_master_policy_id",result.data[0]['topup_master_policy_id']);

            this.basicInfo.controls['master_Group_Policy_No'].setValue(result.data[0]['topup_master_policy_id']);

          }, 2000);
        
      let policy_sub_type: any  =  result.data[0]['policy_sub_type_idfk'];
      var postdata3: any;
 
       if(policy_sub_type == 4){
         postdata3 = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":13,
         }
       }
       if(policy_sub_type == 5){
         postdata3 = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":14,
         }
       }
       if(policy_sub_type == 6){
         postdata3 = {
           "tokenable_id":localStorage.getItem('tokenable_id'),
           "comp_type_id":15,
         }
       }
       if(policy_sub_type == 1){
        postdata3 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "comp_type_id":12,
        }
      }

      let valuetext:any = result.data[0]['policy_sub_type_idfk'];

      if(valuetext == 1){
        this.clientCreationService.getparentpolicytypes(postdata3)
        .subscribe((result) => {

          this.parentpolicyType = result.data;

        }, (error) => {
            // console.log(result);
      });
      } 

      if(valuetext == 4){
        this.clientCreationService.getparentpolicytypes(postdata3)
        .subscribe((result) => {

          this.parentpolicyTypeTopup = result.data;

        }, (error) => {
            // console.log(result);
      });
      } 
      
       this.basicInfo.controls['topup_parent_policy_type_id'].setValue(result.data[0]['topup_parent_policy_type_id']);
       this.basicInfo.controls['parent_policy_type_id'].setValue(result.data[0]['parent_policy_type_id']);

          
          if(valuetext == 2 || valuetext == 3 || valuetext == 5 || valuetext == 6){
            $('.checkTPA').css('display', 'none');
            $('.TPADiv').css('display', 'none');
            $('.InsurerDiv').css('display', 'block');
          }else{
            $('.checkTPA').css('display', 'block');
            $('.TPADiv').css('display', 'block');
            $('.InsurerDiv').css('display', 'none');
          }
          if(valuetext == 1){
            $('.parantpolicyDiv').css('display', 'block');
          }else{
            $('.parantpolicyDiv').css('display', 'none');
          }
          if(valuetext == 4 || valuetext == 5 || valuetext == 6){
            $('.parantpolicyTopupDiv').css('display', 'block');
          }else{
            $('.parantpolicyTopupDiv').css('display', 'none');
          }


          this.basicInfo.controls['insurer_id'].setValue(result.data[0]['insurer_idfk']);

          this.basicInfo.controls['insurer_perc'].setValue(result.data[0]['insurer_perc']);

          let oneval:any = result.data[0]['insurer_perc'];

          if(oneval > 100){
            $("#insurer_perc").val('');
          }
    
          if(oneval == 100 || oneval > 100){
            $(".hr_infocheck").css('display','none');
          }else{
            $(".hr_infocheck").css('display','block');
          }

          if(result.data[1].length > 0){
            $('#hr_info').prop('checked', true);
          }else{
            $('#hr_info').prop('checked', false);
          }

          if(result.data[1].length > 0){
            // this.addHrRow();
            // $("#adrowdiv").css('display', 'block');
            $("#showaddrow").css('display', 'block');
          }else{
            // $("#adrowdiv").css('display', 'none');
            $("#showaddrow").css('display', 'none');
          }

          let co_insurer_data : any = result.data[1];

          console.log(co_insurer_data);

          if(result.data[1].length > 0){
            this.co_insurer_addon(result.data[1]);
          }



          this.basicInfo.controls['tpa_id'].setValue(result.data[0]['tpa_idfk']);
          this.basicInfo.controls['start_date'].setValue(result.data[0]['policy_start_date']);
          this.basicInfo.controls['end_date'].setValue(result.data[0]['policy_end_date_show']);
          // this.basicInfo.controls['employee_tab_view'].setValue(result.data[0]['employee_tab_view']);
          this.basicInfo.controls['broker_commision'].setValue(result.data[0]['broker_commision']);
          this.basicInfo.controls['co_oprate_buffer'].setValue(result.data[0]['co_oprate_buffer']);
          this.basicInfo.controls['broker_branch_id'].setValue(result.data[0]['broker_branch_idfk']);
          this.basicInfo.controls['description'].setValue(result.data[0]['policy_description']);
          this.basicInfo.controls['company_id'].setValue(result.data[0]['company_id']);

          setTimeout(() => {
            this.getclientBranchName();
            this.loadexpirydate();
          }, 1000); 

          this.basicInfo.controls['client_branch_id'].setValue(result.data[0]['client_branch_id']);

          this.basicInfo.controls['employee_tab_view'].setValue(result.data[0]['employee_tab_view_idfk']);

          if(result.data[0]['topup_setup'] === "1"){
            $('#tax_status_include_topup').prop('checked',true);
          }

          if(result.data[0]['topup_setup'] === "2"){
            $('#tax_status_exclude_topup').prop('checked',true);
          }

          if(result.data[0]['is_gst'] == '1'){
            $('#show_gst_flag').val('Yes');
          }else{
            $('#show_gst_flag').val('No');
          }

          if(result.data[0]['is_hide_cover'] == '1'){
            this.basicInfo.controls['hide_cover'].setValue('Yes');
          }else{
            this.basicInfo.controls['hide_cover'].setValue('No');
          }

          if(result.data[0]['is_hide_premium'] == '1'){
            this.basicInfo.controls['hide_premium'].setValue('Yes');
          }else{
            this.basicInfo.controls['hide_premium'].setValue('No');
          }

          if(result.data[0]['is_show_enhance_bifurcation'] == '1'){
            this.basicInfo.controls['show_enhance'].setValue('Yes');
          }else{
            this.basicInfo.controls['show_enhance'].setValue('No');
          }

          if(result.data[0]['is_prorata_premium_calculation'] == '1'){
            this.basicInfo.controls['is_prorata_premium_allowed'].setValue('Yes');
          }else{
            this.basicInfo.controls['is_prorata_premium_allowed'].setValue('No');
          }

          if(result.data[0]['sum_with_base_policy'] == '1'){
            this.basicInfo.controls['sum_with_Base_Policy'].setValue('Yes');
          }else{
            this.basicInfo.controls['sum_with_Base_Policy'].setValue('No');
          }

          //company details
          // this.basicInfo.controls['sub_entity'].setValue(result.data[0]['sub_entity']);

          if(result.data[0]['is_broker_verification_needed'] == '1'){
            $('#broker_verification_needed').prop('checked', true);
          }else{
            $('#broker_verification_needed').prop('checked', false);
          }

          if(result.data[0]['is_employer_verification_needed'] == '1'){
            $('#employer_verification_needed').prop('checked', true);
          }else{
            $('#employer_verification_needed').prop('checked', false);
          }

          if(result.data[0]['is_enrollement_status'] == '1'){
            $('#enrollement_status').prop('checked', true);
            $('.enrollement_statusDiv').css('display', 'flex');
          }else{
            $('#enrollement_status').prop('checked', false);
            $('.enrollement_statusDiv').css('display', 'none');
          }
          //enrolment details

          this.basicInfo.controls['midterm_enrollement'].setValue(result.data[0]['enrollment_considered_from_id']);
          // this.basicInfo.controls['enrollment_window_close_mail_effective_date'].setValue(result.data[0]['enrollment_window_close_mail_effective_date']);
          this.basicInfo.controls['enrollment_allowed_days_mid_term'].setValue(result.data[0]['enrollement_days']);
          this.basicInfo.controls['enrollment_start_date'].setValue(result.data[0]['enrollement_start_date']);
          this.basicInfo.controls['enrollment_end_date'].setValue(result.data[0]['enrollement_end_date']);

        }, (error) => {
            // console.log(result);
      });
    }

    co_insurer_addon(res: any) {    

      $('.newhr_row').html("");

      for (var i = 0; i < res.length; i++) {
        if (this.co_insurer_perc_sum > 100) {
          $(".Insurersum").css('display', 'block');
        } else {
          let insurer_id = parseInt(res[i].co_insurer);
          console.log(res[i].co_insurer)          
    
          let html = `
          <div id="hr_info_row${this.cnt}" class="row mt-3">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="co_insurer_${this.cnt}">Co Insurer </label>
                    <select class="form-select" formControlName="co_insurer_${this.cnt}" id="co_insurer_${this.cnt}">
                      <option value="" selected>Select Co Insurer</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="co_insurer_perc_${this.cnt}">Co Insurer (%) </label>
                    <input type="text" class="form-control" formControlName="co_insurer_perc_${this.cnt}" id="co_insurer_perc_${this.cnt}" maxlength="5" placeholder="Co Insurer (%)" value='${res[i]['co_insurer_perc']}' />
                </div>
            </div>
            <div class="col-sm-4 form-group">
                <img src="../../../assets/img/common icons/trash.png" id="hr_row${this.cnt}" role="button" class="img-fluid mt-3 pt-3" style="width:20px" title="Remove" />
            </div>
          </div>
          `;
    
          $('.newhr_row').append(html); 
    
          var val = '#co_insurer_' + this.cnt;
          
          console.log(`Appending options to ${val}`); // Debugging
          console.log(this.Insurer); // Debugging
          console.log(insurer_id); // Debugging
    
          if ($(val).length) { // Ensure element exists
            for (var j = 0; j < this.Insurer.length; j++) {
              if (insurer_id == this.Insurer[j]['insure_comp_id']) {
                $(val).append($("<option selected></option>")
                  .attr("value", this.Insurer[j]['insure_comp_id'])
                  .text(this.Insurer[j]['name']));
              } else {
                $(val).append($("<option></option>")
                  .attr("value", this.Insurer[j]['insure_comp_id'])
                  .text(this.Insurer[j]['name']));
              }
            }
          } else {
            console.error(`Element ${val} not found`);
          }
    
          // this.basicInfo.addControl("co_insurer_" + this.cnt, new FormControl(''));
          // this.basicInfo.addControl("co_insurer_perc_" + this.cnt, new FormControl(''));
    
          this.hr_info_arr[this.cnt] = { co_insurer: "", co_insurer_perc: "" };
    
          let flag = this;
          let cntflag = flag.cnt;
          $("#hr_row" + this.cnt).on('click', function (e) {
            flag.removeBenefitRow(e, cntflag);
          });
    
          $("#co_insurer_perc_" + this.cnt).on('blur', function (e) {
            flag.ishrperc(e, cntflag);
          });
    
          $("#co_insurer_perc_" + this.cnt).on('keydown', function (e) {
            flag.checkaddmoreDiv(e, cntflag);
          });
    
          $("#co_insurer_perc_" + this.cnt).on('keyup', function (e) {
            flag.checkaddmoreDiv(e, cntflag);
          });
    
          this.cnt++;
    
          $(".Insurersum").css('display', 'none');
        }
      }
    }
    
    
    /************ Edit GET Step 2 ***************/
    getStep2Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep2Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {
          this.familyInfo.controls['no_of_members_allowed'].setValue(result.data['no_of_members_allowed']);

          // console.log(result.data.selfData);

          for(let i = 0; i < result.data.member_typeselect.length; i++){
            let a:any = result.data.member_typeselect[i];
            this.memberType.push(String(a));

            this.Membertype = this.Membertype.filter((item: MemberType) => item.id !== a);
          }
          if(result.data['employee_included'] == '1'){
            this.familyInfo.controls['employee_included'].setValue('Yes');
            $(".member_typeNo").css('display', 'none');
            $(".member_typeYes").css('display', 'flex');
          }else{
            this.familyInfo.controls['employee_included'].setValue('No');
            $(".member_typeNo").css('display', 'flex');
            $(".member_typeYes").css('display', 'none');
          }

          if(result.data.parentData['id'] && result.data.Parents_In_LawData['id'])
          {
            $('.Parents_addional_div').css('display', 'block');
            $('.no_of_parent_in_law').css('display', 'none');
            $('.no_of_parents').css('display', 'none');
          }else{
            $('.Parents_addional_div').css('display', 'none');
            $('.no_of_parent_in_law').css('display', 'flex');
            $('.no_of_parents').css('display', 'flex');
          }

          if(result.data['is_parent_cross_section'] == 1){
            $('#parent_cross_section').prop('checked', true);
          }else{
            $('#parent_cross_section').prop('checked', false);
          }

          if(result.data['is_parent_parentinlaw_or_condition'] == 1){
            $('.div_no_parent_parentInLaw').css('display','flex');
            
            $('#no_of_parents_or_parentsinlaw').val(result.data['max_parents_or_parentsinlaw']);
          }else{
            $('.div_no_parent_parentInLaw').css('display','none');
          }

          if(result.data.selfData['no_age'] == 1){
            $('#no_age').prop('checked', true);
          }else{
            $('#no_age').prop('checked', false);
          }

          if(result.data.selfData['no_age'] == 1){

            $('.Div_no_age').css('display', 'none');
            }else{
            $('.Div_no_age').css('display', 'block');



          this.familyInfo.controls['member_type'].setValue(result.data.selfData['member_type']);
          this.familyInfo.controls['no_age'].setValue(result.data.selfData['no_age']);
          this.familyInfo.controls['min_age'].setValue(result.data.selfData['min_age']);
          this.familyInfo.controls['max_age'].setValue(result.data.selfData['max_age']);
        }

          // spouse

          // console.log(result.data.spouseData);


          if(result.data.spouseData['id'] || result.data.spouseData.length > 0){
            $('.spouseDiv').css('display', 'block');
            }else{
            $('.spouseDiv').css('display', 'none');
          }

          if(result.data.spouseData['id'] || result.data.spouseData.length > 0){

            if(result.data.spouseData['no_age_spouse'] == 1){

            $('.Div_no_age_spouse').css('display', 'none');
            }else{
            $('.Div_no_age_spouse').css('display', 'block');
            }

            if(result.data.spouseData['no_age_spouse'] == 1){
              $('#no_age_spouse').prop('checked', true);
            }else{
              $('#no_age_spouse').prop('checked', false);
            }

          // this.familyInfo.controls['no_age_spouse'].setValue(result.data.spouseData[0]['no_age_spouse']);
          this.familyInfo.controls['min_age_spouse'].setValue(result.data.spouseData['min_age_spouse']);
          this.familyInfo.controls['max_age_spouse'].setValue(result.data.spouseData['max_age_spouse']);
          this.familyInfo.controls['no_of_spouse'].setValue(result.data.spouseData['no_of_spouse']);



          if(result.data.spouseData['marraige_certificate'] == 1){
            $('#marraige_certificate').prop('checked', true);
          }else{
            $('#marraige_certificate').prop('checked', false);
          }

          if(result.data.spouseData['lgbt'] == 1){
            $('#lgbt').prop('checked', true);
          }else{
            $('#lgbt').prop('checked', false);
          }

          if(result.data.spouseData['midterm_allowed_for_spouse'] == 1){
            $('#midterm_allowed_for_spouse').prop('checked', true);
          }else{
            $('#midterm_allowed_for_spouse').prop('checked', false);
          }

          if(result.data.spouseData['midterm_allowed_for_spouse'] == 1){
            $('#midterm_allowed_for_spouseDiv').css('display', 'block');
          }else{
            $('#midterm_allowed_for_spouseDiv').css('display', 'none');
          }

          this.familyInfo.controls['spouse_enrolment_allowed_days'].setValue(result.data.spouseData['spouse_enrolment_allowed_days']);
          this.familyInfo.controls['spouse_enrolment_considered'].setValue(result.data.spouseData['spouse_enrolment_considered']);
          }




          // //Child

          // console.log(result.data.chilData);
          if(result.data.chilData['id'] || result.data.chilData.length > 0){
            $('.ChildrenDiv').css('display', 'block');
            }else{
            $('.ChildrenDiv').css('display', 'none');
          }

          if(result.data.chilData['id'] || result.data.chilData.length > 0){
          this.familyInfo.controls['Child_age_depend'].setValue(result.data.chilData['Child_age_depend']);

          if(result.data.chilData['no_age_Children'] == '1'){
            $('#no_age_Children').prop('checked', true);
          }else{
            $('#no_age_Children').prop('checked', false);
          }

          if(result.data.chilData['no_age_Children'] == 1){

            $('.Div_no_age_Children').css('display', 'none');
            }else{
            $('.Div_no_age_Children').css('display', 'block');
            }

          this.familyInfo.controls['min_age_Child'].setValue(result.data.chilData['min_age_Child']);
          this.familyInfo.controls['max_age_Child'].setValue(result.data.chilData['max_age_Child']);

          if(result.data.chilData['birth_certificate'] == '1'){
            $('#birth_certificate').prop('checked', true);
          }else{
            $('#birth_certificate').prop('checked', false);
          }
          this.familyInfo.controls['age_difference_Child'].setValue(result.data.chilData['age_difference_Child']);
          this.familyInfo.controls['no_of_Child'].setValue(result.data.chilData['no_of_Child']);

          if(result.data.chilData['midterm_allowed_for_Child'] == '1'){
            $('#midterm_allowed_for_Child').prop('checked', true);
            $('#midterm_allowed_for_childDiv').css('display', 'block');
            this.familyInfo.controls['child_enrolment_allowed_days'].setValue(result.data.chilData['child_enrolment_allowed_days']);
            this.familyInfo.controls['child_enrolment_considered'].setValue(result.data.chilData['child_enrolment_considered']);
          }else{
            $('#midterm_allowed_for_Child').prop('checked', false);
            $("#midterm_allowed_for_childDiv").css('display', 'none');
          }

          if(result.data.chilData['special_Child'] == '1'){
            $('#special_Child').prop('checked', true);
          }else{
            $('#special_Child').prop('checked', false);
          }

          if(result.data.chilData['unmarried_Child'] == '1'){
            $('#unmarried_Child').prop('checked', true);
          }else{
            $('#unmarried_Child').prop('checked', false);
          }

          if(result.data.chilData['adopted_Child'] == '1'){
            $('#adopted_Child').prop('checked', true);
          }else{
            $('#adopted_Child').prop('checked', false);
          }
          this.familyInfo.controls['no_of_twin_child'].setValue(result.data.chilData['no_of_twin_child']);
          }

          // //Parent

          if(result.data.parentData['id'] || result.data.parentData.length > 0){
            $('.ParentDiv').css('display', 'block');
            }else{
            $('.ParentDiv').css('display', 'none');
          }
          if(result.data.parentData['id'] || result.data.parentData.length > 0){

            if(result.data.parentData['no_age_Parent'] == 1){

              $('.Div_no_age_Parent').css('display', 'none');
              }else{
              $('.Div_no_age_Parent').css('display', 'block');
              }


            if(result.data.parentData['no_age_Parent'] == '1'){
              $('#no_age_Parent').prop('checked', true);
            }else{
              $('#no_age_Parent').prop('checked', false);
            }

            if(result.data.parentData['employee_allowed_Parents'] == 0){
              this.familyInfo.controls['employee_allowed_Parents'].setValue("0");
            }

            if(result.data.parentData['employee_allowed_Parents'] == 1){
              this.familyInfo.controls['employee_allowed_Parents'].setValue("1");
            }

            if(result.data.parentData['employee_allowed_Parents'] == 2){
              this.familyInfo.controls['employee_allowed_Parents'].setValue("2");
            }
          this.familyInfo.controls['Parent_age_depend'].setValue(result.data.parentData['Parent_age_depend']);
          this.familyInfo.controls['min_age_parent'].setValue(result.data.parentData['min_age_parent']);
          this.familyInfo.controls['max_age_parent'].setValue(result.data.parentData['max_age_parent']);
          this.familyInfo.controls['age_difference_parent'].setValue(result.data.parentData['age_difference_parent']);
          this.familyInfo.controls['no_of_Parents'].setValue(result.data.parentData['no_of_Parents']);
          }

           //Parent In Laws

          // console.log(result.data.Parents_In_LawData);

          if(result.data.Parents_In_LawData['id'] || result.data.Parents_In_LawData.length > 0){
            $('.Parents_In_LawDiv').css('display', 'block');
            }else{
            $('.Parents_In_LawDiv').css('display', 'none');
          }

          if(result.data.Parents_In_LawData['id'] || result.data.Parents_In_LawData.length > 0){

            if(result.data.Parents_In_LawData['no_age_Parents_In_Law'] == 1){

              $('.Div_no_age_Parents_In_Law').css('display', 'none');
              }else{
              $('.Div_no_age_Parents_In_Law').css('display', 'block');
              }

              if(result.data.Parents_In_LawData['no_age_Parents_In_Law'] == '1'){
                $('#no_age_Parents_In_Law').prop('checked', true);
              }else{
                $('#no_age_Parents_In_Law').prop('checked', false);
              }

              if(result.data.Parents_In_LawData['employee_allowed_Parents_In_Law'] == 0){
                this.familyInfo.controls['employee_allowed_Parents_In_Law'].setValue("0");
              }

              if(result.data.Parents_In_LawData['employee_allowed_Parents_In_Law'] == 1){
                this.familyInfo.controls['employee_allowed_Parents_In_Law'].setValue("1");
              }

              if(result.data.Parents_In_LawData['employee_allowed_Parents_In_Law'] == 2){
                this.familyInfo.controls['employee_allowed_Parents_In_Law'].setValue("2");
              }


          this.familyInfo.controls['Parents_In_Law_age_depend'].setValue(result.data.Parents_In_LawData['Parents_In_Law_age_depend']);

          // this.familyInfo.controls['no_age_Parents_In_Law'].setValue(result.data.Parents_In_LawData[0]['no_age_Parents_In_Law']);
          this.familyInfo.controls['min_age_parent_in_law'].setValue(result.data.Parents_In_LawData['min_age_parent_in_law']);
          this.familyInfo.controls['max_age_parent_in_law'].setValue(result.data.Parents_In_LawData['max_age_parent_in_law']);
          this.familyInfo.controls['age_difference_parent_in_law'].setValue(result.data.Parents_In_LawData['age_difference_parent_in_law']);
          this.familyInfo.controls['no_of_Parents_In_Laws'].setValue(result.data.Parents_In_LawData['no_of_Parents_In_Laws']);
          // this.familyInfo.controls['employee_allowed_Parents_In_Law'].setValue(result.data.Parents_In_LawData['employee_allowed_Parents_In_Law']);
          }

          // //Sibling
          // console.log(result.data.siblingData);

          if(result.data.siblingData['id'] || result.data.siblingData.length > 0){
            $('.SiblingDiv').css('display', 'block');
            }else{
            $('.SiblingDiv').css('display', 'none');
          }

          if(result.data.siblingData['id'] || result.data.siblingData.length > 0){

            // console.log(result.data.siblingData['no_age_Sibling']);

            if(result.data.siblingData['no_age_Sibling'] == 1){

              $('.Div_no_age_Sibling').css('display', 'none');
              }else{
              $('.Div_no_age_Sibling').css('display', 'block');
              }

              if(result.data.siblingData['no_age_Sibling'] == '1'){
                $('#no_age_Sibling').prop('checked', true);
              }else{
                $('#no_age_Sibling').prop('checked', false);
              }

          this.familyInfo.controls['no_of_Siblings'].setValue(result.data.siblingData['no_of_Siblings']);
          // this.familyInfo.controls['no_age_Sibling'].setValue(result.data.siblingData[0]['no_age_Sibling']);
          this.familyInfo.controls['min_age_sibling'].setValue(result.data.siblingData['min_age_sibling']);
          this.familyInfo.controls['max_age_sibling'].setValue(result.data.siblingData['max_age_sibling']);
          }

        }, (error) => {
            // console.log(result);
      });
    }

    visibleRows:any;
    /************ Edit GET Step 3 ***************/
    getStep3Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep3Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          this.sumInsured_Basis = result.data.policy_data[0]['sum_insured_basis_id'];
          this.premiumType = result.data.policy_data[0]['premium_type_id'];

          this.SIPremiumInfo.controls['cover_type'].setValue(result.data.policy_data[0]['cover_type_id']);
          this.SIPremiumInfo.controls['salary_type'].setValue(result.data.policy_data[0]['salary_type']);
          this.SIPremiumInfo.controls['basic_policy_mapping'].setValue(result.data.policy_data[0]['basic_policy_mapping']);

          this.SIPremiumInfo.controls['SI_Type'].setValue(result.data.policy_data[0]['suminsurued_type_id']);
          this.SIPremiumInfo.controls['SI_Basis'].setValue(result.data.policy_data[0]['sum_insured_basis_id']);
          this.SIPremiumInfo.controls['premium_Basis'].setValue(result.data.policy_data[0]['premium_type_id']);

          this.emmployeefile_id = result.data.policy_data[0]['premium_type_id'];
          
          this.FileDownload = result.data.policy_data[0]['policy_rater_upload_file'];

          // this.edit_file_url = result.data.uploaded_file_url;

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '2'|| result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '8' || result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '9'|| result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '13' || result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '15' || result.data.policy_data[0]['sum_insured_basis_id'] == '3' && result.data.policy_data[0]['premium_type_id'] == '1' || result.data.policy_data[0]['sum_insured_basis_id'] == '18' && result.data.policy_data[0]['premium_type_id'] == '21'){

            $('.employee_age_div').css('display', 'block');

            if(result.data.policy_data[0]['tax_status'] == '1'){
              $('#tax_status_include').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'block');
              this.SIPremiumInfo.controls['employee_premium_tax'].setValue(result.data.policy_data[0]['employee_premium_tax']);
              }else{
              $('#tax_status_exclude').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'none');
            }

          }else{
            $('.employee_age_div').css('display', 'none');
          }

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '3' && result.data.policy_data[0]['premium_type_id'] == '4' || result.data.policy_data[0]['sum_insured_basis_id'] == '3' && result.data.policy_data[0]['premium_type_id'] == '2' || result.data.policy_data[0]['sum_insured_basis_id'] == '3' && result.data.policy_data[0]['premium_type_id'] == '15'){
            $('.grade_div').css('display', 'flex');

            if(result.data.policy_data[0]['tax_status'] == '1'){
              $('#tax_status3_include').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'block');
              this.SIPremiumInfo.controls['employee_premium_tax3'].setValue(result.data.policy_data[0]['employee_premium_tax']);
              }else{
              $('#tax_status3_exclude').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'none');
            }

          }else{
            $('.grade_div').css('display', 'none');
          }
          
          if(result.data.policy_data[0]['sum_insured_basis_id'] == '8' && result.data.policy_data[0]['premium_type_id'] == '13'){
            $('.family_construct_div').css('display', 'flex');
          }else{
            $('.family_construct_div').css('display', 'none');
          }

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '19' && result.data.policy_data[0]['premium_type_id'] == '22'){
            $('.family_group_div').css('display', 'flex');
          }else{
            $('.family_group_div').css('display', 'none');
          }
          
          // Number of times salary - Premili age wise
          if(result.data.policy_data[0]['sum_insured_basis_id'] == '5' && result.data.policy_data[0]['premium_type_id'] == '5'){
            $('.no_times_salary_div').css('display', 'flex');

            if(result.data.policy_data[0]['tax_status'] == '1'){
              $('#tax_status2_include').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'block');
              this.SIPremiumInfo.controls['employee_premium_tax2'].setValue(result.data.policy_data[0]['employee_premium_tax']);
              }else{
              $('#tax_status2_exclude').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'none');
            }

            // Rater Data
            if(result.data.rater_data.length > 0){

              for(i = 0; i < result.data.rater_data.length; i++){

                if(result.data.rater_data[i]['no_of_times_of_salary'] !== null &&
                result.data.rater_data[i]['min_si_limit'] !== null &&
                result.data.rater_data[i]['max_si_limit'] !== null){

                  this.NoOfTimesSalary.push({
                    "no_of_times_salary" : result.data.rater_data[i]['no_of_times_of_salary'],
                    "lowest_SI_limit": result.data.rater_data[i]['min_si_limit'],
                    "highest_SI_limit": result.data.rater_data[i]['max_si_limit'],
                  });

                  // this.postRateArray.push(result.data.rater_data);

                }

              }

              $('.no_of_times_salary').css('display', 'block');

            }else{
              $('.no_of_times_salary').css('display', 'none');
            }

          }else{
            $('.no_times_salary_div').css('display', 'none');
          }

          if(result.data.uploaded_file_url != undefined || result.data.uploaded_file_url != ''){
            this.downloadlink = result.data.uploaded_file_url;

            this.edit_file_url = result.data.uploaded_file_url;
            this.edit_file_urlName = result.data.uploaded_file_url_name;
          }

          if(result.data.uploaded_file_url1 != undefined || result.data.uploaded_file_url1 != ''){
            this.edit_file_url1 = result.data.uploaded_file_url1;
            this.edit_file_url1Name = result.data.uploaded_file_url1_name;
          }

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '17' && result.data.policy_data[0]['premium_type_id'] == '19'){
            $('.unnamedDiv').css('display', 'block');

            if(result.data.policy_data[0]['tax_status'] == '1'){
              $('#tax_status1_include').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'block');
              this.SIPremiumInfo.controls['employee_premium_tax1'].setValue(result.data.policy_data[0]['employee_premium_tax']);
              }else{
              $('#tax_status1_exclude').prop('checked', true)
              $('.employee_tax_age_div').css('display', 'none');
            }

            // this.SIPremiumInfo.controls['total_employee'].setValue(result.data.policy_data[0]['employee_premium_tax']);
            // this.SIPremiumInfo.controls['total_SI'].setValue(result.data.policy_data[0]['employee_premium_tax']);
            // this.SIPremiumInfo.controls['total_premium'].setValue(result.data.policy_data[0]['employee_premium_tax']);


          }else{
            $('.unnamedDiv').css('display', 'none');
          }

          // Unnamed - Unnamed
          if(result.data.policy_data[0]['sum_insured_basis_id'] == '17' && result.data.policy_data[0]['premium_type_id'] == '20'){

            $('.unnamed_unnamedDiv').css('display', 'block');
            this.SIPremiumInfo.controls['total_employee_unnamed'].setValue(result.data.policy_data[0]['gpa_total_employee']);
            this.SIPremiumInfo.controls['gpa_individual_cover'].setValue(result.data.policy_data[0]['gpa_individual_cover']);
            this.SIPremiumInfo.controls['total_SI_unnamed'].setValue(result.data.policy_data[0]['gpa_total_SI']);
            this.SIPremiumInfo.controls['total_premium_unnamed'].setValue(result.data.policy_data[0]['gpa_total_premium']);

          }else{
            $('.unnamed_unnamedDiv').css('display', 'none');
          }

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '1'){
            $(".Sum_Insured_BasisDiv").css('display', 'block');
          }else{
            $(".Sum_Insured_BasisDiv").css('display', 'none');
          }

          // Flat - Flat
          // if(result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '1'){
          //   $(".Sum_Insured_BasisDiv").css('display', 'block');

          //   this.SIPremiumInfo.controls['minimum_SI'].setValue(result.data.policy_data[0]['minimum_si']);
          //   this.SIPremiumInfo.controls['maximum_SI'].setValue(result.data.policy_data[0]['maximum_si']);
          //   this.SIPremiumInfo.controls['interval_between_min_max_SI'].setValue(result.data.policy_data[0]['interval_between_min_max_si']);

          //   if(result.data.rater_data.length > 0){

          //   let first:any = result.data.policy_data[0]['minimum_si'];
          //   let second:any = result.data.policy_data[0]['maximum_si'];
          //   let interval:any = result.data.policy_data[0]['interval_between_min_max_si'];

          //   // Calculate the difference between the start and end numbers
          //   const difference = Math.abs(first - second);
          //   this.RowCount = 0;

          //   // Calculate the count by dividing the difference by the interval and rounding down
          //   if(this.RowCount == 0){
          //     this.RowCount = Math.floor(difference / interval);
          //   }else{
          //     this.RowCount = parseInt(this.RowCount) + Math.floor(difference / interval);
          //   }
          //   console.log("GenerateSICount count", this.GenerateSICount.length)

          //   this.RowCount = parseInt(this.RowCount) + 1;

          //   for(var i=0; i<this.RowCount; i++){

          //     if(this.GenerateSICount.length == 0){
          //       this.diffval = parseInt(first);
          //       this.GenerateSICount.push(this.diffval);

          //     }else{

          //       console.log(this.GenerateSICount);


          //       this.diffval = parseInt(this.diffval) + parseInt(interval);
          //       this.diffArr.push(this.diffval);

          //       let arrval:any = this.GenerateSICount[this.GenerateSICount.length - 1];
          //       let val = parseInt(arrval) + 1;
          //       this.GenerateSICount.push(this.diffval);

          //     }
          //   }


          //   if(result.data.rater_data.length == 0 && result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '1'){
          //     $(".SITable").css('display', 'none');
          //   }else{
          //     $(".SITable").css('display', 'block');
          //   }

          //   setTimeout(() => {
          //     this.visibleRows = []; // Array to store visibility status for each row
      
          //     for (var i = 0; i < this.GenerateSICount.length; i++) {
          //         let flatPremium = result.data.rater_data[i]['flat_premium'];
          //         if (flatPremium > 0) {
          //             this.visibleRows[i] = true; // Set visibility to true if premium is greater than 0
          //         } else {
          //             this.visibleRows[i] = false; // Set visibility to false if premium is 0 or less
          //         }
          //     }
          // }, 2000);

          //   setTimeout(() => {

          //     for(var i = 0; i < this.GenerateSICount.length; i++){
                
          //       $("#premium_" + i).val(result.data.rater_data[i]['flat_premium']);
          //       $("#premium_tax_" + i).val(result.data.rater_data[i]['tax']);
          //       $("#premium_total_" + i).val(result.data.rater_data[i]['total_premium']);

          //     }

          //   }, 3000);

          // }

          // }else{
          //   $(".Sum_Insured_BasisDiv").css('display', 'none');
          // }

          if(result.data.policy_data[0]['sum_insured_basis_id'] == '1' && result.data.policy_data[0]['premium_type_id'] == '1'){
            if(result.data.rater_data.length > 0){
              this.addonRaterData(result.data.rater_data);
            }
          }

          this.SIPremiumInfo.controls['ca_employer'].setValue(result.data.policy_data[0]['employer_contribution']);
          this.SIPremiumInfo.controls['ca_employee'].setValue(result.data.policy_data[0]['employee_contribution']);

          if(result.data.policy_data[0]['hide_employer_contribution'] == '1'){
            this.SIPremiumInfo.controls['hide_employer_contribution'].setValue('Yes');
          }else{
            this.SIPremiumInfo.controls['hide_employer_contribution'].setValue('No');
          }

          if(result.data.policy_data[0]['hide_employee_contribution'] == '1'){
            this.SIPremiumInfo.controls['hide_employee_contribution'].setValue('Yes');
          }else{
            this.SIPremiumInfo.controls['hide_employee_contribution'].setValue('No');
          }

          // this.SIPremiumInfo.controls['self_additional_employer'].setValue(result.data.policy_data[0]['self_additional_employer']);
          // this.SIPremiumInfo.controls['self_additional_employee'].setValue(result.data.policy_data[0]['self_additional_employee']);
          // this.SIPremiumInfo.controls['self_additional_premium'].setValue(result.data.policy_data[0]['self_additional_premium']);

          // this.SIPremiumInfo.controls['parent_in_laws_additional_employer'].setValue(result.data.policy_data[0]['parent_in_laws_additional_employer']);
          // this.SIPremiumInfo.controls['parent_in_laws_additional_employee'].setValue(result.data.policy_data[0]['parent_in_laws_additional_employee']);
          // this.SIPremiumInfo.controls['parent_in_laws_additional_premium'].setValue(result.data.policy_data[0]['parent_in_laws_additional_premium']);

          // this.SIPremiumInfo.controls['siblings_additional_employer'].setValue(result.data.policy_data[0]['siblings_additional_employer']);
          // this.SIPremiumInfo.controls['siblings_additional_employee'].setValue(result.data.policy_data[0]['siblings_additional_employee']);
          // this.SIPremiumInfo.controls['siblings_additional_premium'].setValue(result.data.policy_data[0]['siblings_additional_premium']);

          // this.SIPremiumInfo.controls['parent_additional_employer'].setValue(result.data.policy_data[0]['parent_additional_employer']);
          // this.SIPremiumInfo.controls['parent_additional_employee'].setValue(result.data.policy_data[0]['parent_additional_employee']);
          // this.SIPremiumInfo.controls['parent_additional_premium'].setValue(result.data.policy_data[0]['parent_additional_premium']);

          // this.SIPremiumInfo.controls['spouse_additional_employer'].setValue(result.data.policy_data[0]['spouse_additional_employer']);
          // this.SIPremiumInfo.controls['spouse_additional_employee'].setValue(result.data.policy_data[0]['spouse_additional_employee']);
          // this.SIPremiumInfo.controls['spouse_additional_premium'].setValue(result.data.policy_data[0]['spouse_additional_premium']);

          // this.SIPremiumInfo.controls['children_additional_employer'].setValue(result.data.policy_data[0]['children_additional_employer']);
          // this.SIPremiumInfo.controls['children_additional_employee'].setValue(result.data.policy_data[0]['children_additional_employee']);
          // this.SIPremiumInfo.controls['children_additional_premium'].setValue(result.data.policy_data[0]['children_additional_premium']);

          if(result.data.policy_data[0]['special_child'] == 1){
            $('.tabSpecialChild').css('display', 'block');
          this.SIPremiumInfo.controls['special_child_contribution_all_employee'].setValue(result.data.policy_data[0]['special_child_contribution_all_employee']);
          this.SIPremiumInfo.controls['special_child_contribution_all_employer'].setValue(result.data.policy_data[0]['special_child_contribution_all_employer']);
          this.SIPremiumInfo.controls['special_child_contribution_all_premium'].setValue(result.data.policy_data[0]['special_child_contribution_all_premium']);
          }else{
            $('.tabSpecialChild').css('display', 'none');
          }

          if(result.data.policy_data[0]['unmarried_child'] == 1){
            $('.tabUnmarriedChildren').css('display', 'block');
            this.SIPremiumInfo.controls['unmarried_child_contribution_all_employee'].setValue(result.data.policy_data[0]['unmarried_child_contribution_all_employee']);
            this.SIPremiumInfo.controls['unmarried_child_contribution_all_employer'].setValue(result.data.policy_data[0]['unmarried_child_contribution_all_employer']);
            this.SIPremiumInfo.controls['unmarried_child_contribution_all_premium'].setValue(result.data.policy_data[0]['unmarried_child_contribution_all_premium']);  
          }else{
            $('.tabUnmarriedChildren').css('display', 'none');
          }
         
          // this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employee'].setValue(result.data.policy_data[0]['unmarried_child_contribution_additional_employee']);
          // this.SIPremiumInfo.controls['unmarried_child_contribution_additional_employer'].setValue(result.data.policy_data[0]['unmarried_child_contribution_additional_employer']);
          // this.SIPremiumInfo.controls['unmarried_child_contribution_additional_premium'].setValue(result.data.policy_data[0]['unmarried_child_contribution_additional_premium']);

          // this.SIPremiumInfo.controls['special_child_contribution_additional_employee'].setValue(result.data.policy_data[0]['special_child_contribution_additional_employee']);
          // this.SIPremiumInfo.controls['special_child_contribution_additional_employer'].setValue(result.data.policy_data[0]['special_child_contribution_additional_employer']);
          // this.SIPremiumInfo.controls['special_child_contribution_additional_premium'].setValue(result.data.policy_data[0]['special_child_contribution_additional_premium']);

          if(result.data.payroll_data[0]['is_flex'] == '1'){
            this.SIPremiumInfo.controls['use_flex'].setValue(true);
          }else{
            this.SIPremiumInfo.controls['use_flex'].setValue(false);
          }

          if(result.data.payroll_data[0]['is_payroll'] == '1'){
            this.SIPremiumInfo.controls['payroll'].setValue(true);
            $('.payroll').css('display','block');
          }else{
            this.SIPremiumInfo.controls['payroll'].setValue(false);
            $('.payroll').css('display','none');
            $('.installment_allowed').css('display','none');
          }

          if(result.data.payroll_data[0]['is_installment_allow'] == '1'){
            this.SIPremiumInfo.controls['installment_allowed'].setValue(true);
            $('.installment_allowed').css('display','block');
          }else{
            this.SIPremiumInfo.controls['installment_allowed'].setValue(false);
            $('.installment_allowed').css('display','none');
          }

          if(result.data.payroll_data[0]['is_installment_prorata'] == '1'){
            this.SIPremiumInfo.controls['installment_prorata'].setValue('Yes');
          }else{
            this.SIPremiumInfo.controls['installment_prorata'].setValue('No');
          }

          let a:any = result.data.payroll_data[0]['installment_type'] == '2';
          if(a == true){
            this.SIPremiumInfo.controls['installment_type'].setValue('0');
          }else{
            this.SIPremiumInfo.controls['installment_type'].setValue('1');
          }

          this.MonthlyEquitible = [];

          let arr:any = result.data.monthly_equitible_period;
          for(var i=0; i<arr.length; i++){
            this.MonthlyEquitible.push(arr[i]);
          }

          this.SIPremiumInfo.controls['no_of_times_salary'].setValue(result.data.policy_data[0]['no_of_times_salary']);
          this.SIPremiumInfo.controls['lowest_SI_limit'].setValue(result.data.policy_data[0]['lowest_SI_limit']);
          this.SIPremiumInfo.controls['highest_SI_limit'].setValue(result.data.policy_data[0]['highest_SI_limit']);

        // Additinal Benefits
          if(result.data.policy_data[0]['additional_cover_check'] == 1){
            $('#additional_cover').prop('checked', true);
            $('.additional_cover').css('display', 'block');
            $('.additionalCover').css('display', 'block');

            for(var i = 0; i < result.data.additional_benefits.length; i++){
              this.AdditionalCoverArray.push(
                {"label": result.data.additional_benefits[i]['label'], "limit": result.data.additional_benefits[i]['cover']}
              );
            }

            // console.log(this.AdditionalCoverArray);

          }else{
            $('#additional_cover').prop('checked', false);
            $('.additional_cover').css('display', 'none');
            $('.additionalCover').css('display', 'none');
          }

        }, (error) => {
      });
    }

    onDownloadFiles(){

      // console.log(this.edit_file_url);

      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', this.edit_file_url);
      link.setAttribute('download',this.edit_file_urlName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    onDownloadFilesPremium(){

      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      link.setAttribute('href', this.edit_file_url1);
      link.setAttribute('download',this.edit_file_url1Name);

      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    addonRaterData(res: any){

      $('.newCustomField').html("");

      for(var i = 0; i < res.length; i++){

        if(res[i]['is_custom_flat_flat'] == 1){          
          
          let html = `
          <div id="this_Custom_row_`+ this.cnt_custom + `" class="row">
            <div class="col-sm-3 form-group mt-3">
              <label class="bs-form-label">Sum Insured</label>
              <input type="text" class="form-control" id="sum_insured_custom_`+ this.cnt_custom + `" placeholder="Sum Insured" value='`+res[i]['flat_suminsured']+`' />
            </div>
            <div class="col-sm-3 form-group mt-3">
              <label class="bs-form-label">Premium</label>
              <input type="text" class="form-control" id="premium_custom_`+ this.cnt_custom + `" placeholder="Premium" value='`+res[i]['flat_premium']+`' />
            </div>
            <div class="col-sm-3 form-group mt-3">
              <label class="bs-form-label">Premium Tax</label>
              <input type="text" class="form-control" id="premium_tax_custom_`+ this.cnt_custom + `" placeholder="Premium Tax" value='`+res[i]['tax']+`' />
            </div>
            <div class="col-sm-2 form-group mt-3">
              <label class="bs-form-label">Premium Total</label>
              <input type="text" class="form-control" id="premium_total_custom_`+ this.cnt_custom + `" placeholder="Premium Total" value='`+res[i]['total_premium']+`' />
            </div>
            <div class="col-sm-1 form-group mt-3 pt-2">
                <img src="../../../assets/img/common icons/trash.png" id="custom_row_`+ this.cnt_custom + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
            </div>
          </div>
          `;
    
          $('.newCustomField').append(html);
    
          // this.SIPremiumInfo.addControl("sum_insured_custom_" + this.cnt_custom, new FormControl(''));
          // this.SIPremiumInfo.addControl("premium_custom_" + this.cnt_custom, new FormControl(''));
          // this.SIPremiumInfo.addControl("premium_tax_custom_" + this.cnt_custom, new FormControl(''));
          // this.SIPremiumInfo.addControl("premium_total_custom_" + this.cnt_custom, new FormControl(''));
    
          var sum_insured = "sum_insured_custom_" + this.cnt_custom;
          var premium = "premium_custom_" + this.cnt_custom;
          var premium_tax = "premium_tax_custom_" + this.cnt_custom;
          var premium_total = "premium_total_custom_" + this.cnt_custom;
    
          this.CustomArr[this.cnt_custom] = { sum_insured: "", premium: "", premium_tax: "", premium_total: "" };
    
          let flag = this;
          $("#custom_row_" + this.cnt_custom).on('click', function (e) {
            flag.removeSICustomRow(e);
          });
    
          $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
            flag.isnumberSICustom(e);
          });
    
          $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
            flag.isnumberSICustom(e);
          });
    
          $("#premium_custom_" + this.cnt_custom).on('keyup', function (e) {
            flag.sum_insured_Total_premium(e);
          });
    
          $("#premium_tax_custom_" + this.cnt_custom).on('keyup', function (e) {
            flag.sum_insured_Total_premium(e);
          });
    
          $("#premium_custom_" + this.cnt_custom).on('keydown', function (e) {
            flag.sum_insured_Total_premium(e);
          });
    
          $("#premium_tax_custom_" + this.cnt_custom).on('keydown', function (e) {
            flag.sum_insured_Total_premium(e);
          });
    
          this.cnt_custom++;

        }
      }
    }

    /************ Edit GET Step 4 ***************/
    getStep4Data(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep4Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          /***** Broker ********/

          setTimeout(() => {
            var postdata = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "user_type_id":result.data.broker.brokerbase[0]['designation_id'],
              "broker_id":localStorage.getItem('broker_id'),
            }
            this.clientCreationService.getDesignationType(postdata)
              .subscribe((result) => {
  
                this.entityDetails = [];
  
                this.entityDetails = result.data;
  
              }, (error) => {
  
                this.entityDetails = [];
                  // console.log(result);
            });
          }, 2000); 

          

          /*******************/


          /***** Client ********/
          var postdata2 = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":result.data.client.clientbase[0]['designation_id'],
            "master_policies_id":localStorage.getItem('master_policies_id'),
          }
          this.clientCreationService.getDesignationType_e(postdata2)
            .subscribe((result) => {

              this.entityDetails_e = [];

              this.entityDetails_e = result.data;

            }, (error) => {

              this.entityDetails_e = [];
                // console.log(result);
          });
          /*******************/

          // Broker
          this.contactInfo.controls['b_role_designation_type'].setValue(result.data.broker.brokerbase[0]['designation_id']);
          this.contactInfo.controls['b_emailID'].setValue(result.data.broker.brokerbase[0]['contact_email_ID']);
          this.contactInfo.controls['b_contactname'].setValue(result.data.broker.brokerbase[0]['contact_name']);
          this.contactInfo.controls['b_mobileno'].setValue(result.data.broker.brokerbase[0]['contact_number']);
          this.contactInfo.controls['b_level'].setValue(result.data.broker.brokerbase[0]['level']);

          this.selectedUserName = result.data.broker.brokerbase[0]['contact_email'];

          setTimeout(() => {
          if(result.data.broker.brokeraddon.length > 0){
            this.addaddonbroker(result.data.broker.brokeraddon);
          }
         }, 2000); 

          // Client
          this.contactInfo.controls['e_role_designation_type'].setValue(result.data.client.clientbase[0]['designation_id']);
          this.contactInfo.controls['e_emailID'].setValue(result.data.client.clientbase[0]['contact_email_ID']);
          this.contactInfo.controls['e_contactname'].setValue(result.data.client.clientbase[0]['contact_name']);
          this.contactInfo.controls['e_mobileno'].setValue(result.data.client.clientbase[0]['contact_number']);
          this.contactInfo.controls['e_level'].setValue(result.data.client.clientbase[0]['level']);

          this.empSelectedName = result.data.client.clientbase[0]['contact_email'];

          if(result.data.client.clientaddon.length > 0){
            this.addaddonclient(result.data.client.clientaddon);
          }

          /**** TPA ******/

          if(result.data.tpa.tpabase.length > 0){
            var postdata3 = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "id":result.data.tpa.tpabase[0]['designation_id'],
              };
             this.clientCreationService.InfoSingle(postdata3)
             .subscribe((result) => {

             this.entityDetails_tpa = result.data[0];

               }, (error) => {

                 this.entityDetails_tpa = [];
                   // console.log(result);
             });
          }

          // Tpa
          if(result.data.tpa.tpabase.length > 0){
            this.contactInfo.controls['tpa_role_designation_type'].setValue(result.data.tpa.tpabase[0]['designation_id']);
            this.contactInfo.controls['tpa_emailID'].setValue(result.data.tpa.tpabase[0]['contact_email']);
            this.contactInfo.controls['tpa_contactname'].setValue(result.data.tpa.tpabase[0]['contact_name']);
            this.contactInfo.controls['tpa_mobileno'].setValue(result.data.tpa.tpabase[0]['contact_number']);
            this.contactInfo.controls['tpa_level'].setValue(result.data.tpa.tpabase[0]['level']);
          }

          if(result.data.tpa.tpaaddon.length > 0){
            this.addaddontpa(result.data.tpa.tpaaddon);
          }

          // Insrer
          if(result.data.insu.insubase.length > 0){
            this.contactInfo.controls['insurer_id_step4'].setValue(result.data.insu.insubase[0]['designation_id']);
            this.contactInfo.controls['Insurer_emailID'].setValue(result.data.insu.insubase[0]['contact_email']);
            this.contactInfo.controls['Insurer_contactname'].setValue(result.data.insu.insubase[0]['contact_name']);
            this.contactInfo.controls['Insurer_mobileno'].setValue(result.data.insu.insubase[0]['contact_number']);
            this.contactInfo.controls['Insurer_level'].setValue(result.data.insu.insubase[0]['level']);
          }

          if(result.data.insu.insuaddon.length > 0){
            this.addaddoninsurer(result.data.insu.insuaddon);
          }


        }, (error) => {
            // console.log(result);
      });
    }

    addaddonbroker(res:any){

      for(var i = 0; i < res.length; i++){

        // console.log(res[i].designation_id);

        let did = res[i].designation_id;
        let eid = res[i].contact_email_ID;
        let level = res[i].level;

        let html = `
          <div id="this_broker_row_`+ this.cnt_broker + `" class="row">
            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="b_role_designation_type_`+ this.cnt_broker + `">Role/Designation Type <span class="redstar">*</span></label>
                <select class="form-select" formControlName="b_role_designation_type_`+ this.cnt_broker + `" id="b_role_designation_type_`+ this.cnt_broker + `">
                  <option value="">Select Role/Designation Type</option>
                </select>
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="b_emailID">Email ID <span class="redstar">*</span></label>
                <select class="form-select" id="b_emailID_`+ this.cnt_broker + `">
                  <option value="">Select Email ID</option>
                </select>
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="b_contactname">Name <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="b_contactname_`+ this.cnt_broker + `" id="b_contactname_`+ this.cnt_broker + `" value='`+res[i]['contact_name']+`' />
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="b_mobileno">Mobile No. <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="b_mobileno_`+ this.cnt_broker + `" id="b_mobileno_`+ this.cnt_broker + `" maxlength="10" value='`+res[i]['contact_number']+`' />
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="b_level">Level <span class="redstar">*</span></label>
                <select class="form-select" formControlName="b_level_`+ this.cnt_broker + `" id="b_level_`+ this.cnt_broker + `" value='`+res[i]['level']+`'>
                  <option value="">Select Level</option>
                  <option value="1">Level 1</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                </select>
              </div>
            </div>
            <div class="col-sm-1 form-group mt-3 pt-2">
                <img src="../../../assets/img/common icons/trash.png" id="broker_row_`+ this.cnt_broker + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
            </div>
          </div>
        `;

          $('.newbrokerField').append(html);

          var val = '#' + 'b_role_designation_type_'+ this.cnt_broker;
          var val2 = '#' + 'b_emailID_'+ this.cnt_broker;
          var val3 = '#' + 'b_level_'+ this.cnt_broker;

          // Level
          $(val3).val(level);

          // Broker
          console.log("second",this.contactroleArr);
          console.log("did",did);
          console.log("eid",eid);

          $(val).empty();
          
          for(var i=0; i<this.contactroleArr.length; i++){

            // Broker
            if(did == this.contactroleArr[i]['user_type_id']){
              var val = '#' + 'b_role_designation_type_'+ this.cnt_broker;
              $(val)
              .append($("<option selected></option>")
                .attr("value", this.contactroleArr[i]['user_type_id'])
                .text(this.contactroleArr[i]['tag_name']));
            }

            if(did != this.contactroleArr[i]['user_type_id']){
              $(val)
                .append($("<option></option>")
                  .attr("value", this.contactroleArr[i]['user_type_id'])
                  .text(this.contactroleArr[i]['tag_name']));
            }

          }


          // Email
          var postdata2 = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":did,
            "broker_id":localStorage.getItem('broker_id'),
            "policy_id": localStorage.getItem('master_policies_id'),
          };
          this.clientCreationService.getDesignationType(postdata2)
            .subscribe((result) => {

              // console.log(result.data[0]);

              for(var i=0; i<result.data.length; i++){

                // Email
                if(eid == result.data[i].user_id){
                  $(val2)
                  .append($("<option selected></option>")
                    .attr("value", result.data[i].user_id)
                    .text(result.data[i].username));
                }

                if(eid != result.data[i].user_id){
                  $(val2)
                    .append($("<option></option>")
                      .attr("value", result.data[i].user_id)
                      .text(result.data[i].username));
                }

              }

            }, (error) => {

          });



          // this.SIPremiumInfo.addControl("b_role_designation_type_" + this.cnt_broker, new FormControl(''));
          // this.SIPremiumInfo.addControl("b_emailID_" + this.cnt_broker, new FormControl(''));
          // this.SIPremiumInfo.addControl("b_contactname_" + this.cnt_broker, new FormControl(''));
          // this.SIPremiumInfo.addControl("b_mobileno_" + this.cnt_broker, new FormControl(''));

          this.brokerArr_step4[this.cnt_broker] = { b_role_designation_type: "", b_contactname: "", b_emailID_tax: "", b_mobileno: "" };

          let flag = this;
          $("#broker_row_" + this.cnt_broker).on('click', function (e) {
            flag.removeSIbrokerRow(e);
          });

		      $("#b_role_designation_type_" + this.cnt_broker).on('change', function (e) {

            var str = e.target.id;
            var id = str.charAt(str.length -1 );

            //clear
            var idclear = flag.cnt_broker - 1;
            var valclear = '#' + 'b_emailID_'+ idclear;
            $(valclear).empty();

            //First Value append
            var id_firstval = flag.cnt_broker - 1;
            var val_firstval = '#' + 'b_emailID_'+ id_firstval;
            $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

            // Load other option values
            var postdata2 = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "user_type_id":$('#b_role_designation_type_' + id).val(),
              "broker_id":localStorage.getItem('broker_id'),
              "policy_id": localStorage.getItem('master_policies_id'),
            };

            flag.clientCreationService.getDesignationType(postdata2)
              .subscribe((result) => {

                for(var i=0; i<result.data.length; i++){
                  var id = flag.cnt_broker - 1;
                  var val = '#' + 'b_emailID_'+ id;
                  $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['username']));
                }

              }, (error) => {

            });

          });

          $("#b_emailID_" + this.cnt_broker).on('change', function (e) {

            var str = e.target.id;
            var id = str.charAt(str.length -1 );

            var postdata = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "user_type_id":$('#b_role_designation_type_' + id).val(),
              "broker_id":localStorage.getItem('broker_id'),
              "policy_id": localStorage.getItem('master_policies_id'),
            }
            flag.clientCreationService.getDesignationType(postdata)
              .subscribe((result) => {

                let valchecked:any = $("#b_emailID_" + id).val();

                for(var i=0; i < result.data.length; i++){
                  if(result.data[i]['user_id'] ==  valchecked){
                    $("#b_contactname_" + id).val(result.data[i]['name']);
                    $("#b_mobileno_" + id).val(result.data[i]['mobile_no']);
                  }else{

                  }
                }

              }, (error) => {

            });

          });

          $("#b_mobileno_" + this.cnt_broker).on('keyup', function (e) {
            flag.isnumberSIbroker(e);
          });

          $("#b_emailID_" + this.cnt_broker).on('keyup', function (e) {
            flag.appendbrokerdetails(e);
          });

		    this.cnt_broker++;

      }

    }

    addaddonclient(res:any){

      for(var i = 0; i < res.length; i++){

        // console.log(res[i].designation_id);

        let did = res[i].designation_id;
        let eid = res[i].contact_email_ID;
        let level = res[i].level;

        let html = `
        <div id="this_employer_row_`+ this.cnt_employer + `" class="row">
          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_role_designation_type_`+ this.cnt_employer + `">Role/Designation Type <span class="redstar">*</span></label>
              <select class="form-select" formControlName="e_role_designation_type_`+ this.cnt_employer + `" id="e_role_designation_type_`+ this.cnt_employer + `">
                <option value="" selected>Select Role/Designation Type</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_emailID">Email ID <span class="redstar">*</span></label>
              <select class="form-select" id="e_emailID_`+ this.cnt_employer + `">
                <option value="" selected>Select Email ID</option>
              </select>
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_contactname">Name <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="e_contactname_`+ this.cnt_employer + `" id="e_contactname_`+ this.cnt_employer + `" value='`+res[i]['contact_name']+`' />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_mobileno">Mobile No. <span class="redstar">*</span></label>
              <input type="text" class="form-control" formControlName="e_mobileno_`+ this.cnt_employer + `" id="e_mobileno_`+ this.cnt_employer + `" maxlength="10" value='`+res[i]['contact_number']+`' />
            </div>
          </div>

          <div class="col-sm-4 mt-3">
            <div class="form-group">
              <label class="bs-form-label" for="e_level">Level <span class="redstar">*</span></label>
              <select class="form-select" formControlName="e_level_`+ this.cnt_employer + `" id="e_level_`+ this.cnt_employer + `">
                <option value="" selected>Select Level</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
              </select>
            </div>
          </div>
          <div class="col-sm-1 form-group mt-3 pt-2">
              <img src="../../../assets/img/common icons/trash.png" id="employer_row_`+ this.cnt_employer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
          </div>
        </div>
        `;

        $('.newemployerField').append(html);

        var val = '#' + 'e_role_designation_type_'+ this.cnt_employer;
        var val2 = '#' + 'e_emailID_'+ this.cnt_employer;
        var val3 = '#' + 'e_level_'+ this.cnt_employer;

        // Level
        $(val3).val(level);

        // Broker
        for(var i=0; i<this.contactroleemployerArr.length; i++){

          // Broker
          if(did == this.contactroleemployerArr[i]['user_type_id']){
            $(val)
            .append($("<option selected></option>")
              .attr("value", this.contactroleemployerArr[i]['user_type_id'])
              .text(this.contactroleemployerArr[i]['tag_name']));
          }

          if(did != this.contactroleemployerArr[i]['user_type_id']){
            $(val)
              .append($("<option></option>")
                .attr("value", this.contactroleemployerArr[i]['user_type_id'])
                .text(this.contactroleemployerArr[i]['tag_name']));
          }

        }


        // Email
        var postdata2 = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "user_type_id":did,
          "master_policies_id":localStorage.getItem('master_policies_id')
        };
        this.clientCreationService.getDesignationType_e(postdata2)
          .subscribe((result) => {

            // console.log(result.data[0]);

            for(var i=0; i<result.data.length; i++){

              // Email
              if(eid == result.data[i].user_id){
                $(val2)
                .append($("<option selected></option>")
                  .attr("value", result.data[i].user_id)
                  .text(result.data[i].username));
              }

              if(eid != result.data[i].user_id){
                $(val2)
                  .append($("<option></option>")
                    .attr("value", result.data[i].user_id)
                    .text(result.data[i].username));
              }

            }

          }, (error) => {

        });

        // this.SIPremiumInfo.addControl("e_role_designation_type_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_emailID_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_contactname_" + this.cnt_employer, new FormControl(''));
        // this.SIPremiumInfo.addControl("e_mobileno_" + this.cnt_employer, new FormControl(''));

        this.employer_step4Arr[this.cnt_employer] = { e_role_designation_type: "", e_contactname: "", e_emailID_tax: "", e_mobileno: "" };

        let flag = this;
        $("#employer_row_" + this.cnt_employer).on('click', function (e) {
          flag.removeSIemployerRow(e);
        });

        $("#e_role_designation_type_" + this.cnt_employer).on('change', function (e) {

          var str:any = e.target.id;
          var id = str.charAt(str.length -1 );

          //clear
          var idclear = flag.cnt_employer - 1;
          var valclear = '#' + 'e_emailID_'+ idclear;
          $(valclear).empty();

          //First Value append
          var id_firstval = flag.cnt_employer - 1;
          var val_firstval = '#' + 'e_emailID_'+ id_firstval;
          $(val_firstval).append($("<option selected></option>").attr("value", '').text('Select Email ID'));

          // Load other option values
          var postdata2 = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#e_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),
          };

          flag.clientCreationService.getDesignationType_e(postdata2)
            .subscribe((result) => {

              for(var i=0; i<result.data.length; i++){
                var id = flag.cnt_employer - 1;
                var val = '#' + 'e_emailID_'+ id;
                $(val).append($("<option></option>").attr("value", result.data[i]['user_id']).text(result.data[i]['username']));
              }

            }, (error) => {

          });

        });

        $("#e_emailID_" + this.cnt_employer).on('change', function (e) {

          var str = e.target.id;
          var id = str.charAt(str.length -1 );

          var postdata = {
            "tokenable_id":localStorage.getItem('tokenable_id'),
            "user_type_id":$('#e_role_designation_type_' + id).val(),
            "broker_id":localStorage.getItem('broker_id'),
            "master_policies_id": localStorage.getItem('master_policies_id'),
          }
          flag.clientCreationService.getDesignationType_e(postdata)
            .subscribe((result) => {

              let valchecked:any = $("#e_emailID_" + id).val();

              for(var i=0; i < result.data.length; i++){
                if(result.data[i]['user_id'] ==  valchecked){
                  $("#e_contactname_" + id).val(result.data[i]['name']);
                  $("#e_mobileno_" + id).val(result.data[i]['mobile_no']);
                }else{

                }
              }

            }, (error) => {

          });

        });

        $("#e_mobileno_" + this.cnt_employer).on('keyup', function (e) {
          flag.isnumberSIemployer(e);
        });

        this.cnt_employer++;

      }

    }

    addaddontpa(res:any){
      for(var i = 0; i < res.length; i++){

        let did = res[i].designation_id;
        let eid = res[i].tpa_contactname;
        let level = res[i].level;

        let html = `
          <div id="this_tpa_row_`+ this.cnt_tpa + `" class="row">
            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="tpa_role_designation_type_`+ this.cnt_tpa + `">Role/Designation Type </label>
                <select class="form-select" formControlName="tpa_role_designation_type_`+ this.cnt_tpa + `" id="tpa_role_designation_type_`+ this.cnt_tpa + `">
                  <option value="" selected>Select Role/Designation Type</option>
                </select>
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="tpa_emailID">Email ID <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="tpa_emailID_`+ this.cnt_tpa + `" id="tpa_emailID_`+ this.cnt_tpa + `" " value='`+res[i]['contact_email']+`' />
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="tpa_contactname">Name </label>
                <input type="text" class="form-control" formControlName="tpa_contactname_`+ this.cnt_tpa + `" id="tpa_contactname_`+ this.cnt_tpa + `" " value='`+res[i]['contact_name']+`' />
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="tpa_mobileno">Mobile No. <span class="redstar">*</span></label>
                <input type="text" class="form-control" formControlName="tpa_mobileno_`+ this.cnt_tpa + `" id="tpa_mobileno_`+ this.cnt_tpa + `" maxlength="10" " value='`+res[i]['contact_number']+`' />
              </div>
            </div>

            <div class="col-sm-4 mt-3">
              <div class="form-group">
                <label class="bs-form-label" for="tpa_level">Level </label>
                <select class="form-select" formControlName="tpa_level_`+ this.cnt_tpa + `" id="tpa_level_`+ this.cnt_tpa + `" " value='`+res[i]['level']+`'>
                  <option value="" selected>Select Level</option>
                  <option value="1">Level 1</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                </select>
              </div>
            </div>
            <div class="col-sm-1 form-group mt-3 pt-2">
                <img src="../../../assets/img/common icons/trash.png" id="tpa_row_`+ this.cnt_tpa + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
            </div>
          </div>
          `;

          $('.newtpaField').append(html);

          var val = '#' + 'tpa_role_designation_type_'+ this.cnt_tpa;
          var val2 = '#' + 'tpa_contactname_'+ this.cnt_tpa;
          var val3 = '#' + 'tpa_level_'+ this.cnt_tpa;

          // Level
          $(val3).val(level);

          for(var i=0; i<this.TPA.length; i++){

            // Broker
            if(did == this.TPA[i]['tpa_id']){
              $(val)
              .append($("<option selected></option>")
                .attr("value", this.TPA[i]['tpa_id'])
                .text(this.TPA[i]['name']));
            }

            if(did != this.TPA[i]['tpa_id']){
              $(val)
                .append($("<option></option>")
                  .attr("value", this.TPA[i]['tpa_id'])
                  .text(this.TPA[i]['name']));
            }

          }

          for(var i=0; i<this.TPA.length; i++){
            var val = '#' + 'tpa_role_designation_type_'+ this.cnt_tpa;
            $(val)
              .append($("<option></option>")
                .attr("value", this.TPA[i]['tpa_id'])
                .text(this.TPA[i]['name']));
          }

          for(var i=0; i<this.emailID.length; i++){
            var val = '#' + 'tpa_emailID_'+ this.cnt_tpa;
            $(val)
              .append($("<option></option>")
                .attr("value", this.emailID[i]['user_id'])
                .text(this.emailID[i]['username']));
          }

          // this.SIPremiumInfo.addControl("tpa_role_designation_type_" + this.cnt_tpa, new FormControl(''));
          // this.SIPremiumInfo.addControl("tpa_emailID_" + this.cnt_tpa, new FormControl(''));
          // this.SIPremiumInfo.addControl("tpa_contactname_" + this.cnt_tpa, new FormControl(''));
          // this.SIPremiumInfo.addControl("tpa_mobileno_" + this.cnt_tpa, new FormControl(''));

          var tpa_role_designation_type_ = "tpa_role_designation_type_" + this.cnt_tpa;
          var b_emailID = "tpa_emailID_" + this.cnt_tpa;
          var tpa_contactname_ = "tpa_contactname_" + this.cnt_tpa;
          var b_mobileno = "tpa_mobileno_" + this.cnt_tpa;

          this.tpa_step4Arr[this.cnt_tpa] = { tpa_role_designation_type_: "", tpa_contactname_: "", tpa_emailID_: "", tpa_mobileno_: "" };

          let flag = this;
          $("#tpa_row_" + this.cnt_tpa).on('click', function (e) {
            flag.removeSItpaRow(e);
          });

          $("#tpa_mobileno_" + this.cnt_tpa).on('keyup', function (e) {
            flag.isnumberSItpa(e);
          });

          $("#tpa_role_designation_type_" + this.cnt_tpa).on('change', function (e) {

            var str = e.target.id;
            var id = str.charAt(str.length -1 );

            var tpa_role_designation_type = "tpa_role_designation_type_" + id;
            var b_emailID = "tpa_emailID_" + id;
            var tpa_contactname_ = "tpa_contactname_" + id;
            var b_mobileno = "tpa_mobileno_" + id;

            let tpa_id:any = $('#tpa_role_designation_type_' + id).val();
            var postdata = {
              "tokenable_id":localStorage.getItem('tokenable_id'),
              "id":tpa_id
            };
            flag.clientCreationService.InfoSingle(postdata)
              .subscribe((result) => {

                flag.entityDetails_tpa = result.data[0];

                $('#tpa_emailID_' + id).val(flag.entityDetails_tpa.email_1);
                $('#tpa_contactname_' + id).val(flag.entityDetails_tpa.name);
                $('#tpa_mobileno_' + id).val(flag.entityDetails_tpa.contact_1);

              }, (error) => {
                  // console.log(result);

                  flag.entityDetails_tpa = [];
            });
          });

          flag.cnt_tpa++;

        }
    }

    addaddoninsurer(res:any){
    for(var i = 0; i < res.length; i++){

      let did = res[i].designation_id;
      let eid = res[i].contact_name;
      let level = res[i].level;

      let html = `
      <div id="this_Insurer_row_`+ this.cnt_Insurer + `" class="row">
        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="step4_insurer_id_`+ this.cnt_Insurer + `">Insurer <span class="redstar">*</span></label>
            <select class="form-select" formControlName="step4_insurer_id_`+ this.cnt_Insurer + `" id="step4_insurer_id_`+ this.cnt_Insurer + `">
              <option value="" selected>Select Insurer</option>
            </select>
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_emailID">Email ID </label>
            <input type="text" class="form-control" formControlName="Insurer_emailID_`+ this.cnt_Insurer + `" id="Insurer_emailID_`+ this.cnt_Insurer + `" value='`+res[i]['contact_email']+`' />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_contactname">Name </label>
            <input type="text" class="form-control" formControlName="Insurer_contactname_`+ this.cnt_Insurer + `" id="Insurer_contactname_`+ this.cnt_Insurer + `" value='`+res[i]['contact_name']+`' />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_mobileno">Mobile No. </label>
            <input type="text" class="form-control" formControlName="Insurer_mobileno_`+ this.cnt_Insurer + `" id="Insurer_mobileno_`+ this.cnt_Insurer + `" maxlength="10" value='`+res[i]['contact_number']+`' />
          </div>
        </div>

        <div class="col-sm-4 mt-3">
          <div class="form-group">
            <label class="bs-form-label" for="Insurer_level">Level </label>
            <select class="form-select" formControlName="Insurer_level_`+ this.cnt_Insurer + `" id="Insurer_level_`+ this.cnt_Insurer + `" value='`+res[i]['level']+`'>
              <option value="" selected>Select Level</option>
              <option value="1">Level 1</option>
              <option value="2">Level 2</option>
              <option value="3">Level 3</option>
            </select>
          </div>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="Insurer_row_`+ this.cnt_Insurer + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newInsurerField').append(html);

      var val = '#' + 'step4_insurer_id_'+ this.cnt_Insurer;
      var val2 = '#' + 'Insurer_contactname_'+ this.cnt_Insurer;
      var val3 = '#' + 'Insurer_level_'+ this.cnt_Insurer;

      // Level
      $(val3).val(level);

      for(var i=0; i<this.Insurer.length; i++){

        // Broker
        if(did == this.Insurer[i]['insure_comp_id']){
          $(val)
          .append($("<option selected></option>")
            .attr("value", this.Insurer[i]['insure_comp_id'])
            .text(this.Insurer[i]['name']));
        }

        if(did != this.Insurer[i]['insure_comp_id']){
          $(val)
            .append($("<option></option>")
              .attr("value", this.Insurer[i]['insure_comp_id'])
              .text(this.Insurer[i]['name']));
        }

      }


      for(var i=0; i<this.Insurer.length; i++){
        var val = '#' + 'step4_insurer_id_'+ this.cnt_Insurer;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Insurer[i]['insure_comp_id'])
            .text(this.Insurer[i]['name']));
      }

      for(var i=0; i<this.emailID.length; i++){
        var val = '#' + 'Insurer_emailID_'+ this.cnt_Insurer;
        $(val)
          .append($("<option></option>")
            .attr("value", this.emailID[i]['user_id'])
            .text(this.emailID[i]['username']));
      }

      // this.SIPremiumInfo.addControl("step4_insurer_id_" + this.cnt_Insurer, new FormControl(''));
      // this.SIPremiumInfo.addControl("Insurer_emailID_" + this.cnt_Insurer, new FormControl(''));
      // this.SIPremiumInfo.addControl("Insurer_contactname_" + this.cnt_Insurer, new FormControl(''));
      // this.SIPremiumInfo.addControl("Insurer_mobileno_" + this.cnt_Insurer, new FormControl(''));

      var step4_insurer_id_ = "step4_insurer_id_" + this.cnt_Insurer;
      var b_emailID = "Insurer_emailID_" + this.cnt_Insurer;
      var Insurer_contactname_ = "Insurer_contactname_" + this.cnt_Insurer;
      var b_mobileno = "Insurer_mobileno_" + this.cnt_Insurer;

      this.Insurer_step4Arr[this.cnt_Insurer] = { step4_insurer_id_: "", Insurer_contactname_: "", Insurer_emailID_: "", Insurer_mobileno_: "" };

      let flag = this;
      $("#Insurer_row_" + this.cnt_Insurer).on('click', function (e) {
        flag.removeSIInsurerRow(e);
      });

      $("#Insurer_mobileno_" + this.cnt_Insurer).on('keyup', function (e) {
        flag.isnumberSIInsurer(e);
      });

      $("#step4_insurer_id_" + this.cnt_Insurer).on('change', function (e) {

        var str = e.target.id;
        var id = str.charAt(str.length -1 );

        var step4_insurer_id = "step4_insurer_id_" + id;
        var b_emailID = "Insurer_emailID_" + id;
        var Insurer_contactname_ = "Insurer_contactname_" + id;
        var b_mobileno = "Insurer_mobileno_" + id;

        let Insurer_id:any = $('#step4_insurer_id_' + id).val();
        var postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "id":Insurer_id
        };
        flag.clientCreationService.InfoSingle_Insurer(postdata)
          .subscribe((result) => {

            flag.entityDetails_Insurer = result.data[0];

            $('#Insurer_emailID_' + id).val(flag.entityDetails_Insurer.contact_email);
            $('#Insurer_contactname_' + id).val(flag.entityDetails_Insurer.name);
            $('#Insurer_mobileno_' + id).val(flag.entityDetails_Insurer.contact_number);

          }, (error) => {
              // console.log(result);

              flag.entityDetails_Insurer = [];
        });
      });

      flag.cnt_Insurer++;

    }
    }


    /************ Edit GET Step 5 ***************/
    getStep5Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep5Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          // console.log(result.data);

          if(result.data.cd_statement.length > 0){

            // this.additionalInfo.controls['add_benefit'].setValue(result.data[0]['add_benefit']);            
            if (result.data.cd_summary_checkbox == 1) {
              $('#confirm_policy').prop('checked', true);
            } else {
              $('#confirm_policy').prop('checked', false);
            }          
            this.additionalInfo.controls['opening_cd_balance'].setValue(result.data.cd_statement[0]['opening_balance']);
            this.additionalInfo.controls['cd_balance_thrsold'].setValue(result.data.cd_statement[0]['threshold_balance']);
            this.additionalInfo.controls['inception_premium'].setValue(result.data.cd_statement[0]['inception_premium']);
            this.additionalInfo.controls['inception_premium_installment'].setValue(result.data.cd_statement[0]['inception_premium_installment']);

          }


          if(result.data.cd_statement.length > 0){

            if(result.data.nominees[0]['nomination_applicable'] == '1'){
              this.additionalInfo.controls['nomination_applicable'].setValue('Yes');
            }else{
              this.additionalInfo.controls['nomination_applicable'].setValue('No');
            }

            if(result.data.nominees[0]['nomination_mandatory'] == '1'){
              this.additionalInfo.controls['nomination_mandatory'].setValue('Yes');
            }else{
              this.additionalInfo.controls['nomination_mandatory'].setValue('No');
            }

            if(result.data.nominees[0]['declaration_mandatory'] == '1'){
              this.additionalInfo.controls['declaration_mandatory'].setValue('Yes');
            }else{
              this.additionalInfo.controls['declaration_mandatory'].setValue('No');
            }

            if(result.data.nominees[0]['allowed_members'] == "2"){
              // allowvalue = 2;
              this.additionalInfo.controls['allowed_members'].setValue('Selected Members');
            }else{
              // allowvalue = 1;
              this.additionalInfo.controls['allowed_members'].setValue('Insured Members');
            }

            this.additionalInfo.controls['nominee_description'].setValue(result.data.nominees[0]['nominee_description']);

          }

          // console.log(this.relationDetails);

          this.checkNominee();

          if(result.data.cd_statement.length > 0){


            if(result.data.cd_statement[0]['inception_premium_installment'] != '' && result.data.cd_statement[0]['inception_premium_installment'] != null){
              $('.InstallmentDiv').css('display','block');
            }else{
              $('.InstallmentDiv').css('display','none');
            }



            var obj = JSON.parse(result.data.cd_statement[0]['installment_amount_info']);
            // console.log(obj);

            const mapped:any = Object.entries(obj).map(([type, value]) => ({type, value}));
            // console.log(mapped);

            this.installmentArr = [];
            for(var i=0; i<mapped.length; i++){
              var val = mapped[i]['value']['installment_amount'];
              this.installmentArr.push(val);
              $('.InstallmentDiv').css('display','block');
            }

            setTimeout(() => {
              for(var i=0; i<mapped.length; i++){
                var val = mapped[i]['value']['installment_amount'];
                var id = '#installment_amount_' + i;
                $(id).val(val);
              }
            }, 2000);



            if(result.data.nominees[0]['relationdetails_arr']['id'].length > 0){
              for(var i = 0; i<this.relationDetails.length; i++){
                for(var j = 0; j<result.data.nominees[0]['relationdetails_arr']['id'].length; j++){
                  // console.log(this.relationDetails[i]['id']);
                  // console.log(result.data.nominees[0]['relationdetails_arr']['id'][j]);
                  var a = this.relationDetails[i]['id'];
                  var b = result.data.nominees[0]['relationdetails_arr']['id'][j];

                  if(b != undefined){
                    // console.log(a+' == '+b);
                    if(a == b){
                      var id = "#relation_" + b;
                      $(id).prop('checked', true);
                    }else{
                      // console.log('not matched');
                    }
                  }else{
                    // console.log(2);
                  }
                }

              }
            }else{
              // console.log(2);
            }

          }

        }, (error) => {
            // console.log(result);
      });
    }

    /************ Edit GET Step 6 ***************/
    getStep6Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep6Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          this.claimInfo.controls['no_of_back_days'].setValue(result.data.policy_data[0]['claim_back_date_days']);

          if(result.data.policy_data[0]['is_document_center_mandatory'] === 1){
            this.claimInfo.controls['document_mandatory'].setValue('Yes');
            $('#addDoc').css('display', 'block');
          }else{
            this.claimInfo.controls['document_mandatory'].setValue('No');
            $('#addDoc').css('display', 'none');
          }

          if(result.data.policy_data[0]['digital_claim_form'] == '1'){
            this.claimInfo.controls['digital_claim'].setValue('Yes');
          }else{
            this.claimInfo.controls['digital_claim'].setValue('No');
          }

          if(result.data.policy_data[0]['is_claim_intimation_mandatory'] == '1'){
            this.claimInfo.controls['claim_intimate_mandatory'].setValue('Yes');
          }else{
            this.claimInfo.controls['claim_intimate_mandatory'].setValue('No');
          }


          // this.claimInfo.controls['document_name'].setValue(result.data.clain_doc[0]['document_name']);
          // this.claimInfo.controls['document_type'].setValue(result.data.clain_doc[0]['document_type']);


          var obj = result.data.clain_doc;
          // console.log(obj);

          const mapped:any = Object.entries(obj).map(([type, value]) => ({type, value}));
          // console.log(mapped);

          if(mapped.length > 0){

            this.uploadcnt = mapped.length;

            for(var i=0; i<mapped.length; i++){
              var id = mapped[i]['value']['id'];
              var idstr = '#this_upload_row_' + i;
              $(idstr).remove();
              // this.uploadExtraArr = {};
              // delete this.uploadExtraArr[id];
              // this.uploadcnt = parseInt(this.uploadcnt) - 1;
            }

            // console.log(mapped.length);

            for(var i=0; i<mapped.length; i++){

              // console.log("here call", i);

              var doc_man = mapped[i]['value']['document_mandatory'];
              var document_name = mapped[i]['value']['document_name'];
              var document_type = mapped[i]['value']['document_type'];
              var document_files = mapped[i]['value']['document_files'];
              var id = mapped[i]['value']['id'];

              // console.log(doc_man,document_name,document_type,document_files);
              this.addUploadField(doc_man, document_name, document_type, document_files, id);

            }
          }

        }, (error) => {
            // console.log(result);
      });
    }

    /************ Edit GET Step 7 ***************/
    getStep7Data(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "master_policies_id": localStorage.getItem('master_policies_id'),
      }
      this.clientCreationService.getStep7Data(postdata, localStorage.getItem('Token'))
        .subscribe((result) => {

          // console.log(result.data[0]['policy_features_uploadfiles']);

          this.step7file = result.data[0]['policy_features_uploadfiles'];
          this.policysamplefile = result.data[0]["policy_features_sample"];
          this.downloadbuttontooltip = result.data[0]["policy_name"];

        }, (error) => {
            // console.log(result);
      });
    }

    checkpolicy_number(e:any){

      let val:any = e.target.value;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "policy_number":val,
      }
      this.clientCreationService.checkpolicyNumber(postdata)
        .subscribe((result) => {

          if(result.data == '1'){
            $('#policy_number').val('');
            $('#policy_number_duplicate').css('display','block');
          }else{
            $('#policy_number_duplicate').css('display','none');
          }

        }, (error) => {

            // console.log(result);
      });

    }

    getcontactrole(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "role_id":4,
      }
      this.clientCreationService.getcontactrole(postdata)
        .subscribe((result) => {

          this.contactroleArr = result.data;
          // console.log("first - ",result.data);

        }, (error) => {

          this.contactroleArr = [];

          // console.log(result);
      });
    }

    getcontactroleemployer(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "role_id":3,
      }
      this.clientCreationService.getcontactrole(postdata)
        .subscribe((result) => {

          this.contactroleemployerArr = result.data;
          // console.log(result.data);

        }, (error) => {

          this.contactroleemployerArr = [];

            // console.log(result);
      });
    }

    getcontactlistbyusertype(){
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "user_type_id":localStorage.getItem('user_type_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "employer_id":localStorage.getItem('employer_id'),
      }
      this.clientCreationService.getcontactrole(postdata)
        .subscribe((result) => {

          this.contactroleemployerArr = result.data;
          console.log(result.data);

        }, (error) => {

          this.contactroleemployerArr = [];

            // console.log(result);
      });
    }

    Download_permily_age_file(){
      window.open(this.downloadlink,'_blank');
    }

    enrollment_start_date(e:any){
      this.min_enrollment_end_date = e.target.value;
    }

    check_spouse_enrolment_allowed_days(e:any){
      let a:any =  this.basicInfo.controls['enrollment_allowed_days_mid_term'].value;
      if(e.target.value > a){
        $('#spouse_enrolment_allowed_days').val('');
          this.toastService.showFailed('Failed', 'No. of days could not be greater than Enrollment Allowed in Days(mid term) On Basic tab');
      }
    }

    check_child_enrolment_allowed_days(e:any){
      let a:any =  this.basicInfo.controls['enrollment_allowed_days_mid_term'].value;
      if(e.target.value > a){
        $('#child_enrolment_allowed_days').val('');
          this.toastService.showWarning('Warning', 'No. of days could not be greater than Enrollment Allowed in Days(mid term) On Basic tab');
      }
    }

    checkAgeLimit(e: any){
      let minLimitSelf = parseInt(this.familyInfo.controls['min_age'].value);

      if(e.target.value < minLimitSelf){
        this.familyInfo.controls['max_age'].setValue('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    checkAgeLimitSpouse(e: any){
      let minLimitSpouse = parseInt(this.familyInfo.controls['min_age_spouse'].value);

      if(e.target.value < minLimitSpouse){
        this.familyInfo.controls['max_age_spouse'].setValue('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    checkAgeLimitChild(e: any){
      let minLimitChild = parseInt(this.familyInfo.controls['min_age_Child'].value);

      if(e.target.value < minLimitChild){
        // this.familyInfo.controls['max_age_Child'].setValue('');
        $('#max_age_Child').val('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    checkAgeLimitParent(e: any){
      let minLimitParents = parseInt(this.familyInfo.controls['min_age_parent'].value);

      if(e.target.value < minLimitParents){
        this.familyInfo.controls['max_age_parent'].setValue('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    checkAgeLimitParentInLaws(e: any){
      let minLimitParentInLaws = parseInt(this.familyInfo.controls['min_age_parent_in_law'].value);

      if(e.target.value < minLimitParentInLaws){
        this.familyInfo.controls['max_age_parent_in_law'].setValue('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    checkAgeLimitSibling(e: any){
      let minLimitSibling = parseInt(this.familyInfo.controls['min_age_sibling'].value);

      if(e.target.value < minLimitSibling){
        this.familyInfo.controls['max_age_sibling'].setValue('');
        this.toastService.showWarning('Warning', 'Maximum Age should be greater than Minimum Age Limit');
      }
    }

    handlePaste(event: ClipboardEvent) {
        // Prevent the default paste behavior
        event.preventDefault();
    
        // Get pasted data from clipboard
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        const pastedData = clipboardData.getData('Text');
    
        // Check if the pasted data is numeric
        if (!/^\d*$/.test(pastedData)) {
            // alert('Only numeric values are allowed.'); // Optionally show an alert
            this.toastService.showWarning('Warning', 'Only numeric values are allowed.')
        } else {
            // If the data is numeric, manually insert the value
            const inputElement = event.target as HTMLInputElement;
            const start = inputElement.selectionStart || 0;
            const end = inputElement.selectionEnd || 0;
    
            // Insert the pasted data at the current cursor position
            inputElement.value = inputElement.value.slice(0, start) + pastedData + inputElement.value.slice(end);
    
            // Move the cursor to the end of the pasted data
            inputElement.setSelectionRange(start + pastedData.length, start + pastedData.length);
        }
    }
    
    preventTyping(event: KeyboardEvent): boolean {
      // Prevent default behavior for key presses except for Tab (key code 9)
      if (event.key !== "Tab") {
          event.preventDefault();
      }
      return false;
    }

    mobileNoCheck(evt: any){
      var value = evt.target.value;
      var inputElement = $('#'+ evt.target.id);      
      var buttonElement = $('#step4button');

      if (value == 0 || value === '') {
          inputElement.val('');
          this.toastService.showFailed('Failed', 'Please enter valid mobile format.');
          $('#step4button').attr('disabled', 'disabled');
      } else {          
          if (value.length !== 10) {
            this.toastService.showFailed('Failed', 'Mobile no must be 10 digits');
            inputElement.val('');
            $('#step4button').attr('disabled', 'disabled');
          }else{
            $('#step4button').removeAttr('disabled');
          }
      }    
    }

    checkbranch(e:any) {
      var str = e.target.id;
      var id = str.charAt(str.length - 1);
      var inputElement:any = $('#'+ e.target.id);

      // Use a regular expression to check for leading or trailing spaces
      var value = e.target.value;
      if (/^\s|\s$/.test(value)) {
        // If there's a space at the start or end, show the error
        this.toastService.showFailed('Failed', 'Space not allowed at start');
        $('#step4button').attr('disabled', 'disabled');
        inputElement.val('');
      } else {
        // If there are no leading/trailing spaces, hide the error        
        $('#step4button').removeAttr('disabled');
      }
    }
  
  }
