import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import {Subject} from 'rxjs';
import { ToastService } from '../../toast.service';


@Component({
  selector: 'app-add-broker-user',
  templateUrl: './add-broker-user.component.html',
  styleUrls: ['./add-broker-user.component.css']
})
export class AddBrokerUserComponent implements OnInit {

  
  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  clientName = localStorage.getItem('brokeruser_employer_name');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  public DeleteInfo:FormGroup;
  
  roleDisabled: boolean = true;

  InfoArray: any = [];      
  InfoArray_Role: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none";

  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();    

  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router,  private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
   }

  ngOnInit(): void {

    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo();
    this.getRole();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.AddInfo = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
    });

    // Form element defined below
    this.EditInfo = this.fb.group({
      name_edit: new FormControl('', Validators.required),
      email_edit: new FormControl('', Validators.required),
      mobile_edit: new FormControl('', Validators.required),
      role_edit: new FormControl('', Validators.required),
      
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });    

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

  }  

  //Add
  get getAddInformationFormControls() {
    return this.AddInfo.controls;
  }  

  openPopupAdd(){
    this.addflag = 1;    
  }  

  closePopupAdd(){
    this.addflag = 0;      
    window.location.reload();    
  }

  onSubmitAdd() {

    this.AddInfoSubmitted = true;
    if (this.AddInfoSubmitted && this.AddInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id":localStorage.getItem('brokeruser_employer_id'),
        "broker_id": localStorage.getItem('broker_id'),

        "name":this.AddInfo.controls['name'].value,
        "email":this.AddInfo.controls['email'].value,
        "mobile":this.AddInfo.controls['mobile'].value,
        "role_id":this.AddInfo.controls['role'].value,
      };
      // console.log(postdata);

      this.show = false;

      this.dataservice.AddInfo(postdata)
        .subscribe((result: any) => {                 

          if (result.status == true) {  
            
            this.show = false;
           
            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);

            this.getInfo();
            this.dtTrigger.unsubscribe();
          }else {            
            this.show = false;         
          }
        }, (error) => {          
          this.toastService.showFailed('Failed', 'Try using another Email or Mobile No.');
    
      });
      
    } else {
      // validate all form fields
    }
  }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    this.addflag = 2;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['name_edit'].setValue(result.data[0]['name']);
        this.EditInfo.controls['email_edit'].setValue(result.data[0]['username']);      
        this.EditInfo.controls['mobile_edit'].setValue(result.data[0]['mobile_no']);
        this.EditInfo.controls['role_edit'].setValue(result.data[0]['type_name']);
        // this.EditInfo.controls['role_edit'].setValue(result.data[0]['user_type_id_fk']);

        this.EditInfo.controls['edit_id'].setValue(result.data[0]['user_id']);                      

      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "employer_id":localStorage.getItem('brokeruser_employer_id'),
        "broker_id": localStorage.getItem('broker_id'),
        
        "name":this.EditInfo.controls['name_edit'].value,                
        "username":this.EditInfo.controls['email_edit'].value,        
        "mobile_no":this.EditInfo.controls['mobile_edit'].value,
        // "user_type_id_fk":this.EditInfo.controls['role_edit'].value,
      };

      this.show = false;

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            this.closePopup();         
            this.validmsg = result.message;

            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          this.toastService.showFailed('Failed', this.validmsg);
   
      });

    }

  }

  //Delete
  clientId: any;

  openPopupDelete(id: any) {
    this.displayStyleDelete = "block";  
    this.clientId = id;
  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.clientId,
      "employer_id":localStorage.getItem('brokeruser_employer_id'),
      "broker_id": localStorage.getItem('broker_id'),
    };

    this.dataservice.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false      
          this.closePopup();       
          this.getInfo();
          // this.dtTrigger.unsubscribe();

          this.toastService.showSuccess('Success', 'Client User Deleted Successfully!');

          setTimeout(()=>{
            window.location.reload();
          },3000);
        
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        this.toastService.showFailed('Failed', 'There was some error');
    
    });

  }

  //Common functions
  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "employer_id": localStorage.getItem('brokeruser_employer_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }
          
      });
  }  

  getRole(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "role_id":3,

    }

    this.dataservice.getRole(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.InfoArray_Role = result.data; 

        }, (error) => {          
          
          this.InfoArray_Role = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  closePopup() {    

    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDelete = "none"; 
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none"; 
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }                 
    return true;
  }

  isDecimal(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address").val('');
    } else {
      $("#ip_address").val(content);
    }
  }
  
  isDecimal_edit(evt: any){
    var content = evt.target.value;
    var ipaddress = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;          
    if (ipaddress.test(content) == false) {       
      $("#ip_address_edit").val('');
    } else {
      $("#ip_address_edit").val(content);
    }
  }  

  goback(){

    this.route.navigate([`/broker-manage-master`]);

  }

  // Deactivate
  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['user_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    // let id = $('#delete_id').val();
    let id = this.DeactivateInfo.controls['delete_id'].value;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Client User Deactivated Successfully!');
     
            setTimeout(() => {
              window.location.reload();
            }, 2000); 

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');

    });

  }

  // Activate
  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['user_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.dataservice.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Client User Activated Successfully!');
     
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
      
    });

  }

}

