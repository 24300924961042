<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title">Edit Flex Configurator
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button"
                    style="float:right">Back</button>
            </h3>

            <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2 mt-4" style="height: 0px">


            <!-- Edit Form -->
            <div>
                <form [formGroup]="EditInfo">

                    <!-- Plan Details -->
                    <h3 class="bs-title mt-4" style="font-size: 18px;">Plan Details</h3>
                    <hr>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="corporate">Client <span class="redstar">*</span></label>
                                <ng-select formControlName="corporate" id="corporate"  (change)="getPolicyType()"
                                        [items]="InfoArrayCorporate"
                                        bindLabel="clientname"
                                        bindValue="id"
                                        [placeholder]="'Select Client'" >
                                 </ng-select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getPolicyName()">
                                    <option value="0">Select Policy Type</option>
                                    <option *ngFor="let brokerlist of InfoArrayPolicyType; let i = index" value="{{brokerlist.policytype_id}}">
                                        {{brokerlist.policytypename}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="bs-form-label" for="policy_name">Policy Name <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="policy_name" id="policy_name" (change)="getMultiSelect()">
                                    <option value="0">Select Policy Name</option>
                                    <option *ngFor="let brokerlist of InfoArrayPolicyName; let i = index" value="{{brokerlist.policy_id}}">
                                        {{brokerlist.policy_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="sum_insured">{{add_base_fieldname}}</label>
                                <ng-multiselect-dropdown class="ng-multiselect-dropdown"
                                    [placeholder]="'Select'"
                                    name="si_grade_dropdown"
                                    [disabled]="false"
                                    formControlName="si_grade_dropdown"
                                    [settings]="dropdownSettings"
                                    [data]="InfoArrayMultiple"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        
                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_name">Plan Name <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="plan_name" id="plan_name" placeholder="Plan Name" />
                            </div>
                        </div>

                        <div class="col-sm-4 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_type">Flex Plan Type <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="plan_type" id="plan_type">
                                    <option value="">Select Type</option>
                                    <option *ngFor="let planlist of InfoArrayPlanType; let i = index" value="{{planlist.id}}">
                                        {{planlist.title}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12"></div>
                        <div class="col-sm-2 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="roll_back_allowed">Roll Back Allowed</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_roll_back_allowed" formControlName="roll_back_allowed" name="roll_back_allowed" value="No" />
                                    <label for="radio-two_roll_back_allowed" id="roll_back_allowed_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_roll_back_allowed" formControlName="roll_back_allowed" name="roll_back_allowed" value="Yes" />
                                    <label for="radio-one_roll_back_allowed" id="roll_back_allowed_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="show_diff_premium">Show Difference Premium</label>
                                <div class="switch-field wFC" style="margin: 5px 0px;">
                                    <input type="radio" id="radio-two_show_diff_premium" formControlName="show_diff_premium" name="show_diff_premium" value="No" />
                                    <label for="radio-two_show_diff_premium" id="show_diff_premium_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-one_show_diff_premium" formControlName="show_diff_premium" name="show_diff_premium" value="Yes" />
                                    <label for="radio-one_show_diff_premium" id="show_diff_premium_yes" class="bs-form-label">Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="plan_description">Plan Description</label>
                                <textarea type="text" class="form-control" formControlName="plan_description" id="plan_description" placeholder="Enter Comment Here..."></textarea>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="disclaimer">Disclaimer</label>
                                <textarea type="text" class="form-control" formControlName="disclaimer" id="disclaimer" placeholder="Enter Disclaimer Here..."></textarea>
                            </div>
                        </div>
                    </div>


                    <!-- ADD On's -->
                    <div class="col-sm-12 mt-5"></div>
                    <h3 class="bs-title" style="font-size: 18px;">Add On's</h3>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name">Benefit Name</label>
                                <select class="form-select" formControlName="benefit_name" id="benefit_name">
                                    <option value="">Select Benefit Name</option>
                                    <option *ngFor="let benefitlist of InfoArrayBenefit; let i = index" value="{{benefitlist.id}}">
                                        {{benefitlist.title}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description">Benefit Description</label>
                                <input type="text" class="form-control" formControlName="benefit_description" id="benefit_description" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="showPopup()" style="cursor: pointer" role="button">Benefit Details +</button>
                        </div>

                        <!-- Benefits Popup -->
                        <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupBenefits}"
                            style="overflow:hidden">
                            <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                                <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                    <div class="modal-header">
                                        <span *ngIf="add_edit_flag == 0">
                                            <h4 class="modal-title">Add Benefits
                                               <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;right: 10px;"> &#10006;</button>
                                           </h4>
                                           </span>
                                           <span *ngIf="add_edit_flag == 1">
                                               <h4 class="modal-title">Update Benefits
                                                  <button type="button" class="btn btn-danger" (click)="closePopup()" style="position: absolute;right: 10px;"> &#10006;</button>
                                              </h4>
                                              </span>
                                    </div>
                                    <form [formGroup]="benefit_info">
                                        <div class="modal-body modal-body_edit" style="min-height: 470px;">
                                            <div class="row" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_name_val">Benefit Name</label>
                                                        <select class="form-select" formControlName="benefit_name_val" id="benefit_name_val">
                                                            <option value="" selected>Select Benefit Name</option>
                                                            <option *ngFor="let benefitlist of InfoArrayBenefit; let i = index" value="{{benefitlist.id}}">
                                                                {{benefitlist.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_description_val">Benefit Description</label>
                                                        <input type="text" class="form-control" formControlName="benefit_description_val" id="benefit_description_val" placeholder="Benefit Description" />
                                                    </div>
                                                </div>                                              

                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="benefit_visible_from_SI">Benefit Visible For SI</label>
                                                        <ng-multiselect-dropdown class="ng-multiselect-dropdown"
                                                            [placeholder]="'Select'"
                                                            name="addon_si_grade_dropdown"
                                                            [disabled]="false"
                                                            formControlName="addon_si_grade_dropdown"
                                                            [settings]="dropdownSettings_SI"
                                                            [data]="InfoArrayMultiple"
                                                            (onSelect)="onItemSelect_SI($event)"
                                                            (onDeSelect)="OnItemDeSelect_SI($event)"
                                                            (onSelectAll)="onSelectAll_SI($event)"
                                                            (onDeSelectAll)="onDeSelectAll_SI($event)">
                                                        </ng-multiselect-dropdown>
                                                    </div>                                                    
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Benefit
                                                        Mandatory</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input" formControlName="mandatory_type" id="mandatory_type" value="Mandatory" name="mandatory_type" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                                                                <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Mandatory</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="mandatory_type" id="benefit_mandatory_val" value="Optional"
                                                                    name="mandatory_type"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Optional</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4 mt-3 benefit_mandatory_row" style="display: none">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="mandatory_not_selected">Mandatory if Not Selected</label>
                                                        <select class="form-select" formControlName="mandatory_not_selected" id="mandatory_not_selected">
                                                            <option value="" selected>Select Benefit Name</option>
                                                            <option *ngFor="let benefitlist of InfoArrayBenefit; let i = index" value="{{benefitlist.id}}">
                                                                {{benefitlist.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <label class="bs-form-label" for="no_of_members_allowed">Prorata
                                                        Calculation
                                                        Applicable</label>

                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable" id="prorata_calculation_applicable"
                                                                    value="Yes" name="prorata_calculation_applicable"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                    checked />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-radio">
                                                                <input type="radio" class="form-radio-input"
                                                                    formControlName="prorata_calculation_applicable" id="prorata_calculation_applicable"
                                                                    value="No" name="prorata_calculation_applicable"
                                                                    style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                                <label class="form-radio-label bs-radio-label"
                                                                    style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-3 mt-3">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="relations">Relations</label>
                                                        <ng-multiselect-dropdown class="ng-multiselect-dropdown"
                                                            [placeholder]="'Select Relations'"
                                                            name="relations"
                                                            [disabled]="false"
                                                            formControlName="relations"
                                                            [settings]="dropdownSettings_relations"
                                                            [data]="InfoArrayMultipleRelations"
                                                            [(ngModel)]="selectedItemsRelation"
                                                            (onSelect)="onItemSelectRelation($event)"
                                                            (onDeSelect)="OnItemDeSelectRelation($event)"
                                                            (onSelectAll)="onSelectAllRelation($event)"
                                                            (onDeSelectAll)="onDeSelectAllRelation($event)">
                                                        </ng-multiselect-dropdown>
                                                           
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <hr>
                                                </div>                                                

                                                <!-- Add More -->
                                                <div class="col-sm-12">
                                                    <div class="newbenefitData">

                                                    </div>
                                                </div>

                                                <div class="col-sm-12 mt-3">
                                                    <label class="bs-form-label" (click)="addBenefitData()"
                                                        style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png"
                                                            style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add Sub Plans and Features</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" style="float: right;" (click)="savebenefit()" *ngIf="add_edit_flag == 0">Save</button>
                                            <button class="btn btn-primary" style="float: right;" (click)="savebenefit_update()" *ngIf="add_edit_flag == 1">Update</button>
                                            <button type="button" class="btn btn-danger" (click)="closePopup()">
                                                Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        <!-- List -->
                        <div class="table-responsive col-sm-12 mt-3" *ngIf="addonbaseArray.length > 0">
                            <table class="table table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let group of arrayAddOn; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['arrbase'][0]['benefit_name']}}</td>
                                        <td style=" text-wrap: wrap;">{{group['arrbase'][0]['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <img src="../../../assets/img/common icons/edit_new.png" id="editbefefit"
                                              (click)="showPopup_edit(i)"  role="button" title="Edit" />
                                            <img src="../../../assets/img/common icons/trash.png" id="deleteuser"
                                                (click)="removeaddonbaseArray(i)" class="ml-4" role="button"
                                                title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                       

                    </div>


                    <!-- What's Not Covered -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">What's Not Covered</h3>
                    </div>
                    <hr>
                    <div class="row">

                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_name_not_cover">Benefit Name</label>
                                <select class="form-select" formControlName="benefit_name_not_cover" id="benefit_name_not_cover">
                                    <option value="">Select Benefit Name</option>
                                    <option *ngFor="let benefitlist of InfoArrayBenefit; let i = index" value="{{benefitlist.id}}">
                                        {{benefitlist.title}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="benefit_description_not_cover">Benefit
                                    Description</label>
                                <input type="text" class="form-control" formControlName="benefit_description_not_cover"
                                    id="benefit_description_not_cover" placeholder="Benefit Description" />
                            </div>
                        </div>
                        <div class="col-sm-3 mt-2">
                            <button type="button" class="btn btn-primary mt-3" (click)="saveWhatsNotCover()"
                                style="cursor: pointer" role="button">Add +</button>
                        </div>

                        <div class="table-responsive col-sm-12 mt-3" *ngIf="NotCoverTableArray.length > 0">
                            <table class="table table-sm row-border">
                                <thead>
                                    <tr>
                                        <th style="width:5%">SrNo</th>
                                        <th>Benefit Name</th>
                                        <th>Benefit Desc</th>
                                        <th style="text-align: center;">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff;">
                                    <tr *ngFor="let group of NotCoverTableArray; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['benefit_name']}}</td>
                                        <td style="text-wrap: wrap;">{{group['benefit_description']}}</td>
                                        <td style="width: 15%" align="center">
                                            <img src="../../../assets/img/common icons/trash.png" id="benefit_row" (click)="removeNotCoverTableArray(i)" role="button" title="Delete" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <!-- Additional Features -->
                    <div class="col-sm-12 mt-5">
                        <h3 class="bs-title" style="font-size: 18px;">Additional Features</h3>
                    </div>
                    <hr>
                    <div class="divgrey">
                        <p>Premium Credit/Debit based on member Addition/Deletion </p>
                        <div>
                            <input type="checkbox" class="form-check-input" id="will_there_be_any_premium" (click)="checkwill_there_be_any_premium()">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will there be any Premium Credit/Debit based on member Addition/Deletion ? </label>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 mt-3 will_there_be_any_premiumDiv" style="display: none">
                                <div class="form-group">
                                    <label class="bs-form-label" for="feature_desc">Feature Description</label>
                                    <input type="text" class="form-control" formControlName="feature_desc" id="feature_desc" placeholder="Enter Feature Description" />
                                </div>
                            </div>
    
                            <div class="col-sm-3 mt-4 will_there_be_any_premiumDiv" style="display: none;">
                                <button type="button" class="btn btn-primary mt-3" (click)="showPopupFeatures()" style="cursor: pointer" role="button">Fearture Details +</button>    
                            </div>
                        </div>                        

                    </div>

                    <!-- Features Popup -->
                    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':PopupFeatures}"
                        style="overflow:hidden">
                        <div class="modal-dialog modal-dialog_view" role="document" style="margin-top: 10px;">
                            <div class="modal-content model-content-view" style="overflow-y: hidden !important;">
                                <div class="modal-header">
                                    <h4 class="modal-title" *ngIf="feature_flag == 0">Create Features
                                        <button type="button" class="btn btn-danger" (click)="closePopup()"
                                            style="position: absolute;right: 10px;"> &#10006;</button>
                                    </h4>
                                    <h4 class="modal-title" *ngIf="feature_flag == 1">Update Features
                                        <button type="button" class="btn btn-danger" (click)="closePopup()"
                                            style="position: absolute;right: 10px;"> &#10006;</button>
                                    </h4>
                                </div>
                                <form [formGroup]="features_info">
                                    <div class="modal-body modal-body_edit">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="feature_desc">Feature
                                                        Description</label>
                                                    <textarea type="text" class="form-control"
                                                        formControlName="feature_desc" id="feature_desc_val"
                                                        placeholder="Enter Feature Description">
                                                                </textarea>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="relations">Relations</label>
                                                    <ng-multiselect-dropdown class="ng-multiselect-dropdown"
                                                        [placeholder]="'Select Relations'"
                                                        name="relations"
                                                        [disabled]="false"
                                                        formControlName="relations"
                                                        [settings]="dropdownSettings_relations"
                                                        [data]="InfoArrayMultipleRelations"
                                                        (onSelect)="onItemSelectRelation($event)"
                                                        (onDeSelect)="OnItemDeSelectRelation($event)"
                                                        (onSelectAll)="onSelectAllRelation($event)"
                                                        (onDeSelectAll)="onDeSelectAllRelation($event)">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="to_hide_detail">To
                                                        Hide Detail</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_to_hide_detail"
                                                            formControlName="to_hide_detail" name="to_hide_detail"
                                                            value="No" />
                                                        <label for="radio-two_to_hide_detail" id="to_hide_detail"
                                                            class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_to_hide_detail"
                                                            formControlName="to_hide_detail" name="to_hide_detail"
                                                            value="Yes" />
                                                        <label for="radio-one_to_hide_detail"
                                                            id="to_hide_detail_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="relation_inclusive">Relation
                                                        Inclusive</label>
                                                    <div class="switch-field wFC" style="margin: 5px 0px;">
                                                        <input type="radio" id="radio-two_relation_inclusive"
                                                            formControlName="relation_inclusive"
                                                            name="relation_inclusive" value="No" />
                                                        <label for="radio-two_relation_inclusive"
                                                            id="relation_inclusive" class="bs-form-label">No</label>
                                                        <input type="radio" id="radio-one_relation_inclusive"
                                                            formControlName="relation_inclusive"
                                                            name="relation_inclusive" value="Yes" />
                                                        <label for="radio-one_relation_inclusive"
                                                            id="relation_inclusive_yes"
                                                            class="bs-form-label">Yes</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured">Sum
                                                        Insured</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="sum_insured" id="sum_insured_features"
                                                        placeholder="Sum Insured" (keypress)="isNumber($event)" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="sum_insured_by">Sum
                                                    Insured By</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_by" value="by_value"
                                                                name="sum_insured_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="sum_insured_by" value="by_SI"
                                                                name="sum_insured_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                SI %</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="sum_insured_type">Sum
                                                        Insured Type</label>
                                                    <select class="form-select" formControlName="sum_insured_type"
                                                        id="sum_insured_type">
                                                        <option value="0">Select Sum Insured Type</option>
                                                        <option *ngFor="let SIlist of Covertype; let i = index" value="{{SIlist.value_id}}"> {{SIlist.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium">Premium</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="premium" id="premium"
                                                        placeholder="Premium" (keypress)="isNumber($event)" />
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="premium_by">Premium By</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="premium_by" value="by_value"
                                                                name="premium_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="premium_by" value="by_premium"
                                                                name="premium_by"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By
                                                                Premium %</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="premium_type">Premium
                                                        Type</label>
                                                    <select class="form-select" formControlName="premium_type"
                                                        id="premium_type">
                                                        <option value="0">Select Premium Type</option>
                                                        <option *ngFor="let Premiumlist of Premiumtype; let i = index" value="{{Premiumlist.value_id}}"> {{Premiumlist.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <label class="bs-form-label" for="is_optional">Is
                                                    optional</label>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional" value="Yes"
                                                                name="is_optional"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;"
                                                                checked />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-radio">
                                                            <input type="radio" class="form-radio-input"
                                                                formControlName="is_optional" value="No"
                                                                name="is_optional"
                                                                style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                            <label class="form-radio-label bs-radio-label"
                                                                style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="contributions">Contribution</label>
                                                    <select class="form-select" formControlName="contributions"
                                                        id="contributions">
                                                        <option value="0">Select</option>
                                                        <option value="1">Employee Paid </option>
                                                        <option value="2">Employer Paid </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-sm-12">
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" style="float: right;" (click)="savefeature()" *ngIf="feature_flag == 0">Save</button>
                                        <button class="btn btn-primary" style="float: right;" (click)="savefeature_update()" *ngIf="feature_flag == 1">Update</button>
                                        <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- List -->
                    <div class="table-responsive col-sm-12 mt-3" *ngIf="FeatureTableArray.length > 0">
                        <table class="table table-sm row-border">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Feature Description </th>
                                    <th>Sum Insured</th>
                                    <th>Sum Insured By</th>
                                    <th>Sum Insured Type</th>
                                    <th>Premium</th>
                                    <th>Premium By</th>
                                    <th>Premium Type</th>
                                    <th>Is Optional</th>
                                    <th style="text-align: center;">Action</th>
                                </tr>
                            </thead>
                            <tbody style="background: #fff;">
                                <tr *ngFor="let group of FeatureTableArray; let i = index">
                                    <td style="width:5%">{{i+1}}</td>
                                    <td style="text-wrap: wrap;">{{group['feature_desc']}}</td>
                                    <td>{{group['sum_insured']}}</td>                                                                              
                                    <td *ngIf="group['sum_insured_by']== 1 || group['sum_insured_by']== 'by_value'">By Value</td>                                                                              
                                    <td *ngIf="group['sum_insured_by']== 2 || group['sum_insured_by']== 'by_SI'">By SI</td>
                                    <td>{{group['sum_insured_type_name']}}</td>
                                    <td>{{group['premium']}}</td>  
                                    <td *ngIf="group['premium_by']== 1 || group['premium_by']== 'by_value'">By Value</td>                                                                                                                                                                                                                                        
                                    <td *ngIf="group['premium_by']== 2 || group['premium_by']== 'by_premium'">By Premium</td> 
                                    <td>{{group['premium_type_name']}}</td>                                                                                                                                                                                                                                       
                                    <td *ngIf="group['is_optional']== 1 || group['is_optional']== 'Yes'">Yes</td>                                                                                                                                                                                                                                        
                                    <td *ngIf="group['is_optional']== 0 || group['is_optional']== 'No'">No</td>                                                                                                                                                                                                                                        
                                    <td style="width: 15%" align="center">
                                        <img src="../../../assets/img/common icons/edit_new.png" id="editfeature" (click)="showPopupFeatures_edit(i)"  role="button" title="Edit" />
                                        <img src="../../../assets/img/common icons/trash.png" (click)="removeFeatureTableArray(i)" id="delete_feature" role="button" title="Delete" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                        

                    <div class="divgrey">
                        <div>
                            <p> Initial Premium will be paid by employer </p>
                        </div>
                        <div>
                            <input type="checkbox" class="form-check-input" id="initial_Premium_will_be_paid">
                            <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Will Initial Premium will be paid by employer ? </label>
                        </div>
                    </div>                                            

                    <div class="divgrey">
                        <p> Update Cover Level Configuration</p>
                        <div class="row">                           
                            <div class="col-sm-4">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_increase" (click)="checkset_allowed_increase()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Increase Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group set_allowed_increaseDiv" style="display: none">                                
                                <label class="bs-form-label" for="increase_cover_level">Increase Cover Level</label>
                                <input type="text" class="form-control" formControlName="increase_cover_level" id="increase_cover_level" placeholder="Enter Increase Cover Level" (keypress)="isNumber($event)" />                                
                            </div>

                            <div class="col-sm-12"></div>
    
                            <div class="col-sm-4 mt-3">                                
                                <input type="checkbox" class="form-check-input" id="set_allowed_decrease" (click)="checkset_allowed_decrease()">
                                <label class="form-check-label bs-radio-label" style="margin-left: 10px;"> Set Allowed Decrease Cover Level ? </label>                                
                            </div>
                            <div class="col-sm-3 form-group mt-3 set_allowed_decreaseDiv" style="display: none">                                
                                <label class="bs-form-label" for="decrease_cover_level">Decrease Cover Level</label>
                                <input type="text" class="form-control" formControlName="decrease_cover_level" id="decrease_cover_level" placeholder="Enter Decrease Cover Level" (keypress)="isNumber($event)" />                                
                            </div>
                        </div>                        
                    </div>                    

                    <!-- Final Submit -->
                    <div class="col-sm-12" align="right">
                        <hr>
                        <button type="button" class="btn btn-primary my-2" (click)="onSubmit()" role="button">Submit</button>
                    </div>

                </form>
            </div>
            

        </div>
        <app-footer></app-footer>
    </div>
</div>