import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastService } from '../../toast.service';


@Component({
  selector: 'app-flex-configurator-edit',
  templateUrl: './flex-configurator-edit.component.html',
  styleUrls: ['./flex-configurator-edit.component.css']
})

export class FlexConfiguratorEditComponent implements OnInit {

  selectedItems: any = [];
  selectedItemsRelation: any = [];
  selectedItemsSI: any = [];

  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_relations: IDropdownSettings = {};
  dropdownSettings_SI: IDropdownSettings = {};

  familyArrData: any = [];
  familyArr: any = {};

  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');

  InfoArrayBenefit: any = [];
  InfoArrayBroker: any = [];
  InfoArrayCorporate: any = [];
  InfoArrayCorporate_edit: any = [];
  InfoArrayPolicyType: any = [];
  InfoArrayPolicyTypeEdit: any = [];
  InfoArrayPolicyName: any = [];
  InfoArrayPolicyNameEdit: any = [];
  InfoArrayMultiple: any = [];
  InfoArrayMultipleEdit: any = [];
  InfoArrayPlanType: any = [];
  InfoArrayMultipleRelations: any = [];

  addonbaseArray: any = [];
  addonmultipleArray: any = [];
  FeatureTableArray: any = [];
  NotCoverTableArray: any = [];

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  PopupBenefits = "none";
  PopupFeatures = "none";
  PopupBenefits_edit = "none";
  PopupFeatures_edit = "none";
  PopupAuditDetails = "none";

  add_base_fieldname: any;
  addonbasecount = 1;

  add_base_flag: any = 0;
  update_id: any;
  id_multiple_flag: any = 0;
  add_edit_flag: any = 0;

  feature_flag: any = 0;
  feature_update_id: any;
  feature_id: any;
  feature_relation_id: any;

  cnt_BenefitData = 1;
  cnt_allowed = 1;

  cnt_BenefitData_edit = 1;
  cnt_allowed_edit = 1;

  Covertype: any;
  Premiumtype: any;

  flexarray: any = [];

  public benefit_info: FormGroup;
  public features_info: FormGroup;

  public benefit_info_edit: FormGroup;
  public features_info_edit: FormGroup;

  public EditInfo: FormGroup;
  EditInfoSubmitted: boolean = false;

  arrayAddOn: any = [];

  cntmultiple: any = 0;

  PlanName: any = [];
  checkedValues: any[] = [];

  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder, private dataservice: Service, private route: Router, private toastService: ToastService) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup() {

    $(window).scrollTop(0);


    this.getFlexPlan();
    this.getCorporate();
    this.getBenefit();
    this.getCoverType();
    this.getPremiumType();
    this.getPlanName();
    this.getPlanType();

    this.features_info = this.fb.group({
      feature_desc: new FormControl(''),
      to_hide_detail: new FormControl('Yes'),
      relation_inclusive: new FormControl('Yes'),
      sum_insured: new FormControl(''),
      sum_insured_type: new FormControl(''),
      sum_insured_by: new FormControl(''),
      premium_by: new FormControl(''),
      premium_type: new FormControl(''),
      contributions: new FormControl(''),
      premium: new FormControl(''),
      is_optional: new FormControl('Yes'),
      relations: [this.selectedItemsRelation]
    });

    //Edit
    // Form element defined below
    this.EditInfo = this.fb.group({

      corporate: new FormControl(''),
      policy_type:  new FormControl('0'),  
      policy_name: new FormControl('0'),
      sum_insured: new FormControl(''),
      plan_name: new FormControl(''),
      plan_type: new FormControl(''),
      roll_back_allowed: new FormControl('Yes'),
      show_diff_premium: new FormControl('Yes'),
      plan_description: new FormControl(''),
      disclaimer: new FormControl(''),

      benefit_name: new FormControl(''),
      benefit_description: new FormControl(''),

      benefit_name_not_cover: new FormControl(''),
      benefit_description_not_cover: new FormControl(''),

      set_allowed_increase: new FormControl(''),
      increase_cover_level: new FormControl(''),
      set_allowed_decrease: new FormControl(''),
      decrease_cover_level: new FormControl(''),

      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
      si_grade_dropdown: [this.selectedItems]
    });

    this.benefit_info = this.fb.group({
      benefit_name_val: new FormControl(''),
      benefit_description_val: new FormControl(''),
      benefit_visible_from_SI: new FormControl(''),
      mandatory_type: new FormControl('Mandatory'),
      prorata_calculation_applicable: new FormControl('Yes'),
      mandatory_not_selected: new FormControl(''),
      addon_si_grade_dropdown: [this.selectedItemsSI]

    });

    // Edit
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: true,
    };

    this.dropdownSettings_relations = {
      singleSelection: false,
      idField: 'relation_id',
      textField: 'relation',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: true,
    };

    // Addon SI Benefit
    this.dropdownSettings_SI = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      // showSelectedItemsAtTop: true,      
    };

  }

  getFlexPlan() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let flex_plan_id: any = localStorage.getItem('flex_edit_id');
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "id": flex_plan_id
    };

    this.dataservice.InfoSingle(postdata)
      .subscribe((result: any) => {

        // console.log(result.data.flex_policy_plans_dropdown[0]['grade_idfk']);

        this.EditInfo.controls['corporate'].setValue(result.data.flexplan_info[0]['employer_idfk']);

        setTimeout(() => {        
          var postdata = {
            "tokenable_id": localStorage.getItem('tokenable_id'),
            "client_id": this.EditInfo.controls['corporate'].value,
          };
      
          this.dataservice.getPolicyType(postdata)
            .subscribe((result: any) => {
      
              this.InfoArrayPolicyType = result.data;
      
            }, (error) => {
              this.InfoArrayPolicyType = [];
      
            });
        }, 1000);

        this.EditInfo.controls['policy_type'].setValue(result.data.flexplan_info[0]['policy_type_id']);

        setTimeout(() => {
          var postdata = {
            "tokenable_id": localStorage.getItem('tokenable_id'),
            "client_id": this.EditInfo.controls['corporate'].value,
            "policytype_id": this.EditInfo.controls['policy_type'].value,
          };
      
          this.dataservice.getPolicyName(postdata)
            .subscribe((result: any) => {
      
              this.InfoArrayPolicyName = result.data;
      
            }, (error) => {
              this.InfoArrayPolicyName = [];
            });
        }, 2000);

        this.EditInfo.controls['policy_name'].setValue(result.data.flexplan_info[0]['policy_idfk']);

        // Multiselect
        setTimeout(() => {
          this.getMultiSelect();
        }, 4000);

        for (let i = 0; i < result.data.flex_policy_plans_dropdown.length; i++) {
          let selectedItem = {
            id: result.data.flex_policy_plans_dropdown[i]['sum_insured_id'],
            name: result.data.flex_policy_plans_dropdown[i]['name']
          };

          this.selectedItems.push(selectedItem);
        }

        setTimeout(() => {
          this.EditInfo.patchValue({
            si_grade_dropdown: this.selectedItems
          });
        }, 1000);

        // Trigger change detection after updating selectedItems
        this.cd.detectChanges();

        // End
        // setTimeout(() => {
        //   this.getMultiSelectRelation();
        // }, 5000);

        this.EditInfo.controls['plan_name'].setValue(result.data.flexplan_info[0]['plan_name']);
        this.EditInfo.controls['plan_description'].setValue(result.data.flexplan_info[0]['plan_description']);
        this.EditInfo.controls['disclaimer'].setValue(result.data.flexplan_info[0]['disclaimer']);
        this.EditInfo.controls['plan_type'].setValue(result.data.flexplan_info[0]['plan_type_id']);

        if (result.data.flexplan_info[0]['is_rollback_allowed'] == '1') {
          this.EditInfo.controls['roll_back_allowed'].setValue("Yes");
        } else {
          this.EditInfo.controls['roll_back_allowed'].setValue("No");
        }

        if (result.data.flexplan_info[0]['is_differnce_premium'] == '1') {
          this.EditInfo.controls['show_diff_premium'].setValue("Yes");
        } else {
          this.EditInfo.controls['show_diff_premium'].setValue("No");
        }

        if (result.data.flexplan_info[0]['is_relation_wise_calculation_applicable'] == '1') {
          $('#will_there_be_any_premium').prop("checked", true);
        } else {
          $('#will_there_be_any_premium').prop("checked", false);
        }

        if (result.data.flexplan_info[0]['is_relation_wise_calculation_applicable'] == '1') {
          $('.will_there_be_any_premiumDiv').css('display', 'block');
        } else {
          $('.will_there_be_any_premiumDiv').css('display', 'none');
        }

        if (result.data.flexplan_info[0]['will_initial_premium_be_paid_by_employer'] == '1') {
          $('#initial_Premium_will_be_paid').prop("checked", true);
        } else {
          $('#initial_Premium_will_be_paid').prop("checked", false);
        }

        if (result.data.flexplan_info[0]['is_set_allowed_increase'] == '1') {
          $('.set_allowed_increaseDiv').css('display', 'block');
        } else {
          $('.set_allowed_increaseDiv').css('display', 'none');
        }

        if (result.data.flexplan_info[0]['is_set_allowed_increase'] == '1') {
          $('#set_allowed_increase').prop("checked", true);
        } else {
          $('#set_allowed_increase').prop("checked", false);
        }


        if (result.data.flexplan_info[0]['is_set_allowed_decrease'] == '1') {
          $('#set_allowed_decrease').prop("checked", true);
        } else {
          $('#set_allowed_decrease').prop("checked", false);
        }

        if (result.data.flexplan_info[0]['is_set_allowed_decrease'] == '1') {
          $('.set_allowed_decreaseDiv').css('display', 'block');
        } else {
          $('.set_allowed_decreaseDiv').css('display', 'none');
        }

        this.EditInfo.controls['increase_cover_level'].setValue(result.data.flexplan_info[0]['cover_increment_level']);
        this.EditInfo.controls['decrease_cover_level'].setValue(result.data.flexplan_info[0]['cover_decrement_level']);

        // AddOn
        if (result.data.addOnArray.length > 0) {
          for (var i = 0; i < result.data.addOnArray.length; i++) {
            this.addonbaseArray.push(result.data.addOnArray[i]['arrbase'][0]);
            this.arrayAddOn.push({
              "arrbase": [result.data.addOnArray[i]['arrbase'][0]], "arrmultiple": result.data.addOnArray[i]['arrmultiple']
            });
            this.flexarray = result.data.addOnArray[i]['arrmultiple'];
          }
        }

        // console.log(this.arrayAddOn);        

        // Whats not covered
        if (result.data.noCoverArray.length > 0) {
          this.NotCoverTableArray = result.data.noCoverArray;
        }

        if (result.data.relationWiseArray.length > 0) {
          this.FeatureTableArray = result.data.relationWiseArray;
        }

      }, (error) => {
        this.show = false;
      });
  }

  onSubmit() {

    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      // this.show = true;
      // this.fullScreen = true;
      // this.template = ``; 

      var roll_back_allowed: any;

      if (this.EditInfo.controls['roll_back_allowed'].value == 'Yes') {
        roll_back_allowed = 1;
      } else {
        roll_back_allowed = 0;
      }

      var show_diff_premium: any;

      if (this.EditInfo.controls['show_diff_premium'].value == 'Yes') {
        show_diff_premium = 1;
      } else {
        show_diff_premium = 0;
      }

      // return false;
      var postdata = {
        "tokenable_id": localStorage.getItem('tokenable_id'),
        "id": localStorage.getItem('flex_edit_id'),

        "roll_back_allowed": roll_back_allowed,
        "show_diff_premium": show_diff_premium,
        "corporate": this.EditInfo.controls['corporate'].value,
        "policy_type": this.EditInfo.controls['policy_type'].value,
        "policy_name": this.EditInfo.controls['policy_name'].value,
        "sum_insured": JSON.stringify(this.selectedItems),
        "plan_name": this.EditInfo.controls['plan_name'].value,
        "type": this.EditInfo.controls['plan_type'].value,
        "plan_description": this.EditInfo.controls['plan_description'].value,
        "disclaimer": this.EditInfo.controls['disclaimer'].value,
        "will_there_be_any_premium": $('#will_there_be_any_premium').is(':checked'),
        "initial_Premium_will_be_paid": $('#initial_Premium_will_be_paid').is(':checked'),

        "set_allowed_increase": $('#set_allowed_increase').is(':checked'),
        "increase_cover_level": this.EditInfo.controls['increase_cover_level'].value,
        "set_allowed_decrease": $('#set_allowed_decrease').is(':checked'),
        "decrease_cover_level": this.EditInfo.controls['decrease_cover_level'].value,

        // "addonbaseArray": JSON.stringify(this.addonbaseArray),
        // "addonmultipleArray": JSON.stringify(this.addonmultipleArray),
        "addOnArray": JSON.stringify(this.arrayAddOn),
        "notCoverTableArray": JSON.stringify(this.NotCoverTableArray),
        "featureTableArray": JSON.stringify(this.FeatureTableArray),

        "flag": this.add_base_flag

      };

      console.log(postdata);

      this.dataservice.InfoUpdate(postdata)
        .subscribe((result: any) => {

          // console.log(result);

          if (result.status == true) {

            this.show = false;
            // this.AddInfo.reset();            
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
            // this.displayStyle_add = "none";

            // window.location.reload();
            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

            setTimeout(() => {
              this.route.navigate(['/flex-configurator']);
            }, 1000);

            // this.getInfo();
            // this.dtTrigger.unsubscribe();
            this.validmsg = result.message;

          } else {
            this.show = false;
          }
        }, (error) => {
          this.show = false;
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block');  
          this.toastService.showFailed('Failed', 'There was some error');

        });

    } else {
      // validate all form fields
    }
  }

  /**************** Sum Insured Dropdown **********************/
  onItemSelect(item: any) {

    var abcflag = false;
    for (var i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i]['id'] == item['id']) {
        abcflag = true;
        break;
      } else {
        abcflag = false;
      }
    }

    if (!abcflag) {
      this.selectedItems.push(item);
    }
  }

  OnItemDeSelect(item: any) {
    this.selectedItems = this.selectedItems.filter((el: any) => el.id !== item.id);
    console.log(this.selectedItems);
  }

  onSelectAll(items: any) {
    this.selectedItems = [];
    this.selectedItems = items;
    console.log(this.selectedItems);
  }

  onDeSelectAll(items: any) {
    this.selectedItems = [];
    console.log(this.selectedItems);
  }

  /**************** Relations **************/

  onItemSelectRelation(item: any) {
    var abcflag = false;
    for (var i = 0; i < this.selectedItemsRelation.length; i++) {
      if (this.selectedItemsRelation[i]['relation_id'] == item['relation_id']) {
        abcflag = true;
        break;
      } else {
        abcflag = false;
      }
    }

    if (!abcflag) {
      this.selectedItemsRelation.push(item);
    }

  }

  OnItemDeSelectRelation(item: any) {
    this.selectedItemsRelation = this.selectedItemsRelation.filter((el: any) => el.id !== item.relation_id);
  }

  onSelectAllRelation(items: any) {
    this.selectedItemsRelation = [];
    this.selectedItemsRelation = items;
  }

  onDeSelectAllRelation(items: any) {
    this.selectedItemsRelation = [];
  }

  /******************* Addon SI Benefits Dropdown *************************/

  onItemSelect_SI(item: any) {

    var abcflag = false;
    for (var i = 0; i < this.selectedItemsSI.length; i++) {
      if (this.selectedItemsSI[i]['id'] == item['id']) {
        abcflag = true;
        break;
      } else {
        abcflag = false;
      }
    }

    if (!abcflag) {
      this.selectedItemsSI.push(item);
    }
  }

  OnItemDeSelect_SI(item: any) {
    this.selectedItemsSI = this.selectedItemsSI.filter((el: any) => el.id !== item.id);
  }

  onSelectAll_SI(items: any) {
    this.selectedItemsSI = [];
    this.selectedItemsSI = items;
  }

  onDeSelectAll_SI(items: any) {
    this.selectedItemsSI = [];
  }

  closealert() {
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  benefit_optional() {
    let val: any = this.benefit_info.controls['mandatory_type'].value;
    if (val == "Optional") {
      $('.benefit_mandatory_row').css('display', 'flex');
    } else {
      $('.benefit_mandatory_row').css('display', 'none');
    }
  }

  closePopupAdd() {

    this.route.navigate(['flex-configurator']);

    // window.location.reload();    
  }


  getMultiSelect() {

    // if($('#policy_name').val()=='0' || $('#policy_name').val()==0){
    //   return;
    // }

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "policy_id": this.EditInfo.controls['policy_name'].value,
    };

    this.dataservice.getMultiSelect(postdata)
      .subscribe((result: any) => {

        this.show = false;
        this.InfoArrayMultiple = result.data.dropdowninfo;
        this.add_base_fieldname = result.data.field_name;
        this.add_base_flag = result.data.flag;

      }, (error) => {
        this.show = false;
        this.InfoArrayMultiple = [];

      });
  }

  getMultiSelectRelation() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "policy_id": $('#policy_name').val(),
    };

    this.dataservice.getMultiSelectRelation(postdata)
      .subscribe((result: any) => {
        this.InfoArrayMultipleRelations = result.data;
        this.show = false;
        // for(var i=0;i<result.data.length;i++){
        //   this.InfoArrayMultipleRelations.push({relation_id:result.data[i]['relation_id'],relation:result.data[i]['relation']});
        // }

      }, (error) => {
        this.show = false;
        this.InfoArrayMultipleRelations = [];

      });
  }

  getPolicyType() {

    setTimeout(() => {
      $('#policy_type').val('0');
      $('#policy_name').val('0');
    }, 1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "client_id": this.EditInfo.controls['corporate'].value,
    };

    this.dataservice.getPolicyType(postdata)
      .subscribe((result: any) => {

        this.InfoArrayPolicyType = result.data;

      }, (error) => {
        this.InfoArrayPolicyType = [];

      });
  }

  getPolicyName() {
    
    setTimeout(() => {      
      $('#policy_name').val('0');
    }, 1000);

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "client_id": this.EditInfo.controls['corporate'].value,
      "policytype_id": this.EditInfo.controls['policy_type'].value,
    };

    this.dataservice.getPolicyName(postdata)
      .subscribe((result: any) => {

        this.InfoArrayPolicyName = result.data;

      }, (error) => {
        this.InfoArrayPolicyName = [];
      });
  }

  getCorporate() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    // $('#corporate').val('0');


    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getCorporate(postdata)
      .subscribe((result: any) => {

        this.show = false;
        this.InfoArrayCorporate = result.data;

        // console.log(this.InfoArrayCorporate_edit);

      }, (error) => {

        this.show = false;

        this.InfoArrayCorporate = [];

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });
  }

  getBenefit() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getBenefit(postdata)
      .subscribe((result: any) => {

        this.show = false;
        this.InfoArrayBenefit = result.data;

        // console.log(this.InfoArrayBenefit);

      }, (error) => {

        this.InfoArrayBenefit = [];

      });
  }

  getPlanType() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getPlanType(postdata)
      .subscribe((result: any) => {

        this.show = false;
        this.InfoArrayPlanType = result.data;

      }, (error) => {

        this.InfoArrayPlanType = [];

      });

  }

  closePopup() {
    this.displayStyle_add = "none";
    this.displayStyle_edit = "none";
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";
    this.PopupBenefits = "none";
    this.PopupFeatures = "none";
    this.PopupBenefits_edit = "none";
    this.PopupFeatures_edit = "none";
    this.PopupAuditDetails = "none";

    $('.newbenefitData').empty();
    // this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');

  }

  /*** Add Field ***/
  addBenefitData() {

    this.id_multiple_flag = 1;
    console.log(this.id_multiple_flag);
    let cnt = this.cntmultiple - 1;

    let html = `
    <div id="this_Data_benefit_row_`+ this.cnt_BenefitData + `" class="row mt-4" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">
      
      <div class="col-sm-3 form-group mt-3">
        <label class="bs-form-label" for="plan_name_`+ this.cnt_BenefitData + `">Sub Plan Name</label>
        <select class="form-select" formControlName="plan_name_`+ this.cnt_BenefitData + `" id="plan_name_` + this.cnt_BenefitData + `">
              <option value="0" selected>Select Sub Plan Name</option>              
        </select>
      </div>

      <div class="col-sm-3 form-group mt-3 other_plan_name_div_`+ this.cnt_BenefitData + `" style="display:none">
          <label class="bs-form-label" for="other_plan_name_`+ this.cnt_BenefitData + `">Sub Plan Name (if Other option selected)</label>
          <input type="text" class="form-control" formControlName="other_plan_name_`+ this.cnt_BenefitData + `" id="other_plan_name_` + this.cnt_BenefitData + `" placeholder="Sub Plan Name"/>
      </div>
      
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="feature_name_`+ this.cnt_BenefitData + `">Feature Name </label>
          <input type="text" class="form-control" formControlName="feature_name_`+ this.cnt_BenefitData + `" id="feature_name_` + this.cnt_BenefitData + `" placeholder="Feature Name" />
      </div>

      <div class="col-sm-6 form-group mt-3">
          <label class="bs-form-label" for="feature_description_`+ this.cnt_BenefitData + `">Feature Description </label>
          <input type="text" class="form-control" formControlName="feature_description_`+ this.cnt_BenefitData + `" id="feature_description_` + this.cnt_BenefitData + `" placeholder="Enter Content Here..." />
      </div>        

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="add_on_SI_`+ this.cnt_BenefitData + `">Add On SI</label>
          <input type="text" class="form-control" formControlName="add_on_SI_`+ this.cnt_BenefitData + `" id="add_on_SI_` + this.cnt_BenefitData + `" placeholder="Add On SI" value="0" />
      </div>     

      <input type="hidden" id="flag_add_more_`+ this.cnt_BenefitData + `" value='add' />

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="cover_by_`+ this.cnt_BenefitData + `">Cover By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+ this.cnt_BenefitData + `" value="by_value" name="cover_by_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="cover_by_`+ this.cnt_BenefitData + `" value="by_SI" name="cover_by_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                  </div>
              </div>
          </div>
      </div>
        
      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="cover_type_`+ this.cnt_BenefitData + `">Cover Type</label>
          <select class="form-select" formControlName="cover_type_`+ this.cnt_BenefitData + `" id="cover_type_` + this.cnt_BenefitData + `">
                <option value="0" selected>Select Cover Type</option>
          </select>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="sum_insured_cap_`+ this.cnt_BenefitData + `">Sum Insured Cap</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+ this.cnt_BenefitData + `" value="Yes" name="sum_insured_cap_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+ this.cnt_BenefitData + `" value="No" name="sum_insured_cap_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="premium_by_`+ this.cnt_BenefitData + `">Premium By</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+ this.cnt_BenefitData + `" value="by_value" name="premium_by_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="premium_by_`+ this.cnt_BenefitData + `" value="by_premium" name="premium_by_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_value_`+ this.cnt_BenefitData + `">Premium Value</label>
          <input type="text" class="form-control" formControlName="premium_value_`+ this.cnt_BenefitData + `" id="premium_value_` + this.cnt_BenefitData + `" placeholder="Premium Value" value="0" />
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_comparison_`+ this.cnt_BenefitData + `">Premium Comparision</label>
          <select class="form-select" formControlName="premium_comparison_`+ this.cnt_BenefitData + `" id="premium_comparison_` + this.cnt_BenefitData + `">
                <option value="" selected>Select Premium Comparision</option>
                <option value="Low">Low</option>
                <option value="Lower">Lower</option>
                <option value="Lowest">Lowest</option>
                <option value="High">High</option>
                <option value="Higher">Higher</option>
                <option value="Highest">Highest</option>
          </select>
      </div>

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_type_`+ this.cnt_BenefitData + `">Premium Type</label>
          <select class="form-select" formControlName="premium_type_`+ this.cnt_BenefitData + `" id="premium_type_` + this.cnt_BenefitData + `">
                <option value="0" selected>Select Premium Type</option>
          </select>
      </div>     

      <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="tag_name_`+ this.cnt_BenefitData + `">Tag Name</label>
          <input type="text" class="form-control" formControlName="tag_name_`+ this.cnt_BenefitData + `" id="tag_name_` + this.cnt_BenefitData + `" placeholder="Tag Name"/>
      </div>

      <div class="col-sm-3 mt-3">
          <label class="bs-form-label" for="is_differential_`+ this.cnt_BenefitData + `">Is Differential</label>
          <div class="row">
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="is_differential_`+ this.cnt_BenefitData + `" value="Yes" name="is_differential_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" checked />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="form-radio">
                      <input type="radio" class="form-radio-input" formControlName="is_differential_`+ this.cnt_BenefitData + `" value="No" name="is_differential_` + this.cnt_BenefitData + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                      <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-sm-12 mt-3">
        <div class="form-group mychk" name="visible_from_SI_value[]" id="visible_from_SI_value_${this.cnt_BenefitData}">
          <label class="bs-form-label">Visible From SI Value</label><br>
        </div>
      </div>
      
      <div class="col-sm-2 form-group mt-3 pt-2">
          <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+ this.cnt_BenefitData + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
      </div>
      <div class="col-sm-12"><hr></div>
    </div>      
    `;

    $('.newbenefitData').append(html);

    $('head').append('<style>.form-check input[type="checkbox"] { display: inline-block; width: auto; margin-right: 5px; position: relative; padding: 10px;} </style><style>.form-check label { display: inline-block; vertical-align: middle; }</style><style>.form-check{position: relative; display: inline-block; margin-top: 0px; margin-bottom: 5px; padding-left: 5px; }</style>');

    for (var i = 0; i < this.PlanName.length; i++) {
      var val = '#' + 'plan_name_' + this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.PlanName[i]['id'])
          .text(this.PlanName[i]['title']));
    }

    for (var i = 0; i < this.Covertype.length; i++) {
      var val = '#' + 'cover_type_' + this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Covertype[i]['value_id'])
          .text(this.Covertype[i]['name']));
    }

    for (var i = 0; i < this.Premiumtype.length; i++) {
      var val = '#' + 'premium_type_' + this.cnt_BenefitData;
      $(val)
        .append($("<option></option>")
          .attr("value", this.Premiumtype[i]['value_id'])
          .text(this.Premiumtype[i]['name']));
    }

    for (var i = 0; i < this.InfoArrayMultiple.length; i++) {
      var checkboxId = 'visible_from_SI_value_' + this.cnt_BenefitData + '_' + i;
      var checkboxLabel = this.InfoArrayMultiple[i]['name'];

      // Create checkbox element
      var checkbox = $('<input>').attr({
        type: 'checkbox',
        id: checkboxId,
        name: 'visible_from_SI_value_' + this.cnt_BenefitData,
        value: this.InfoArrayMultiple[i]['id']
      });

      // Create label for checkbox
      var label = $('<label>').attr('for', checkboxId).text(checkboxLabel);

      // Create div to contain checkbox and label
      var containerDiv = $('<div>').addClass('form-check');

      // Append checkbox and label to container div
      containerDiv.append(checkbox).append(label);

      // Append container div to the target element
      $('#visible_from_SI_value_' + this.cnt_BenefitData).append(containerDiv);

    }

    let flag = this;
    $("#Data_benefit_row_" + this.cnt_BenefitData).on('click', function (e) {
      flag.removeRow_Benefit(e);
    });

    $("#plan_name_" + this.cnt_BenefitData).on('change', function (e) {
      flag.plan_name(e);
    });

    $("#add_on_SI_" + this.cnt_BenefitData).on('keypress', function (e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#premium_value_" + this.cnt_BenefitData).on('keypress', function (e) {
      flag.isnumberBenefit_benefit(e);
    });

    $("#visible_from_SI_value_"+this.cnt_BenefitData + " input[type='checkbox']").on('click', function(e) {
      var value = $(this).val(); 
      flag.onCheckboxChange(e, value, flag.cnt_BenefitData);
  });

    this.cnt_BenefitData++;

    this.cntmultiple = this.cnt_BenefitData;

    // console.log("cntmultiple after add more - ",this.cntmultiple);

  }

  plan_name(e: any) {
    var inputString:any = e.target.id;
    const keyword:any = "plan_name_";
    const cnt:any = inputString.split(keyword)[1];
    var val = '.other_plan_name_div_' + cnt;      
    var value:any = $("select[id=plan_name_"+cnt+"] option:selected").text();    
    if(value == "other"){
      $(val).css('display','block');
    }else{
      $(val).css('display','none');
    }
  }

  /***************** Add ons Benefits ***********************************/

  showPopup() {

    this.getCoverType();
    this.getPremiumType();
    this.getMultiSelectRelation();

    this.add_edit_flag = 0;

    this.PopupBenefits = "block";

    let val1: any = $('#benefit_name').val();
    $('#benefit_name_val').val(val1);
    let val2: any = $('#benefit_description').val();
    $('#benefit_description_val').val(val2);
    this.benefit_info.controls['mandatory_type'].setValue('Mandatory');
    this.benefit_info.controls['mandatory_not_selected'].setValue('');
    $('.benefit_mandatory_row').css('display', 'none');
    this.benefit_info.controls['prorata_calculation_applicable'].setValue('Yes');

    this.selectedItemsSI = [];
    this.checkedValues = [];

  }

  savebenefit() {

    this.PopupBenefits = "none";
    let val1: any = $("select[id=benefit_name_val] option:selected").text();
    let val2: any = $('#benefit_description_val').val();
    let val3 = this.selectedItemsSI;
    let val4 = $('input[name="mandatory_type"]:checked').val();
    let val5 = $('input[name="prorata_calculation_applicable"]:checked').val();
    let val6 = $('#mandatory_not_selected').val();
    let val7 = $('#benefit_name_val').val();

    let mandType: any;
    if(val4 == 'Mandatory'){
      mandType = 1;
    }else{
      mandType = 0;
    }

    const dynamicArrays: any = {};

    this.checkedValues.forEach(item => {
        if (dynamicArrays[item.cnt]) {
            dynamicArrays[item.cnt].push(item);
        } else {
            dynamicArrays[item.cnt] = [item];
        }
    });

    console.log(dynamicArrays)

    let result: string[][] = [];

    // Get the maximum count value
    const maxCount = Math.max(...Object.keys(dynamicArrays).map(Number));

    // Initialize the result array with empty arrays up to the maximum count value
    for (let i = 0; i <= maxCount; i++) {
        result.push([]);
    }

    // Populate the result array with values from the data object
    Object.entries(dynamicArrays).forEach(([countStr, values]) => {
        const count = parseInt(countStr);
        (values as { cnt: number; value: string; }[]).forEach(({ value }) => {
            result[count].push(value);
        });
    });
    
    // Remove empty arrays
    result = result.filter(arr => arr.length > 0);

    console.log(result);

    let visbleSICheckbox:any = [];

    for(var l=0; l < this.checkedValues.length; l++){
      visbleSICheckbox.push(this.checkedValues[l].value);
    }

    let arr: any = [];
    for (var i = 1; i < this.cnt_BenefitData; i++) {
      // console.log(i);       

      if ($('#feature_name_' + i).val() != undefined) {
        var index = i - 1;
        arr[index] = {
          id: i,
          plan_name: $('#plan_name_' + i).val(),
          other_plan_name: $('#other_plan_name_' + i).val(),
          premium_comparison: $('#premium_comparison_' + i).val(),
          tag_name: $('#tag_name_' + i).val(),
          is_differential: $('input[name="is_differential_' + i + '"]:checked').val(),
          feature_name: $('#feature_name_' + i).val(),
          feature_description: $('#feature_description_' + i).val(),
          flag_add_more: $('#flag_add_more_' + i).val(),
          add_on_SI: $('#add_on_SI_' + i).val(),
          cover_by: $('input[name="cover_by_' + i + '"]:checked').val(),
          cover_type: $('#cover_type_' + i).val(),
          cover_type_text: $("select[id=cover_type_" + i + "] option:selected").text(),
          sum_insured_cap: $('input[name="sum_insured_cap_' + i + '"]:checked').val(),
          premium_by: $('input[name="premium_by_' + i + '"]:checked').val(),
          premium_value: $('#premium_value_' + i).val(),
          premium_type: $('#premium_type_' + i).val(),
          premium_type_text: $("select[id=premium_type_" + i + "] option:selected").text(),
          visible_from_SI_id_json: result[i] || [],
        };
      }
    }

    var filtered = arr.filter(function (el: any) {
      return el != null;
    });

    // let cnt_BenefitDataval;
    // if(this.cnt_BenefitData == 1){
    //   cnt_BenefitDataval = 1;
    // }else{
    //   cnt_BenefitDataval = this.cnt_BenefitData - 1;
    // }

    this.addonbaseArray.push(
      {
        "id": this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "mandatory_type": mandType,
        "prorata_calculation_applicable": val5,
        "mandatory_not_selected": val6,
        "benefit_id": val7,
      }
    );

    let arrbase = [];

    arrbase.push(
      {
        "id": this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "mandatory_type": mandType,
        "prorata_calculation_applicable": val5,
        "mandatory_not_selected": val6,
        "benefit_id": val7,
      }
    );

    // this.addonmultipleArray.push(arr);

    this.arrayAddOn.push({
      "arrbase": arrbase, "arrmultiple": filtered
    });

    console.log(this.arrayAddOn);

    $('.newbenefitData').empty();
    // this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');
    // this.cnt_BenefitData = 1;
    this.addonbasecount = this.addonbasecount + 1;

  }

  getCoverType() {
    this.dataservice.getCoverType()
      .subscribe((result) => {

        this.Covertype = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  getPlanName() {

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
    };

    this.dataservice.getPlanName(postdata)
      .subscribe((result) => {

        this.PlanName = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  getPremiumType() {
    this.dataservice.getPremiumType()
      .subscribe((result) => {

        this.Premiumtype = result.data;

      }, (error) => {
        // console.log(result);
      });
  }

  removeRow_Benefit(e: any) {
    var str = e.target.id;
    var id = str.charAt(str.length - 1);
    var idstr = '#this_Data_benefit_row_' + id;
    $(idstr).remove();

  }

  onCheckboxChange(event: any, value: any, cnt:any) {
    if (event.target.checked) {
        this.checkedValues.push({cnt, value});
    } else {
        const index = this.checkedValues.indexOf(value);
        if (index !== -1) {
            this.checkedValues.splice(index, 1);
        }
    }
  }

  isnumberBenefit_benefit(evt: any) {
    // Get the input value
    const inputValue: string = evt.target.value;

    // Check if the entered character is a number or a decimal point
    const charCode: number = (evt.which) ? evt.which : evt.keyCode;

    // Allow only numbers, decimal point, backspace, and delete keys
    if (
      (charCode >= 48 && charCode <= 57) || // Numbers 0-9
      charCode === 46 || // Decimal point
      charCode === 8 || // Backspace
      charCode === 46 || // Delete
      charCode === 37 || // Left arrow
      charCode === 39 || // Right arrow
      charCode === 9 // Tab
    ) {
      // Ensure only one decimal point is entered
      if (charCode === 46 && inputValue.indexOf('.') !== -1) {
        evt.preventDefault();
      }

      if (evt.target.value == "00") {
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;
    } else {
      evt.preventDefault();
      return false;
    }
  }


  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /*** What's Not Cover */
  saveWhatsNotCover(){
    let val1:any = $("select[id=benefit_name_not_cover] option:selected").text();
    let val2:any = $('#benefit_description_not_cover').val();
    let val3:any = $('#benefit_name_not_cover').val();

    if(val2 != 0 && val2 != '' && val2 != null && val3 != 0 && val3 != '' && val3 != null){
      this.NotCoverTableArray.push(
        {
          
          "benefit_name": val1,
          "benefit_description": val2,
          "benefit_not_cover_id": val3,
          
        }
      )
    }else{
      this.toastService.showFailed('Failed', 'Benefit Name and Description are mandatory');
    }

    console.log(this.NotCoverTableArray);

    $('#benefit_name_not_cover').val('');
    $('#benefit_description_not_cover').val('');


  } 

  removeNotCoverTableArray(i: any) {
    this.NotCoverTableArray.splice(i, 1);
    console.log(this.NotCoverTableArray);
  }

  removeaddonbaseArray(i: any) {
    this.addonbaseArray.splice(i, 1);
    this.arrayAddOn.splice(i, 1);
    console.log(this.addonbaseArray);
    console.log(this.arrayAddOn);
  }

  checkset_allowed_increase() {
    let val = $('#set_allowed_increase').is(':checked');
    if (val) {
      $('.set_allowed_increaseDiv').css('display', 'block');
    } else {
      $('.set_allowed_increaseDiv').css('display', 'none');
    }
  }

  checkset_allowed_decrease() {
    let val = $('#set_allowed_decrease').is(':checked');
    if (val) {
      $('.set_allowed_decreaseDiv').css('display', 'block');
    } else {
      $('.set_allowed_decreaseDiv').css('display', 'none');
    }
  }

  checkwill_there_be_any_premium() {
    let val = $('#will_there_be_any_premium').is(':checked');
    if (val) {
      $('.will_there_be_any_premiumDiv').css('display', 'block');
    } else {
      $('.will_there_be_any_premiumDiv').css('display', 'none');
    }
  }


  /******************* EDIT BENEFIT *********/
  showPopup_edit(id: any) {

    this.add_edit_flag = 1;

    this.update_id = id;

    this.PopupBenefits = "block";

    this.getMultiSelectRelation();


    // let val11:any = $('#benefit_name').val();
    // $('#benefit_name_val').val(val11);
    // let val22:any = $('#benefit_description').val();
    // $('#benefit_description_val').val(val22);   
    // this.benefit_info.controls['mandatory_type'].setValue('');              
    // this.benefit_info.controls['mandatory_not_selected'].setValue('');          
    // $('.benefit_mandatory_row').css('display', 'none');
    // this.benefit_info.controls['prorata_calculation_applicable'].setValue('');

    // console.log(this.arrayAddOn[id]);

    let val1: any = this.arrayAddOn[id]['arrbase'][0]['benefit_id'];
    $('#benefit_name_val').val(val1);

    let val2: any = this.arrayAddOn[id]['arrbase'][0]['benefit_description'];
    $('#benefit_description_val').val(val2);

    // let val3:any = this.arrayAddOn[id]['arrbase'][0]['min_enchance_si_id'];
    // $('#benefit_visible_from_SI_val').val(val3);

    let val4: any = this.arrayAddOn[id]['arrbase'][0]['mandatory_type'];
    if (val4 == 1) {
      $("input[name=mandatory_type][value=Mandatory]").prop('checked', 'checked');
    } else {
      $("input[name=mandatory_type][value=Optional]").prop('checked', 'checked');
    }

    // let val5:any = this.arrayAddOn[id]['arrbase'][0]['mandatory_not_selected'];
    // $('#mandatory_not_selected').val(val5);

    let val6: any = this.arrayAddOn[id]['arrbase'][0]['prorata_calculation_applicable'];
    if (val6 == 1) {
      $("input[name=prorata_calculation_applicable][value=Yes]").prop('checked', true);
    } else {
      $("input[name=prorata_calculation_applicable][value=No]").prop('checked', true);
    }



    /**************************************************/

    let aa = this.arrayAddOn[id]['arrmultiple'];

    console.log(this.arrayAddOn[id]['arrmultiple']);

    this.cntmultiple = aa.length;
    this.cnt_BenefitData = aa.length + 1;

    // console.log('aa - ',aa)

    for (var i = 0; i < aa.length; i++) {

      let flag_add_more: any;
      if (aa[i]['flag_add_more'] == undefined || aa[i]['flag_add_more'] == 'show') {
        flag_add_more = 'show';
      } else {
        flag_add_more = 'add';
      }

      let html = `
        <div id="this_Data_benefit_row_`+ i + `" class="row mt-4" style="padding: 10px; background: #9494943f; border-radius: 6px; cursor: pointer; margin: 0px;">
              
        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="plan_name_`+ i + `">Sub Plan Name</label>
          <select class="form-select" formControlName="plan_name_`+ i + `" id="plan_name_` + i + `">
                <option value="0" selected>Select Sub Plan Name</option>
          </select>
        </div>

        <div class="col-sm-3 form-group mt-3 other_plan_name_div_`+ i + `" style="display:none">
            <label class="bs-form-label" for="other_plan_name_`+ i + `">Sub Plan Name (if Other option selected)</label>
            <input type="text" class="form-control" formControlName="other_plan_name_`+ i + `" id="other_plan_name_` + i + `" placeholder="Sub Plan Name" value="` + aa[i]['plan_name'] + `" />
        </div>

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="feature_name_`+ i + `">Feature Name </label>
            <input type="text" class="form-control" formControlName="feature_name_`+ i + `" id="feature_name_` + i + `" placeholder="Feature Name" value="` + aa[i]['feature_name'] + `" />
        </div>

        <div class="col-sm-6 form-group mt-3">
            <label class="bs-form-label" for="feature_description_`+ i + `">Feature Description </label>
            <input type="text" class="form-control" formControlName="feature_description_`+ i + `" id="feature_description_` + i + `" placeholder="Enter Content Here..." value="` + aa[i]['feature_description'] + `" />
        </div>        

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="add_on_SI_`+ i + `">Add On SI</label>
            <input type="text" class="form-control" formControlName="add_on_SI_`+ i + `" id="add_on_SI_` + i + `" placeholder="Add On SI" value="` + aa[i]['add_on_SI'] + `" />
        </div>     

        <input type="hidden" id="flag_add_more_`+ i + `" value='` + flag_add_more + `' />

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="cover_by_`+ i + `">Cover By</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="cover_by_`+ i + `" value="by_value" name="cover_by_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="cover_by_`+ i + `" value="by_SI" name="cover_by_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By SI %</label>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="cover_type_`+ i + `">Cover Type</label>
            <select class="form-select" formControlName="cover_type_`+ i + `" id="cover_type_` + i + `">
                  <option value="0" selected>Select Cover Type</option>
            </select>
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="sum_insured_cap_`+ i + `">Sum Insured Cap</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+ i + `" value="Yes" name="sum_insured_cap_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="sum_insured_cap_`+ i + `" value="No" name="sum_insured_cap_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="premium_by_`+ i + `">Premium By</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="premium_by_`+ i + `" value="by_value" name="premium_by_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Value</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="premium_by_`+ i + `" value="by_premium" name="premium_by_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">By Premium %</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="premium_value_`+ i + `">Premium Value</label>
            <input type="text" class="form-control" formControlName="premium_value_`+ i + `" id="premium_value_` + i + `" placeholder="Premium Value" value="` + aa[i]['premium_value'] + `" />
        </div>

        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="premium_comparison_`+ i + `">Premium Comparision</label>
          <select class="form-select" formControlName="premium_comparison_`+ i + `" id="premium_comparison_` + i + `">
                <option value="" selected>Select Premium Comparision</option>
                <option value="Low">Low</option>
                <option value="Lower">Lower</option>
                <option value="Lowest">Lowest</option>
                <option value="High">High</option>
                <option value="Higher">Higher</option>
                <option value="Highest">Highest</option>
          </select>
        </div>

        <div class="col-sm-3 form-group mt-3">
            <label class="bs-form-label" for="premium_type_`+ i + `">Premium Type</label>
            <select class="form-select" formControlName="premium_type_`+ i + `" id="premium_type_` + i + `">
                  <option value="0" selected>Select Premium Type</option>
            </select>
        </div>    

        <div class="col-sm-3 form-group mt-3">
          <label class="bs-form-label" for="tag_name_`+ i + `">Tag Name</label>
          <input type="text" class="form-control" formControlName="tag_name_`+ i + `" id="tag_name_` + i + `" placeholder="Tag Name" value="` + aa[i]['tag_name'] + `" />
        </div>

        <div class="col-sm-3 mt-3">
            <label class="bs-form-label" for="is_differential_`+ i + `">Is Differential</label>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="is_differential_`+ i + `" value="Yes" name="is_differential_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">Yes</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-radio">
                        <input type="radio" class="form-radio-input" formControlName="is_differential_`+ i + `" value="No" name="is_differential_` + i + `" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                        <label class="form-radio-label bs-radio-label" style="vertical-align: middle; margin-left: 10px; cursor: pointer;">No</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="form-group" name="visible_from_SI_value[]" id="visible_from_SI_value_${i}">
              <label class="bs-form-label">Visible From SI Value</label><br>
            </div>
        </div>
        
        <div class="col-sm-2 form-group mt-3 pt-2">
            <img src="../../../assets/img/common icons/trash.png" id="Data_benefit_row_`+ i + `" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
        <div class="col-sm-12"><hr></div>
      </div>      
      `;

      $('.newbenefitData').append(html);

      $('head').append('<style>.form-check input[type="checkbox"] { display: inline-block; width: auto; margin-right: 5px; position: relative; padding: 10px;} </style><style>.form-check label { display: inline-block; vertical-align: middle; }</style><style>.form-check{position: relative; display: inline-block; margin-top: 0px; margin-bottom: 5px; padding-left: 5px; }</style>');

      var value_cover_by = aa[i]['cover_by'];
      if (value_cover_by == 1 || value_cover_by == "by_value") {
        $("input[name=cover_by_" + i + "][value=by_value]").prop('checked', true);
      } else {
        $("input[name=cover_by_" + i + "][value=by_SI]").prop('checked', true);
      }

      var value_sum_insured_cap = aa[i]['sum_insured_cap'];
      if (value_sum_insured_cap == 1 || value_sum_insured_cap == "Yes") {
        $("input[name=sum_insured_cap_" + i + "][value=Yes]").prop('checked', true);
      } else {
        $("input[name=sum_insured_cap_" + i + "][value=No]").prop('checked', true);
      }

      var value_premium_by = aa[i]['premium_by'];
      if (value_premium_by == 1 || value_premium_by == "by_value") {
        $("input[name=premium_by_" + i + "][value=by_value]").prop('checked', true);
      } else {
        $("input[name=premium_by_" + i + "][value=by_premium]").prop('checked', true);
      }

      var is_differential = aa[i]['is_differential'];
      if (is_differential == 1 || is_differential == "Yes") {
        $("input[name=is_differential_" + i + "][value=Yes]").prop('checked', true);
      } else {
        $("input[name=is_differential_" + i + "][value=No]").prop('checked', true);
      }

      var valcovertype = '#cover_type_' + i;
      $(valcovertype).val(aa[i]['cover_type']);

      var valpremium_type = '#premium_type_' + i;
      $(valpremium_type).val(aa[i]['premium_type']);

      var valplan_name = '#plan_name_' + i;
      $(valplan_name).val(aa[i]['planid']);

      var premium_comparison = '#premium_comparison_' + i;
      $(premium_comparison).val(aa[i]['premium_comparison']);

      var visible_from_SI_value = '#visible_from_SI_value_' + i;
      $(visible_from_SI_value).val(aa[i]['visible_from_SI_id']);      

      //Dropdown
      for (var j = 0; j < this.Covertype.length; j++) {
        var val = '#' + 'cover_type_' + i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Covertype[j]['value_id'])
            .text(this.Covertype[j]['name']));
      }

      //Dropdown
      for (var k = 0; k < this.Premiumtype.length; k++) {
        var val = '#' + 'premium_type_' + i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.Premiumtype[k]['value_id'])
            .text(this.Premiumtype[k]['name']));
      }

      //Dropdown
      for (var h = 0; h < this.PlanName.length; h++) {
        var val = '#' + 'plan_name_' + i;
        $(val)
          .append($("<option></option>")
            .attr("value", this.PlanName[h]['id'])
            .text(this.PlanName[h]['title']));
      }
      

      var visibleSI = aa[i]['visible_from_SI_id_json'];

      for (var m = 0; m < this.InfoArrayMultiple.length; m++) {
        var checkboxId = 'visible_from_SI_value_' + m + '_' + i;
        var checkboxLabel = this.InfoArrayMultiple[m]['name'];
    
        // Create checkbox element
        var checkbox = $('<input>').attr({
            type: 'checkbox',
            id: checkboxId,
            name: 'visible_from_SI_value_' + this.cnt_BenefitData,
            value: this.InfoArrayMultiple[m]['id']
        });

        // Check if the checkbox should be checked based on aa[i]['visible_from_SI_id']
        for(var b=0; b < visibleSI.length; b++){
          if(visibleSI[b] == this.InfoArrayMultiple[m]['id']){
            checkbox.attr('checked', 'checked');
          }
        }
    
        // Create label for checkbox
        var label = $('<label>').attr('for', checkboxId).text(checkboxLabel);
    
        // Create div to contain checkbox and label
        var containerDiv = $('<div>').addClass('form-check');
    
        // Append checkbox and label to container div
        containerDiv.append(checkbox).append(label);
    
        // Append container div to the target element
        $('#visible_from_SI_value_' + i).append(containerDiv);
    }
    
    

      let flag = this;
      $("#Data_benefit_row_" + i).on('click', function (e) {
        flag.removeRow_Benefit(e);
      });

      $("#add_on_SI_" + i).on('keypress', function (e) {
        flag.isnumberBenefit_benefit(e);
      });

      $("#premium_value_" + i).on('keypress', function (e) {
        flag.isnumberBenefit_benefit(e);
      });

      $("#plan_name_"+i).on('change',function(e) {
        flag.plan_name(e);
      });

      $("#plan_name_"+i).trigger('change');
      

      $("#visible_from_SI_value_" + i + " input[type='checkbox']").on('click', function (e) {
        var value = $(this).val();
        flag.onCheckboxChange(e, value, i);
      });

    }

    for (var i = 0; i < aa.length; i++) {
      $('#cover_type_' + i).val(aa[i]['cover_type']);
      $('#premium_type_' + i).val(aa[i]['premium_type']);
      $('#plan_name_' + i).val(aa[i]['planid']);
      // $('#visible_from_SI_value_' + i).val(aa[i]['visible_from_SI_id']);
    }
    /***************************************************/


    this.selectedItemsSI = [];

    let val3: any = this.arrayAddOn[id]['arrbase'][0]['benefit_visible_from_SI'];

    for (let i = 0; i < val3.length; i++) {
      console.log(val3[i]['id']);
      
      let selectedItem = {
        id: parseInt(val3[i]['id']),
        name: val3[i]['name']
      };

      this.selectedItemsSI.push(selectedItem);
    }

    setTimeout(() => {
      this.benefit_info.patchValue({
        addon_si_grade_dropdown: this.selectedItemsSI
      });

      // Trigger change detection after updating selectedItems
      this.cd.detectChanges();
    }, 1000);


    // End

  }

  savebenefit_update() {

    const delindex: any = this.update_id;

    // console.log('------',this.arrayAddOn[this.update_id]['arrmultiple']);

    // base array
    let flex_benefit_id: any;
    let flex_plan_idfk: any;

    let visbleSICheckbox:any = [];

    console.log(this.checkedValues);

    for(var l=0; l < this.checkedValues.length; l++){
      visbleSICheckbox.push(this.checkedValues[l].value);
    }



    for (var i = 0; i < this.addonbaseArray.length; i++) {
      if (this.addonbaseArray[i]['flex_benefit_id'] != '' ||
        this.addonbaseArray[i]['flex_benefit_id'] != '0' ||
        this.addonbaseArray[i]['flex_benefit_id'] != null ||
        this.addonbaseArray[i]['flex_benefit_id'] != undefined) {

        flex_benefit_id = this.addonbaseArray[i]['flex_benefit_id'];
        flex_plan_idfk = this.addonbaseArray[i]['flex_plan_idfk'];

      } else {
        flex_benefit_id = 0;
        flex_plan_idfk = 0;
      }
    }

    // multiple array
    let flex_benefit_idfk: any;
    let id_multiple: any[] = [];

    /*********************************/
    if (this.arrayAddOn[this.update_id]['arrmultiple'].length != 0) {
      this.id_multiple_flag = 0;
      console.log(this.id_multiple_flag);

      /*******/
      for (var i = 0; i < this.arrayAddOn[this.update_id]['arrmultiple'].length; i++) {

        flex_benefit_idfk = this.arrayAddOn[this.update_id]['arrmultiple'][i]['flex_benefit_idfk'];
        id_multiple.push(this.arrayAddOn[this.update_id]['arrmultiple'][i]['id_multiple']);
      }
    } else {
      flex_benefit_idfk = 1;
    }

    /*********************************/

    if (delindex > -1) {
      this.arrayAddOn.splice(delindex, 1);
      this.addonbaseArray.splice(delindex, 1);
    }

    this.PopupBenefits = "none";
    let val1: any = $("select[id=benefit_name_val] option:selected").text();
    let val2: any = $('#benefit_description_val').val();
    let val3 = this.selectedItemsSI;
    let val4 = $('input[name="mandatory_type"]:checked').val();
    let manvalue: any;

    if (val4 == 'Mandatory') {
      manvalue = 1;
    } else {
      manvalue = 0;
    }

    let val5 = $('input[name="prorata_calculation_applicable"]:checked').val();
    let prorata_calculation_applicable_val: any;
    if (val5 == 'Yes') {
      prorata_calculation_applicable_val = 1;
    } else {
      prorata_calculation_applicable_val = 0;
    }
    let val6 = $('#mandatory_not_selected').val();
    let val7 = $('#benefit_name_val').val();

    let arr: any = [];
    let arrcnr = this.cntmultiple + this.cnt_BenefitData;

    for (var i = 0; i < arrcnr; i++) {
      if ($('#feature_name_' + i).val() != undefined) {

        var currentIdMultiple = $('#flag_add_more_' + i).val() === "add" ? 0 : id_multiple[i];
        arr[i] = {
          id: i,
          id_multiple: currentIdMultiple,
          flex_benefit_idfk: flex_benefit_idfk,
          flag_add_more: $('#flag_add_more_' + i).val(),
          plan_name: $('#plan_name_' + i).val(),
          other_plan_name: $('#other_plan_name_' + i).val(),
          premium_comparison: $('#premium_comparison_' + i).val(),
          tag_name: $('#tag_name_' + i).val(),
          is_differential: $('input[name="is_differential_' + i + '"]:checked').val(),
          feature_name: $('#feature_name_' + i).val(),
          feature_description: $('#feature_description_' + i).val(),
          add_on_SI: $('#add_on_SI_' + i).val(),
          cover_by: $('input[name="cover_by_' + i + '"]:checked').val(),
          cover_type: $('#cover_type_' + i).val(),
          cover_type_text: $("select[id=cover_type_" + i + "] option:selected").text(),
          sum_insured_cap: $('input[name="sum_insured_cap_' + i + '"]:checked').val(),
          premium_by: $('input[name="premium_by_' + i + '"]:checked').val(),
          premium_value: $('#premium_value_' + i).val(),
          premium_type: $('#premium_type_' + i).val(),
          premium_type_text: $("select[id=premium_type_" + i + "] option:selected").text(),
          visible_from_SI_id_json: visbleSICheckbox,
        };
      } else {
        // arr.splice(delindex, i);
      }
    }

    var filtered = arr.filter(function (el: any) {
      return el != null;
    });

    // console.log('filtered',filtered);

    this.addonbaseArray.push(
      {
        "id": this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "mandatory_type": manvalue,
        "prorata_calculation_applicable": prorata_calculation_applicable_val,
        "mandatory_not_selected": val6,
        "benefit_id": val7,
        "flex_benefit_id": flex_benefit_idfk,
        "flex_plan_idfk": flex_plan_idfk
      }
    );

    let arrbase = [];

    arrbase.push(
      {
        "id": this.addonbasecount,
        "benefit_name": val1,
        "benefit_description": val2,
        "benefit_visible_from_SI": val3,
        "mandatory_type": manvalue,
        "prorata_calculation_applicable": prorata_calculation_applicable_val,
        "mandatory_not_selected": val6,
        "benefit_id": val7,
        "flex_benefit_id": flex_benefit_idfk,
        "flex_plan_idfk": flex_plan_idfk

      }
    );

    this.arrayAddOn.push({
      "arrbase": arrbase, "arrmultiple": filtered
    });

    console.log(this.arrayAddOn);
    console.log(arr);

    $('.newbenefitData').empty();
    // this.cnt_BenefitData = 1;
    $('#benefit_visible_from_SI_val').val('');
    $('#benefit_name').val('');
    $('#benefit_description').val('');
    this.cnt_BenefitData = 1;
    this.addonbasecount = this.addonbasecount + 1;
  }

  /***************************************** Features ****************************************/

  showPopupFeatures() {

    this.feature_flag = 0;

    this.PopupFeatures = "block";
    this.getMultiSelectRelation();
    this.getCoverType();
    this.getPremiumType();

    let val1: any = $('#feature_desc').val();
    $('#feature_desc_val').val(val1);

    this.features_info.controls['to_hide_detail'].setValue('Yes');
    this.features_info.controls['relation_inclusive'].setValue('Yes');
    this.features_info.controls['sum_insured'].setValue('');
    this.features_info.controls['sum_insured_by'].setValue('by_value');
    this.features_info.controls['sum_insured_type'].setValue('0');
    this.features_info.controls['premium'].setValue('');
    this.features_info.controls['premium_by'].setValue('by_value');
    this.features_info.controls['premium_type'].setValue('0');
    this.features_info.controls['is_optional'].setValue('Yes');
    this.features_info.controls['contributions'].setValue('0');
    this.selectedItemsRelation = [];

  }

  savefeature() {

    this.PopupFeatures = "none";

    let val1: any = $('#feature_desc').val();
    let val2: any = this.selectedItemsRelation;
    let val3 = $('#sum_insured_features').val();
    let val4 = $('#sum_insured_type').val();
    let val5 = $('input[name="to_hide_detail"]:checked').val();
    let val6 = $('input[name="relation_inclusive"]:checked').val();
    let val7 = $('input[name="sum_insured_by"]:checked').val();
    let val8 = $('input[name="is_optional"]:checked').val();
    let val9 = $('#premium_type').val();
    let val10 = $('input[name="premium_by"]:checked').val();
    let val11 = $('#premium').val();
    let val14 = $('#contributions').val();

    let val12: any = $("select[id=sum_insured_type] option:selected").text();
    let val13: any = $("select[id=premium_type] option:selected").text();


    this.FeatureTableArray.push(
      {
        "id": 0,
        "feature_desc": val1,
        "relations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "sum_insured_type_name": val12,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_type_name": val13,
        "premium_by": val10,
        "premium": val11,
        "contributions": val14,

      }
    )

    // show this array in table of added benefit
    console.log(this.FeatureTableArray);
    $('#feature_desc').val('');
    $('#feature_desc_val').val('');
    $('#relations').val('');
    $('#sum_insured_features').val('');
    $('#sum_insured_type').val('0');
    $('#premium_by').val('');
    $('#premium_type').val('0');

  }

  showPopupFeatures_edit(id: any) {

    this.feature_flag = 1;
    this.feature_update_id = id

    this.PopupFeatures = "block";

    this.feature_id = this.FeatureTableArray[id]['id'];

    let val1: any = this.FeatureTableArray[id]['feature_desc'];
    $('#feature_desc_val').val(val1);

    let val2: any = this.FeatureTableArray[id]['to_hide'];
    if (val2 == 1) {
      $('input[name="to_hide_detail"][value="Yes"]').prop('checked', true);
    } else {
      $('input[name="to_hide_detail"][value="No"]').prop('checked', true);
    }

    let val3: any = this.FeatureTableArray[id]['is_relation_inclusive'];
    if (val3 == 1) {
      $('input[name="relation_inclusive"][value="Yes"]').prop('checked', true);
    } else {
      $('input[name="relation_inclusive"][value="No"]').prop('checked', true);
    }

    let val4: any = this.FeatureTableArray[id]['sum_insured'];
    $('#sum_insured_features').val(val4);

    let val5: any = this.FeatureTableArray[id]['sum_insured_by'];
    if (val5 == 1) {
      this.features_info.controls['sum_insured_by'].setValue('by_value');
    } else {
      this.features_info.controls['sum_insured_by'].setValue('by_SI');
    }

    setTimeout(() => {
      this.getCoverType();
    }, 1000);

    let val6: any = this.FeatureTableArray[id]['sum_insured_type'];
    for (var i = 0; i < this.Covertype.length; i++) {
      if (val6 == this.Covertype[i]['value_id']) {
        this.features_info.controls['sum_insured_type'].setValue(val6);
      }
    }


    let val7: any = this.FeatureTableArray[id]['premium'];
    $('#premium').val(val7);

    let val8: any = this.FeatureTableArray[id]['premium_by'];
    if (val8 == 1) {
      this.features_info.controls['premium_by'].setValue('by_value');
    } else {
      this.features_info.controls['premium_by'].setValue('by_premium');
    }

    setTimeout(() => {
      this.getPremiumType();
    }, 1000);

    let val9: any = this.FeatureTableArray[id]['premium_type'];
    for (var k = 0; k < this.Premiumtype.length; k++) {
      if (val9 == this.Premiumtype[k]['value_id']) {
        this.features_info.controls['premium_type'].setValue(val9);
      }
    }

    let val10: any = this.FeatureTableArray[id]['is_optional'];
    if (val10 == 1) {
      this.features_info.controls['is_optional'].setValue('Yes');
    } else {
      this.features_info.controls['is_optional'].setValue('No');
    }

    let val11: any = this.FeatureTableArray[id]['sum_insured_by'];
    $('input[name="sum_insured_by"]').filter(`[value="${val11}"]`).prop('checked', true);

    let val12: any = this.FeatureTableArray[id]['premium_by'];
    $('input[name="premium_by"]').filter(`[value="${val12}"]`).prop('checked', true);

    let val13: any = this.FeatureTableArray[id]['is_optional'];
    $('input[name="is_optional"]').filter(`[value="${val13}"]`).prop('checked', true);

    let val14: any = this.FeatureTableArray[id]['to_hide_detail'];
    $('input[name="to_hide_detail"]').filter(`[value="${val14}"]`).prop('checked', true);

    let val15: any = this.FeatureTableArray[id]['relation_inclusive'];
    $('input[name="relation_inclusive"]').filter(`[value="${val15}"]`).prop('checked', true);

    let val16: any = this.FeatureTableArray[id]['contributions'];
    $('#contributions').val(val16);

    setTimeout(() => {
      this.getMultiSelectRelation();
    }, 1000);

    this.selectedItemsRelation = [];
    let val17: any = this.FeatureTableArray[id]['relations'];

    for (let i = 0; i < val17.length; i++) {
      let selectedItem = {
        relation_id: parseInt(val17[i]['relation_id']),
        relation: val17[i]['relation']
      };

      this.selectedItemsRelation.push(selectedItem);
    }

    setTimeout(() => {
      this.features_info.patchValue({
        relations: this.selectedItemsRelation
      });
      this.cd.detectChanges();
    }, 1000);

  }

  savefeature_update() {
    const delindex: any = this.feature_update_id;
    if (delindex > -1) {
      this.FeatureTableArray.splice(delindex, 1);
    }

    this.PopupFeatures = "none";

    let val1: any = $('#feature_desc_val').val();
    let val2: any = this.selectedItemsRelation;
    let val3 = $('#sum_insured_features').val();
    let val4 = $('#sum_insured_type').val();
    let val5 = $('input[name="to_hide_detail"]:checked').val();
    let val6 = $('input[name="relation_inclusive"]:checked').val();
    let val7 = $('input[name="sum_insured_by"]:checked').val();
    let val8 = $('input[name="is_optional"]:checked').val();
    let val9 = $('#premium_type').val();
    let val10 = $('input[name="premium_by"]:checked').val();
    let val11 = $('#premium').val();
    let val14 = $('#contributions').val();

    let val12: any = $("select[id=sum_insured_type] option:selected").text();
    let val13: any = $("select[id=premium_type] option:selected").text();


    this.FeatureTableArray.push(
      {

        "id": this.feature_id,
        "feature_desc": val1,
        "relations": val2,
        "sum_insured": val3,
        "sum_insured_type": val4,
        "sum_insured_type_name": val12,
        "to_hide_detail": val5,
        "relation_inclusive": val6,
        "sum_insured_by": val7,
        "is_optional": val8,
        "premium_type": val9,
        "premium_type_name": val13,
        "premium_by": val10,
        "premium": val11,
        "contributions": val14,

      }
    )

    console.log(this.FeatureTableArray);
    $('#feature_desc').val('');
    $('#feature_desc_val').val('');
    $('#relations').val('');
    $('#sum_insured_features').val('');
    $('#sum_insured_type').val('0');
    $('#premium_by').val('');
    $('#premium_type').val('0');

  }

  removeFeatureTableArray(i: any) {
    this.FeatureTableArray.splice(i, 1);
    console.log(this.FeatureTableArray);
  }

}
