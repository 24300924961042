<head>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css">
</head>
<!-- header -->
<header class="header-area">
    <div class="header-top second-header d-none d-md-block">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-3 d-none d-lg-block">
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <div class="header-cta">
                        <ul>
                            <li>
                                <img src="../../assets/website/icon/email.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">info@neodigit.in</span>
                            </li>
                            <!-- <li>
                                <img src="../../assets/website/icon/telephone.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">+8 12 3456897</span>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-3 d-none d-lg-block">
                    <div class="header-social text-right">
                        <span>
                            <a href="#" title="Facebook"><i class="fab fa-facebook"></i></a>
                            <a href="#" title="Twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>                            
                        </span>
                        <!--  /social media icon redux -->                        
                    </div>                    
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                </div>                

            </div>
        </div>
    </div>
    <div id="header-sticky" class="menu-area">
        <div class="container">
            <div class="second-menu">
                <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-2">
                        <div class="logo">
                            <a routerLink="/home" href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                    </div>
                    <div class="col-xl-10 col-lg-8">
                        <div class="main-menu text-right pr-15">
                            <nav id="mobile-menu">
                                <ul>
                                    <li class="has-sub">
                                        <a routerLink="/home" href="#">Home</a>
                                    </li>
                                    <li><a routerLink="/about-us" href="#">About Us</a></li>                                    
                                    <li><a routerLink="/schedule-demo" href="#">Schedule A Demo</a></li>
                                    
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <!-- <div class="col-xl-2 col-lg-2 d-none d-lg-block">
                        <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                    </div> -->
                    <div class="col-12">
                        <div class="mobile-menu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header-end -->
<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <!-- breadcrumb-area -->
    <section class="breadcrumb-area d-flex align-items-center" style="background-image:url(../../assets/website/bg/header-bg.png);">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="breadcrumb-wrap text-center">
                        <div class="breadcrumb-title mb-30">
                            <h2>Schedule A Demo</h2>                                    
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/home" href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Schedule A Demo</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- breadcrumb-area-end -->

    <!-- contact-area -->
    <section id="contact" class="contact-area contact-bg pt-100 pb-70 p-relative fix" style="background-image:url(../../assets/website/an-bg/an-bg11.png); background-size: cover;background-repeat: no-repeat;">
        <div class="container">
     
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-img">
                        <img src="../../assets/website/bg/touch-illustration.png" alt="touch-illustration">
                    </div>
                </div>
                <div class="col-lg-6">
                <div class="section-title mb-60" >
                        <span>Schedule A Demo</span>                        
                    </div>
                <form action="#" class="contact-form" >
                    <div class="row">
                    <div class="col-lg-12">
                        <div class="contact-field p-relative c-name mb-20">                                    
                            <input type="text" placeholder="Name of the company">
                        </div>                               
                    </div>
                        <div class="col-lg-12">
                        <div class="contact-field p-relative c-name mb-20">                                    
                            <input type="text" placeholder="Contact Person Name">
                        </div>                               
                    </div>
                    <div class="col-lg-6">                               
                        <div class="contact-field p-relative c-email mb-20">                                    
                            <input type="text" placeholder="Company mail Id">
                        </div>                                
                    </div>
                    <div class="col-lg-6">                               
                        <div class="contact-field p-relative c-subject mb-20">                                   
                            <input type="text" placeholder="Mobile No.">
                        </div>
                    </div>							
                    <div class="col-lg-12">
                        <div class="contact-field p-relative c-message mb-45">                                  
                            <textarea name="message" id="message" cols="30" rows="10" placeholder="Remark"></textarea>
                        </div>
                        <div class="slider-btn">                                          
                            <a href="#" class="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">Send Message</a>					
                        </div>                             
                    </div>
                    </div>
                
            </form>
                </div>
            </div>
            
        </div>
       
    </section>
    <!-- contact-area-end -->
</main>
<!-- main-area-end -->
<!-- footer -->
<footer class="footer-bg footer-p" style="background-color: #fff;">
    <div class="overly"><img src="../../assets/website/an-bg/footer-bg.png" alt="rest"></div>
    <div class="footer-top pb-30" style="background-color: #ECF1FA;">
        <div class="container">
            <div class="row justify-content-between">

                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="flog mb-35">
                            <a href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                        <div class="footer-text mb-20">
                            <p>NeoDigit is backed by a team of industry veterans and technological experts with over 21 years of combined experience in insurance and technology.</p>
                        </div>
                        <div class="footer-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-google-plus-g"></i></a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Our Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Schedule A Demo</a></li>                                                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Other Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> About Us</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Services</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Project</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Our Team</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Latest Blog</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="footer-link">
                            <div class="f-contact">
                                <ul>
                                    <li>
                                        <img src="../../assets/img/common icons/to-do-list.png" style="width: 40px; height: 40px;" />
                                        <span>9999422119</span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/clientuser.png" style="width: 40px; height: 40px;" />
                                        <span><a href="mailto:info@neodigit.in">info@neodigit.in</a></span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/product.png" style="width: 40px; height: 40px;" />
                                        <!-- <span>380 St Kilda Road, Melbourne<br>VIC 3004, Australia</span> -->
                                    </li>
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="copyright-text text-center">
                        <div class="container text-center">
                            <small class="text-black">Copyright &copy; 2023 NeoDigit | All rights reserved</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer-end -->