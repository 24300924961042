<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>
  
    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>
  

          <h3 class="bs-title"  *ngIf="addflag == 0">Wellness<span class="spanbs-title"> Benefit </span></h3>
          <h3 class="bs-title"  *ngIf="addflag == 1">Wellness Benefit Edit
            <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
          </h3>
          
          <hr class="mb-2 mt-4" style="height: 0px">
  
          <!-- Add -->
          <form [formGroup]="basicInfo" *ngIf="addflag == 0 && writeaccess == 1">
            <div class="row">
  
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="wellness_partner">Wellness Partner<span class="redstar">*</span></label>
                        <select class="form-select" formControlName="wellness_partner" id="wellness_partner" [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.wellness_partner.errors}"
                        required>
                            <option value="">Select Wellness Partner</option>
                            <option *ngFor="let wellness of InfoArrayWellness; let i = index" value="{{wellness.id}}">
                                {{wellness.wellness_name}}</option>
                        </select>
                        <ng-container
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.wellness_partner.errors">
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.wellness_partner.errors.required">
                    Wellness Partner is required
                    </p>
                  </ng-container>
                    </div>
                </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_name">Benefit Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_name" id="benefit_name" placeholder="Benefit Name"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors}"
                        required />
                    <ng-container
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors">
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors.required">
                    Benefit Name is required
                    </p>
                    <p class="error"
                    *ngIf="basicInfoSubmitted && getBasicInformationFormControls.benefit_name.errors.leadingWhitespace">
                    Benefit Name cannot start with a space
                 </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="url">URL <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="url" id="url" placeholder="URL"
                        [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.url.errors}"
                        required pattern="https?://.*" />
                    <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.url.errors">
                        <p class="error"
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.url.errors.required">
                          URL is required
                        </p>
                        <p class="error"
                            *ngIf="basicInfoSubmitted && getBasicInformationFormControls.url.errors.pattern">
                         Invalid URL 
                        </p>
                    </ng-container>
                </div>
            </div>
            
            
            <div class="col-sm-12 mt-3">
              <div class="form-group">
                  <label class="bs-form-label" for="benefit_description">Benefit Description</label>
                  <textarea type="text" class="form-control" formControlName="benefit_description" id="benefit_description" placeholder="Benefit Description" style="height: 70px;"></textarea>
              </div>
            </div>
           
  
              <div class="col-sm-12" align="center">
                  <button id="submitbutton" type="button" class="btn btn-primary mt-3" (click)="onSubmitAdd()" role="button">Save</button>
              </div>
            </div>
          </form>
  
          <hr  *ngIf="addflag == 0">
  
          <!-- List -->
          <div *ngIf="addflag == 0">
            <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%">SrNo</th>
                        <th style="text-align: center;"> Wellness Partner</th>
                        <th style="text-align: center;">Benefit Name</th>
                        <th>URL</th>
                        <th>Status</th>
                        <th  *ngIf="writeaccess == 1">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of InfoArray; let i = index">
                        <td style="width:5%">{{i+1}}</td>
                        <td>{{group['partner_name']}}</td>
                        <td>{{group['benefit_name']}}</td>
                        <td>{{group['benefit_url']}}</td>                                            
                        <td>
                          <span *ngIf="group['is_active'] == 1" class="btn btn-inverse-success btn-fw">Active</span>
                          <span *ngIf="group['is_active'] == 0" class="btn btn-inverse-danger btn-fw">Deactive</span>
                        </td>
                        <td style="width: 15%" align="center"  *ngIf="writeaccess == 1">
                            <img src="../../../assets/img/common icons/edit_new.png" id="edituser" (click)="openPopupEdit(group)" role="button" title="Edit" />
                            <img src="../../../assets/img/selectproduct/delete.png" id="deleteuser" (click)="openPopupDelete(group)" class="ml-4" role="button" title="Delete" />
                            <img src="../../../assets/img/selectproduct/deactive.png" *ngIf="group['is_active']==1" (click)="openPopupDeactivate(group)" id="deactive"  class="ml-4" role="button" title="Deactive" />
                            <img src="../../../assets/img/selectproduct/active.png" *ngIf="group['is_active']==0" (click)="openPopupActivate(group)" id="active"  class="ml-4" role="button" title="Active" />
                        </td>        
                    </tr>
                </tbody>
            </table>
          </div>

          <!-- Edit -->
          <form [formGroup]="EditInfo" *ngIf="addflag == 1">
            <div class="row">
              <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="wellness_partner_edit">Wellness Partner <span class="redstar">*</span></label>
                    <select class="form-select" formControlName="wellness_partner_edit" id="wellness_partner_edit" [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.wellness_partner_edit.errors}"
                        required>
                            <option value="">Select Wellness Partner</option>
                            <option *ngFor="let wellness of InfoArrayWellness; let i = index" value="{{wellness.id}}">
                                {{wellness.wellness_name}}</option>
                    </select>
                    <ng-container
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_partner_edit.errors">
                    <p class="error"
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.wellness_partner_edit.errors.required">
                    Wellness Partner is required
                    </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_name_edit">Benefit Name <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_name_edit" id="benefit_name_edit" placeholder="Benefit Name"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors}"
                        required />
                    <ng-container
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors">
                    <p class="error"
                    *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors.required">
                    Benefit Name is required
                    </p>
                    <p class="error"
                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_name_edit.errors.leadingWhitespace">
                        Benefit Name cannot start with a space
                     </p>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_url">URL <span class="redstar">*</span></label>
                    <input type="text" class="form-control" formControlName="benefit_url" id="benefit_url" placeholder="URL"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors}"
                        required pattern="https?://.*" />
                    <ng-container *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors">
                        <p class="error"
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors.required">
                          URL is required
                        </p>
                        <p class="error"
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.benefit_url.errors.pattern">
                          URL must start with http:// or https://
                        </p>
                    </ng-container>
                </div>
            </div>
            
            
              <div class="col-sm-12 mt-3">
                <div class="form-group">
                    <label class="bs-form-label" for="benefit_description_edit">Benefit Description</label>
                    <textarea type="text" class="form-control" formControlName="benefit_description_edit" id="benefit_description_edit" placeholder="Benefit Description" style="height: 70px;"></textarea>
                </div>
              </div>

                            
              <div class="col-sm-12" align="center">
                  <button type="button" class="btn btn-primary mt-3" (click)="onSubmitEdit()" role="button">Update</button>
              </div>
            </div>
          </form>
  
            <!-- Deactivate Form -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDeactivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Deactivate Wellness Benefit</h4>
                      </div>
                      <form [formGroup]="DeactivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Deactivate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitDeactivate()"
                                  role="button">Deactivate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          <!-- Activate Form -->
          <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleActivate}">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">Activate Wellness Benefit</h4>
                      </div>
                      <form [formGroup]="ActivateInfo">
                          <div class="modal-body">
                              Are you sure you want to Activate?
                          </div>
                          <input type="hidden" class="form-control" formControlName="active_delete_id" id="active_delete_id" />
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="onSubmitActivate()"
                                  role="button">Activate</button>
                              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

            <!-- Delete -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Delete Wellness Partner</h4>
                        </div>
                        <form [formGroup]="DeleteInfo">
                            <div class="modal-body">
                                Are you sure you want to delete?
                            </div>
                            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmitDelete()"
                                    role="button">Delete</button>
                                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
  