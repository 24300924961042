<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">Policy Details
                <button type="button" class="btn btn-primary" (click)="showBenefitpopup()" role="button" style="float:right; margin-top: -5px; margin-right: 10px; padding: 5px 25px !important;">
                    <img src="../../../../../../assets/img/employee/view.png" />
                     Benefit Summary
                </button>
                <button type="button" class="btn btn-primary" (click)="goto()" role="button" style="float:right; margin-top: -5px; margin-right: 10px;">Back</button>
            </h3>
            <hr>           

            <div class="accordion" id="myAccordion"> 
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button type="button" class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                            <span class="numberheading">1</span>
                            Review Self Details</button>									
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                        <div class="card-body accorCardbody">                                                                
                            <form [formGroup]="basicInfo">
                                <!-- <h3 class="bs-title">Basic Policy Details </h3> -->
                                <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Basic Policy Details created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>                                                                 -->
                                <!-- <hr> -->

                                <div class="row">

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_name">Employer Name</label>
                                            <input type="text" class="form-control" formControlName="employer_name" id="employer_name" placeholder="Employer Name" readonly />                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_dob">Date of Birth</label>
                                            <input type="date" class="form-control" formControlName="employer_dob" id="employer_dob" placeholder="dd/mm/yyyy" readonly />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_joining">Date of Joining</label>
                                            <input type="date" class="form-control" formControlName="employer_joining" id="employer_joining" placeholder="dd/mm/yyyy" readonly />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_email">Email</label>
                                            <input type="email" class="form-control" formControlName="employer_email" id="employer_email" placeholder="Email" readonly />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_contact_number">Mobile No. <span class="redstar">*</span></label>
                                            <input type="email" class="form-control" formControlName="employer_contact_number" id="employer_contact_number" placeholder="Contact No" maxlength="10" (keypress)="isNumber($event)"
                                            [ngClass]="{'control-red': basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors}"
                                            required />  
                                            <ng-container *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors">
                                                <p class="error" *ngIf="basicInfoSubmitted && getBasicInformationFormControls.employer_contact_number.errors.required">
                                                    Please fill out this field
                                                </p>
                                            </ng-container>
                                            <p class="error" id="employer_contact_number_error" style="display: none; margin: 0px;">
                                                Not Valid Number 
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_alternate_email">Alternate Email</label>
                                            <input type="email" class="form-control" formControlName="employer_alternate_email" id="employer_alternate_email" placeholder="Alternate Email" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_emergency_person">Emergency Contact Person</label>
                                            <input type="text" class="form-control" formControlName="employer_emergency_person" id="employer_emergency_person" placeholder="Emergency Contact Person"/>                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="employer_emergency_contact_number">Emergency Mobile No.</label>
                                            <input type="email" class="form-control" formControlName="employer_emergency_contact_number" id="employer_emergency_contact_number" placeholder="Emergency Mobile No." maxlength="10" (keypress)="isNumber($event)" />                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 mt-3">
                                        <div class="form-group">
                                            <button (click)="next(1)" class="btn btn-primary" style="float: right; display: flow-root;">Save & next</button>
                                        </div>
                                    </div>

                                </div>                                                             

                            </form>
                            
                            <!-- <form class="mt-5">
                                <img src="../../../../../assets/img/employee/step1_bg.png" class="step1bg" />
                            </form> -->
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                            <span class="numberheading">2</span>
                            Review Members 
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body accorCardbody">
                            <form [formGroup]="Insuredinfo">
                                <!-- <h3 class="bs-title">Insured Member</h3>                                 -->
                                <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Insured Member created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div> -->
                                <!-- <hr> -->

                                <div class="row" *ngFor="let res of RowArray; let i = index;"> 

                                    <div class="col-sm-12 mb-3 py-2 headingbg">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <b>Policy: {{res['policy_name']}}</b>
                                                <b style="float: right; color: var(--bs-sidebar)" *ngIf="is_hide_premium == 0">
                                                    <span style="cursor: pointer" (click)="viewDesc(res)"><u>View Description</u></span> 
                                                    <!-- |  -->
                                                    <!-- Total Premium: {{res['total_premium']}} -->
                                                </b>                                                
                                            </div>
                                            <div class="col-sm-12">
                                                <p class="mb-0">
                                                    <span *ngIf="res['hide_employer_contribution'] == 0">
                                                        Employer Pays  : {{res['employer_contribution_premium']}}                                                        
                                                        <span *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</span>
                                                        <span *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</span>
                                                    </span>                                                


                                                    <span style="float: right; color: #000;" *ngIf="res['hide_employee_contribution'] == 0">
                                                        You Pay Premium : {{res['employee_contribution_premium']}}
                                                        <span *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</span>
                                                        <span *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</span>
                                                    </span>
                                                </p>       
                                            </div>                                            
                                        </div>                                        
                                    </div>
                                    
                                    <!-- <div class="col-sm-8 row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="relation_with_employee_{{i}}">Relation With Employee <span class="redstar">*</span></label>
                                                <select class="form-select" id="relation_with_employee_{{i}}" (change)="selectgender_step2($event, i, res['policy_id'])">
                                                    <option value="0" selected>Select</option>
                                                    <option *ngFor="let image of res['relation_data']; let j = index;" [value]="image.relation_id">{{image.relation}}</option>
                                                </select>
                                                <p class="error" id="relation_with_employee_error_{{i}}" style="display: none">
                                                    Please fill out this field
                                                </p>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="gender_{{i}}">Gender <span class="redstar">*</span></label>
                                                <select class="form-select" id="gender_{{i}}">
                                                    <option value="" selected>Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <p class="error" id="gender_error_{{i}}" style="display: none">
                                                    Please fill out this field
                                                </p>
                                            </div>
                                        </div>
                                                  
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="first_name_{{i}}">First Name <span class="redstar">*</span></label>
                                                <input type="text" class="form-control" id="first_name_{{i}}" placeholder="First Name" />                                            
                                                <p class="error" id="first_name_error_{{i}}" style="display: none">
                                                    Please fill out this field
                                                </p>
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="last_name_{{i}}">Last Name</label>
                                                <input type="text" class="form-control" id="last_name_{{i}}" placeholder="Last Name"/>                                            
                                            </div>
                                        </div>                                    
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_contact_number_{{i}}">Mobile No.</label>
                                                <input type="email" class="form-control" id="insured_contact_number_{{i}}" placeholder="Contact No" maxlength="10" (keypress)="isNumber($event)" />                                            
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_email_{{i}}">Email</label>
                                                <input type="email" class="form-control" id="insured_email_{{i}}" placeholder="Email" />                                            
                                            </div>
                                        </div>
    
                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="insured_dob_{{i}}">Date of Birth <span class="redstar">*</span></label>
                                                <input type="date" class="form-control" [max]="dob_future" id="insured_dob_{{i}}" pattern="\d{2}/\d{2}/\d{4}" placeholder="dd/mm/yyyy" (change)="changedob(i, $event, res['policy_id'])" />                                            
                                                <p class="error" id="dob_error_{{i}}" style="display: none">
                                                    Please fill out this field
                                                </p>
                                                <small class="error age_greater_error_{{i}}" style="display: none">Invalid Age ! Age must between {{min_age_add}} and {{max_age_add}}</small>
                                                <small class="error age_equal_to_error_{{i}}" style="display: none">Invalid Age ! Age must equal to {{relation_age_difference}}</small> 
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mt-3 si_array_Div" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="si_array_{{i}}">SI</label>
                                                <select class="form-select" id="si_array_{{i}}">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let siarray of si_array; let p = index;" [value]="siarray.rater_id">{{siarray.total_cover}}</option>                                                                                                       
                                                </select>    
                                                <small id="si_array_error_{{i}}" class="bs-form-label" style="font-size: 12px; color: red; display: none;">Please Select Sum Insured</small>                                                                                           
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mt-3 marriage_date_{{i}}" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="marriage_date_{{i}}">Marriage Date</label>
                                                <input type="date" class="form-control" [max]="dob_future" id="marriage_date_{{i}}" pattern="\d{2}/\d{2}/\d{4}" placeholder="dd/mm/yyyy" />                                                                                            
                                            </div>
                                        </div>       
                                        
                                        <div class="col-sm-12 mt-3 marriage_certificate_file_{{i}}" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="marriage_certificate_file_{{i}}">Upload Marriage Certificate</label>
                                                <label for="marriage_certificate_file_{{i}}" id="features-file-upload_marriage_certificate_file_{{i}}" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                                </label>
                                                <input id="marriage_certificate_file_{{i}}" type="file" (change)="claim_doc($event, i, 'marriage')" accept=".png, .pdf, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                                <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .png, .pdf, .jpg, .jpeg files</small>
                                                <small id="marriage_certificate_file_error_{{i}}" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                              </div>
                                        </div>

                                        <div class="col-sm-12 mt-3 birth_certificate_file_{{i}}" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="birth_certificate_file_{{i}}">Upload Birth Certificate</label>
                                                <label for="birth_certificate_file_{{i}}" id="features-file-upload_birth_certificate_file_{{i}}" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                                </label>
                                                <input id="birth_certificate_file_{{i}}" type="file" (change)="claim_doc($event, i, 'birth')" accept=".png, .pdf, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                                <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .png, .pdf, .jpg, .jpeg files</small>
                                                <small id="birth_certificate_file_error_{{i}}" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                              </div>
                                        </div> -->

                                        <!-- Special Child -->
                                        <!-- <div class="col-sm-6 form-group is_special_child_div_{{i}}" style="display: none">
                                            <div class="form-check" style="margin-top: 50px;">
                                                <input type="checkbox" class="form-check-input" id="is_special_child_{{i}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Special Child</label>
                                            </div>
                                        </div> -->

                                        <!-- Unmarried Child -->
                                        <!-- <div class="col-sm-6 form-group is_unmarried_child_div_{{i}}" style="display: none">
                                            <div class="form-check" style="margin-top: 50px;">
                                                <input type="checkbox" class="form-check-input" id="is_unmarried_child_{{i}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Unmarried Child</label>
                                            </div>
                                        </div> -->

                                        <!-- Declaration -->
                                        <!-- <div class="col-sm-6 form-group is_adopted_div_{{i}}" style="display: none">
                                            <div class="form-check" style="margin-top: 50px;">
                                                <input type="checkbox" class="form-check-input" id="is_adopted_child_{{i}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (click)="is_adopted_child(i)" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Adopted Child</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mt-3 declaration_{{i}}" style="display: none">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="step2_file_{{i}}">Upload Declaration Document</label>
                                                <label for="step2_file_{{i}}" id="features-file-upload_step2_file_{{i}}" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data_{{i}}" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                                </label>
                                                <input id="step2_file_{{i}}" type="file" (change)="claim_doc($event, i, 'adopted')" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                                                <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .csv, .xls, .xlsx files</small>
                                                <small id="step2_file_error_{{i}}" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                                <small id="step2_file_download_sample_{{i}}" (click)="ondownload()" class="bs-form-label" style="font-size: 12px; text-decoration: underline; color: var(--bs-sidebar); float: right; cursor: pointer">Download Sample File</small>

                                              </div>
                                        </div>

                                        <div class="col-sm-6 mt-3">
                                            <div class="form-group">
                                                <button (click)="addIsurer(i)" class="btn btn-primary" style="background-color: #F8934C; color: #fff; border: #F8934C; margin-top: 25px;">
                                                    <img src="../../../../../../assets/img/employee/Vector23.png" alt="img" style="margin-right: 5px; vertical-align: bottom;" />
                                                    Save Details
                                                </button>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mt-3 crossParentCheckDiv_{{i}}" style="display: none; color: red; font-size: 12px;">
                                            No Cross Parent is Allowed!!
                                        </div>

                                        <div class="col-sm-12 mt-3 parents_allowed_{{i}}" style="display: none; color: red; font-size: 12px;">
                                            Relation Allowed for this employee does allows to add this employee !!
                                        </div>

                                        <div class="col-sm-12 mt-3 duplicateparent_{{i}}" style="display: none; color: red; font-size: 12px;">
                                            Another parent of same relation cannot be added!!
                                        </div> -->
                                     
                                    <!-- </div> -->

                                    <!-- Add Member Div -->
                                    <div class="col-sm-12" style="padding: 0px;border: solid 2px #40189D; margin-left: 15px; margin-right: 15px; width: 97%;">
                                        <div style="font-size: 14px; background-color: #D9D9D9; padding: 8px; font-weight: 600;">
                                            Insured Members Enrolled Into Policy
                                        </div>
                                        <div class="row addmemberdiv">
                                            
                                            <!-- is_parent_policy = 0 -->
                                            <div class="col-sm-12 row m-0 p-1" *ngIf="res['is_parent_policy'] == 0">
                                                <div class="col-sm-2 p-2" *ngFor="let image of res['insured_members']; let k = index;">
                                                    <div *ngIf="image['first_name'] != ''" class="bgdiv divclass">
                                                        <div class="row">                                                        
                                                            <div class="col-sm-4">
                                                                <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                            </div>
                                                            <div class="col-sm-8 pad8">
                                                                <div class="textclass">{{image['relation']}}</div>
                                                                <div class="textclass">{{image['gender']}}</div> 
                                                                <div class="text-center mb-0" *ngIf="image['member_premium'] != null && image['member_premium'] != 0">
                                                                    <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                    <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                    <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                </div>
                                                            </div> 
                                                        </div>                                                                                                                                        
                                                    </div>                                                
                                                </div>
                                            </div> 

                                            <!-- is_parent_policy = 1 -->
                                            <div class="col-sm-12 mt-2" *ngIf="res['is_parent_policy'] == 1">                                                
                                                <div class="row" style="padding-bottom: 10px;">

                                                    <!-- Self -->
                                                    <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;">                                                        
                                                        <div *ngIf="image['relation'] === 'Self' || image['relation'] === 'Spouse/Partner'" class="bgdiv divclass">
                                                            <div class="row" *ngIf="image['first_name'] != ''">                                                        
                                                                <div class="col-sm-4">
                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                </div>
                                                                <div class="col-sm-8 pad8">
                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                    <div class="text-center mb-0" *ngIf="image['member_premium'] != null && image['member_premium'] != 0">
                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                    </div>
                                                                </div> 
                                                            </div>                                                                                                                                        
                                                        </div>                                                                                                        
                                                    </div>                                                   

                                                    <div class="col-sm-12"></div>

                                                    <!-- pcross_comb_block == 0 -->
                                                    <div class="p-0" *ngIf="res['pcross_comb_block'] == 0">
                                                        <div class="col-sm-12 row m-0 p-0" *ngIf="res['max_parents'] != res['max_parents_filled'] || res['max_parents_inlaw'] != res['max_parents_inlaw_filled']">
                                                                                                        
                                                            <!-- Father, Mother -->                                                        
                                                            <div class="col-sm-12 row m-0" [ngClass]="{'classborder': res['max_parents_filled'] != '0'}">
    
                                                                <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;" style="display: contents">                                                        
                                                                    <div class="p-2" *ngIf="image['relation'] == 'Father' || image['relation'] == 'Mother'" style="width: inherit;">
    
                                                                        <div *ngIf="image['relation'] == 'Father' && image['first_name'] != ''" class="bgdiv divclass">
                                                                            <div class="row">                                                        
                                                                                <div class="col-sm-4">
                                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                                </div>
                                                                                <div class="col-sm-8 pad8">
                                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                                    <div class="text-center mb-0" *ngIf="res['max_parents'] != res['max_parents_filled'] && (image['member_premium'] != null || image['member_premium'] != 0)">
                                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                                    </div>
                                                                                </div> 
                                                                            </div>  
                                                                        </div>
    
                                                                        <div *ngIf="image['relation'] == 'Mother' && image['first_name'] != ''" class="bgdiv divclass">
                                                                            <div class="row">                                                        
                                                                                <div class="col-sm-4">
                                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                                </div>
                                                                                <div class="col-sm-8 pad8">
                                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                                    <div class="text-center mb-0" *ngIf="res['max_parents'] != res['max_parents_filled'] && (image['member_premium'] != null || image['member_premium'] != 0)">
                                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                                    </div>
                                                                                </div> 
                                                                            </div>  
                                                                        </div>
                                                                                                                                                                                                              
                                                                    </div>                                                                                                        
                                                                </div>                                                                                                                                                     
                                                                <div class="premiumdiv" *ngIf="res['max_parents'] == res['max_parents_filled'] && res['max_parents'] != 0">
                                                                    <p>
                                                                        <label class="bs-form-label">
                                                                            <b>Premium: &#8377; {{res['vp_policy_mother_father_premium']}}</b>
                                                                        </label>
                                                                        <span *ngIf="res['is_premium_tax'] == 1" class="f10 ml-2"> (Tax Included)</span>
                                                                        <span *ngIf="res['is_premium_tax'] == 0" class="f10 ml-2"> (Tax Excluded)</span>
                                                                    </p>                                                                
                                                                </div>                                                                                                                                                                                   
                                                            </div>                                                                                                                                                                                                           
                                                            <div class="col-sm-12"></div>
                                                            
                                                            <!-- Father-in-law, Mother-in-law -->                                                        
                                                            <div class="col-sm-12 row m-0" [ngClass]="{'classborder': res['max_parents_inlaw_filled'] != '0'}">
    
                                                                <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;" style="display: contents">                                                        
                                                                    <div class="p-2" *ngIf="image['relation'] == 'Father-in-law' || image['relation'] == 'Mother-in-law'" style="width: inherit;">
    
                                                                        <div *ngIf="image['relation'] == 'Father-in-law' && image['first_name'] != ''" class="bgdiv divclass">
                                                                            <div class="row">                                                        
                                                                                <div class="col-sm-4">
                                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                                </div>
                                                                                <div class="col-sm-8 pad8">
                                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                                    <div class="text-center mb-0" *ngIf="res['max_parents_inlaw'] != res['max_parents_inlaw_filled'] && (image['member_premium'] != null || image['member_premium'] != 0)">
                                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                                    </div>
                                                                                </div> 
                                                                            </div>  
                                                                        </div>
    
                                                                        <div *ngIf="image['relation'] == 'Mother-in-law' && image['first_name'] != ''" class="bgdiv divclass">
                                                                            <div class="row">                                                        
                                                                                <div class="col-sm-4">
                                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                                </div>
                                                                                <div class="col-sm-8 pad8">
                                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                                    <div class="text-center mb-0" *ngIf="res['max_parents_inlaw'] != res['max_parents_inlaw_filled'] && (image['member_premium'] != null || image['member_premium'] != 0)">
                                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                                    </div>
                                                                                </div> 
                                                                            </div>  
                                                                        </div>
                                                                                                                                                                                                              
                                                                    </div>                                                                                                        
                                                                </div>                                                                                                                                                     
                                                                <div class="premiumdiv" *ngIf="res['max_parents_inlaw'] == res['max_parents_inlaw_filled'] && res['max_parents_inlaw'] != 0">
                                                                    <p>
                                                                        <label class="bs-form-label">
                                                                            <b>Premium: &#8377; {{res['vp_policy_mother_father_premium']}}</b>
                                                                        </label>
                                                                        <span *ngIf="res['is_premium_tax'] == 1" class="f10 ml-2"> (Tax Included)</span>
                                                                        <span *ngIf="res['is_premium_tax'] == 0" class="f10 ml-2"> (Tax Excluded)</span>
                                                                    </p>                                                                
                                                                </div>                                                                                                                                                                                   
                                                            </div>                                                                                                                                                                                                           
                                                            <div class="col-sm-12"></div>                                                                                                              
    
                                                        </div>
                                                    </div>
                                                                                                        
                                                    <!-- pcross_comb_block == 1 -->
                                                    <div class="p-0" *ngIf="res['pcross_comb_block'] == 1">
                                                        <div class="col-sm-12 row m-0 p-0" *ngIf="res['max_parents'] != res['max_parents_filled'] || res['max_parents_inlaw'] != res['max_parents_inlaw_filled']">

                                                            <div class="col-sm-12 row m-0" [ngClass]="{'classborder': res['max_parents'] != res['max_parents_filled'] || res['max_parents_inlaw'] != res['max_parents_inlaw_filled'] && res['pcross_comb_block'] == 1}">
    
                                                                <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;" style="display: contents">                                                        
                                                                    <div class="p-2" *ngIf="image['relation'] == 'Father' || image['relation'] == 'Mother' || image['relation'] == 'Father-in-law' || image['relation'] == 'Mother-in-law'" style="width: inherit;">
    
                                                                        <div class="bgdiv divclass">
                                                                            <div class="row">                                                        
                                                                                <div class="col-sm-4">
                                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                                </div>
                                                                                <div class="col-sm-8 pad8">
                                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                                    <div class="textclass">{{image['gender']}}</div>                                                                                     
                                                                                </div> 
                                                                            </div>  
                                                                        </div>                                                                           
                                                                                                                                                                                                              
                                                                    </div>                                                                                                        
                                                                </div>                                                                                                                                                     
                                                                <div class="premiumdiv">
                                                                    <p>
                                                                        <label class="bs-form-label">
                                                                            <b>Premium: &#8377; {{res['vp_policy_mother_father_premium']}}</b>
                                                                        </label>
                                                                        <span *ngIf="res['is_premium_tax'] == 1" class="f10 ml-2"> (Tax Included)</span>
                                                                        <span *ngIf="res['is_premium_tax'] == 0" class="f10 ml-2"> (Tax Excluded)</span>
                                                                    </p>                                                                
                                                                </div>                                                                                                                                                                                   
                                                            </div>                                                     
    
                                                        </div>
                                                    </div>                                                    

                                                    <!-- All Four parents are present -->
                                                    <div class="col-sm-12 row m-0 p-0" *ngIf="res['max_parents'] == res['max_parents_filled'] && res['max_parents_inlaw'] == res['max_parents_inlaw_filled']">

                                                        <div class="col-sm-12 row m-0" [ngClass]="{'classborder': res['max_parents'] == res['max_parents_filled'] && res['max_parents_inlaw'] == res['max_parents_inlaw_filled']}">

                                                            <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;" style="display: contents">                                                        
                                                                <div class="p-2" *ngIf="image['relation'] == 'Father' || image['relation'] == 'Mother' || image['relation'] == 'Father-in-law' || image['relation'] == 'Mother-in-law'" style="width: inherit;">

                                                                    <div class="bgdiv divclass">
                                                                        <div class="row">                                                        
                                                                            <div class="col-sm-4">
                                                                                <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                                <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                            </div>
                                                                            <div class="col-sm-8 pad8">
                                                                                <div class="textclass">{{image['relation']}}</div>
                                                                                <div class="textclass">{{image['gender']}}</div>                                                                                     
                                                                            </div> 
                                                                        </div>  
                                                                    </div>                                                                           
                                                                                                                                                                                                          
                                                                </div>                                                                                                        
                                                            </div>                                                                                                                                                     
                                                            <div class="premiumdiv">
                                                                <p>
                                                                    <label class="bs-form-label">
                                                                        <b>Premium: &#8377; {{res['vp_policy_mother_father_premium']}}</b>
                                                                    </label>
                                                                    <span *ngIf="res['is_premium_tax'] == 1" class="f10 ml-2"> (Tax Included)</span>
                                                                    <span *ngIf="res['is_premium_tax'] == 0" class="f10 ml-2"> (Tax Excluded)</span>
                                                                </p>                                                                
                                                            </div>                                                                                                                                                                                   
                                                        </div>                                                     

                                                    </div>  
                                                    
                                                    <!-- others -->
                                                    <div class="col-sm-2" *ngFor="let image of res['insured_members']; let k = index;">                                                        
                                                        <div *ngIf="image['relation'] === 'Son' || image['relation'] === 'Daughter' || image['relation'] === 'Sister' || image['relation'] === 'Brother'" class="bgdiv divclass">
                                                            <div class="row" *ngIf="image['first_name'] != ''">                                                        
                                                                <div class="col-sm-4">
                                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />
                                                                    <div class="viewbtn" (click)="showNomineepopup(i, k)"><u>View/Edit</u></div>
                                                                </div>
                                                                <div class="col-sm-8 pad8">
                                                                    <div class="textclass">{{image['relation']}}</div>
                                                                    <div class="textclass">{{image['gender']}}</div> 
                                                                    <div class="text-center mb-0" *ngIf="image['member_premium'] != null && image['member_premium'] != 0">
                                                                        <div class="colorsidebar">&#8377; {{image['member_premium']}}</div>
                                                                        <div *ngIf="res['is_premium_tax'] == 1" class="f11">(Tax Included)</div>
                                                                        <div *ngIf="res['is_premium_tax'] == 0" class="f11">(Tax Excluded)</div>
                                                                    </div>
                                                                </div> 
                                                            </div>                                                                                                                                        
                                                        </div>                                                                                                        
                                                    </div>                                                                                                                                                                                                          

                                                </div>                                                                                             
                                            </div>
                                                                                                                                                              
                                        </div>
                                    </div>                                                                                                          
                                    
                                    <hr style="margin-top: 40px; margin-bottom: 20px;">    

                                    <!-- Topup Section -->
                                    <div *ngIf="res['is_topup'] == 1">
                                    
                                        <div class="row" style="margin: auto;" *ngFor="let topupdiv of res['topupArray']; let g = index; let lastItem = last">
                                            
                                            <h3 class="bs-title">{{topupdiv['policy_name']}}</h3>                                        
                                            
                                            <div class="col-sm-3 mt-3" *ngFor="let topup_radio of topupdiv['topup_radio']; let f = index;">
                                                <div class="p-3 topUp_card">
                                                    <div>
                                                        <input type="checkbox" class="topupradio toggle-checkbox" id="radioButton_{{f}}{{g}}" [checked]="topup_radio['topup_selected']" (click)="topup_section(f,g,topupdiv['policy_name'], topup_radio['grade_suminsured'], topupdiv['topup_master_policy_id'], topup_radio['policy_idfk'], topup_radio['id'], topupdiv['topup_setup'], res['insured_members'])">                                                    
                                                    </div>
                                                    <div class="row cardinfo">
                                                        <div class="col-sm-6">
                                                            <p>Sum Insured</p>
                                                            <p class="cardinfotext">{{topup_radio['grade_suminsured']}}</p>

                                                        </div>
                                                        <div class="col-sm-6">
                                                            <p>Premium</p>
                                                            <p class="cardinfotext">{{topup_radio['grade_premium']}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                            
                                            <hr class="my-3" *ngIf="!lastItem">

                                        </div>
                                
                                    </div>
                                </div>                                

                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <!-- <button (click)="next(2);" class="btn btn-primary" style="float: right;">Save & next</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                 -->
                                        </div>
                                    </div>
                                </div>

                            </form>                                                        
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                            <span class="numberheading">3</span>
                            Nominee Into Policies                             
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body accorCardbody">
                            <form [formGroup]="Nomineeinfo">
                                <!-- <h3 class="bs-title">Insured Member</h3>                                 -->
                                <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                                    <strong>Insured Member created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div>
                                <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                                    <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                                </div> -->
                                <!-- <hr> -->                              

                                <div class="row" *ngFor="let resnominee of RowArray_Step3; let id = index;">                                   

                                    <div>
                                        <button class="btn btn-primary downloadbtn_nominee" *ngIf="id == 0" (click)="downloadNomineeDetails()">Download Nominees Details Form</button>
                                    </div>

                                    <div class="col-sm-12 mb-3 py-2 headingbg" *ngIf="resnominee['nomination_applicable'] == 1  && resnominee['nomination_is_show_box'] == 1">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <span>
                                                    <b>Policy: {{resnominee['policy_name']}}</b>
                                                </span>
                                                <!-- <span *ngIf="resnominee['nominee_description'] != null && resnominee['nominee_description'] != ''">
                                                    <b>Policy: {{resnominee['nominee_description']}}</b>
                                                </span> -->
                                            </div>
                                        </div>                                        
                                    </div>
                                    
                                    <div class="col-sm-8 row" *ngIf="resnominee['nomination_applicable'] == 1 && resnominee['nomination_is_show_box'] == 1">
                                        
                                        <div class="col-sm-12 form-group" *ngIf="id > 0 && RowArray_Step3.length > 1">
                                            <div class="form-check" style="margin-top: 20px;">
                                                <input type="checkbox" class="form-check-input" (click)="checknominee($event, id)" id="nominee_same_{{id}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" [disabled]="gmc_same_as_above_flag" />
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Same As Above  policy Nominee details</label>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-12 row nomineDiv_{{id}}" style="display: contents;">                                                                                    

                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_relation_with_employee_{{id}}">Relation With Employee <span class="redstar">*</span></label>
                                                    <select class="form-select" id="nominee_relation_with_employee_{{id}}" (change)="getrelinfo(id, resnominee['policy_id'])">
                                                        <option value="0" selected>Select</option>
                                                        <option *ngFor="let image of resnominee['relation_data']; let j = index;" [value]="image.relation_id">{{image.relation}}</option>
                                                    </select>
                                                    <p class="error" id="nominee_relation_with_employee_error_{{id}}" style="display: none">
                                                        Please fill out this field
                                                    </p>
                                                </div>
                                            </div>                                           
                                                    
                                            <div class="col-sm-6" [ngClass]="id > 0 ? 'mt-3':''">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_first_name_{{id}}">First Name <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" id="nominee_first_name_{{id}}" placeholder="First Name" />                                            
                                                    <p class="error" id="nominee_first_name_error_{{id}}" style="display: none">
                                                        Please fill out this field
                                                    </p>
                                                </div>
                                            </div>
        
                                            <div class="col-sm-6 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_last_name_{{id}}">Last Name</label>
                                                    <input type="text" class="form-control" id="nominee_last_name_{{id}}" placeholder="Last Name"/>                                            
                                                </div>
                                            </div>                                                                              
        
                                            <div class="col-sm-6 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_dob_{{id}}">Date of Birth <span class="redstar">*</span></label>
                                                    <input type="date" class="form-control" [max]="dob_future" id="nominee_dob_{{id}}" placeholder="dd/mm/yyyy" (change)="checkDob_add($event, id)" />                                            
                                                    <p class="error" id="nominee_dob_error_{{id}}" style="display: none">
                                                        Please fill out this field
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <div class="col-sm-6 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_share_{{id}}">Share (%) <span class="redstar">*</span></label>
                                                    <input type="email" class="form-control" id="nominee_share_{{id}}" placeholder="Share (%)" maxlength="3" (keypress)="isNumber($event);" (keyup)="checkshare(id)" (keydown)="checkshare(id)" />                                            
                                                    <p class="error" id="nominee_share_error_{{id}}" style="display: none">
                                                        Please fill out this field
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <div class="col-sm-6 mt-3">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="nominee_email_{{id}}">Email <span class="redstar">*</span></label>
                                                    <input type="email" class="form-control" id="nominee_email_{{id}}" placeholder="Email" />                                            
                                                    <p class="error" id="nominee_email_error_{{id}}" style="display: none">
                                                        Please fill out this field
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 mt-3 form-group">
                                                <div class="form-check" style="margin-top: 20px;">
                                                    <input type="checkbox" class="form-check-input" id="send_email_{{id}}" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Send Email</label>
                                                </div>
                                            </div>

                                            <!-- Declaration -->                                       
                                            <div class="col-sm-12 mt-3 declaration_step3_{{id}}" style="display: none">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="step3_file_{{id}}">Upload Declaration Document</label>
                                                    <label for="step3_file_{{id}}" id="features-file-upload_step3_file_{{id}}" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data_{{id}}" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                                    </label>
                                                    <input id="step3_file_{{id}}" type="file" (change)="claim_doc($event, id, 'step3')" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                                                    <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .csv, .xls, .xlsx files</small>
                                                    <small id="step3_file_error_{{id}}" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                                    <small id="step3_file_download_sample_{{id}}" (click)="ondownload()" class="bs-form-label" style="font-size: 12px; text-decoration: underline; color: var(--bs-sidebar); float: right; cursor: pointer">Download Sample File</small>
                                                </div>
                                            </div>

                                            <!-- Guardian Details -->
                                            <div class="col-sm-12 guardian_div_add_{{id}}" style="display: none">                                                            
                                                <h3 class="bs-title mt-5">Guardian Details</h3>
                                                <hr>
                                                <div class="row">
                                                    
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="nominee_relation_with_employee_add_guardian_{{id}}">Relation With Employee <span class="redstar">*</span></label>
                                                            <select class="form-select" id="nominee_relation_with_employee_add_guardian_{{id}}">
                                                                <option value="0" selected>Select</option>
                                                                <option value="5">Father</option>
                                                                <option value="6">Mother</option>
                                                                <option value="9">Siblings</option>
                                                                <!-- <option *ngFor="let covertype of Relation" [value]="covertype.name">{{ covertype.name }}</option> -->
                                                            </select>  
                                                            <p class="error" id="nominee_relation_with_employee_add_guardian_error_{{id}}" style="display: none;">
                                                                Please fill out this field
                                                            </p>                                          
                                                        </div>
                                                    </div>                                                                                                  
                                                            
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="nominee_first_name_add_guardian_{{id}}">First Name <span class="redstar">*</span></label>
                                                            <input type="text" class="form-control" id="nominee_first_name_add_guardian_{{id}}" placeholder="First Name" />                                                                                        
                                                            <p class="error" id="nominee_first_name_add_guardian_error_{{id}}" style="display: none;">
                                                                Please fill out this field
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="nominee_last_name_add_guardian_{{id}}">Last Name</label>
                                                            <input type="text" class="form-control" id="nominee_last_name_add_guardian_{{id}}" placeholder="Last Name"/>                                            
                                                        </div>
                                                    </div>                                                                                                          

                                                    <div class="col-sm-6 mt-3">
                                                        <div class="form-group">
                                                            <label class="bs-form-label" for="nominee_dob_add_guardian_{{id}}">Date of Birth</label>
                                                            <input type="date" class="form-control" [max]="dob_future" id="nominee_dob_add_guardian_{{id}}" placeholder="dd/mm/yyyy" />                                                                                        
                                                            <p class="error" id="nominee_dob_add_guardian_error_{{id}}" style="display: none;">
                                                                Please fill out this field
                                                            </p>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>

                                            <div class="col-sm-6 mt-3">
                                                <div class="form-group">
                                                    <button (click)="addIsurerPolicy(id)" class="btn btn-primary" style="background-color: #F8934C; color: #fff; border: #F8934C; margin-top: 25px;">
                                                        <img src="../../../../../../assets/img/employee/Vector23.png" alt="img" style="margin-right: 5px; vertical-align: bottom;" />
                                                        Save Nominee Details
                                                    </button>
                                                </div>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    <!-- Add Member Div -->
                                    <div class="col-sm-4" style="padding: 0px;border: solid 2px #40189D;" *ngIf="resnominee['insured_members'].length > 0 && resnominee['nomination_applicable'] == 1">
                                        <div style="font-size: 14px; background-color: #D9D9D9; padding: 8px; font-weight: 600;">
                                            Nominees Enrolled Into Policy
                                        </div>
                                        <div class="row addmemberdiv">
                                            
                                            <div class="col-sm-12 row m-0 p-1">
                                                <div class="col-sm-6 p-2" *ngFor="let imagenominee of resnominee['insured_members']; let k = index;">
                                                    <div class="bgdiv divclass">
                                                        <div class="row">                                                        
                                                            <div class="col-sm-4">
                                                                <img src="{{imagenominee['image_url']}}" alt="img" class="imgclass" />
                                                                <div class="viewbtn" (click)="showNomineePolicypopupPolicy(id, k)"><u>View/Edit</u></div>
                                                            </div>
                                                            <div class="col-sm-8 pad8">
                                                                <div class="textclass">{{imagenominee['relation']}}</div>
                                                                <div class="textclass">{{imagenominee['gender']}}</div>                                                                 
                                                            </div> 
                                                        </div>                                                                                                                                        
                                                    </div>                                                
                                                </div>
                                            </div>

                                            <!-- <div class="col-sm-4 mt-2" *ngFor="let image of resnominee['insured_members']; let k = index;" style="display: flex" style="width: inherit; display: contents;">
                                                <div *ngIf="image['first_name'] != ''" class="divclass">
                                                    <img src="{{image['image_url']}}" alt="img" class="imgclass" />                                                                                                       
                                                    <p class="text-center mb-0">{{image['relation']}}</p>
                                                    <p class="text-center mb-0">{{image['gender']}}</p>
                                                    <p style="color: #FF0000; text-align: center; cursor: pointer;" (click)="showNomineePolicypopupPolicy(id, k)"><u>View/Edit</u></p>
                                                </div>                                                
                                            </div>                                           -->

                                        </div>
                                    </div>                                                                            
                                    
                                <hr *ngIf="resnominee['nomination_is_show_box'] == 1" style="margin-top: 40px; margin-bottom: 40px;">                                    
                                </div>

                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <!-- <button (click)="next(2);" class="btn btn-primary" style="float: right;">Save & next</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                 -->
                                        </div>
                                    </div>
                                </div>

                            </form>                                                        
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                            <span class="numberheading">4</span>
                            Enrollment Confirmation</button>                     
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                        <div class="card-body accorCardbody">
                            <form [formGroup]="enrollmentinfo">
                                                            
                                <div  *ngIf="installment_allow == 1 && is_payroll == 1 && stepfourdata[0]['installment_array'].length > 0">                                   
                                    <div class="row" style="margin: auto;" *ngFor="let stepfourdata of stepfourdata; let w = index;">                                                                              
                                        <div class="col-sm-3 mt-3" *ngFor="let insarray of stepfourdata.installment_array; let l = index;">
                                            <div class="p-3 topUp_card">
                                                <div>
                                                    <input type="checkbox" class="step4radio toggle-checkbox2" id="step4radio_{{w}}{{l}}" [checked]="stepfourdata['member_installment_id'] == insarray['id']" (click)="savestepfour(w, l, stepfourdata, insarray)">                                                    
                                                </div>
                                                <div class="cardinfo">                                            
                                                    <div class="textclass" style="margin-top: -20px;">{{insarray['installment']}} Installment<span *ngIf="l > 0">s</span></div>
                                                    <div class="textclass">Premium &#x20B9; {{insarray['installment_amount']}} / Installment<span *ngIf="l > 0">s</span></div>                                                
                                                </div>
                                            </div>
                                        </div>                                               
                                    </div>
                                    <div class="mt-3" *ngIf="stepfourdata[0]['member_installment_id'] != 0">
                                        I agree to pay <b>&#x20B9; {{stepfourdata[0]['employee_premium']}}</b> 
                                        <small *ngIf="stepfourdata[0]['is_premium_tax'] == 1">(Tax Included)</small> 
                                        <small *ngIf="stepfourdata[0]['is_premium_tax'] == 0">(Tax Excluded)</small> in 
                                        <b>{{selectedinscount}} installment<span *ngIf="selectedinscount > 1">s</span></b> from my salary.
                                    </div>                                    
                                    <hr>
                                </div>                                                                                                   

                                <div class="row">

                                    <div class="col-sm-6">
                                        
                                        <div>
                                            <label class="bs-form-label">Please verify Your Data for the following:</label>
                                        </div>

                                        <ul style="list-style: none;">
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Typographical errors</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Date of birth & Age Information</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Gender Information</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Relationship with Employee</b></li>
                                            <li class="my-2"><b> <img src="../../../../../../assets/img/employee/Vector.png" /> Selection in Core Benefits</b></li>
                                        </ul>                                      

                                    </div>                                        

                                    <div class="col-sm-6">
                                        <!-- <img src="../../../../../assets/img/employee/step4_bg.png" style="width: 250px; float: right; margin-bottom: 30px;" /> -->
                                    </div>

                                    <div class="col-sm-12">
                                        <div>
                                            <label class="bs-form-label">
                                                You cannot modify the enrollment information post enrollment period after 
                                                which the data submitted by you will not be editable andconsidered as final.
                                            </label>
                                        </div>
                                        <div>
                                            <label class="bs-form-label"><b>I confirm that the information provided above by me is correct</b></label>
                                            <div class="agreeradio my-2">
                                                <input type="checkbox" name="agree" formControlName="agree" id="agree" (click)="checkagree()" style="cursor: pointer; position: relative; top: 2px;" /> 
                                                <span style="margin-left: 3px;"><label class="bs-form-label"><b> I Agree</b></label></span>
                                            </div>
                                        </div>                                        
                                    </div>

                                    <div class="col-sm-12 nomination_mandatory_div" style="display: none">
                                        <p style="color:#FF0000">At least one Nominee required! </p>
                                    </div>
                                    
                                </div>                                    

                                <div class="row mt-3">
                                    <div class="col-sm-12">                                        
                                        <div>
                                            <button (click)="next(4);" class="btn btn-primary finishbtn" style="float: right;">Submit</button>
                                            <button (click)="previous()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Previous</button>                                
                                        </div>
                                    </div>
                                </div>

                            </form>                           
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Standard Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Standardmsgtoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            {{Validation_Error_message}}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Benefit Popup dynamic -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Benefitpopup}">
                <div class="modal-dialog" role="document" style="margin-top: -30px;">
                    <div class="modal-content">
                        <div class="modal-header p-2">
                            <h4 class="modal-title">Benefit Summary
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                                <button type="button" class="btn btn-primary downloadbtn" (click)="downloadAsPDF()">Download as PDF</button>
                            </h4>
                        </div>
                        <div class="modal-body p-0" #contentToConvert style="max-height: 400px; overflow-y: auto; overflow-x: hidden;">
                          
                            <div class="m-3 mb-4" style="box-shadow: 0px 0px 10px 1px; border-radius: 10px;" id="emptab_{{j}}" *ngFor="let group11 of Employeepoliciesbenefits; let j = index">
                                
                                <div class="row m-0">
                                    <div class="col-sm-6 p-2" style="background-color: var(--bs-sidebar); color:#fff; text-align: center; border-radius: 10px 0px 0px 0px;">
                                        <span>{{group11['name']}}</span>
                                    </div>
                                    <div class="col-sm-6 p-2" style="background-color: #F8934C; border-radius: 0px 10px 0px 0px; text-align: center; color: #fff">
                                        <span *ngIf="group11['is_hide_cover'] == 0">Sum Insured - {{group11['member_si']}}</span>
                                    </div>
                                    <div class="col-sm-6 p-2" style="background-color: #D9D9D9; border-radius: 0px 0px 0px 0px; text-align: center; font-size: 14px;">
                                        <b>Employee Premium : 253454</b>
                                    </div>
                                    <div class="col-sm-6 p-2" style="background-color: #D9D9D9; border-radius: 0px 0px 0px 0px; text-align: center; font-size: 14px">
                                        <b>Employer Premium : 2600456</b>
                                    </div>
                                </div>                                                                        

                                <h4 class="bs-title m-2 ml-1" style="color: var(--bs-sidebar); text-align: center;">Insured Member Details</h4>
                                <hr class="m-0 mb-2">

                                <div class="row m-0 p-2" *ngFor="let group2 of BenefittableArray; let k = index" style="font-size: 14px">                                   
                                    <div class="col-sm-6">
                                        <b>Member Name : 
                                            <span *ngIf="group2['first_name'] != ''">
                                                {{group2['first_name']}} {{group2['last_name']}}
                                            </span>
                                            <span *ngIf="group2['first_name'] == ''">-</span>
                                        </b>
                                    </div>
                                    <div class="col-sm-6">
                                        <b>Relation : 
                                            <span *ngIf="group2['relation'] != ''">
                                                {{group2['relation']}}
                                            </span>
                                            <span *ngIf="group2['relation'] == ''">-</span>
                                        </b>
                                    </div>
                                    <div class="col-sm-6">
                                        <b>Gender : M</b>
                                    </div>
                                    <div class="col-sm-6">
                                        <b>Email : abc#gmail.com</b>
                                    </div>                            
                                </div>                            
                            </div>                          
                                                       
                        </div>
                        <!-- <div class="modal-footer p-2">
                            <button (click)="closePopup()" class="btn btn-primary" style="background: #fff; border: solid 1px #2b2fa6; color: #2b2fa6;">Close</button>                                
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- Nominee Popup Step 2 -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Nomineepopup}">
                <div class="modal-dialog" role="document" style="margin-top: -30px;">
                    <div class="modal-content">
                        <div class="modal-header p-2">
                            <h4 class="modal-title">Insured Member Details
                                <span *ngIf="show_si_premium == '1'" style="font-size: 13px; margin-left: 140px;">
                                    Sum Insured : {{member_suminsured}}
                                    <span *ngIf="is_premium_tax_edit_popup == 1" class="f11">(Tax Included)</span>
                                    <span *ngIf="is_premium_tax_edit_popup == 0" class="f11">(Tax Excluded)</span>
                                </span>
                                <img class="mt-3" src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>                           
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                
                                    <div class="col-sm-6 relation_with_employee_edit">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="relation_with_employee_edit">Relation With Employee <span class="redstar">*</span></label>
                                            <!-- <select class="form-select" id="relation_with_employee_edit">
                                                <option value="" selected>Select</option>
                                                <option *ngFor="let covertype of Relation" [value]="covertype.name">{{ covertype.name }}</option>
                                            </select>                                             -->
                                            <input type="text" class="form-control" id="relation_with_employee_edit" />                                                                                        
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="gender">Gender <span class="redstar">*</span></label>
                                            <select class="form-select" id="gender_edit">
                                                <option value="" selected>Select</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                              
                                    <div class="col-sm-6" [ngClass]="{'mt-3' : fnameflag != 'Self'}">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="first_name">First Name <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" id="first_name_edit" placeholder="First Name" />                                                                                        
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="last_name">Last Name</label>
                                            <input type="text" class="form-control" id="last_name_edit" placeholder="Last Name"/>                                            
                                        </div>
                                    </div>                                    

                                    <div class="col-sm-6 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="insured_contact_number">Mobile No.</label>
                                            <input type="email" class="form-control" id="insured_contact_number_edit" placeholder="Contact No" maxlength="10" (keypress)="isNumber($event)" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="insured_email">Email</label>
                                            <input type="email" class="form-control" id="insured_email_edit" placeholder="Email" />                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="insured_dob">Date of Birth</label>
                                            <input type="date" class="form-control" [max]="dob_future" id="insured_dob_edit" placeholder="dd/mm/yyyy" readonly />                                                                                        
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mt-3 si_array_edit_div">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="si_array_edit">Sum Insured</label>
                                            <!-- <select class="form-select" id="si_array_edit">
                                                <option value="" selected>Select</option>
                                                <option *ngFor="let siarray_edit of si_array; let p = index;" [value]="siarray_edit">{{siarray_edit}}</option>                                                                                                       
                                            </select>  -->
                                            <input type="text" class="form-control" id="si_array_edit" readonly />                                                                                        
                                        </div>
                                    </div>

                                    <div class="col-sm-6 mt-3 marriage_date_edit" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="marriage_date_edit">Marriage Date</label>
                                            <input type="date" class="form-control" [max]="dob_future" id="marriage_date_edit" pattern="\d{2}/\d{2}/\d{4}" placeholder="dd/mm/yyyy" />                                                                                            
                                        </div>
                                    </div>

                                    <div class="col-sm-12 mt-3 marriage_certificate_file_edit" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="marriage_certificate_file_edit">Upload Marriage Certificate</label>
                                            <label for="marriage_certificate_file_edit" id="features-file-upload_marriage_certificate_file_edit" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                            </label>
                                            <input id="marriage_certificate_file_edit" type="file" (change)="claim_doc_edit($event, '', 'marriage')" accept=".png, .pdf, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                            <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .png, .pdf, .jpg, .jpeg files</small>
                                            <small id="marriage_certificate_file_error_edit" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                            <small id="marriage_certificate_file_error_view" class="bs-form-label" style="font-size: 12px; float: right;" *ngIf="marraigefile_url != ''"><a href="{{marraigefile_url}}" target="_blank">View File</a></small>
                                          </div>
                                    </div>

                                    <div class="col-sm-12 mt-3 birth_certificate_file_edit" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="birth_certificate_file_edit">Upload Birth Certificate</label>
                                            <label for="birth_certificate_file_edit" id="features-file-upload_birth_certificate_file_edit" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                            </label>
                                            <input id="birth_certificate_file_edit" type="file" (change)="claim_doc_edit($event, '', 'birth')" accept=".png, .pdf, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                            <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .png, .pdf, .jpg, .jpeg files</small>
                                            <small id="birth_certificate_file_error_edit" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                            <small id="marriage_certificate_file_error_view" class="bs-form-label" style="font-size: 12px; float: right;" *ngIf="birth_certificate_document_url != ''"><a href="{{birth_certificate_document_url}}" target="_blank">View File</a></small>
                                          </div>
                                    </div>

                                    <div class="col-sm-12 mt-3 death_certificate_file_edit" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="death_certificate_file_edit">Upload Death Certificate</label>
                                            <label for="death_certificate_file_edit" id="features-file-upload_death_certificate_file_edit" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                            </label>
                                            <input id="death_certificate_file_edit" type="file" (change)="claim_doc_edit($event, '', 'death')" accept=".png, .pdf, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                            <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .png, .pdf, .jpg, .jpeg files</small>
                                            <small id="death_certificate_file_error_edit" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                            <small id="death_certificate_file_error_view" class="bs-form-label" style="font-size: 12px; float: right; display: none"><a href="{{death_certificate_document_url}}" target="_blank">View File</a></small>
                                          </div>
                                    </div>

                                    <!-- Special Child -->
                                    <div class="col-sm-6 form-group is_special_child_div_edit" style="display: none">
                                        <div class="form-check" style="margin-top: 50px;">
                                            <input type="checkbox" class="form-check-input" id="is_special_child_edit" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Special Child</label>
                                        </div>
                                    </div>

                                    <!-- Unmarried Child -->
                                    <div class="col-sm-6 form-group is_unmarried_child_div_edit" style="display: none">
                                        <div class="form-check" style="margin-top: 50px;">
                                            <input type="checkbox" class="form-check-input" id="is_unmarried_child_edit" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Unmarried Child</label>
                                        </div>
                                    </div>

                                    <!-- Declaration -->
                                    <div class="col-sm-6 form-group is_adopted_div_edit" style="display: none">
                                        <div class="form-check" style="margin-top: 50px;">
                                            <input type="checkbox" class="form-check-input" id="is_adopted_child_edit" style="margin-left: 0px; margin-top: 0px; cursor: pointer;" (click)="is_adopted_child_edit(editID)" />
                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Adopted Child</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 mt-3 declaration_edit" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="step2_file_edit">Upload Declaration Document</label>
                                            <label for="step2_file_edit" id="features-file-upload_step2_file_edit" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data_edit" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                            </label>
                                            <input id="step2_file_edit" type="file" (change)="claim_doc_edit($event, editID, 'adopted')" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                                            <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .csv, .xls, .xlsx files</small>
                                            <small id="step2_file_error_edit" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                            <small id="step2_file_download_sample_edit" (click)="ondownload()" class="bs-form-label" style="font-size: 12px; text-decoration: underline; color: var(--bs-sidebar); float: right; cursor: pointer">Download Sample File</small>
                                            <small id="step2_file_download_sample_edit_view" class="bs-form-label" style="font-size: 12px; float: right; margin-right: 20px;" *ngIf="declaration_file_url != ''"><a href="{{declaration_file_url}}" target="_blank">View File</a></small>
                                          </div>
                                    </div>

                                    <input type="hidden" class="form-control" id="index_edit" />
                                    <input type="hidden" class="form-control" id="index_edit_child" />
                                    <input type="hidden" class="form-control" id="relation_id_edit" />
                                    <input type="hidden" class="form-control" id="emp_id_edit" />

                            </div>                            
                        </div>
                        <div class="modal-footer p-2" style="display: flex;">
                            <button (click)="deleteNominee()" class="btn btn-primary deltbn is_deleted" style="background: #F8934C; border: solid 1px #F8934C; color: #fff;">Delete Member</button>                                
                            <button (click)="updateNominee()" class="btn btn-primary savebtn" style="float: right;">Save & Update</button>                                
                            <button (click)="closePopup()" class="btn btn-primary" style="float: right;">Close</button>                                
                        </div>
                    </div>
                </div>
            </div>

            <!-- Nominee Policy Popup Step 3 -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':NomineePolicypopup}">
                <div class="modal-dialog2" role="document" style="margin-top: -30px;">
                    <div class="modal-content">
                        <div class="modal-header p-2">
                            <h4 class="modal-title">Nominee Details
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">

                            <!-- Form -->
                            <div class="row">
                                
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_relation_with_employee_edit">Relation With Employee <span class="redstar">*</span></label>
                                            <select class="form-select" id="nominee_relation_with_employee_edit">
                                                <option value="0" selected>Select</option>
                                                <option *ngFor="let rel of Relation_edit" [value]="rel.relation_id">{{ rel.relation }}</option>
                                            </select>                          
                                            <p class="error" id="nominee_relation_with_employee_edit_error" style="display: none;">
                                                Relation With Employee is required
                                            </p>                  
                                            <!-- <input type="text" class="form-control" id="nominee_relation_with_employee_edit" /> -->
                                        </div>
                                    </div>                                   
                                              
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_first_name_edit">First Name <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" id="nominee_first_name_edit" placeholder="First Name" />                                                                                        
                                            <p class="error" id="nominee_first_name_edit_error" style="display: none;">
                                                First Name is required
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_last_name_edit">Last Name</label>
                                            <input type="text" class="form-control" id="nominee_last_name_edit" placeholder="Last Name"/>                                            
                                        </div>
                                    </div>                                                                                                          

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_dob_edit">Date of Birth <span class="redstar">*</span></label>
                                            <input type="date" class="form-control" [max]="dob_future" id="nominee_dob_edit" placeholder="dd/mm/yyyy" />                                                                                        
                                            <p class="error" id="nominee_dob_edit_error" style="display: none;">
                                                Date of Birth is required
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_share_edit">Share* (%) <span class="redstar">*</span></label>
                                            <input type="email" class="form-control" id="nominee_share_edit" placeholder="Share (%)" maxlength="3" (keypress)="isNumber($event);" (keyup)="checkshare_edit()" (keydown)="checkshare_edit()" />                                            
                                            <p class="error" id="nominee_share_edit_error" style="display: none;">
                                                Share is required
                                            </p>                                           
                                        </div>
                                    </div> 

                                    <!-- Declaration -->
                                    <div class="col-sm-12 mt-3 declaration_edit_step3" style="display: none">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="step3_file_edit">Upload Declaration Document</label>
                                            <label for="step3_file_edit" id="features-file-upload_step3_file_edit" class=" form-control" style="text-align:center; cursor:pointer; padding: 8px 5px; background: #fff;">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data_edit" style="color:#0b2478; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop File here</span>
                                            </label>
                                            <input id="step3_file_edit" type="file" (change)="claim_doc_edit($event, editID_step3, 'step3')" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />
                                            <small style="font-size: 12px; color:#605D62; position: relative; top: -5px;">Upload only .csv, .xls, .xlsx files</small>
                                            <small id="step3_file_error_edit" class="bs-form-label" style="font-size: 12px; color: red; display: none; float: right;">Please Upload Document</small>
                                            <small id="step3_file_download_sample_edit" (click)="ondownload()" class="bs-form-label" style="font-size: 12px; text-decoration: underline; color: var(--bs-sidebar); float: right; cursor: pointer">Download Sample File</small>
                                            <small id="step3_file_download_sample_edit_view" class="bs-form-label" style="font-size: 12px; float: right; margin-right: 20px;" *ngIf="declaration_file_url_step3 != ''"><a href="{{declaration_file_url_step3}}" target="_blank">View File</a></small>
                                          </div>
                                    </div>

                                    <input type="hidden" class="form-control" id="nominee_index_edit" />
                                    <input type="hidden" class="form-control" id="nominee_index_edit_child" />
                                    <input type="hidden" class="form-control" id="nominee_relation_id_edit" />

                            </div>
                            
                            <!-- Guardian Details -->
                            <div class="guardian_div" style="display: none">                                                            
                                <h3 class="bs-title mt-5">Guardian Details</h3>
                                <hr>
                                <div class="row">
                                    
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_relation_with_employee_edit_guardian">Relation With Employee <span class="redstar">*</span></label>
                                            <select class="form-select" id="nominee_relation_with_employee_edit_guardian">
                                                <option value="0" selected>Select</option>
                                                <option value="5">Father</option>
                                                <option value="6">Mother</option>
                                                <option value="9">Siblings</option>
                                                <!-- <option *ngFor="let covertype of Relation" [value]="covertype.name">{{ covertype.name }}</option> -->
                                            </select>  
                                            <p class="error" id="nominee_relation_with_employee_edit_guardian_error" style="display: none;">
                                                Relation With Employee is required
                                            </p>                                          
                                        </div>
                                    </div>   
                                    
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-4"></div>
                                            
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_first_name_edit_guardian">First Name <span class="redstar">*</span></label>
                                            <input type="text" class="form-control" id="nominee_first_name_edit_guardian" placeholder="First Name" />                                                                                        
                                            <p class="error" id="nominee_first_name_edit_guardian_error" style="display: none;">
                                                First Name is required
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_last_name_edit_guardian">Last Name</label>
                                            <input type="text" class="form-control" id="nominee_last_name_edit_guardian" placeholder="Last Name"/>                                            
                                        </div>
                                    </div>                                                                                                          

                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="nominee_dob_edit_guardian">Date of Birth</label>
                                            <input type="date" class="form-control" [max]="dob_future" id="nominee_dob_edit_guardian" placeholder="dd/mm/yyyy" />                                                                                        
                                            <p class="error" id="nominee_dob_edit_guardian_error" style="display: none;">
                                                Date of Birth is required
                                            </p>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer p-2" style="display: flex;">
                            <button (click)="deleteNomineePolicy()" class="btn btn-primary" style="background: #F8934C; border: solid 1px #F8934C; color: #fff;">Delete Member</button>                                
                            <button (click)="updateNomineePolicy()" class="btn btn-primary" style="float: right;">Save & Update</button>                                
                            <button (click)="closePopup()" class="btn btn-primary" style="float: right;">Close</button>                                
                        </div>
                    </div>
                </div>
            </div>

            <!-- Save Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Savetoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Success!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/check.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Data Saved Sucessfully ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Delete Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Deletetoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Success!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/check.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Member Deleted Sucessfully ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Update Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Updatetoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Success!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/check.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Member Updated Sucessfully ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Load Date Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Datetoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 360px;">
                    <div class="modal-content" style="background-image: url('../../../../../../assets/img/employee/popupbg.png'); background-size: cover; color: #fff; border-radius: 10px 10px 0px 0px; border:none">                        
                        <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn" />
                        <div class="modal-body" style="height: auto; max-height: 400px; overflow-x: hidden; overflow-y: auto; background-color: #40189D; opacity: 0.8; border-radius: 10px 10px 0px 0px; padding-top: 50px;">                                                                                    
                            <div style="text-align: center; font-size: 14px;" *ngFor="let res of popuarr; let k = index"> 
                                <p>{{res['name']}} : <b>{{res['policy_number']}}</b></p>
                                <p>Enrolment window is open from</p>
                                <p><b>{{res['enrollement_start_date']}} to {{res['enrollement_end_date']}}</b></p>                                
                                <img src="../../../../../../assets/img/employee/Line 34.png" class="lineimg" *ngIf="k < popuarr.length - 1" />
                            </div>                                                        
                        </div>
                        <div class="modal-footer" style="background-color: #40189D; opacity: 0.8; border-radius: 0px; border-top: 1px dashed #fff;">
                            <button type="button" class="btn btn-danger" (click)="closePopup()" style="margin: auto; display: block; background: #fff; color:#FF0000; font-size: 12px; padding: 8px 30px"> OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dialoge Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':dialogePopup}">
                <div class="modal-dialog" role="document" style="max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Alert
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            Do you want to confirm, no changes will be allowed after confiramation.
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" style="background: #F8934C; border: solid 1px #F8934C; color: #fff;" (click)="closePopup()"> No</button>
                            <button type="button" class="btn btn-primary" (click)="submitstep4()" style="padding: 8px 15px;"> Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Max Limit Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':MaxLimittoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Reached Max Limit ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':addErrorPopup}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Member not added, Some error occured ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Global Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Errortoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> {{ErrortoggleMsg}} ! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Desc Error Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Descriptiontoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Description
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">                            
                            <p> {{DescriptionMsg}} </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Topup Section Popup -->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':TopUpSectiontoggle}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 800px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Select Your Policy & Family Details
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">  

                            <div class="row text-center" style="font-size: 16px;">
                                <div class="col-sm-6">
                                    <div class="cardinfotext text-black">{{topup_Sum_Insured_lable}}</div>
                                </div>
                                <div class="col-sm-6">                                    
                                    <div class="cardinfotext text-black">Group Mediclaim Policy - {{topup_Sum_Insured_value}}</div>
                                </div>                                                                                            
                            </div>

                            <hr>

                            <div class="row">

                                <div class="col-sm-2 mt-3" *ngFor="let popupArray of topup_popupArray; let w = index">
                                    <img src="{{popupArray['image_url']}}" alt="img" class="imgclass" />
                                    <div class="textclass">{{popupArray['relation']}}</div>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer p-2" style="display: flex;">
                            <button class="btn btn-primary savebtn" style="float: right;" (click)="submittopup_Popup()">Submit</button>                                
                            <button (click)="closePopup()" class="btn btn-primary" style="float: right;">Close</button>                                
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dialoge Popup Nominee same as above-->
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':dialogePopup_nominee}">
                <div class="modal-dialog" role="document" style="max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to select this option?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup_nominee(nomineesame)"> No</button>
                            <button type="button" class="btn btn-primary" (click)="checknominee_dailogue(nomineesame)" style="padding: 8px 15px;"> Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lockin Popup -->
             <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':Lockinpopup}">
                <div class="modal-dialog" role="document" style="margin-top: 10px; max-width: 500px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Message!
                                <img src="../../../../../../assets/img/employee/close.png" (click)="closePopup()" class="closebtn_all" />
                            </h4>
                        </div>
                        <div class="modal-body">
                            <div align="center" class="mb-2">
                                <img src="../../../../../../assets/img/common icons/warning.png" alt="img" style="width: 100px;" />
                            </div>
                            <h4 style="text-align: center;"> Policy Is Lock-In Mode, You Cannot Add Member information! </h4>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <app-footer></app-footer>
    </div>
</div>
