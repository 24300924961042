import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface DropdownItem {
  id: string;
  name: string; // Assuming the dropdown items have 'id' and 'name' properties
}

@Component({
  selector: 'app-policieslisting',
  templateUrl: './policieslisting.component.html',
  styleUrls: ['./policieslisting.component.css']
})
export class PolicieslistingComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  addflag: any = 0;
  show = false;
  fullScreen = true;
  template = ``;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');


  InfoArray: any = [];
  public InfoArraydropdown: DropdownItem[] = [];
  public selectedClientName: string | null = null;

  displayStyle_add = "none";
  displayStyle_edit = "none";
  displayStyleDelete = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'List Policies';

  form: FormGroup;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  clientNameControl!: FormControl;

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
      this.form = this.fb.group({
        client_name: [null]
      });
    });
  }

  ngOnInit(): void {
    this.initGroup();
    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 1000);
    this.clientNameControl = this.form.get('client_name') as FormControl;
  }

  initGroup(): void {
    this.InfoArray = [];
    const savedClientId = localStorage.getItem('listpolicies_company_id_listing');
    this.getInfodropdown(savedClientId);
    this.assignrights();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  getInfo(val: any): void {
    this.InfoArray = [];
    let postdata: any;

    if (val === '') {
      postdata = {
        tokenable_id: localStorage.getItem('tokenable_id'),
        employer_id: '0',
        broker_id: localStorage.getItem('broker_id')
      };
      localStorage.setItem('listpolicies_company_id_listing', '0');
    } else {
      postdata = {
        tokenable_id: localStorage.getItem('tokenable_id'),
        employer_id: val.id,
        broker_id: localStorage.getItem('broker_id')
      };
      localStorage.setItem('listpolicies_company_id_listing', val.id);
    }

    this.dataservice.getInfo(postdata).subscribe((result: any) => {
      this.show = false;
      this.InfoArray = result.data;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
        this.dtTrigger.next();
        setTimeout(() => {
          $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
        }, 1000);
      });
    }, (error) => {
      this.show = false;
      this.InfoArray = [];
      if (error.error.message === 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/login']);
      }
    });
  }

  getInfodropdown(savedClientId: string | null): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    const postdata = {
      tokenable_id: localStorage.getItem('tokenable_id'),
      broker_id: localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata).subscribe((result: any) => {
      this.show = false;
      this.InfoArraydropdown = result.data;
      if (this.InfoArraydropdown.length > 0 && savedClientId) {
        const savedClient = this.InfoArraydropdown.find(client => client.id.toString() === savedClientId);
        if (this.InfoArraydropdown.length > 0 && savedClientId) {
          const savedClient = this.InfoArraydropdown.find(client => client.id.toString() === savedClientId);
          if (savedClient) {
            this.form.get('client_name')?.setValue(savedClient);
            this.selectedClientName = savedClient.id; // Assign the ID of the saved client
            this.getInfo(savedClient);
          }
        }
        
      }
    }, (error) => {
      this.show = false;
      this.InfoArraydropdown = [];
      if (error.error.message === 'Unauthenticated.') {
        localStorage.clear();
        localStorage.setItem('resstatus', 'false');
        this.route.navigate(['/login']);
      }
    });
  }

  onClientChange(event: any): void {
    this.selectedClientName = event;
    this.getInfo(event);
    console.log('Selected client:', this.selectedClientName);
    window.location.reload();
  }

  fn_deactivatepolicy(id: any): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    const postdata = {
      id: id,
      tokenable_id: localStorage.getItem('tokenable_id'),
      is_active: 0
    };

    this.dataservice.activedeactive(postdata).subscribe((result: any) => {
      this.show = false;
      this.toastService.showSuccess('Success', 'Policy Deactivated Successfully!');

      setTimeout(()=>{
        window.location.reload();
      },3000);

      this.dtTrigger.next();
    }, (error) => {
      this.show = false;
      console.log(error);
      this.InfoArray = [];
    });
  }

  fn_activatepolicy(id: any): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    const postdata = {
      id: id,
      tokenable_id: localStorage.getItem('tokenable_id'),
      is_active: 1
    };

    this.dataservice.activedeactive(postdata).subscribe((result: any) => {
      this.show = false;
      this.toastService.showSuccess('Success', 'Policy Activated Successfully!');
      setTimeout(()=>{
        window.location.reload();
      },3000);
      this.dtTrigger.next();
    }, (error) => {
      this.show = false;
      console.log(error);
      this.InfoArray = [];
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  editpolicy(group: any): void {
    localStorage.setItem('master_policies_id', group.id);
    this.route.navigate(['policy-edit']);
  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }

  }
  