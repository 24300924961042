import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './Service';
import {Subject, from} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from '../../toast.service';

interface Wellness {
  id: number;
  wellness_name: string;
  is_active: number;
}

export function noLeadingWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const hasLeadingWhitespace = (control.value || '').startsWith(' ');
    return hasLeadingWhitespace ? { 'leadingWhitespace': true } : null;
  };
}

@Component({
  selector: 'app-wellness-benefit',
  templateUrl: './wellness-benefit.component.html',
  styleUrls: ['./wellness-benefit.component.css']
})
export class WellnessBenefitComponent implements OnInit {

  resstatus = localStorage.getItem('resstatus');

  validmsg:any;
  template = ``;
  show = false;
  fullScreen = true;

  public basicInfo:FormGroup;
  basicInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  
  public DeactivateInfo:FormGroup;
  public ActivateInfo:FormGroup;
  public DeleteInfo:FormGroup;
  
  InfoArray: any = [];   
  InfoArrayWellness: Wellness[] = [];

  document_file: any;
  downloadbuttontooltip:any;
  samplefile: any;
  Policyuploaddoclist:any = [];
  addflag: any = 0;
  imgpath: any;

  displayStyleDeactivate = "none";
  displayStyleActivate = "none";
  displayStyleDelete = "none";

  readaccess   : any = 0;
  writeaccess  : any = 0;
  deleteaccess :  any = 0;
  modulename = 'Wellness Benefit';

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private service:Service,
    private fb: FormBuilder,
    private route: Router,
    private toastService: ToastService
  ) {
    this.route.events.subscribe((e) => {
      if (this.resstatus == 'false') {
          this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {
    this.initGroup();
    // $('#defaultOpen')[0].click()

    setTimeout(() => {
      $('.dataTables_wrapper .dataTables_filter input').attr('placeholder', 'Search...');
    }, 2000);


  }

  initGroup(){

    this.getInfo();
    this.getWellness();
    this.assignrights();
    $(window).scrollTop(0);

    this.basicInfo = this.fb.group({
      wellness_partner: new FormControl('', Validators.required),
      url: ['', [Validators.required, Validators.pattern('https?://[\\w.-]+\\.(com|in)$')]],
      benefit_name: ['', [Validators.required, noLeadingWhitespaceValidator()]],
      benefit_description: new FormControl(''),

    });

    this.EditInfo = this.fb.group({      
      wellness_partner_edit: new FormControl('', Validators.required),
      benefit_url: ['', [Validators.required, Validators.pattern('https?://.*')]],
      benefit_name_edit: ['', [Validators.required, noLeadingWhitespaceValidator()]],
      benefit_description_edit: new FormControl(''),
      edit_id: new FormControl(''),
      edit_is_active: new FormControl(''),
    });

    // Form element defined below
    this.DeactivateInfo = this.fb.group({
      delete_id: new FormControl('')      
    });

    // Form element defined below
    this.ActivateInfo = this.fb.group({
      active_delete_id: new FormControl('')      
    });

    // Form element defined below
    this.DeleteInfo = this.fb.group({
      delete_id: new FormControl('')      
    });
  }

  get getBasicInformationFormControls() {
    return this.basicInfo.controls;
  }

  getsamplefile(){


    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "master_policies_id":this.basicInfo.controls['policy_number'].value,
      "sub_type_id":this.basicInfo.controls['policy_type'].value,
    }
    this.service.getsamplefile(postdata)
      .subscribe((result) => {
        if(result.status){
          $("#download_sample_file").css("display", "block");
          this.samplefile=result.data['policy_member_sample'];
        }else{
          $("#download_sample_file").css("display", "none");
        }
      }, (error) => {
          console.log(error);
    });

  }

  openCity(evt:any, cityName:any) {
    console.log(evt);
    console.log(cityName);

    var i, tabcontent, tablinks;
    tabcontent = $(".tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    $("#"+cityName).css("display", "block")
    evt.currentTarget.className += " active";

  }

  ondownload(val: any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if(val == "policyfeatures"){
      link.setAttribute('href', this.samplefile);
      link.setAttribute('download', 'Sample_Policy_Member_Data.xls');
    }

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadfile(event: any){
    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');


    }
  }

  onSubmitAdd(){

    $('#submitbutton').prop('disabled', true);

    setTimeout(() => {
      $('#submitbutton').prop('disabled', false);
    }, 4000);

    this.basicInfoSubmitted = true;
    if (this.basicInfoSubmitted && this.basicInfo.valid) {
     
       var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id":localStorage.getItem('broker_id'),
        "benefit_name":this.basicInfo.controls['benefit_name'].value,
        "benefit_url":this.basicInfo.controls['url'].value,
        "partner_id":this.basicInfo.controls['wellness_partner'].value,
        "benefit_description":this.basicInfo.controls['benefit_description'].value,
      };
      console.log(postdata);

      this.service.uploadsamplefile(postdata)
        .subscribe((result) => {
          if(result.status){
            this.show = false;            

            this.toastService.showSuccess('Success', 'Details Saved Successfully!');

            setTimeout(() => {
              window.location.reload();
            }, 3000);
            
          }
        }, (error) => {
            // console.log(error);
            this.toastService.showFailed('Failed', 'There was some error');

      });
      
    }    

  }

  closePopup() {    
    this.displayStyleDeactivate = "none";
    this.displayStyleActivate = "none";  
    this.displayStyleDelete = "none";  
  }

  getInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.service.getInfo(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArray = result.data; 
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  getWellness(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
    }

    this.service.getWellness(postdata)
        .subscribe((result: any) => {          
          
          this.show = false;

          // console.log(result);
          this.InfoArrayWellness = result.data.filter((wellness: Wellness) => wellness.is_active === 1);
          this.dtTrigger.next();

        }, (error) => {          
          
          this.InfoArrayWellness = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/home']);
          }
          
      });
  }

  // getPolicyuploaddoclist(){    
  //   var postdata = {
  //     "tokenable_id":localStorage.getItem('tokenable_id'),
  //     "broker_id":localStorage.getItem('broker_id'),
  //     "company_id":this.basicInfo.controls['company_id'].value,
  //   }
  //   this.service.getPolicyuploaddoclist(postdata)
  //     .subscribe((result) => {

  //       this.Policyuploaddoclist = result.data;

  //       this.dtTrigger.next();
  //     }, (error) => {
  //         // console.log(result);
  //         this.Policyuploaddoclist = [];
  //   });
  // }

  ondownload_doc(val:any){
    window.open(val,'_blank');
  }

  ondownload_doc_failed(id: any){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "uploadid":id,
    }
    this.service.downloadfailedDcoument(postdata)
      .subscribe((result) => {

        // console.log(result);

        window.open(result.data.error_filename,'_blank');

        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');

        // link.setAttribute('href', result.error_filename);
        // link.setAttribute('download', 'Document.xls');

        // document.body.appendChild(link);
        // link.click();
        // link.remove();

      }, (error) => {
          // console.log(result);
    });    

  }

  closePopupAdd(){
    // this.addflag = 0;      
    this.show = true;

    setTimeout(()=>{
      window.location.reload();    
    },3000);
  }

  // ngAfterViewInit(): void {
  //   this.dtTrigger.next();
  // } 

  // ngOnDestroy(): void {
  //   this.dtTrigger.unsubscribe();
  // }

  //Edit
  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  openPopupEdit(res: any){          

    $(window).scrollTop(0);

    this.addflag = 1;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['username']);

        this.EditInfo.controls['wellness_partner_edit'].setValue(result.data[0]['partner_id']);
        this.EditInfo.controls['benefit_name_edit'].setValue(result.data[0]['benefit_name']);
        this.EditInfo.controls['benefit_url'].setValue(result.data[0]['benefit_url']);
        this.EditInfo.controls['benefit_description_edit'].setValue(result.data[0]['benefit_description']);
        this.EditInfo.controls['edit_id'].setValue(result.data[0]['id']);        

      }, (error) => {          
               
    });

  }

  openPopupActivate(res: any) {
    this.displayStyleActivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.ActivateInfo.controls['active_delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    // console.log(id);
   
    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "broker_id":localStorage.getItem('broker_id'),      
        "partner_id":this.EditInfo.controls['wellness_partner_edit'].value,
        "benefit_name":this.EditInfo.controls['benefit_name_edit'].value,
        "benefit_url":this.EditInfo.controls['benefit_url'].value,
        "benefit_description":this.EditInfo.controls['benefit_description_edit'].value,
      };

      console.log(postdata);

      this.show = false;

      this.service.InfoUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();

            this.toastService.showSuccess('Success', 'Details Updated Successfully!');

          
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }else {
              this.show = false;
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'none');  
          // $('#errormsg').css('display', 'block'); 
          this.toastService.showFailed('Failed', 'There was some error');
       
      });

    }

  }


  onSubmitActivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#active_delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 1
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Activated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDeactivate(res: any) {
    this.displayStyleDeactivate = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeactivateInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDeactivate(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id,
      "is_active": 0
    };

    this.service.InfoActiveDeactivate(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          this.toastService.showSuccess('Success', 'Details Deactivated Successfully!');

          // $('#validmsg').css('display', 'block');
          // $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {  
        this.show = false;  
        this.closePopup();
        this.validmsg = error.error.message;      
        // $('#validmsg').css('display', 'none');  
        // $('#errormsg').css('display', 'block');   
        this.toastService.showFailed('Failed', 'There was some error');
     
    });

  }

  openPopupDelete(res: any) {

    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.id
    };

    this.service.InfoSingle(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.DeleteInfo.controls['delete_id'].setValue(result.data[0]['id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": this.DeleteInfo.controls['delete_id'].value,
      "broker_id":localStorage.getItem('broker_id'),
    };

    this.service.InfoDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          this.toastService.showSuccess('Success', 'Details Deleted Successfully!');

          
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          this.closePopup();       
          this.getInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.toastService.showFailed('Failed', 'There was some error');
      
    });

  }

  assignrights(){
    let module_info_arr:any = localStorage.getItem("module_info");
    let module_info = JSON.parse(module_info_arr);
    for(var i=0;i<module_info.length;i++){
      if(module_info[i]['child'].length==0){
        if(module_info[i]['module_content']==this.modulename){
          this.writeaccess = module_info[i]['module_write'];
          this.readaccess = module_info[i]['module_read'];
          this.deleteaccess = module_info[i]['module_delete'];
        }
      }else{
        for(var j=0;j<module_info[i]['child'].length;j++){
          let json = module_info[i]['child'][j];
          if(json['module_content']==this.modulename){
            this.writeaccess = json['module_write'];
            this.readaccess = json['module_read'];
            this.deleteaccess = json['module_delete'];
          }
        }
      }
    }
  }
  
}
