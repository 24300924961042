<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
                style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

            <h3 class="bs-title" *ngIf="addflag == 0">Policy <span class="spanbs-title">Listing</span>
                <!-- <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button> -->
            </h3>
            <!-- <h3 class="bs-title" *ngIf="addflag == 1">Add <span class="spanbs-title">Employer User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3>
            <h3 class="bs-title" *ngIf="addflag == 2">Edit <span class="spanbs-title">Employer User</span>
                <button type="button" class="btn btn-primary" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
            </h3> -->

            <hr class="mb-2 mt-4" style="height: 0px">

            <!-- Filters -->
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client</label>
                        <!-- <ng-select formControlName="client_name" id="client_name"  (change)="getInfo($event)"
                            [items]="InfoArraydropdown"
                            bindLabel="name"
                            bindValue="id"
                            [placeholder]="'Select Client Name'" >
                         </ng-select> -->
                        <ng-select [items]="InfoArraydropdown" bindLabel="name" bindValue="id"
                            [(ngModel)]="selectedClientName" [formControl]="clientNameControl" [placeholder]="'Select Client'"
                            (change)="onClientChange($event)">
                        </ng-select>

                    </div>
                </div>
            </div>

            <!-- Listing -->
            <div class="table-responsive mt-3" *ngIf="addflag == 0">
                <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="width:5%; position: sticky; left: 0; background-color: #efefef; z-index: 10;">
                                SrNo</th>
                            <th style="position: sticky; left: 5%; background-color: #efefef; z-index: 10;">Policy Number
                            </th>
                            <th>Policy Name</th>
                            <!-- <th>Client Name </th> -->
                            <th>Policy Type</th>
                            <th>Policy Sub Type</th>
                            <th>TPA </th>
                            <th>Insurer</th>
                            <th>Policy Start Date</th>
                            <th>Policy End Date</th>
                            <th>Policy Status</th>
                            <th style="text-align: center;" *ngIf="writeaccess == 1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%; position: sticky; left: 0; background-color: #fff; z-index: 10;">
                                {{i+1}}</td>
                            <td style="position: sticky; left: 5%; background-color: #fff; z-index: 10;">
                                {{group['policy_number']}}</td>
                            <td>{{group['policy_name']}}</td>
                            <!-- <td>{{group['client_name']}}</td> -->
                            <td>{{group['policy_type']}}</td>
                            <td>{{group['policy_sub_type']}}</td>
                            <td>{{group['tpa_name']}}</td>
                            <td>{{group['insurer_name']}}</td>
                            <td>{{group['policy_start_date']}}</td>
                            <td>{{group['policy_end_date']}}</td>
                            <td *ngIf="group['is_active']==1"><span _ngcontent-igh-c148=""
                                    class="btn btn-inverse-success btn-fw">Active</span></td>
                            <td *ngIf="group['is_active']==0"><span _ngcontent-igh-c148=""
                                    class="btn btn-inverse-danger btn-fw">Deactive</span></td>
                            <td style="width: 15%" align="center" *ngIf="writeaccess == 1">
                                <!-- <label id="edituser" (click)="editpolicy(group)" title="Edit">
                                
                            </label>                             -->
                                <img src="../../../assets/img/common icons/edit_new.png" id="edituser"
                                    (click)="editpolicy(group)" role="button" title="Edit" />
                                <img src="../../../assets/img/selectproduct/deactive.png" *ngIf="group['is_active']==1"
                                    (click)="fn_deactivatepolicy(group['id'])" id="deactive" class="ml-4" role="button"
                                    title="Deactive" />
                                <img src="../../../assets/img/selectproduct/active.png" *ngIf="group['is_active']==0"
                                    (click)="fn_activatepolicy(group['id'])" id="active" class="ml-4" role="button"
                                    title="Active" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>