<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">
          <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

          <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
              <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
          </div>
          <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
              <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
          </div>
          <h3 class="bs-title">Endorsement<span class="spanbs-title"> Request</span></h3>
          <hr class="mb-2 mt-4" style="height: 0px">

          <!-- Add -->
          <div class="row">

              <!-- Filters -->
              <form [formGroup]="basicInfo">

                <div class="row">

                  <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="company_id">Client <span class="redstar">*</span></label>
                        <ng-select formControlName="company_id" id="company_id" (change)="getpolicytype()" 
                        [items]="companyDetails"
                        bindLabel="name"
                        bindValue="id"
                        [placeholder]="'Select Client'">
                       </ng-select>
                       
                        <small class="error company_id_error" style="display: none;">Client is required</small>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_type">Policy Type <span class="redstar">*</span></label>
                        <select class="form-select" formControlName="policy_type" id="policy_type" (change)="getpolicynumbers()">
                            <option value="" selected>Select Policy Type</option>
                            <option *ngFor="let type of policy_type" [value]="type.sub_type_id">{{ type.name }}</option>
                        </select>
                        <small class="error policy_type_error" style="display: none;">Policy Type is required</small>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="policy_number">Policy Number <span class="redstar">*</span></label>
                        <select class="form-select" formControlName="policy_number" id="policy_number" (change)="getsamplefile()">
                            <option value="" selected>Select Policy Type</option>
                            <option *ngFor="let type of policynumbers" [value]="type.id">{{ type.policy_name }} / {{ type.policy_number }}</option>
                        </select>
                        <small class="error policy_number_error" style="display: none;">Policy Number is required</small>
                    </div>
                  </div>

                </div>

              </form>

              <!-- Tabs -->
              <div class="col-sm-12 mt-4">

                <!-- Tabs Headings -->
                <ul class="nav nav-tabs" id="myTab" role="tablist" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 10px 10px 0px 0px;">
                  <li class="nav-item liwidth" role="presentation">
                      <button class="nav-link w100" id="Add-tab" data-bs-toggle="tab" data-bs-target="#Add" type="button"
                          role="tab" aria-controls="home" aria-selected="true">Add</button>
                  </li>
                  <li class="nav-item liwidth" role="presentation">
                      <button class="nav-link active w100" id="Remove-tab" data-bs-toggle="tab" data-bs-target="#Remove" type="button"
                          role="tab" aria-controls="Remove" aria-selected="false">Deletion</button>
                  </li>
                  <li class="nav-item liwidth" role="presentation">
                      <button class="nav-link w100" id="Corrections-tab" data-bs-toggle="tab" data-bs-target="#Corrections" type="button"
                          role="tab" aria-controls="Corrections" aria-selected="false">Corrections</button>
                  </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tab-content" id="myTabContent" style="box-shadow: 0px 0px 10px 1px #0000004f; border-radius: 0px 0px 10px 10px;padding: 10px 20px;">

                  <!-- Tab Add -->
                  <div class="tab-pane fade" id="Add" role="tabpanel" aria-labelledby="Add-tab">
                    <form [formGroup]="addInfo">

                      <div class="row">

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="endoresement_name_add">Endorsement Name <span class="redstar">*</span></label>
                                <input class="form-control" formControlName="endoresement_name_add" id="endoresement_name_add" placeholder="Endorsement Name"
                                [ngClass]="{'control-red': addInfoSubmitted && getaddInformationFormControls.endoresement_name_add.errors}"
                                required />
                                <ng-container
                                *ngIf="addInfoSubmitted && getaddInformationFormControls.endoresement_name_add.errors">
                                <p class="error"
                                *ngIf="addInfoSubmitted && getaddInformationFormControls.endoresement_name_add.errors.required">
                                Endorsement Name is required
                                </p>
                              </ng-container>
                            </div>
                          </div>

                        </div>

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label class="bs-form-label" for="document_file_add">Attached Sample File</label>
                              <label for="document_file_add" id="features-file-upload_document_file_add" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                              </label>
                              <input id="document_file_add" type="file" (change)="uploadfile_add($event)" formControlName="document_file_add" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                            </div>
                            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file_add">Upload only .xlsx, .xls, .csv files</small>
                          </div>

                          <div class="col-sm-3" id="download_sample_file_add" style="text-align: center;margin-top:13px;display:none">
                              <div class="form-group mt-3">
                                  <label class="bs-form-label" for=""></label>
                                  <button (click)="ondownload('add')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                              </div>
                          </div>

                        </div>

                      </div>

                      <div class="col-sm-12" align="center">
                        <button type="button" class="btn btn-primary mt-3" (click)="onSubmitadd()" role="button">Save</button>
                      </div>

                    </form>
                  </div>

                  <!-- Tab Remove -->
                  <div class="tab-pane fade show active" id="Remove" role="tabpanel" aria-labelledby="Remove-tab">

                    <form [formGroup]="removeInfo">

                      <div class="row">

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="endoresement_name">Endorsement Name <span class="redstar">*</span></label>
                                <input class="form-control" formControlName="endoresement_name" id="endoresement_name" placeholder="Endorsement Name"
                                [ngClass]="{'control-red': removeInfoSubmitted && getremoveInformationFormControls.endoresement_name.errors}"
                                required />
                                <ng-container
                                *ngIf="removeInfoSubmitted && getremoveInformationFormControls.endoresement_name.errors">
                                <p class="error"
                                *ngIf="removeInfoSubmitted && getremoveInformationFormControls.endoresement_name.errors.required">
                                Endorsement Name is required
                                </p>
                              </ng-container>
                            </div>
                          </div>

                        </div>

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label class="bs-form-label" for="document_file">Attached Sample File</label>
                              <label for="document_file" id="features-file-upload_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                              </label>
                              <input id="document_file" type="file" (change)="uploadfile($event)" formControlName="document_file" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                            </div>
                            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file">Upload only .xlsx, .xls, .csv files</small>
                          </div>

                          <div class="col-sm-3" id="download_sample_file" style="text-align: center;margin-top:13px;display:none">
                              <div class="form-group mt-3">
                                  <label class="bs-form-label" for=""></label>
                                  <button (click)="ondownload('remove')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                              </div>
                          </div>

                        </div>

                      </div>

                      <div class="col-sm-12" align="center">
                        <button type="button" class="btn btn-primary mt-3" (click)="onSubmitRemove()" role="button">Save</button>
                      </div>

                    </form>

                  </div>

                  <!-- Tab Corrections -->
                  <div class="tab-pane fade" id="Corrections" role="tabpanel" aria-labelledby="Corrections-tab">

                    <form [formGroup]="correctionInfo">

                      <div class="row">

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="endoresement_name_correction">Endorsement Name <span class="redstar">*</span></label>
                                <input class="form-control" formControlName="endoresement_name_correction" id="endoresement_name_correction" placeholder="Endorsement Name"
                                [ngClass]="{'control-red': correctionInfoSubmitted && getcorrectionInformationFormControls.endoresement_name_correction.errors}"
                                required />
                                <ng-container
                                *ngIf="correctionInfoSubmitted && getcorrectionInformationFormControls.endoresement_name_correction.errors">
                                <p class="error"
                                *ngIf="correctionInfoSubmitted && getcorrectionInformationFormControls.endoresement_name_correction.errors.required">
                                Endorsement Name is required
                                </p>
                              </ng-container>
                            </div>
                          </div>

                        </div>

                        <div class="row mt-2">

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label class="bs-form-label" for="document_file_correction">Attached Sample File</label>
                              <label for="document_file_correction" id="features-file-upload_document_file_correction" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px">
                                  <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                              </label>
                              <input id="document_file_correction" type="file" (change)="uploadfile_corrections($event)" formControlName="document_file_correction" accept=".xlsx, .xls, .csv" enctype="multipart/form-data" style="display: none" />
                            </div>
                            <small style="font-size: 12px; color:#605D62" id="policyfeatures_document_file_correction">Upload only .xlsx, .xls, .csv files</small>
                          </div>

                          <div class="col-sm-3" id="download_sample_file_corrections" style="text-align: center;margin-top:13px;display:none">
                              <div class="form-group mt-3">
                                  <label class="bs-form-label" for=""></label>
                                  <button (click)="ondownload('corrections')" title="{{downloadbuttontooltip}}" class="btn btn-primary m-3 mt-0" style="background: #fff; border: solid 1px #4E0099; color: #4E0099;" id="downloadbtn"> Download Sample File</button>
                              </div>
                          </div>

                        </div>

                      </div>

                      <div class="col-sm-12" align="center">
                        <button type="button" class="btn btn-primary mt-3" (click)="onSubmitcorrection()" role="button">Save</button>
                      </div>

                    </form>

                  </div>

                </div>

              </div>
              <!-- End -->



            </div>

          <hr>

          <!-- List -->
          <div>
            <table class="table table-sm row-border" width="100%" id="maintable" datatable dt-instance="dtInstance" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="width:5%; position: sticky; left: 0; background-color: #efefef; z-index: 10;">SrNo</th>
                        <th style="position: sticky; left: 5%; background-color: #efefef; z-index: 10;">Endorsement No.</th>
                        <th>Policy No.</th>
                        <th>Policy Name</th>
                        <th>Company</th>
                        <th>Upload Member Status</th>
                        <th>Original Document</th>
                        <th>Failed Document</th>
                        <th>Status</th>
                        <th>Report</th>
                        <!-- <th>Success</th> -->
                        <th>Uploaded At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of Policyuploaddoclist; let i = index">
                        <td style="width:5%; position: sticky; left: 0; background-color: #fff; z-index: 10;">{{i+1}}</td>
                        <td style="position: sticky; left: 5%; background-color: #fff; z-index: 10;">{{group['endorsement_no']}}</td>
                        <td>{{group['policy_number']}}</td>
                        <td>{{group['policy_name']}}</td>
                        <td>{{group['company_name']}}</td>
                        <td>
                          <p style="color: #0382cc; font-size: 13px;">
                            <i class="mdi mdi-account"></i>
                            Total Uploaded: {{group['total_member']}}
                            <span *ngIf="group['total_member'] == 1">Member</span>
                            <span *ngIf="group['total_member'] != 1">Members</span>
                          </p>
                          <p style="color: #32cc03; font-size: 13px;">
                            <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                            <span *ngIf="group['upload_type'] == 'Delete'">Delete</span>
                            <span *ngIf="group['upload_type'] == 'Correction'">Corrected</span>
                            <span *ngIf="group['upload_type'] == 'Add'">Added</span>
                             Successfully: {{group['sucess_member']}}
                            <span *ngIf="group['sucess_member'] == 1">Member</span>
                            <span *ngIf="group['sucess_member'] != 1">Members</span>
                          </p>
                          <p style="color: #f03800; font-size: 13px;" *ngIf="group['fail_member'] >= 1">
                            <i class="mdi mdi-close"></i>
                            Failed To 
                            <span *ngIf="group['upload_type'] == 'Delete'">Delete</span>
                            <span *ngIf="group['upload_type'] == 'Correction'">Correct</span>
                            <span *ngIf="group['upload_type'] == 'Add'">Add</span>
                            : {{group['fail_member']}}
                            <span *ngIf="group['fail_member'] == 1">Member</span>
                            <span *ngIf="group['fail_member'] != 1">Members</span>
                          </p>
                        </td>
                        <td>
                          <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc(group['original_document'])" style="cursor: pointer" />
                        </td>
                        <td>
                          <span *ngIf="group['status'] == 'success' || group['status'] == 'pending'"> - </span>
                          <img src="../../assets/img/common icons/downloaddoc.png" title="Download" (click)="ondownload_doc_failed(group['uploadid'], group['policyid'])" style="cursor: pointer" *ngIf="group['fail_member'] >= 1" />
                        </td>
                        <td>
                          <button class="btn btn-primary savebtn" (click)="generate_endorsment(group)" *ngIf="group['endorsement_status'] == 'Added by Broker'"> Generate Endorsement </button>
                          <button class="btn btn-danger" (click)="generate_endorsment(group)" *ngIf="group['endorsement_status'] != 'Added by Broker'" style="background-color: var(--bs-sidebar); border: solid 1px var(--bs-sidebar)"> {{group['endorsement_status']}} </button>
                        </td>
                        <td>
                          <button class="btn btn-primary savebtn" (click)="goto_reports()"> Endorsement Report </button>
                        </td>
                        <!-- <td>
                          <span *ngIf="group['status'] == 'pending'" class="btn btn-inverse-warning btn-fw">Pending</span>
                          <span *ngIf="group['status'] == 'success'" class="btn btn-inverse-success btn-fw">Success</span>
                          <span *ngIf="group['status'] == 'failed'" class="btn btn-inverse-danger btn-fw">Falied</span>
                        </td> -->
                        <td>{{group['uploaded_at']}}</td>
                    </tr>
                </tbody>
            </table>
          </div>

          <!-- Endorsement Popup -->
          <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':TopUpSectiontoggle}">
            <div class="modal-dialog" role="document" style="margin-top: -50px; max-width: 800px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Endorsement Acknowledgement
                        </h4>
                    </div>
                    <div class="modal-body" style="height: 450px; overflow-y: auto;">

                      <!-- Form -->
                      <div class="row text-center" style="font-size: 16px;">

                        <div class="col-sm-6">
                          <label class="bs-form-label" for="company_name">Company Name</label>
                          <input class="form-control" id="company_name" readonly />
                        </div>

                        <!-- <div class="col-sm-6">
                          <label class="bs-form-label" for="company_code">Company Code</label>
                          <input class="form-control" id="company_code" readonly />
                        </div> -->

                        <div class="col-sm-6">
                          <label class="bs-form-label" for="policy_name">Policy Name</label>
                          <input class="form-control" id="policy_name" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="insurer_policy_no">Insurer Policy No</label>
                          <input class="form-control" id="insurer_policy_no" readonly />
                        </div>
                        
                        <hr class="mt-4">

                        <div class="col-sm-6">
                          <label class="bs-form-label" for="broker">Broker</label>
                          <input class="form-control" id="broker" readonly />
                        </div>

                        <div class="col-sm-6">
                          <label class="bs-form-label" for="broker_branch">Branch</label>
                          <input class="form-control" id="broker_branch" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="insurer">Insurer</label>
                          <input class="form-control" id="insurer" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="insurer_branch">Branch</label>
                          <input class="form-control" id="insurer_branch" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="tpa">TPA</label>
                          <input class="form-control" id="tpa" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="cd_balance">CD Balance</label>
                          <input class="form-control" id="cd_balance" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="endoresement_no">Endorsement No</label>
                          <input class="form-control" id="endoresement_no" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="status">Status</label>
                          <input class="form-control" id="status" readonly />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <label class="bs-form-label" for="insurer_endorsement_no">Insurer Endorsement No</label>
                          <input class="form-control" id="insurer_endorsement_no" [readonly]="statuspopupbtn != 'Added by Broker'" />
                        </div>

                        <div class="col-sm-6 mt-3">
                          <input type="checkbox" class="form-check-input" id="add_CD_entry" name="add_CD_entry" style="margin-top: 38px;">
                          <label class="form-check-label form-label" for="add_CD_entry" style="margin-top: 37px; font-size: 12px;"> &nbsp; Confirm for CD Deduction </label>
                        </div>

                        <input type="hidden" class="form-control" id="policyID" />
                        <input type="hidden" class="form-control" id="uploadID" />

                        <!-- <div class="col-sm-6 mt-3">
                        </div> -->

                        <div class="col-sm-6 mt-3" *ngIf="statuspopupbtn == 'Added by Broker'">
                          <div class="form-group">
                            <label class="bs-form-label" for="popup_document_file">Endorsement Doc Upload</label>
                            <label for="popup_document_file" id="features-file-upload_popup_document_file" class=" form-control" style="text-align:center; cursor:pointer; padding: 5px; padding-top: 10px;">
                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                            </label>
                            <input id="popup_document_file" type="file" (change)="uploadfile_popup($event)" accept=".pdf" enctype="multipart/form-data" style="display: none" />
                          </div>
                          <small style="font-size: 12px; color:#605D62" id="policyfeatures_popup_document_file">Upload only .pdf file</small>
                        </div>

                        <div class="col-sm-6 mt-3">
                          <button class="btn btn-primary savebtn mt-4" style="padding: 12px 12px" (click)="ondowloadlink()" *ngIf="statuspopupbtn == 'Added by Broker'"> Generate Endorsement Request</button>
                        </div>

                      </div>

                      <hr>

                      <!-- Table -->
                      <h5><b>Endorsement Members List</b></h5>

                      <!-- Remove -->
                      <div class="table-responsive">
                        <table class="table" *ngIf="showtable == true">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Employee Code</th>
                                    <th>Name</th>
                                    <th>Relation</th>
                                    <th>Sum Insured</th>
                                    <th>Individual Endorsement No</th>
                                    <th>LWD</th>
                                    <th>Reason</th>
                                    <th>Effective Date</th>
                                    <th>Claims</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let PopupData of PopupDataArray; let k = index">
                                    <td style="width:5%">{{k+1}}</td>
                                    <td>{{PopupData['employee_code']}}</td>
                                    <td>{{PopupData['name']}}</td>
                                    <td>{{PopupData['relation_name']}}</td>
                                    <td>{{PopupData['suminsured']}}</td>
                                    <td>{{PopupData['individual_endo_no']}}</td>
                                    <td>{{PopupData['LWD']}}</td>
                                    <td>{{PopupData['reason_of_deletion']}}</td>
                                    <td>{{PopupData['del_effective_date']}}</td>
                                    <td>{{PopupData['claims']}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="showtable == false">
                              <tr>
                                  <td colspan="5">No data</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- Correction -->
                      <div class="table-responsive">
                        <table class="table" *ngIf="showtableCorrection == true">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Employee Code</th>
                                    <th>Corrected Employee Code</th>
                                    <th>Name</th>
                                    <th>Corrected Name</th>
                                    <th>Relation</th>
                                    <th>Corrected Relation</th>
                                    <th>Date of Birth</th>
                                    <th>Corrected Date of Birth</th>
                                    <th>Sum Insured</th>
                                    <th>Corrected Sum Insured</th>
                                    <th>Individual Endorsement No</th>
                                    <th>Corrected Effective Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let PopupData of PopupDataArrayCorrection; let k = index">
                                    <td style="width:5%">{{k+1}}</td>
                                    <td>{{PopupData['employee_code']}}</td>
                                    <td>{{PopupData['correction_emp_code']}}</td>
                                    <td>{{PopupData['name']}}</td>
                                    <td>{{PopupData['correction_name']}}</td>
                                    <td>{{PopupData['relation_name']}}</td>
                                    <td>{{PopupData['correction_relation']}}</td>
                                    <td>{{PopupData['dob']}}</td>
                                    <td>{{PopupData['correction_dob']}}</td>
                                    <td>{{PopupData['suminsured']}}</td>
                                    <td>{{PopupData['correction_suminsured']}}</td>
                                    <td>{{PopupData['individual_endo_no']}}</td>
                                    <td>{{PopupData['correction_effective_date']}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="showtableCorrection == false">
                              <tr>
                                  <td colspan="5">No data</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- Add -->
                      <div class="table-responsive">
                        <table class="table" *ngIf="showtableAdd == true">
                            <thead>
                                <tr>
                                    <th style="width:5%">SrNo</th>
                                    <th>Employee Code</th>
                                    <th>Name</th>
                                    <th>Relation</th>
                                    <th>Date of Birth</th>
                                    <th>Sum Insured</th>
                                    <th>Individual Endorsement No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let PopupDataadd of PopupDataArrayAdd; let k = index">
                                    <td style="width:5%">{{k+1}}</td>
                                    <td>{{PopupDataadd['employee_code']}}</td>
                                    <td>{{PopupDataadd['name']}}</td>
                                    <td>{{PopupDataadd['relation_name']}}</td>
                                    <td>{{PopupDataadd['dob']}}</td>
                                    <td>{{PopupDataadd['suminsured']}}</td>
                                    <td>{{PopupDataadd['individual_endo_no']}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="showtableAdd == false">
                              <tr>
                                  <td colspan="5">No data</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <div class="modal-footer p-2" style="display: flex;">
                        <button class="btn btn-primary" (click)="submitPopup()" style="float: right;" *ngIf="statuspopupbtn == 'Added by Broker'">Finalize</button>
                        <button (click)="closealert()" class="btn btn-primary" style="float: right;">Close</button>
                    </div>
                </div>
            </div>
          </div>

        </div>
        <app-footer></app-footer>
    </div>
</div>
